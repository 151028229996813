import './visualization.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import { RoleConfig } from '../../types';
import { goalkeeperSeasonTableMetrics, metricToDisplayInfo, outfieldSeasonTableMetrics } from '../../static/playerMetrics';


interface RoleConfigWeightBarProps {
  roleConfig: RoleConfig;
}

export const RoleConfigWeightBar: React.FC<RoleConfigWeightBarProps> = ({
  roleConfig,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openTextModal } = useOpenGlobalModal();

  const [sumOfWeightsOfSelectedRoleConfig, setSumOfWeightsOfSelectedRoleConfig] = useState(0);
  const [sortedWeightsOfSelectedRoleConfig, setSortedWeightsOfSelectedRoleConfig] = useState<[string, number][]>([]);


  useEffect(() => {
    let sumOfWeights = 0;
    const sortedWeights: [string, number][] = [];

    const allMetrics = roleConfig.positionGroup === 'GK' ? goalkeeperSeasonTableMetrics : outfieldSeasonTableMetrics;

    allMetrics.forEach(metric => {
      if (roleConfig.weights[metric]) {

        const value = roleConfig.weights[metric];

        sortedWeights.push([metric, value]);
        sumOfWeights += value;
      }
    });

    setSumOfWeightsOfSelectedRoleConfig(sumOfWeights);
    setSortedWeightsOfSelectedRoleConfig(sortedWeights);
  }, [roleConfig]);


  return (
    <div className='role-config-weight-bar-container'>
      <div className='role-config-weight-bar'>
        {sumOfWeightsOfSelectedRoleConfig > 0 && sortedWeightsOfSelectedRoleConfig.map(([metric, weight], index) => {

          const width = (weight / sumOfWeightsOfSelectedRoleConfig) * 100;

          const verticalLabelOffset = 52;
          const verticalLabelLineOffset = 26;

          const metricName = metricToDisplayInfo[metric].name[userConfig?.language ?? 'en'];
          const metricDescription = metricToDisplayInfo[metric].description[userConfig?.language ?? 'en'];

          return (
            <div
              key={metric}
              className={
                'role-config-weight-bar-segment'
                + (index % 2 === 0
                  ? ' role-config-weight-bar-segment-even'
                  : ' role-config-weight-bar-segment-odd')
              }
              style={{
                width: width + '%',
                borderRadius: index === 0
                  ? '2px 0px 0px 2px'
                  : index === Object.keys(roleConfig.weights).length - 1
                    ? '0px 2px 2px 0px'
                    : undefined,
                borderLeft: index > 0 ? '0.5px solid #91969f' : undefined,
              }}
            >
              <div
                className='role-config-weight-bar-label player-view-metric-label'
                style={{ marginTop: verticalLabelOffset * (index % 2 === 0 ? -1 : 1) }}
                onClick={() => openTextModal(metricName, metricDescription, undefined, true)}
              >
                {metricName}
              </div>

              <div
                className='role-config-weight-bar-label-line'
                style={{ marginTop: verticalLabelLineOffset * (index % 2 === 0 ? -1 : 1) }}
              />
            </div>
          );
        })}

        {sumOfWeightsOfSelectedRoleConfig === 0 && (
          <div className='role-config-weight-bar-segment role-config-weight-bar-segment-empty' />
        )}
      </div>
    </div>
  );
};
