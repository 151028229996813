

type SliderProps = {
  value: number;
  onChange: (newValue: number) => void

  color?: string;
  min?: number;
  max?: number;
  step?: number;
};


export const Slider: React.FC<SliderProps> = ({
  value,
  onChange,

  color = '#7cb5ffdd',
  min = 0,
  max = 10,
  step = 1,
}) => {

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    onChange(newValue);
  };

  const percentage = ((value - min) / (max - min)) * 100;


  const markers = [];
  for (let i = min; i <= max; i += step) {
    const position = ((i - min) / (max - min)) * 100;

    const leftOfCircle = position <= percentage;
    markers.push(
      <div
        key={i}
        className={`slider-marker slider-marker-${leftOfCircle ? 'left' : 'right'}-of-circle`}
        style={{
          left: `${position}%`,
        }}
      />
    );
  }

  return (
    <div className='slider-container'>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleSliderChange}
        style={
          {
            background: `linear-gradient(to right, ${color} ${percentage}%, #ffffff40 ${percentage}%)`,
            '--thumb-color': color
          } as React.CSSProperties
        }
        className='slider'
      />

      <div className='slider-marker-container'>
        {markers}
      </div>
    </div>
  );
};
