import '../../platform.css';
import './settings.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { Account } from './Account';
import { ClubSettings } from './ClubSettings';
import { Support } from './Support';
import { RoleConfigs } from './RoleConfigs';


export const Settings: React.FC = () => {

  const navigationProps = useRecoilValue(navigationPropsState);
  const trackEvent = useTrackEvent();

  const initialActiveTab = navigationProps?.activeSubTab ?? 0;
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const tabOptions = [
    'account',
    'club',
    'roles',
    'help',
  ];

  const tabIcons = [
    <AccountCircle key={'account-icon'} style={{ fontSize: 22 }} />,
    <GroupsIcon key={'groups-icon'} style={{ fontSize: 22 }} />,
    <InsertChartOutlinedIcon key={'location-icon'} style={{ fontSize: 22 }} />,
    <HelpIcon key={'help-icon'} style={{ fontSize: 22 }} />
  ];

  const handleSetActiveTab = (index: number) => {
    if (index !== activeTab) {
      trackEvent('SubTabClicked', { parentTab: 'settings', tab: tabOptions[index] });
    }

    setActiveTab(index);
  };


  return (
    <div className='platform-view-section'>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        initialActiveTab={initialActiveTab}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && <Account />}

      {activeTab === 1 && <ClubSettings />}

      {activeTab === 2 && <RoleConfigs />}

      {activeTab === 3 && <Support />}

    </div>
  );
};
