import '../../platform.css';
import './../../components/documents/documents.css';
import './archive.css';

import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useGetAndAddPlayerOverview } from '../../recoil/hooks/useGetAndAddPlayerOverview';
import { getNewPlayerOverviewsForArchivedTeams } from '../../services/server/application/playerOverviews';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { getAllReports } from '../../services/firestore/reports';
import { ExistingReports } from '../../components/existingDocuments/ExistingReports';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { Report } from '../../components/documents/report/Report';
import { getAllArchivedTeams, updateArchivedTeamIsDeletedStatus } from '../../services/firestore/teamsOrSquads';
import { StringToAnyMap, PlayerId, PlayerOverview, PlayerOverviews, PlayerReport } from '../../types';
import { ExistingArchivedTeams } from '../../components/existingDocuments/ExistingArchivedTeams';
import { TeamView } from '../../components/teamView/TeamView';
import { ReportStatistics } from './ReportStatistics';


export const Archive = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const getAndAddPlayerOverview = useGetAndAddPlayerOverview();

  const [additionalPlayerOverviews, setAdditionalPlayerOverviews] = useState<PlayerOverviews>({});

  const [selectedUserEmail, setSelectedUserEmail] = useState<string>('');

  const [allReports, setAllReports] = useState<PlayerReport[]>([]);
  const [reportsToShow, setReportsToShow] = useState<PlayerReport[]>([]);
  const [isReportsLoading, setIsReportsLoading] = useState<boolean>(true);
  const [existingReportSelected, setExistingReportSelected] = useState<PlayerReport | undefined>(undefined);
  const [reportHasUnsavedChanges, setReportHasUnsavedChanges] = useState<boolean>(false);

  const [archivedTeams, setArchivedTeams] = useState<StringToAnyMap[]>([]);
  const [isArchivedTeamsLoading, setIsArchivedTeamsLoading] = useState<boolean>(true);
  const [archivedTeamSelected, setArchivedTeamSelected] = useState<StringToAnyMap | undefined>(undefined);

  const [selectedPlayerOverview, setSelectedPlayerOverview] = useState<PlayerOverview | undefined>(undefined);
  const [isLoadingSelectedPlayerOverview, setIsLoadingSelectedPlayerOverview] = useState<boolean>(false);


  const [activeTab, setActiveTab] = useState(0);

  const tabOptions = [
    'reports',
    'archivedTeams',
  ];
  const tabIcons = [
    <FolderSharedIcon key={'folder-shared-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <FolderSpecialIcon key={'folder-special-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
  ];


  const handleSetSelectedPlayerOverview = async (playerId: PlayerId) => {

    setSelectedPlayerOverview(undefined);

    const numberId = Number(playerId);

    if (isNaN(numberId)) {
      return;
    }

    if (playerOverviews[numberId]) {
      setSelectedPlayerOverview(playerOverviews[numberId]);
    }
    else if (additionalPlayerOverviews[numberId]) {
      setSelectedPlayerOverview(additionalPlayerOverviews[numberId]);
    }
    else {
      setIsLoadingSelectedPlayerOverview(true);
      const playerOverview = await getAndAddPlayerOverview(numberId, currentUser);
      setSelectedPlayerOverview(playerOverview);
      setIsLoadingSelectedPlayerOverview(false);
    }
  };


  const openConfirmLeaveReportModal = (action: () => void) => {
    const reportType = existingReportSelected?.reportType;
    openConfirmModal(
      action,
      reportType === 'Comment' ? 'exitComment?' : reportType === 'Attachment' ? 'exitAttachment?' : 'exitReport?',
      reportType === 'Comment' ? 'exitCommentInfo' : reportType === 'Attachment' ? 'exitAttachmentInfo' : 'exitReportInfo'
    );
  };


  const handleTabClick = (tabIndex: number) => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(() => handleSetActiveTab(tabIndex));
    }
    else {
      handleSetActiveTab(tabIndex);
    }
  };


  const handleSetActiveTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
    setExistingReportSelected(undefined);
    setArchivedTeamSelected(undefined);
    setSelectedPlayerOverview(undefined);
    setReportHasUnsavedChanges(false);
  };


  const handleReportCloseIconClick = () => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(handleCloseReport);
    }
    else {
      handleCloseReport();
    }
  };


  const handleCloseReport = () => {
    setExistingReportSelected(undefined);
    setSelectedPlayerOverview(undefined);
    setReportHasUnsavedChanges(false);
  };


  const handleExistingReportClicked = (report: PlayerReport) => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(() => handleSelectReport(report));
    }
    else {
      handleSelectReport(report);
    }
  };


  const handleSelectReport = (report: PlayerReport) => {
    setExistingReportSelected(report);
    handleSetSelectedPlayerOverview(report.playerId);
    setReportHasUnsavedChanges(false);
  };


  const updateReport = (report: PlayerReport) => {
    let reportWasDeleted = false;

    const updatedReports = allReports.map((existingReport: PlayerReport) => {
      if (existingReport.id === report.id) {

        if (!existingReport.isDeleted && report.isDeleted) {
          reportWasDeleted = true;
        }

        return report;
      }

      return existingReport;
    });

    setAllReports(updatedReports);

    if (reportWasDeleted) {
      setExistingReportSelected(undefined);
    }
    else {
      setExistingReportSelected(report);
    }

    setReportHasUnsavedChanges(false);
  };


  const deleteOrRestoreArchivedTeam = async () => {
    if (!userConfig || !archivedTeamSelected) return;

    const archivedTeamUpdated = await updateArchivedTeamIsDeletedStatus(archivedTeamSelected['id'], !archivedTeamSelected['isDeleted'], userConfig.club);
    if (archivedTeamUpdated) {
      setArchivedTeams(archivedTeams.map(team => {
        if (team.id === archivedTeamSelected['id']) {
          team['isDeleted'] = !team['isDeleted'];
        }
        return team;
      }));
    }

    if (archivedTeamSelected['isDeleted']) {
      setArchivedTeamSelected(undefined);
    }
  };


  useEffect(() => {
    if (userConfig?.club) {
      getAllReports(userConfig.club).then((allReports: PlayerReport[]) => {
        setAllReports(allReports);
        setIsReportsLoading(false);
      });

      getAllArchivedTeams(userConfig.club).then((allArchivedTeams: StringToAnyMap[]) => {
        setArchivedTeams(allArchivedTeams);
        setIsArchivedTeamsLoading(false);
      });
    }
  }, [userConfig?.club]);


  useEffect(() => {
    if (!isArchivedTeamsLoading && archivedTeams.length > 0 && playerOverviews) {
      getNewPlayerOverviewsForArchivedTeams(archivedTeams, playerOverviews, currentUser).then((newPlayerOverviews: PlayerOverviews) => {
        setAdditionalPlayerOverviews(newPlayerOverviews);
      });
    }
  }, [archivedTeams, currentUser, isArchivedTeamsLoading, playerOverviews]);


  useEffect(() => {
    if (allReports.length > 0) {
      if (selectedUserEmail === 'all') {
        setReportsToShow(allReports);
      }
      else {
        setReportsToShow(allReports.filter(report => report.userEmail === selectedUserEmail));
      }
    }
  }, [allReports, selectedUserEmail]);


  return (
    <div className='platform-view-section platform-view-section-dark'>

      {!archivedTeamSelected && (
        <div className='player-view-reports-right-section-background'>
          <OwnTeamBackgoundSvg />
        </div>
      )}

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleTabClick}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && (
        <div className='archive-view-container'>
          {!isReportsLoading && (
            <div className='archive-existing-reports-container fade-in'>
              <ExistingReports
                reports={reportsToShow}
                existingReportSelected={existingReportSelected}
                handleExistingReportClicked={handleExistingReportClicked}
                isPlayerView={false}
              />
            </div>
          )}
          <div className='archive-report-container'>
            {existingReportSelected && (
              <Report
                newReportTypeSelected={undefined}
                existingReportSelected={existingReportSelected}

                reportHasUnsavedChanges={reportHasUnsavedChanges}
                setReportHasUnsavedChanges={setReportHasUnsavedChanges}

                handleCloseIconClick={handleReportCloseIconClick}
                addOrUpdateReport={updateReport}

                playerOverview={selectedPlayerOverview}
                playerEntry={{ id: existingReportSelected.playerId, fullname: existingReportSelected.playerName }}

                isArchiveView={true}
                isLoadingSelectedPlayerOverview={isLoadingSelectedPlayerOverview}
              />
            )}

            {!existingReportSelected && !isReportsLoading && (
              <ReportStatistics
                reports={reportsToShow}
                selectedUserEmail={selectedUserEmail}
                setSelectedUserEmail={setSelectedUserEmail} />
            )}
          </div>
        </div>
      )}


      {activeTab === 1 && !isArchivedTeamsLoading && (
        <div className='archive-view-container'>
          <div className='archive-archived-teams-container fade-in'>
            <ExistingArchivedTeams
              archivedTeams={archivedTeams}
              archivedTeamSelected={archivedTeamSelected}
              handleArchivedTeamClicked={setArchivedTeamSelected}
            />
          </div>
          {archivedTeamSelected && (
            <div className='logistic-team-view-section archived-team-view-section'>
              <TeamView
                teamOrSquadId={'archivedTeam'}
                teamOrSquadData={archivedTeamSelected}
                isArchivedTeamDeleted={archivedTeamSelected['isDeleted']}
                closeArchivedTeam={() => setArchivedTeamSelected(undefined)}
                deleteOrRestoreArchivedTeam={deleteOrRestoreArchivedTeam}
                additionalPlayerOverviews={additionalPlayerOverviews}
              />
            </div>
          )}
        </div>
      )}

    </div>
  );
};
