import { StringToNumberMap, StringToStringArrayMap } from '../types';


export const staticTeamKeys: string[] = [
  'masterTeam',
  'shadowTeam',
  'ownTeam',
  'academyTeam',
];


export const roleKeysToSortOrder: StringToNumberMap = {
  'keyPlayer': 30,
  'rotationPlayer': 20,
  'loanedIn': 18,
  'developmentPlayer': 16,
  'reserve': 14,
  'longTermInjured': 12,
  'loanedOut': 10,
};


export const academyRoleKeysToSortOrder: StringToNumberMap = {
  'trialist': 6,
  'talent': 4,
  'potential': 2,
};


export const localityKeysToSortOrder: StringToNumberMap = {
  'local': 5,
  'national': 4,
  'international': 3,
};


export const accessKeysToSortOrder: StringToNumberMap = {
  'admin': 6,
  'full': 5,
  'economy': 4,
  'scout': 3,
  'none': 2,
};


export const editAccessOptions: string[] = [
  'full',
  'economy',
  'scout',
  'none',
];


export const editFokusAccessOptions: string[] = [
  'admin',
  'full',
  'economy',
  'scout',
];


export const newAccessOptions: string[] = [
  'full',
  'economy',
  'scout',
];


export const positionOptionsPlatform: string[] = [
  'LS',
  'S',
  'RS',
  'LM',
  'LW',
  'RW',
  'RM',
  'CAM',
  'LCM',
  'RCM',
  'CDM',
  'LWB',
  'LB',
  'LCB',
  'CB',
  'RCB',
  'RB',
  'RWB',
  'GK',
];


export const positionGroupToPositions: StringToStringArrayMap = {
  'S': ['S'],
  'W': ['LW', 'RW', 'CAM'],
  'CM': ['CM', 'CDM'],
  'FB': ['LB', 'RB'],
  'CB': ['CB'],
  'GK': ['GK'],
};


export const formationOptions: string[] = [
  '433',
  '442',
  '4312',
  '4231',
  '4141',
  '4411',
  '343',
  '3421',
  '541',
  '523',
  '532'
];


export const formationToPositionOptions: StringToStringArrayMap = {

  '433': [
    'S',
    'LW',
    'RW',
    'LCM',
    'RCM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '442': [
    'LS',
    'RS',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4312': [
    'LS',
    'RS',
    'CAM',
    'LCM',
    'RCM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4231': [
    'S',
    'LW',
    'RW',
    'CAM',
    'LCM',
    'RCM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4141': [
    'S',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4411': [
    'S',
    'CAM',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '343': [
    'S',
    'LW',
    'RW',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LCB',
    'CB',
    'RCB',
    'GK'
  ],

  '3421': [
    'S',
    'LS',
    'RS',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LCB',
    'CB',
    'RCB',
    'GK'
  ],

  '541': [
    'S',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ],

  '523': [
    'S',
    'LW',
    'RW',
    'LCM',
    'RCM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ],

  '532': [
    'LS',
    'RS',
    'LCM',
    'RCM',
    'CDM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ]
};


export const contractKeys: string[] = [
  'under6months',
  'under12months',
  'under18months',
  'under2years',
  'under3years',
  'under4years',
];


export const contractKeysToMonths: StringToNumberMap = {
  'freeAgent': 0,
  'under6months': 6,
  'under12months': 12,
  'under18months': 18,
  'under2years': 24,
  'under3years': 36,
  'under4years': 48,
};


export const ageKeys: string[] = [
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
];


export const minConfidenceKeys: string[] = [
  '5',
  '10',
  '20',
  '30',
  '50',
  '70',
  '90',
];


export const indexKeys: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10'
];


export const playingTimeKeys: string[] = [
  'KEY',
  'ROTATION',
  'RESERVE',
  'UNKNOWN',
];


export const formKeys: string[] = [
  'IN_FORM',
  'NORMAL_FORM',
  'OUT_OF_FORM',
  'NO_FORM',
];


export const minuteAbsoluteKeys: string[] = [
  '200',
  '600',
  '1000',
  '1500',
  '2000'
];


export const minutePercentageKeys: string[] = [
  '20%',
  '40%',
  '60%',
  '80%',
  '100%'
];


export const clauseCurrencyKeys: string[] = [
  'NOK',
  'SEK',
  'DKK',
  'EUR',
  'GBP',
  'USD',
];
