import '../../platform.css';
import './searchPlayers.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { StringToAnyMap, PlayerOverview, PlayerOverviewsQueryOptions } from '../../types';

import { translate } from '../../../common/language/translations';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { InputField } from '../input/InputField';
import { debounce } from '../../utils/utils';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../services/server/application/playerOverviews';
import { playerSimpleTablePageSize } from '../../views/scouting/Scouting';


interface SelectPlayerViewProps {
  onPlayerClick: (player: StringToAnyMap) => void;
  titleKey: string;
  maxTableHeight: string;
  isGoalkeeper?: boolean;
  disablePlayersWithoutEventData?: boolean;

  setIsPlayerSearchActive?: (value: boolean) => void;

  marginTop?: string;
  marginBottom?: string;
}

export const SelectPlayerView: React.FC<SelectPlayerViewProps> = ({
  onPlayerClick,
  titleKey,
  maxTableHeight,
  isGoalkeeper,
  disablePlayersWithoutEventData,
  setIsPlayerSearchActive,
  marginTop,
  marginBottom,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const [searchString, setSearchString] = useState<string>('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [activeSearchString, setActiveSearchString] = useState('');
  const [shouldShowSearchResult, setShouldShowSearchResult] = useState(false);
  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);

  const [tableData, setTableData] = useState<PlayerOverview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForPlayers(true, true);
    }
  };


  const setEmptyTableData = () => {
    setTableData([]);
    setCurrentPage(0);
    setTotalHits(0);
  };


  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    if (searchString !== '') {

      const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;
      const querySearchString = searchString;
      const queryOptions: PlayerOverviewsQueryOptions = {
        name: querySearchString.toLowerCase(),
        page: nextPageToQuery,
        pageSize: playerSimpleTablePageSize,
      };

      try {
        const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(queryOptions, currentUser);
        setActiveSearchString(querySearchString);

        const totalHits = result?.total_hits;
        const page = result?.current_page;
        const players: PlayerOverview[] = result?.players ?? [];

        if (!result || totalHits === undefined || page === undefined) {
          setEmptyTableData();
          console.log('Invalid response for searchPlayerOverviews with option: ', queryOptions); // eslint-disable-line no-console
        }
        else if (totalHits === 0 || (isNewSearch && players.length === 0)) {
          setIsSearchWithoutResult(true);
          setEmptyTableData();
        }
        else {
          setIsSearchWithoutResult(false);
          setTableData(isNewSearch ? players : [...tableData, ...players]);
          setCurrentPage(page);
          setTotalHits(totalHits);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }

    setIsLoading(false);
  };


  const resetSearch = () => {
    setDebouncedSearchString('');
    setShouldShowSearchResult(false);
    setEmptyTableData();
    setIsSearchWithoutResult(false);

    if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(false);
    }
  };


  useEffect(() => {
    if (searchString.length === 0) {
      resetSearch();
    }
    else if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(true);
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (searchString !== debouncedSearchString) {
        setDebouncedSearchString(searchString);
      }
    }, 500);

    debounceFilter();

    clearDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString !== '' && searchString === activeSearchString) {
      setShouldShowSearchResult(true);
    }
  }, [searchString, activeSearchString]);


  return (
    <div className='select-player-view-container'>

      <div
        className='select-player-view-title'
        style={{ marginTop: marginTop ?? '2vh', marginBottom: marginBottom ?? '2vh' }}
      >
        {translate(titleKey, userConfig?.language)}
      </div>

      <div className='select-player-view-input-field-container'>
        <InputField
          searchString={searchString}
          onChangeInputField={onChangeSearchField}
          onKeyDownInputField={onKeyDownSearchField}
          resetSearchString={() => setSearchString('')}
          defaultInput={translate('searchForName', userConfig?.language)}
          showDefaultInput={true}
          style={{ boxShadow: '0px 0px 4px 2px #0e101324' }}
        />
      </div>

      <div className='select-player-view-result-section'>

        {shouldShowSearchResult && isSearchWithoutResult && (
          <div className=''>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {shouldShowSearchResult && !isSearchWithoutResult && (
          <div className='select-player-view-table'>
            <PlayerSimpleTable
              data={tableData}
              onPlayerClick={onPlayerClick}
              tableType={'addPlayerView'}
              maxHeight={maxTableHeight}

              isSearchResultTable={true}
              isLoading={isLoading}
              handleSearchForPlayers={handleSearchForPlayers}
              currentPage={currentPage}
              totalHits={totalHits}

              disablePlayersWithoutEventData={disablePlayersWithoutEventData}
              isGoalkeeper={isGoalkeeper}
            />
          </div>
        )}

      </div>

    </div>
  );
};
