import { StringToAnyMap, PlayerReport, StringToBooleanMap, StringToNumberMap } from '../../../types';
import { getLatestReportVersion, reportVersionToScoreKeys } from './reportVersions';


export interface ReportState {
  id: string | undefined;
  reportVersion: string;
  reportType: string;

  comment: string;
  matchReportInfo: StringToAnyMap | undefined;

  scores: StringToNumberMap;

  fileUrls: string[];
  fileNames: string[];

  additionalReportState: {
    requiredFieldsToShow: StringToBooleanMap;
    isEditMode: boolean;
    isEditHistoryModalOpen: boolean;
    uploadedFiles?: File[]; // all files (or new files added in this edit session) that need to be uploaded to storage
    activePreviousEditIndex?: number; // index of the previous edit that is currently being shown
  };
}


const initializeState = (
  newReportTypeSelected: string | undefined,
  existingReportSelected: PlayerReport | undefined,
  club: string | undefined,
  clubName: string | undefined,
): ReportState => {

  const reportVersion = existingReportSelected?.reportVersion ?? getLatestReportVersion(club);
  const reportType = newReportTypeSelected ?? existingReportSelected?.reportType ?? '';

  return {
    id: existingReportSelected?.id,
    reportVersion: reportVersion,
    reportType: reportType,

    comment: existingReportSelected?.reportFields?.comment ?? '',
    matchReportInfo: initializeMatchReportInfo(existingReportSelected?.reportFields ?? {}, reportType, clubName),

    scores: initializeScores(existingReportSelected?.reportFields ?? {}, reportVersion),

    fileUrls: existingReportSelected?.reportFields?.fileUrls ?? [],
    fileNames: existingReportSelected?.reportFields?.fileNames ?? [],

    additionalReportState: {
      isEditMode: false,
      isEditHistoryModalOpen: false,
      requiredFieldsToShow: {},
    },
  };
};


const initializeMatchReportInfo = (
  reportFields: StringToAnyMap,
  reportType: string,
  clubName: string | undefined
): StringToAnyMap | undefined => {

  if (reportType !== 'MatchReport') return undefined;

  return {
    team: reportFields.team ?? clubName ?? '',
    opponent: reportFields?.opponent ?? '',
    matchDate: reportFields?.matchDate ?? new Date().toISOString().split('T')[0],
  };
};


const initializeScores = (
  reportFields: StringToAnyMap,
  reportVersion: string,
): StringToAnyMap => {

  const scoreKeys = reportVersionToScoreKeys[reportVersion];
  const scores: StringToNumberMap = {};

  scoreKeys.forEach(scoreKey => {
    scores[scoreKey] = reportFields[scoreKey] ?? 0;
  });

  return scores;
};


export const getStateForPreviousEdit = (
  state: ReportState,
  reportFields: StringToAnyMap,
): ReportState => {

  return {
    ...state,
    comment: reportFields.comment ?? '',
    matchReportInfo: initializeMatchReportInfo(reportFields, state.reportType, undefined),
    scores: initializeScores(reportFields, state.reportVersion),
    fileUrls: reportFields.fileUrls ?? [],
    fileNames: reportFields.fileNames ?? [],
  };
};


export default initializeState;
