import '../../../platform.css';
import './../scouting.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { playerOverviewsState } from '../../../recoil/atoms/playerOverviewsState';
import { useTrackEvent } from '../../../services/server/analytics/useTrackEvent';

import SearchIcon from '@mui/icons-material/Search';

import {
  Iteration,
  PlayerOverviews,
  RatingRequirement,
  PositionGroupClubIterationsQueryOptions,
  PositionGroupClubIteration
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { searchPositionGroupClubIterations, SearchPositionGroupClubIterationsResponse } from '../../../services/server/application/positionGroupClubIterations';
import { SeasonScoutingFilters } from './SeasonScoutingFilters';
import { stringArraysContainSameElements } from '../../../utils/utils';
import { PlayerSeasonTable } from '../../../components/tables/playerSeasonTable/PlayerSeasonTable';
import { getPlayerOverviews } from '../../../services/server/application/playerOverviews';
import { commonSeasonTableMetrics, getNormalizedMetricName } from '../../../static/playerMetrics';
import { contractKeysToMonths } from '../../../static/propertyValues';
import { playerSeasonTableChapterSize, playerSeasonTablePageSize } from '../Scouting';


export const SeasonScouting: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const trackEvent = useTrackEvent();

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const [tableData, setTableData] = useState<PositionGroupClubIteration[]>([]);
  const [playerIdsOnCurrentPage, setPlayerIdsOnCurrentPage] = useState<number[]>([]);
  const [additionalPlayerOverviews, setAdditionalPlayerOverviews] = useState<PlayerOverviews>({});

  // currentModuloPage equals page % pagesPerChapter and is 1-indexed, while chapters are 0-indexed
  const [currentModuloPage, setCurrentModuloPage] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [selectedMinutes, setSelectedMinutes] = useState<string[]>([]);
  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedIterations, setSelectedIterations] = useState<Iteration[]>([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState<string[]>(['skill_rating']);

  const [selectedRatings, setSelectedRatings] = useState<RatingRequirement[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<string[]>([]);
  const [isContractStatusToggled, setIsContractStatusToggled] = useState(false);

  const [currentMinutes, setCurrentMinutes] = useState<string[]>([]);
  const [currentMaxAge, setCurrentMaxAge] = useState<string[]>([]);
  const [currentPositions, setCurrentPositions] = useState<string[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [currentIterations, setCurrentIterations] = useState<Iteration[]>([]);
  const [currentOrderBy, setCurrentOrderBy] = useState<string[]>([]);
  const [currentOrderByToggle, setCurrentOrderByToggle] = useState(userSettings?.seasonStatsToggles?.orderBy ?? false);

  const [currentRatings, setCurrentRatings] = useState<RatingRequirement[]>([]);
  const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
  const [currentContractStatus, setCurrentContractStatus] = useState<string[]>([]);
  const [currentIsContractStatusToggled, setCurrentIsContractStatusToggled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  const [isAnyFilterExpanded, setIsAnyFilterExpanded] = useState(false);
  const [isAdvancedFiltersExpanded, setIsAdvancedFiltersExpanded] = useState(false);
  const [scoutingViewResultContainerTop, setScoutingViewResultContainerTop] = useState(85);

  const [searchWasLoaded, setSearchWasLoaded] = useState(false);


  const setCurrentFilters = (
    reset: boolean,
    newOverriddenOrderBy?: string | undefined,
    newOverriddenRatings?: RatingRequirement[] | undefined
  ) => {
    setCurrentMinutes(reset ? [] : selectedMinutes);
    setCurrentMaxAge(reset ? [] : selectedMaxAge);
    setCurrentPositions(reset ? [] : selectedPositions);
    setCurrentRoles(reset ? [] : selectedRoles);
    setCurrentIterations(reset ? [] : selectedIterations);
    setCurrentOrderBy(reset ? [] : newOverriddenOrderBy ? [newOverriddenOrderBy] : selectedOrderBy);
    setCurrentOrderByToggle(userSettings?.seasonStatsToggles?.orderBy ?? currentOrderByToggle);

    setCurrentRatings(reset ? [] : newOverriddenRatings ?? selectedRatings);
    setCurrentNationalities(reset ? [] : selectedNationalities);
    setCurrentContractStatus(reset ? [] : selectedContractStatus);
    setCurrentIsContractStatusToggled(reset ? false : isContractStatusToggled);
  };


  const handleTrackEvent = (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    searchQueryOptions: PositionGroupClubIterationsQueryOptions,
  ) => {

    const initiator = nextChapter
      ? 'pagination'
      : !isNewSearch
        ? 'scroll' // not currently possible for season search
        : isUserInitiated
          ? 'user'
          : 'changes';

    // orderBy and includeUnknownContracts are not included in the filtersUsed array
    // positions is only counted if either no roles are selected or not all positions of the selected positionGroup are selected
    const filtersUsed: string[] = [];
    if (searchQueryOptions.minMinutesPlayed || searchQueryOptions.minMinutesPlayedPercentage) { filtersUsed.push('minMinutes'); }
    if (searchQueryOptions.maxAge) { filtersUsed.push('maxAge'); }
    if (searchQueryOptions.positionGroup) { filtersUsed.push('roles'); }
    if (searchQueryOptions.primaryPositions) { filtersUsed.push('primaryPositions'); }
    if (searchQueryOptions.iterations) { filtersUsed.push('iterations'); }
    if (searchQueryOptions.ratingRequirements) { filtersUsed.push('ratings'); }
    if (searchQueryOptions.countryCodes) { filtersUsed.push('nationalities'); }
    if (searchQueryOptions.maxContractLength) { filtersUsed.push('maxContract'); }

    if (filtersUsed.length === 0) { filtersUsed.push('noFilters'); }

    trackEvent('SeasonSearch', {
      initiator: initiator,
      isNewSearch: isNewSearch,
      filtersUsed: filtersUsed,

      minMinutesPlayed: searchQueryOptions.minMinutesPlayed,
      minMinutesPlayedPercentage: searchQueryOptions.minMinutesPlayedPercentage,
      minMinutes: selectedMinutes.length > 0 ? selectedMinutes[0] : undefined,

      maxAge: searchQueryOptions.maxAge,
      primaryPositions: searchQueryOptions.primaryPositions,
      positionGroup: searchQueryOptions.positionGroup,
      iterations: searchQueryOptions.iterations,
      competitions: selectedIterations.length > 0
        ? selectedIterations.map(iteration => iteration.competitionId)
        : undefined,
      seasons: selectedIterations.length > 0
        ? selectedIterations.map(iteration => iteration.season)
        : undefined,

      ratingRequirements: searchQueryOptions.ratingRequirements,
      countryCodes: searchQueryOptions.countryCodes,
      maxContractLength: searchQueryOptions.maxContractLength,
      includeUnknownContracts: searchQueryOptions.includeUnknownContracts ?? false,

      sortByActualValues: searchQueryOptions.sortByActualValues ?? false,
      sortBy: searchQueryOptions.sortBy && clubSettings?.roleConfigs[searchQueryOptions.sortBy]
        ? 'role_rating'
        : searchQueryOptions.sortBy,
    });
  };


  const handleSearchButtonPressed = async (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter?: number,
    newOverriddenOrderBy?: string | undefined,
    newOverriddenRatings?: RatingRequirement[] | undefined,
  ) => {

    setIsLoading(true);

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * playerSeasonTableChapterSize) + 1)
        : ((currentChapter * playerSeasonTableChapterSize) + (currentModuloPage + 1)));

    const sortBy = newOverriddenOrderBy ??
      (selectedOrderBy.length > 0
        ? getNormalizedMetricName(selectedOrderBy[0])
        : undefined);

    const positionGroupOfSelectedRoles = selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : null;

    const queryOptions: PositionGroupClubIterationsQueryOptions = {
      minMinutesPlayed: selectedMinutes.length > 0
        ? String(selectedMinutes[0]).includes('%') ? undefined : parseInt(selectedMinutes[0])
        : undefined,
      minMinutesPlayedPercentage: selectedMinutes.length > 0
        ? String(selectedMinutes[0]).includes('%') ? (parseInt(selectedMinutes[0].replace('%', '')) / 100) : undefined
        : undefined,
      maxAge: selectedMaxAge.length > 0 ? parseInt(selectedMaxAge[0]) : undefined,
      primaryPositions: selectedPositions.length > 0 ? selectedPositions : undefined,
      positionGroup: positionGroupOfSelectedRoles,
      iterations: selectedIterations.length > 0 ? selectedIterations.map(iteration => iteration.iterationId) : undefined,

      ratingRequirements: newOverriddenRatings ??
        (selectedRatings.length > 0
          ? selectedRatings
          : undefined),
      countryCodes: selectedNationalities.length > 0 ? selectedNationalities : undefined,
      maxContractLength: selectedContractStatus.length > 0 ? contractKeysToMonths[selectedContractStatus[0]] : undefined,
      includeUnknownContracts: isContractStatusToggled,

      page: nextPageToQuery,
      pageSize: playerSeasonTablePageSize,
      sortByActualValues: userSettings?.seasonStatsToggles?.orderBy ?? false,
      sortBy: sortBy,
    };

    try {
      handleTrackEvent(isUserInitiated, isNewSearch, nextChapter, queryOptions);
      const result: SearchPositionGroupClubIterationsResponse | undefined = await searchPositionGroupClubIterations(currentUser, queryOptions);

      const totalHits = result?.total_hits;
      const page = result?.current_page;
      const players: PositionGroupClubIteration[] = result?.players ?? [];

      if (!result || totalHits === undefined || page === undefined) {
        trackEvent('SeasonSearchError', queryOptions);
        handleSearchReset();
        setIsLoading(false);
        return;
      }

      if (isNewSearch) { trackEvent('SeasonSearchSuccessResult', { totalHits: totalHits }); }
      setCurrentFilters(false, newOverriddenOrderBy, newOverriddenRatings);

      if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
        setEmptySearchData();
        setIsEmptySearchResult(true);
      }
      else {
        const isFirstPageOfChapter = isNewSearch || nextChapter !== undefined;

        setPlayerIdsOnCurrentPage(players.map(player => player.player_id));

        setIsEmptySearchResult(false);
        setTableData(isFirstPageOfChapter ? players : [...tableData, ...players]);
        setTotalHits(totalHits);
        setCurrentModuloPage(isFirstPageOfChapter ? 1 : (page - (currentChapter * 4)));
        setCurrentChapter(isNewSearch ? 0 : (nextChapter !== undefined ? nextChapter : currentChapter));
      }
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    setIsLoading(false);
  };


  // we automatically fetch player overviews for every player in view to have the data ready when players are clicked
  useEffect(() => {
    if (playerIdsOnCurrentPage.length > 0) {
      const playerIdsToQuery = playerIdsOnCurrentPage
        .filter(playerId => !(
          isNaN(Number(playerId)) ||
          playerOverviews[playerId] ||
          additionalPlayerOverviews[playerId]
        ))
        .map(playerId => Number(playerId));

      if (playerIdsToQuery.length > 0) {
        getPlayerOverviews(playerIdsToQuery, currentUser).then((result) => {
          if (result) {
            setAdditionalPlayerOverviews({ ...additionalPlayerOverviews, ...result });
          }
        });
      }
    }
    setSearchWasLoaded(false);
  }, [currentUser, playerIdsOnCurrentPage]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    setTableData([]);
    const nextChapter = isIncrement ? currentChapter + 1 : currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  const handleSearchReset = () => {
    setEmptySearchData();
    setCurrentFilters(true);
    setIsEmptySearchResult(false);
  };


  const setEmptySearchData = () => {
    setAdditionalPlayerOverviews({});
    setTableData([]);
    setCurrentModuloPage(0);
    setCurrentChapter(0);
    setTotalHits(0);
  };


  const handleAdvancedFiltersExpansion = () => {
    if (isAdvancedFiltersExpanded) {
      setScoutingViewResultContainerTop(85);
      setIsAdvancedFiltersExpanded(false);
    }
    else {
      setScoutingViewResultContainerTop(155);
      setIsAdvancedFiltersExpanded(true);
    }
  };


  const handleFilterUnexpansion = (filter: string) => {

    if (tableData.length === 0 && !isEmptySearchResult) return;

    let shouldTriggerSearch = false;
    let rolesHaveChanged = false;
    let positionsHaveChanged = false;

    switch (filter) {
      case 'positionsAndRoles':
        rolesHaveChanged = !stringArraysContainSameElements(currentRoles, selectedRoles);
        positionsHaveChanged = !stringArraysContainSameElements(currentPositions, selectedPositions);
        shouldTriggerSearch = rolesHaveChanged || positionsHaveChanged;
        break;

      case 'minutes':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinutes, selectedMinutes);
        break;

      case 'maxAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMaxAge, selectedMaxAge);
        break;

      case 'iterations':
        shouldTriggerSearch = !stringArraysContainSameElements(
          currentIterations.map(iteration => String(iteration.iterationId)), selectedIterations.map(iteration => String(iteration.iterationId))
        );
        break;

      case 'orderBy':
        shouldTriggerSearch = !stringArraysContainSameElements(currentOrderBy, selectedOrderBy)
          || currentOrderByToggle !== (userSettings?.seasonStatsToggles?.orderBy ?? false);
        break;

      case 'ratings':
        shouldTriggerSearch = !stringArraysContainSameElements(
          currentRatings.map(rating => rating.metric + '_' + rating.value), selectedRatings.map(rating => rating.metric + '_' + rating.value)
        );
        break;

      case 'nationalities':
        shouldTriggerSearch = !stringArraysContainSameElements(currentNationalities, selectedNationalities);
        break;

      case 'contractStatus':
        shouldTriggerSearch = !stringArraysContainSameElements(currentContractStatus, selectedContractStatus)
          || currentIsContractStatusToggled !== isContractStatusToggled;
        break;
    }

    let newOverriddenOrderBy: string | undefined = undefined;
    let newOverriddenRatings: RatingRequirement[] | undefined = undefined;

    if (positionsHaveChanged) {
      if (selectedPositions.includes('GK') !== currentPositions.includes('GK')) {
        if (selectedOrderBy.length > 0 && !commonSeasonTableMetrics.includes(selectedOrderBy[0])) {
          newOverriddenOrderBy = 'skill_rating';
        }
        newOverriddenRatings = selectedRatings.filter(rating => (rating.metric === 'skill_rating' || commonSeasonTableMetrics.includes(rating.metric)));
      }
    }

    // some changes to the selected roles will automatically set the orderBy
    if (rolesHaveChanged) {

      // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
      // we only want this if there are currently no selected roles - if there are, and the orderBy is not a role, the orderBy was manually set
      if (currentRoles.length === 0 && selectedRoles.length > 0 && (selectedOrderBy.length === 0 || selectedOrderBy[0] === 'skill_rating')) {
        const lastSelectedRole = selectedRoles[selectedRoles.length - 1];
        newOverriddenOrderBy = lastSelectedRole;
      }

      // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
      if (selectedOrderBy.length > 0 && clubSettings?.roleConfigs[selectedOrderBy[0]] && !selectedRoles.includes(selectedOrderBy[0])) {
        if (selectedRoles.length > 0) {
          newOverriddenOrderBy = selectedRoles[0];
        }
        else {
          newOverriddenOrderBy = 'skill_rating';
        }
      }

      // if any rating requirements are no longer applicable to the new roles, we remove them
      if (newOverriddenRatings === undefined && selectedRatings.some(
        rating => (clubSettings?.roleConfigs[rating.metric] && !selectedRoles.includes(rating.metric))
      )) {
        newOverriddenRatings = selectedRatings.filter(rating => !(clubSettings?.roleConfigs[rating.metric] && !selectedRoles.includes(rating.metric)));
      }
    }

    if (newOverriddenOrderBy) {
      setSelectedOrderBy([newOverriddenOrderBy]);
    }

    if (newOverriddenRatings) {
      setSelectedRatings(newOverriddenRatings);
    }

    if (shouldTriggerSearch) {
      handleSearchButtonPressed(false, true, undefined, newOverriddenOrderBy, newOverriddenRatings);
    }
  };


  // if search is not active, some changes to the selected roles will automatically set the orderBy
  useEffect(() => {
    if (tableData.length > 0 || isEmptySearchResult) return;

    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (selectedRoles.length > 0 && (selectedOrderBy.length === 0 || selectedOrderBy[0] === 'skill_rating')) {
      const lastSelectedRole = selectedRoles[selectedRoles.length - 1];
      setSelectedOrderBy([lastSelectedRole]);
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (selectedOrderBy.length > 0 && clubSettings?.roleConfigs[selectedOrderBy[0]] && !selectedRoles.includes(selectedOrderBy[0])) {
      if (selectedRoles.length > 0) {
        setSelectedOrderBy([selectedRoles[0]]);
      }
      else {
        setSelectedOrderBy(['skill_rating']);
      }
    }

    // if any rating requirements are no longer applicable to the new roles, we remove them
    if (selectedRatings.some(rating => (clubSettings?.roleConfigs[rating.metric] && !selectedRoles.includes(rating.metric)))) {
      setSelectedRatings(selectedRatings.filter(rating => !(clubSettings?.roleConfigs[rating.metric] && !selectedRoles.includes(rating.metric))));
    }
  }, [selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  // if search is not active, some changes to the selected positions will automatically set the orderBy and/or ratings
  useEffect(() => {
    if (tableData.length > 0 || isEmptySearchResult) return;

    if (selectedPositions.includes('GK') !== currentPositions.includes('GK')) {
      if (selectedOrderBy.length > 0 && !commonSeasonTableMetrics.includes(selectedOrderBy[0])) {
        setSelectedOrderBy(['skill_rating']);
      }
      setSelectedRatings(selectedRatings.filter(rating => (rating.metric === 'skill_rating' || commonSeasonTableMetrics.includes(rating.metric))));
    }
  }, [selectedPositions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchWasLoaded) {
      handleSearchButtonPressed(true, true);
    }
    setSearchWasLoaded(false);
  }, [searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='scouting-view-content-container'>

      {isAnyFilterExpanded && <div className='filter-section-empty-background' onClick={() => setIsAnyFilterExpanded(false)} />}

      <SeasonScoutingFilters
        selectedMinutes={selectedMinutes}
        setSelectedMinutes={setSelectedMinutes}
        selectedMaxAge={selectedMaxAge}
        setSelectedMaxAge={setSelectedMaxAge}
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        selectedIterations={selectedIterations}
        setSelectedIterations={setSelectedIterations}
        selectedOrderBy={selectedOrderBy}
        setSelectedOrderBy={setSelectedOrderBy}

        selectedRatings={selectedRatings}
        setSelectedRatings={setSelectedRatings}
        selectedNationalities={selectedNationalities}
        setSelectedNationalities={setSelectedNationalities}
        selectedContractStatus={selectedContractStatus}
        setSelectedContractStatus={setSelectedContractStatus}
        isContractStatusToggled={isContractStatusToggled}
        setIsContractStatusToggled={setIsContractStatusToggled}

        isAnyFilterExpanded={isAnyFilterExpanded}
        setIsAnyFilterExpanded={setIsAnyFilterExpanded}
        isAdvancedFiltersExpanded={isAdvancedFiltersExpanded}
        handleAdvancedFiltersExpansion={handleAdvancedFiltersExpansion}

        handleFilterUnexpansion={handleFilterUnexpansion}
        handleSearchReset={handleSearchReset}
        setSearchWasLoaded={setSearchWasLoaded}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: scoutingViewResultContainerTop }}
      >
        {tableData.length > 0 && (
          <PlayerSeasonTable
            data={tableData}
            additionalPlayerOverviews={additionalPlayerOverviews}
            isLoading={isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={currentModuloPage}
            currentChapter={currentChapter}
            totalHits={totalHits}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={selectedOrderBy.length > 0 ? selectedOrderBy[0] : undefined}
            selectedRoles={selectedRoles}
            isGoalkeeperSelected={selectedPositions.includes('GK')}
          />
        )}

        {tableData.length === 0 && isEmptySearchResult && !isLoading && (
          <div className='scouting-info-title fade-in'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {tableData.length === 0 && !isEmptySearchResult && !isLoading && (
          <div className='scouting-info-button' onClick={() => handleSearchButtonPressed(true, true)}>
            <div className='scouting-info-button-text'>
              {translate('searchForSeasons', userConfig?.language)}
            </div>
            <div className='scouting-info-button-icon'>
              <SearchIcon style={{ fontSize: 24, marginLeft: 20, marginTop: 8 }} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
