import './playerView.css';
import './../documents/documents.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { translate } from '../../../common/language/translations';
import { PlayerEntry, PlayerOverview, PlayerReport, StringToNumberMap } from '../../types';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { ExistingReports } from '../existingDocuments/ExistingReports';
import { Report } from '../documents/report/Report';
import { getLatestReportVersion, reportVersionToScoreKeys } from '../documents/report/reportVersions';
import { ReportScores } from '../documents/report/ReportScores';


interface PlayerViewReportsProps {
  playerOverview: PlayerOverview | undefined;
  playerEntry?: PlayerEntry; // provided for players without data

  reports: PlayerReport[];
  setReports: (newReports: PlayerReport[]) => void;

  playerViewIsExpanding: boolean;
  initialReport: PlayerReport | undefined;
  initialReportHasPrecedence: boolean;
  setInitialReportHasPrecedence: (hasPrecedence: boolean) => void;
}

export const PlayerViewReports: React.FC<PlayerViewReportsProps> = ({
  playerOverview,
  playerEntry,

  reports,
  setReports,

  playerViewIsExpanding,
  initialReport,
  initialReportHasPrecedence,
  setInitialReportHasPrecedence,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();
  const trackEvent = useTrackEvent();

  const [newReportTypeSelected, setNewReportTypeSelected] = useState<string | undefined>(undefined);
  const [existingReportSelected, setExistingReportSelected] = useState<PlayerReport | undefined>(undefined);
  const [reportHasUnsavedChanges, setReportHasUnsavedChanges] = useState(false);

  const [averageScoutingReportScores, setAverageScoutingReportScores] = useState<StringToNumberMap>({});
  const [averageMatchReportScores, setAverageMatchReportScores] = useState<StringToNumberMap>({});
  const [matchReportCount, setMatchReportCount] = useState(0);


  const openConfirmLeaveReportModal = (action: () => void) => {
    const reportType = newReportTypeSelected ?? existingReportSelected?.reportType;
    openConfirmModal(
      action,
      reportType === 'Comment' ? 'exitComment?' : reportType === 'Attachment' ? 'exitAttachment?' : 'exitReport?',
      reportType === 'Comment' ? 'exitCommentInfo' : reportType === 'Attachment' ? 'exitAttachmentInfo' : 'exitReportInfo'
    );
  };


  const handleCloseIconClick = () => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(handleCloseReport);
    }
    else {
      handleCloseReport();
    }
  };


  const handleCloseReport = () => {
    setNewReportTypeSelected(undefined);
    setExistingReportSelected(undefined);
    setInitialReportHasPrecedence(false);
    setReportHasUnsavedChanges(false);
  };


  const handleExistingReportClicked = (report: PlayerReport) => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(() => handleSelectReport(report));
    }
    else {
      handleSelectReport(report);
    }
  };


  const handleSelectReport = (report: PlayerReport | undefined, shouldNotResetPrecedence?: boolean) => {
    setNewReportTypeSelected(undefined);
    setExistingReportSelected(report);
    setReportHasUnsavedChanges(false);

    if (!shouldNotResetPrecedence) {
      setInitialReportHasPrecedence(false);
    }
  };


  const addOrUpdateReport = (report: PlayerReport, isNewReport: boolean) => {
    if (isNewReport) {
      setReports([report, ...reports]);
      setNewReportTypeSelected(undefined);
    }
    else {
      let reportWasDeleted = false;

      const updatedReports = reports.map((existingReport: PlayerReport) => {
        if (existingReport.id === report.id) {

          if (!existingReport.isDeleted && report.isDeleted) {
            reportWasDeleted = true;
          }

          return report;
        }

        return existingReport;
      });

      setReports(updatedReports);

      if (reportWasDeleted) {
        setExistingReportSelected(undefined);
      }
      else {
        setExistingReportSelected(report);
      }
    }

    setReportHasUnsavedChanges(false);
  };


  useEffect(() => {
    if (userConfig?.club) {
      const latestReportVersion = getLatestReportVersion(userConfig.club);
      const scoreKeys = reportVersionToScoreKeys[latestReportVersion];

      const scoutingReportScoreSums: StringToNumberMap = {};
      const matchReportScoreSums: StringToNumberMap = {};

      let scoutingReportCount = 0;
      let matchReportSum = 0;

      for (let i = 0; i < reports.length; i++) {
        if (reports[i].reportVersion === latestReportVersion) {
          if (reports[i].reportType === 'ScoutingReport') {
            scoreKeys.forEach(scoreKey => {
              if (!scoutingReportScoreSums[scoreKey]) {
                scoutingReportScoreSums[scoreKey] = 0;
              }
              scoutingReportScoreSums[scoreKey] += reports[i].reportFields[scoreKey];
            });
            scoutingReportCount++;
          }
          else if (reports[i].reportType === 'MatchReport') {
            scoreKeys.forEach(scoreKey => {
              if (!matchReportScoreSums[scoreKey]) {
                matchReportScoreSums[scoreKey] = 0;
              }
              matchReportScoreSums[scoreKey] += reports[i].reportFields[scoreKey];
            });
            matchReportSum++;
          }
        }
      }

      const averageScoutingReportScoresToSet: StringToNumberMap = {};
      const averageMatchReportScoresToSet: StringToNumberMap = {};

      scoreKeys.forEach(scoreKey => {
        const averageScoutingReportScore = scoutingReportCount > 0
          ? scoutingReportScoreSums[scoreKey] / scoutingReportCount
          : 0;

        const averageMatchReportScore = matchReportSum > 0
          ? matchReportScoreSums[scoreKey] / matchReportSum
          : 0;

        averageScoutingReportScoresToSet[scoreKey] = averageScoutingReportScore;
        averageMatchReportScoresToSet[scoreKey] = averageMatchReportScore;
      });

      setAverageScoutingReportScores(averageScoutingReportScoresToSet);
      setAverageMatchReportScores(averageMatchReportScoresToSet);
      setMatchReportCount(matchReportSum);
    }
  }, [userConfig?.club, reports]);


  useEffect(() => {
    if (initialReport) {
      trackEvent('ReportEvent', { action: 'viewed', reportType: initialReport.reportType });
      handleSelectReport(initialReport, true);
    }
  }, [initialReport]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='player-view-main-section'>

      <div className='player-view-reports-existing-reports-container'>
        {!playerViewIsExpanding && (
          <div className={initialReportHasPrecedence ? 'fade-in' : undefined}>
            <ExistingReports
              reports={reports}
              existingReportSelected={existingReportSelected}
              handleExistingReportClicked={handleExistingReportClicked}
              isPlayerView={true}
            />
          </div>
        )}
      </div>


      <div className={'player-view-reports-right-section' + (existingReportSelected ? ' player-view-reports-right-section-existing-report' : '')}>

        <div className='player-view-reports-right-section-background'>
          <OwnTeamBackgoundSvg />
        </div>

        {!newReportTypeSelected && !existingReportSelected && !initialReportHasPrecedence && (
          <div className='document-content-section'>

            <div className='report-content-left-column'>
              <div className='player-view-reports-new-report-buttons-column'>
                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => setNewReportTypeSelected('ScoutingReport')}
                >
                  <div className='document-submit-button-text'>
                    {translate('newScoutingReport', userConfig?.language)}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <NoteAddIcon style={{ fontSize: 21 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => setNewReportTypeSelected('MatchReport')}
                >
                  <div className='document-submit-button-text'>
                    {translate('newMatchReport', userConfig?.language)}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <PostAddIcon style={{ fontSize: 21 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => setNewReportTypeSelected('Comment')}
                >
                  <div className='document-submit-button-text'>
                    {translate('newComment', userConfig?.language)}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <AddCommentIcon style={{ fontSize: 21, marginTop: 1 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => setNewReportTypeSelected('Attachment')}
                >
                  <div className='document-submit-button-text'>
                    {translate('newAttachment', userConfig?.language)}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <AttachFileIcon style={{ fontSize: 21, marginTop: 1 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className='report-content-right-column'>
              <div className='report-content-average-title'>
                {translate('averageRating', userConfig?.language)}
              </div>

              <ReportScores
                scores={averageScoutingReportScores}
              />
            </div>
          </div>
        )}

        {(newReportTypeSelected || existingReportSelected) && (
          <div className={initialReportHasPrecedence ? 'fade-in' : undefined}>
            <Report
              newReportTypeSelected={newReportTypeSelected}
              existingReportSelected={existingReportSelected}

              reportHasUnsavedChanges={reportHasUnsavedChanges}
              setReportHasUnsavedChanges={setReportHasUnsavedChanges}

              handleCloseIconClick={handleCloseIconClick}
              addOrUpdateReport={addOrUpdateReport}

              playerOverview={playerOverview}
              playerEntry={playerEntry}

              averageMatchReportScores={matchReportCount ? averageMatchReportScores : undefined}
            />
          </div>
        )}

      </div>

    </div>
  );
};
