

export const OwnTeamBackgoundSvg = () => {

  return (
    <div className='full-size-container'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>

        <defs>

          <linearGradient id='own-team-2' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.2' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.2' />
          </linearGradient>

          <linearGradient id='own-team-3' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.2' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.2' />
          </linearGradient>

          <linearGradient id='own-team-4' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.2' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.2' />
          </linearGradient>

          <linearGradient id='own-team-5' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.1' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.1' />
          </linearGradient>

          <linearGradient id='own-team-6' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.1' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.1' />
          </linearGradient>

          <linearGradient id='own-team-7' x1='100%' y1='0%' x2='0%' y2='0%'>
            <stop offset='0%' stopColor='#3d4357' stopOpacity='0.1' />
            <stop offset='100%' stopColor='#1f222c' stopOpacity='0.1' />
          </linearGradient>
        </defs>


        <path d='M 0 100 L 0 80 L 100 30 L 100 100 Z' stroke='none' fill='url(#own-team-2)' />

        <path d='M 15 100 L 100 66 L 100 100 Z' stroke='none' fill='url(#own-team-3)' />

        <path d='M 40 100 L 100 92 L 100 100 Z' stroke='none' fill='url(#own-team-4)' />

        <path d='M 0 85 L 0 50 L 100 25 L 100 100 L 80 100 Z' stroke='none' fill='url(#own-team-5)' />

        <path d='M 60 100 L 100 20 L 100 100 Z' stroke='none' fill='url(#own-team-6)' />

        <path d='M 100 55 L 100 75 L 75 100 L 0 100 L 0 30 Z' stroke='none' fill='url(#own-team-7)' />

      </svg>
    </div>
  );
};
