import '../tables.css';

import { useEffect, useMemo, useRef } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { playerOverviewsState } from '../../../recoil/atoms/playerOverviewsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import { StringToAnyMap, PlayerOverviews, PositionGroupClubIteration } from '../../../types';
import { getPlayerSeasonColumns } from './playerSeasonColumns';
import { getClubSecondaryColor } from '../../../static/clubConfigs';
import { debounce } from '../../../utils/utils';
import { playerSeasonTableChapterSize, playerSeasonTablePageSize } from '../../../views/scouting/Scouting';


interface PlayerSeasonTableProps {
  data: PositionGroupClubIteration[];
  additionalPlayerOverviews: PlayerOverviews;
  isLoading: boolean;
  handleSearchButtonPressed: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;
  selectedOrderBy: string | undefined;
  selectedRoles: string[];
  isGoalkeeperSelected: boolean;
}

export const PlayerSeasonTable: React.FC<PlayerSeasonTableProps> = ({
  data,
  additionalPlayerOverviews,
  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  handleChangeCurrentChapter,
  selectedOrderBy,
  selectedRoles,
  isGoalkeeperSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const clubScopes = useRecoilValue(clubScopesState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getPlayerSeasonColumns(
      userConfig,
      isGoalkeeperSelected,
      currentChapter,
      playerSeasonTablePageSize * playerSeasonTableChapterSize,
      totalHits,
      handleChangeCurrentChapter,
      selectedOrderBy,
      selectedRoles,
      clubSettings?.roleConfigs ?? {},
      getClubSecondaryColor(userConfig?.club ?? ''),
      userSettings?.seasonStatsToggles ?? {},
      clubScopes?.hasSkillcorner ?? false,
      openTextModal,
      openRoleInfoModal,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      userConfig,
      currentChapter,
      totalHits,
      selectedOrderBy,
      userSettings?.seasonStatsToggles,
      clubSettings?.roleConfigs,
      clubScopes?.hasSkillcorner,
      // isLoading,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const handleRowClick = (playerSeason: PositionGroupClubIteration) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerSeason.player_id,
      fullname: playerSeason.fullname,
      playerOverview: playerOverviews[playerSeason.player_id] ?? additionalPlayerOverviews[playerSeason.player_id],
      initialPositionGroup: initialPositionGroup,
      initialClubIteration: playerSeason.club.id + ',' + playerSeason.iteration_id,
    });
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerSeasonTablePageSize * playerSeasonTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    if (playerSeasonTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 250);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const getExtendedCellClassName = (cell: StringToAnyMap) => {
    if (cell.column.isFinalSubMetric) {
      return ' player-season-table-body-cell-with-border';
    }
    return '';
  };


  return (
    <div
      ref={tableContainerRef}
      {...getTableProps()}
      className='player-table'
      style={{ overflowX: 'auto' }}
    >

      <div className='table-top-level-header-group'>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map((column: StringToAnyMap) => {
                const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
                return (
                  <div
                    key={columnKey}
                    {...restColumnProps}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div
        {...getTableBodyProps()}
        className='player-table-body player-table-body-with-column-groups'
      >
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? 'player-table-body-row-even' : 'player-table-body-row-odd'}
              onClick={() => handleRowClick(row.original)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'player-season-table-body-cell'
                      + (i % 2 === 0 ? ' player-table-body-cell-even' : ' player-table-body-cell-odd')
                      + getExtendedCellClassName(cell)
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
