import '../tables.css';

import { useRecoilValue } from 'recoil';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import { CellProps } from 'react-table';
import { StringToAnyMap, PlayerOverview } from '../../../types';
import { getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';
import { countryCodeToCountryInfo } from '../../../static/countries';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { translate } from '../../../../common/language/translations';
import { LazyImage } from '../../../../common/components/LazyImage';
import { ReactNode } from 'react';


const PlayerFindingInfoCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-row'>
        {getDisplayPlayerName(row.original.fullname, 25)}

        {row.original.country_code in countryCodeToCountryInfo && (
          <img
            className='player-table-info-cell-flag'
            src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
            alt=''
            draggable={false}
          />
        )}
      </div>

    </div>
  );
};


const CompetitionCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {
  const competitions = useRecoilValue(competitionsState);

  const competitionId = row.original.club?.current_competition_id;
  const competitionName = competitions[competitionId]?.name;
  const competitionCountryCode = competitions[competitionId]?.countryCode;

  return (
    <div className='player-table-cell'>
      {competitionCountryCode && competitionCountryCode in countryCodeToCountryInfo && (
        <img
          className='simple-player-table-info-cell-flag'
          src={countryCodeToCountryInfo[competitionCountryCode]?.flagUrl}
          alt=''
          draggable={false}
        />
      )}
      {competitionName ?? '-'}
    </div>
  );
};


const ClubCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {
  const clubName = row.original.club?.name;
  const clubLogoUrl = row.original.club?.logo_url;

  if (!clubName || !clubLogoUrl) return <div className='player-table-cell'>-</div>;

  return (
    <div className='player-table-cell'>
      <div className='player-table-club-logo-container' style={{ width: 18, marginRight: 7 }}>
        <img
          className='find-player-table-club-logo'
          style={{ height: 16, marginTop: 1 }}
          src={clubLogoUrl}
          alt=''
          draggable={false}
        />
      </div>
      {clubName}
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value ?? '-'}</div>;
};


const renderPositionCell = (positionGroupKey: string, language: string) => {
  const PositionCellRenderer = ({ row }: StringToAnyMap) => {
    return <PositionCell playerOverview={row.original} positionGroupKey={positionGroupKey} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{
  playerOverview: PlayerOverview;
  positionGroupKey: string;
  language: string;
}> = ({ playerOverview, positionGroupKey, language }) => {

  let primaryPositions = [];
  let secondaryPositions = [];

  if (playerOverview.event_data_exist) {
    // if positionGroupKey is overall, we use the positions one level above position_group_stats to align with the server
    // these positions might differ from the positions in overall, in case of limited scopes
    primaryPositions = positionGroupKey === 'overall'
      ? playerOverview.primary_positions ?? []
      : playerOverview.position_group_stats?.[positionGroupKey]?.primary_positions ?? [];

    secondaryPositions = positionGroupKey === 'overall'
      ? playerOverview.secondary_positions ?? []
      : playerOverview.position_group_stats?.[positionGroupKey]?.secondary_positions ?? [];
  }

  else {
    // if FAPI positions, we use the first position as primary and the second position as secondary (whether it's primary or secondary)
    const allPositions = (playerOverview.primary_positions ?? []).concat(playerOverview.secondary_positions ?? []);
    primaryPositions = allPositions.slice(0, 1);
    secondaryPositions = allPositions.slice(1);
  }

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  return (
    <div className='player-table-position-cell'>
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: 11, color: '#000000dd' }}>
          {secondaryPositionString}
        </div>
      )}
    </div>
  );
};


const HeightCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value ? value + ' cm' : '-'}</div>;
};


export const getFindPlayerColumns = (
  widthUnit: number,
  language: string,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  isSelectedPlayer: boolean,
  handleChangeCurrentChapter?: (isIncrement: boolean) => void,
) => {
  return [
    {
      Header:
        <div className='player-table-sticky-header-cell'>

          {isSelectedPlayer
            ? translate('selectedPlayer', language)
            : (totalHits
              + (totalHits >= 10000 ? '+ ' : ' ')
              + (translate(totalHits === 1 ? 'player' : 'players', language, true)))
          }

          {totalHits > hitsPerChapter && (
            <div className='player-table-sticky-header-cell-pagination'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              {currentChapter <= 0 && (
                <div className='player-table-pagination-arrow player-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter > 0 && handleChangeCurrentChapter && (
                <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                </div>
              )}

              <div className='player-table-current-chapter'>
                {currentChapter + 1}
              </div>

              {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                <div className='player-table-pagination-arrow player-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter < Math.floor(totalHits / hitsPerChapter) && handleChangeCurrentChapter && (
                <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                </div>
              )}
            </div>
          )}

        </div>,

      accessor: 'id',
      sticky: 'left',
      Cell: PlayerFindingInfoCell,
      width: 350,
    },
    {
      Header: translate('age', language),
      accessor: 'age',
      Cell: TextCell,
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header: translate('position', language),
      Cell: renderPositionCell('overall', language),
      width: widthUnit * 110,
      minWidth: 110,
    },
    {
      Header: translate('height', language),
      accessor: 'height',
      Cell: HeightCell,
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header: translate('league', language),
      accessor: 'club.competition_id',
      Cell: CompetitionCell,
      width: widthUnit * 170,
      minWidth: 170,
    },
    {
      Header: translate('team', language),
      accessor: 'club.name',
      Cell: ClubCell,
      width: widthUnit * 170,
      minWidth: 170,
    },
  ];
};


export const getFindPlayerTableTotalColumnsMinWidth = () => {
  return 630;
};
