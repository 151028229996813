import 'react-datepicker/dist/react-datepicker.css';
import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import DatePicker from 'react-datepicker';
import { enGB, nb } from 'date-fns/locale';

import { translate } from '../../../../common/language/translations';
import { StringToAnyMap } from '../../../types';
import { countryCodeToCountryInfo } from '../../../static/countries';
import { CountryDropDown } from '../../input/CountryDropDown';
import { InputField } from '../../input/InputField';
import { academyRoleKeysToSortOrder, localityKeysToSortOrder, roleKeysToSortOrder } from '../../../static/propertyValues';
import { getLocalityIcon, getRoleIcon } from '../../../utils/iconUtils';
import { setPlayerProperty } from '../../../services/firestore/players';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { getDateLocaleFormat } from '../../../utils/playerUtils';


interface EditPlayerInfoModalProps {
  editPlayerInfoProperty: string | undefined;
  closeModal: (feedback?: string | undefined) => void;
  playerToEdit: StringToAnyMap | undefined;
  isAcademyPlayer: boolean;
}

export const EditPlayerInfoModal: React.FC<EditPlayerInfoModalProps> = ({
  editPlayerInfoProperty,
  closeModal,
  playerToEdit,
  isAcademyPlayer,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();

  const [invalidInputMessage, setInvalidInputMessage] = useState<string | undefined>(undefined);

  const propertyIsDate = (editPlayerInfoProperty === 'birth_date' || editPlayerInfoProperty === 'contract_expiration');

  const propertyIsOverridden =
    editPlayerInfoProperty &&
    playerToEdit &&
    playerToEdit[editPlayerInfoProperty] !== undefined &&
    playerToEdit[editPlayerInfoProperty] !== null &&
    playerToEdit[editPlayerInfoProperty] !== playerToEdit[editPlayerInfoProperty + '_in_database'];


  const isButtonDisabled = () => {
    if (propertyIsDate) {
      return !(playerToEdit && editPlayerInfoProperty && selectedDate && selectedDate.toISOString().split('T')[0] !== playerToEdit[editPlayerInfoProperty]);
    }
    if (editPlayerInfoProperty === 'country_code') {
      return !(playerToEdit && selectedCountryCodes.length > 0 && selectedCountryCodes[0] !== playerToEdit.country_code);
    }
    return !(searchString.length > 0 && playerToEdit && editPlayerInfoProperty && String(playerToEdit[editPlayerInfoProperty]) !== searchString);
  };


  const handleNewLocalityClick = (localityClicked: string, isOptionAlreadySelected: boolean) => {
    if (!playerToEdit || !userConfig) return;
    setPlayerProperty(playerToEdit.id, 'locality', isOptionAlreadySelected ? null : localityClicked, userConfig.club);
    closeModal();
  };


  // const handleNewAbcClick = (ABC: string, isOptionAlreadySelected: boolean) => {
  //   if (!playerToEdit || !userConfig) return;
  //   setPlayerProperty(playerToEdit.id, 'ABC', isOptionAlreadySelected ? null : ABC, userConfig.club);
  //   closeModal();
  // };


  const handleNewRoleClick = (roleClicked: string, isOptionAlreadySelected: boolean) => {
    if (!playerToEdit || !userConfig) return;
    setPlayerProperty(playerToEdit.id, 'role', isOptionAlreadySelected ? null : roleClicked, userConfig.club);
    closeModal('role');
  };


  const confirmEditPlayerInfo = () => {
    if (!userConfig || !editPlayerInfoProperty || !playerToEdit) return;

    if (propertyIsDate && selectedDate) {
      const newValue = selectedDate.toISOString().split('T')[0];
      setPlayerProperty(playerToEdit.id, editPlayerInfoProperty, newValue, userConfig.club);
      closeModal('contract_expiration');
      return;
    }

    if (editPlayerInfoProperty === 'country_code') {
      const newValue = selectedCountryCodes[0];
      setPlayerProperty(playerToEdit.id, editPlayerInfoProperty, newValue, userConfig.club);
      closeModal();
      return;
    }

    if (isNaN(Number(searchString)) || Number(searchString) < 0) {
      setInvalidInputMessage('inputMustBeNumericAndPositive');
      return false;
    }
    if (searchString.length > 0 && playerToEdit && editPlayerInfoProperty && String(playerToEdit[editPlayerInfoProperty]) !== searchString) {
      setPlayerProperty(playerToEdit.id, editPlayerInfoProperty, Number(searchString), userConfig.club);
      closeModal();
    }

    return;
  };


  const deletePlayerInfo = () => {
    if (!userConfig || !editPlayerInfoProperty || !playerToEdit) return;
    setPlayerProperty(playerToEdit.id, editPlayerInfoProperty, null, userConfig.club);
    closeModal();
  };


  // properties that may both exist in the database and in the players collection
  const potentiallyOverriddenProperties = ['birth_date', 'country_code', 'contract_expiration', 'market_value'];

  const getCurrentDisplayValue = () => {
    if (propertyIsDate) {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      return getDateLocaleFormat(propertyValue);
    }

    if (editPlayerInfoProperty === 'country_code') {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      if (!userConfig || !propertyValue || !countryCodeToCountryInfo[propertyValue]) return '-';

      return countryCodeToCountryInfo[propertyValue].name[userConfig.language] ?? '-';
    }

    if (editPlayerInfoProperty === 'market_value') {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      return propertyValue ? getDisplayEconomicValue(propertyValue, true) : '-';
    }
  };


  // Date section
  const [selectedDate, setSelectedDate] = useState(
    (propertyIsDate && playerToEdit && editPlayerInfoProperty && playerToEdit[editPlayerInfoProperty])
      ? new Date(playerToEdit[editPlayerInfoProperty])
      : editPlayerInfoProperty === 'contract_expiration'
        ? new Date()
        : null
  );

  const handleSetSelectedDate = (date: Date | null) => {
    if (!date) {
      setSelectedDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setSelectedDate(adjustedDate);
  };

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const currentMaxDate = new Date();
  const currentMinDate = new Date();
  const maxDate = new Date(currentMaxDate.setFullYear(currentMaxDate.getFullYear() + (editPlayerInfoProperty === 'birth_date' ? -10 : 10)));
  const minDate = new Date(currentMinDate.setFullYear(currentMinDate.getFullYear() + (editPlayerInfoProperty === 'birth_date' ? -40 : -1)));


  // Nationalities section
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>(playerToEdit?.country_code ? [playerToEdit.country_code] : []);


  // Text sections
  const [searchString, setSearchString] = useState(playerToEdit && editPlayerInfoProperty ? (playerToEdit[editPlayerInfoProperty] ?? '') : '');

  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidInputMessage(undefined);
    setSearchString(event.target.value.replaceAll('.', ''));
  };

  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      confirmEditPlayerInfo();
    }
  };

  const resetSearchString = () => {
    setInvalidInputMessage(undefined);
    setSearchString('');
  };


  const propertyTypeIsText =
    !propertyIsDate &&
    editPlayerInfoProperty !== 'country_code' &&
    editPlayerInfoProperty !== 'role' &&
    editPlayerInfoProperty !== 'locality';
  // editPlayerInfoProperty !== 'ABC'

  return (
    <div
      className='modal-root-container edit-player-info-modal-root'
      style={{
        height: editPlayerInfoProperty === 'locality'
          ? 250
          : editPlayerInfoProperty === 'role'
            ? (isAcademyPlayer ? 250 : 440)
            : propertyTypeIsText
              ? 400
              : 600,
      }}
    >

      {isNationalitiesExpanded && (
        <div className='modal-empty-background' onClick={() => setIsNationalitiesExpanded(false)}>
          &nbsp;
        </div>
      )}

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      {propertyIsOverridden && (
        <div title={translate('deleteProvidedValue', userConfig?.language)}>
          <ReplayIcon
            className='modal-icon modal-back-icon'
            style={{ fontSize: 24 }}
            onClick={() => openConfirmModal(deletePlayerInfo, 'deleteProvidedValue', 'noRegretInfo')}
          />
        </div>
      )}

      <div className='modal-root-title' style={{ height: 65 }}>
        {editPlayerInfoProperty ? translate('provideNewPlayerInfo_' + editPlayerInfoProperty, userConfig?.language, undefined, undefined, true) : ''}
        <div style={{ marginTop: 8 }}>
          {playerToEdit?.fullname}
        </div>
      </div>

      <div className='modal-divider modal-root-title-divider' style={{ top: 65 }}>&nbsp;</div>

      <div className='edit-player-info-modal-input-section' >
        {propertyIsDate && (
          <div>
            <DatePicker
              className='edit-player-info-modal-input'
              selected={selectedDate}
              onChange={(date: Date | null) => handleSetSelectedDate(date)}
              locale={locale}
              dateFormat={dateFormat}
              popperPlacement='bottom'

              minDate={minDate}
              maxDate={maxDate}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              showMonthDropdown
            />
          </div>
        )}

        {editPlayerInfoProperty === 'country_code' && (
          <div className='edit-player-info-modal-country-drop-down'>
            <CountryDropDown
              id={'edit-player-info-modal-country-drop-down'}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={setIsNationalitiesExpanded}
              selectedCountryCodes={selectedCountryCodes}
              setSelectedCountryCodes={setSelectedCountryCodes}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={320}
              singleSelect={true}
            />
          </div>
        )}

        {editPlayerInfoProperty === 'locality' && (
          <div className='modal-content-section' style={{ top: 90, alignItems: 'center' }}>
            {Object.keys(localityKeysToSortOrder).map((locality: string, index: number) => {
              const isSelected = playerToEdit !== undefined && playerToEdit.locality === locality;
              const icon = getLocalityIcon(locality, '#ffffffcc', 22);
              return (
                <div
                  key={locality}
                  className={'edit-player-info-modal-role-option' + (isSelected ? ' edit-player-info-modal-role-option-selected' : '')}
                  style={{ marginTop: index === 0 ? 2 : 18 }}
                  onClick={() => handleNewLocalityClick(locality, isSelected)}
                >
                  <div className='edit-player-info-modal-role-icon'>
                    {icon}
                  </div>
                  <div className='edit-player-info-modal-role-text'>
                    {translate(locality + 'Locality', userConfig?.language, undefined, undefined, true)}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* {editPlayerInfoProperty === 'ABC' && (
          <div className='modal-content-section' style={{ top: 90, alignItems: 'center' }}>
            {['A', 'B', 'C'].map((ABC: string, index: number) => {
              const isSelected = playerToEdit !== undefined && playerToEdit.ABC === ABC;
              return (
                <div
                  key={ABC}
                  className={'edit-player-info-modal-role-option' + (isSelected ? ' edit-player-info-modal-role-option-selected' : '')}
                  style={{ marginTop: index === 0 ? 2 : 18, justifyContent: 'center', width: 60 }}
                  onClick={() => handleNewAbcClick(ABC, isSelected)}
                >
                  {ABC}
                </div>
              );
            })}
          </div>
        )} */}

        {editPlayerInfoProperty === 'role' && (
          <div className='modal-content-section' style={{ top: 90, alignItems: 'center' }}>
            {Object.keys(isAcademyPlayer ? academyRoleKeysToSortOrder : roleKeysToSortOrder).map((role: string, index: number) => {

              const isSelected = playerToEdit !== undefined && playerToEdit.role === role;
              const icon = getRoleIcon(role, '#ffffffcc', 22);

              return (
                <div
                  key={role}
                  className={'edit-player-info-modal-role-option' + (isSelected ? ' edit-player-info-modal-role-option-selected' : '')}
                  style={{ marginTop: index === 0 ? 2 : 18 }}
                  onClick={() => handleNewRoleClick(role, isSelected)}
                >
                  <div className='edit-player-info-modal-role-icon'>
                    {icon}
                  </div>
                  <div className='edit-player-info-modal-role-text'>
                    {translate(role, userConfig?.language)}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {propertyTypeIsText && (
          <div className='edit-player-info-modal-input-field'>
            <InputField
              searchString={getDisplayEconomicValue(searchString)}
              onChangeInputField={onChangeSearchField}
              onKeyDownInputField={onKeyDownSearchField}
              resetSearchString={resetSearchString}
              defaultInput={translate('provideNewValue', userConfig?.language)}
              showDefaultInput={true}
            />
          </div>
        )}
      </div>

      {editPlayerInfoProperty && potentiallyOverriddenProperties.includes(editPlayerInfoProperty) && !playerToEdit?.isPlayerWithoutData && (
        <div className='edit-player-info-modal-database-value-row'>
          {translate('valueInOurDatabase', userConfig?.language)}
          <div style={{ marginLeft: 8 }}>
            {getCurrentDisplayValue()}
          </div>
        </div>
      )}

      {invalidInputMessage && (
        <div className='edit-player-info-modal-invalid-input-message'>
          {translate(invalidInputMessage, userConfig?.language)}
        </div>
      )}

      {editPlayerInfoProperty !== 'role' && editPlayerInfoProperty !== 'locality' && editPlayerInfoProperty !== 'ABC' && (
        <div
          className={'modal-button modal-button-bottom' + (isButtonDisabled() ? ' modal-button-disabled' : '')}
          style={{ marginLeft: 130, bottom: 50 }}
          onClick={() => !isButtonDisabled() ? confirmEditPlayerInfo() : null}
        >
          <div className='modal-button-text'>
            {translate('confirm', userConfig?.language)}
          </div>

          <CheckIcon
            className='modal-button-icon'
            style={{ fontSize: 24 }}
          />
        </div>
      )}
    </div>
  );
};
