import { StringToStringToStringMap, StringToStringArrayMap } from '../../../types';


// overallScore is mandatory as the first score
export const reportVersionToScoreKeys: StringToStringArrayMap = {
  '1.0.0': [
    'overallScore',
    'potentialScore',
    'techniqueScore',
    'paceScore',
    'understandingScore',
    'strengthScore',
    'attitudeScore',
    'staminaScore',
    'influenceScore',
    'intensityScore',
  ],
  'rbk-1.0.0': [
    'overallScore',
    'potentialScore',
    'roleScore',
    'influenceScore',
    'techniqueScore',
    'tacticalScore',
    'physicalScore',
    'mentalScore',
    'attackingScore',
    'defendingScore',
  ],
  'vif-1.0.0': [
    'overallScore',
    'potentialScore',
    'xFactorScore',
  ],
  'fokus-limited-1.0.0': [
    'overallScore',
    'fokus-limited-1',
    'fokus-limited-2',
    'fokus-limited-3'
  ]
};


export const getLatestReportVersion = (club: string | undefined) => {
  switch (club) {
    case 'rbk':
      return 'rbk-1.0.0';
    case 'vif':
      return 'vif-1.0.0';
    case 'fokus-limited':
      return 'fokus-limited-1.0.0';
    default:
      return '1.0.0';
  }
};


export const reportScoreLanguageMap: StringToStringToStringMap = {

  // default scores
  'overallScore': {
    'en': 'Overall',
    'no': 'Overordnet',
    'se': 'Övergripande',
    'dk': 'Samlet',
  },
  'potentialScore': {
    'en': 'Potential',
    'no': 'Potensial',
    'se': 'Potential',
    'dk': 'Potentiale',
  },
  'techniqueScore': {
    'en': 'Technique',
    'no': 'Teknikk',
    'se': 'Teknik',
    'dk': 'Teknik',
  },
  'understandingScore': {
    'en': 'Understanding',
    'no': 'Spilleforståelse',
    'se': 'Spelförståelse',
    'dk': 'Spilleforståelse',
  },
  'attitudeScore': {
    'en': 'Attitude',
    'no': 'Holdning',
    'se': 'Attityd',
    'dk': 'Holdning',
  },
  'influenceScore': {
    'en': 'Influence',
    'no': 'Innflytelse',
    'se': 'Inflytande',
    'dk': 'Indflydelse',
  },
  'paceScore': {
    'en': 'Pace',
    'no': 'Hurtighet',
    'se': 'Fart',
    'dk': 'Hurtighed',
  },
  'strengthScore': {
    'en': 'Strength',
    'no': 'Styrke',
    'se': 'Styrka',
    'dk': 'Styrke',
  },
  'staminaScore': {
    'en': 'Stamina',
    'no': 'Utholdenhet',
    'se': 'Uthållighet',
    'dk': 'Udholdenhed',
  },
  'intensityScore': {
    'en': 'Intensity',
    'no': 'Intensitet',
    'se': 'Intensitet',
    'dk': 'Intensitet',
  },

  // additional scores for rbk
  'roleScore': {
    'en': 'Role skills',
    'no': 'Rolleferdigheter',
    'se': 'Rollfärdigheter',
    'dk': 'Rollefærdigheder',
  },
  'tacticalScore': {
    'en': 'Tactical',
    'no': 'Taktikk',
    'se': 'Taktik',
    'dk': 'Taktik',
  },
  'physicalScore': {
    'en': 'Physical',
    'no': 'Fysikk',
    'se': 'Fysik',
    'dk': 'Fysik',
  },
  'mentalScore': {
    'en': 'Mentality',
    'no': 'Mentalitet',
    'se': 'Mentalitet',
    'dk': 'Mentalitet',
  },
  'attackingScore': {
    'en': 'Attacking',
    'no': 'Angrep',
    'se': 'Anfall',
    'dk': 'Angreb',
  },
  'defendingScore': {
    'en': 'Defending',
    'no': 'Forsvar',
    'se': 'Försvar',
    'dk': 'Forsvar',
  },

  // additional scores for vif
  'xFactorScore': {
    'en': 'X-factor',
    'no': 'X-faktor',
    'se': 'X-faktor',
    'dk': 'X-faktor',
  },

  // additional scores for fokus-limited
  'fokus-limited-1': {
    'en': 'Funnyness',
    'no': 'Køddenhet',
    'se': 'Rolighet',
    'dk': 'Sjovhed',
  },
  'fokus-limited-2': {
    'en': 'Poshness',
    'no': 'Stekhet',
    'se': 'Snobbighet',
    'dk': 'Snobberi',
  },
  'fokus-limited-3': {
    'en': 'Fatness',
    'no': 'Tjukkhet',
    'se': 'Tjockhet',
    'dk': 'Fedme',
  },
};
