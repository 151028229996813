import { useCallback, useEffect, useMemo, useState } from 'react';

import { StringToStringMap, PlayerReport } from '../../types';


interface ReportHistoryLinePlotProps {
  reports: PlayerReport[];
  clubColor: string;
  language: string;
}


// x, y
type ReportHistoryLinePoint = [number, number];


export const ReportHistoryLinePlot: React.FC<ReportHistoryLinePlotProps> = ({
  reports,
  clubColor,
  language,
}) => {

  const [linePoints, setLinePoints] = useState<ReportHistoryLinePoint[]>([]);
  const [linePath, setLinePath] = useState('');
  const [areaPath, setAreaPath] = useState('');

  const [dateToXCoordinates, setDateToXCoordinates] = useState<{ [key: string]: number }>({});
  const [yValues, setYValues] = useState<number[]>([]);


  const startDate = useMemo(() => {
    if (reports.length > 0) {
      const lastReportDate = new Date(reports[reports.length - 1].date);
      return new Date(lastReportDate.getFullYear(), lastReportDate.getMonth() - 2, 1);
    }
    return new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1);
  }, [reports]);

  const endDate = useMemo(() => {
    if (reports.length > 0) {
      const firstReportDate = new Date(reports[0].date);
      return new Date(firstReportDate.getFullYear(), firstReportDate.getMonth() + 1, 1);
    }
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
  }, [reports]);


  const getDaysSinceStartDate = useCallback((date: Date) => {
    const diffInMs = date.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays);
  }, [startDate]);


  const createPathData = (points: ReportHistoryLinePoint[]) => {

    // need at least two points to draw a curve
    if (points.length < 2) {
      return { lPath: '', aPath: '' };
    }

    function catmullRomToBezier(
      p0: ReportHistoryLinePoint,
      p1: ReportHistoryLinePoint,
      p2: ReportHistoryLinePoint,
      p3: ReportHistoryLinePoint,
      tension: number
    ): string {
      const cp1x = p1[0] + (p2[0] - p0[0]) / 6 * tension;
      const cp1y = p1[1] + (p2[1] - p0[1]) / 6 * tension;
      const cp2x = p2[0] - (p3[0] - p1[0]) / 6 * tension;
      const cp2y = p2[1] - (p3[1] - p1[1]) / 6 * tension;

      return ` C ${cp1x},${cp1y}, ${cp2x},${cp2y}, ${p2[0]},${p2[1]}`;
    }

    let line = `M ${points[0][0]},${points[0][1]}`;

    for (let i = 0; i < points.length - 1; i++) {
      const p0 = i === 0 ? points[0] : points[i - 1];
      const p1 = points[i];
      const p2 = points[i + 1];
      const p3 = i === points.length - 2 ? points[points.length - 1] : points[i + 2];

      line += catmullRomToBezier(p0, p1, p2, p3, 0);
    }

    const areaLine = line + ` L ${points[points.length - 1][0]},390 L ${points[0][0]},390 Z`;

    return { lPath: line, aPath: areaLine };
  };


  useEffect(() => {

    // calculate units on x-axis
    const totalDaysSinceStartDate = getDaysSinceStartDate(endDate);
    const xUnitPerDay = 840 / totalDaysSinceStartDate;

    if (reports.length === 0) {
      setYValues([10, 8, 6, 4, 2]);
      setLinePoints([]);
      setLinePath('');
      setAreaPath('');
      setDateToXCoordinates({});
      return;
    }

    // calculate report counts
    const monthToReportCount: { [key: string]: number } = {};
    const currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 2);
    while (currentDate < endDate) {
      const key = currentDate.toISOString().split('T')[0];
      monthToReportCount[key] = 0;
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    reports.forEach((report: PlayerReport) => {
      const reportDate = new Date(report.date);
      const month = (new Date(reportDate.getFullYear(), reportDate.getMonth(), 2)).toISOString().split('T')[0];
      if (month in monthToReportCount) {
        monthToReportCount[month] += 1;
      }
    });

    // calculate max count and set y-values
    // var maxCount = 25;  // Math.max(...Object.values(monthToReportCount));
    // setYValues([maxCount, 20, 15, 10, 5]);

    const maxCount = Math.max(...Object.values(monthToReportCount));
    const yValues = maxCount > 50
      ? [100, 80, 60, 40, 20]
      : maxCount > 25
        ? [50, 40, 30, 20, 10]
        : maxCount > 10
          ? [25, 20, 15, 10, 5]
          : [10, 8, 6, 4, 2];
    setYValues(yValues);

    const maxYValue = yValues[0];

    // calculate x-coordinates of x-labels
    const xCoordinates: { [key: string]: number } = {};
    const xLabelFrequency = Object.keys(monthToReportCount).length < 12 ? 1 : 3;
    Object.keys(monthToReportCount).forEach((dateString: string) => {
      const month = new Date(dateString).getMonth();
      if (month % xLabelFrequency !== 0) {
        return;
      }
      const x = 100 + (getDaysSinceStartDate(new Date(dateString)) * xUnitPerDay);
      if (x > 101 && x < 939) {
        const date = new Date(dateString);
        const options: StringToStringMap = { year: '2-digit', month: 'short' };
        const locale = language === 'no' ? 'nb-NO' : 'en-US';
        xCoordinates[date.toLocaleDateString(locale, options)] = x;
      }
    });
    setDateToXCoordinates(xCoordinates);

    // calculate points
    const points: ReportHistoryLinePoint[] = [];
    Object.entries(monthToReportCount).forEach(([monthDate, count]) => {

      const x = 100 + (getDaysSinceStartDate(new Date(monthDate)) * xUnitPerDay);
      const y = 390 - ((count / maxYValue) * 350);

      points.push([x, y]);
    });

    const { lPath, aPath } = createPathData(points);

    setLinePath(lPath);
    setAreaPath(aPath);
    setLinePoints(points);

  }, [endDate, getDaysSinceStartDate, language, reports, startDate]);


  // x-axis ranges from 100 (January 1st, 2019) to 940 (current date)  ->  840 units
  // y-axis ranges from 40 (clubIndex = 10) to 390 (clubIndex = 0)     ->  350 units

  return (
    // <svg ref={svgRef} className='player-view-svg-plot' viewBox={'0 0 1000 550'} preserveAspectRatio={shouldPreserveAspectRatio ? 'xMidYMid meet' : 'none'}>
    <svg className='player-view-svg-plot' viewBox={'0 0 1000 450'} preserveAspectRatio={'xMidYMid meet'}>

      <defs>
        <linearGradient id='report-history-line-plot-area' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: clubColor, stopOpacity: 0.2 }} />
          <stop offset='100%' style={{ stopColor: clubColor, stopOpacity: 0.05 }} />
        </linearGradient>
      </defs>

      {/* <rect width='1000' height='450' style={{ fill: 'none', stroke: '#ffffff88', strokeWidth: 4 }} /> */}

      {/* x-axis */}
      <line x1='99.7' y1='390' x2='940' y2='390' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* y-axis */}
      <line x1='100' y1='40' x2='100' y2='390' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* x-axis labels */}
      {Object.entries(dateToXCoordinates).map(([date, x]) => (
        <g key={date}>
          <line x1={x} y1='390' x2={x} y2='402' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
          <text x={x} y='424' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{date}</text>
        </g>
      ))}

      {/* y-axis labels and value lines */}
      <line x1='100' y1='40' x2='940' y2='40' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='90' y1='40' textAnchor='middle' x2='100' y2='40' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='67' y='45' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{yValues.length > 0 ? yValues[0] : ''}</text>

      <line x1='100' y1='110' x2='940' y2='110' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='90' y1='110' textAnchor='middle' x2='100' y2='110' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='67' y='115' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{yValues.length > 1 ? yValues[1] : ''}</text>

      <line x1='100' y1='180' x2='940' y2='180' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='90' y1='180' textAnchor='middle' x2='100' y2='180' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='67' y='185' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{yValues.length > 2 ? yValues[2] : ''}</text>

      <line x1='100' y1='250' x2='940' y2='250' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='90' y1='250' textAnchor='middle' x2='100' y2='250' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='67' y='255' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{yValues.length > 3 ? yValues[3] : ''}</text>

      <line x1='100' y1='320' x2='940' y2='320' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='90' y1='320' textAnchor='middle' x2='100' y2='320' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='67' y='325' textAnchor='middle' fill='#ffffffaa' fontSize={16} fontFamily=''>{yValues.length > 4 ? yValues[4] : ''}</text>

      {/* iteration points */}
      {linePoints.map(([x, y]) => (
        <g key={x + ',' + y}>
          <circle cx={x} cy={y} r={4} style={{ fill: clubColor, stroke: 'none' }} />
        </g>
      ))}

      {/* line path */}
      <path d={linePath} style={{ fill: 'none', stroke: clubColor, strokeWidth: 3 }} />

      {/* line area path */}
      <path d={areaPath} fill='url(#report-history-line-plot-area)' />

    </svg>
  );
};
