import '../../platform.css';
import './settings.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';

import { supportEntries } from '../../static/support';
import { StringToAnyMap } from '../../types';
import { SupportAccordion } from '../../components/accordions/SupportAccordion';
import { supportPageMetricGroups, metricToDisplayInfo } from '../../static/playerMetrics';


export const Support: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);


  const statsSubSections: StringToAnyMap[] = Object.entries(supportPageMetricGroups).map(([metricGroup, metrics]: [string, string[]]) => {

    const subSection: StringToAnyMap = {
      'title': {
        'en': metricToDisplayInfo[metricGroup] ? metricToDisplayInfo[metricGroup].name.en : metricGroup,
        'no': metricToDisplayInfo[metricGroup] ? metricToDisplayInfo[metricGroup].name.no : metricGroup,
        'se': metricToDisplayInfo[metricGroup] ? metricToDisplayInfo[metricGroup].name.se : metricGroup,
        'dk': metricToDisplayInfo[metricGroup] ? metricToDisplayInfo[metricGroup].name.dk : metricGroup,
      },
      'text': {
        'en': [],
        'no': [],
        'se': [],
        'dk': [],
      },
    };

    metrics.forEach((metric) => {
      subSection.text.en.push(metricToDisplayInfo[metric].name.en + ' _ ' + metricToDisplayInfo[metric].description.en);
      subSection.text.no.push(metricToDisplayInfo[metric].name.no + ' _ ' + metricToDisplayInfo[metric].description.no);
      subSection.text.se.push(metricToDisplayInfo[metric].name.se + ' _ ' + metricToDisplayInfo[metric].description.se);
      subSection.text.dk.push(metricToDisplayInfo[metric].name.dk + ' _ ' + metricToDisplayInfo[metric].description.dk);
    });

    return subSection;
  });


  return (
    <div className='settings-view-container'>

      <div className='support-view-container'>

        {userConfig && Object.keys(supportEntries).map((key, index) => {

          const subSections = key === 'stats' ? statsSubSections : supportEntries[key].subsections;

          return (
            <div className='support-section' key={index}>
              <div className='support-section-title'>
                {supportEntries[key].title[userConfig.language]}
              </div>

              {subSections.map((subsection: StringToAnyMap, subIndex: number) => {
                if (subsection.title[userConfig.language] === 'SkillCorner' && clubScopes && !clubScopes.hasSkillcorner) return null;
                return (
                  <div key={subIndex} className='support-accordion'>
                    <SupportAccordion
                      title={subsection.title[userConfig.language]}
                      englishTitle={subsection.title.en}
                      text={subsection.text[userConfig.language]}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}

      </div>
    </div>
  );
};
