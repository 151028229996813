import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import '../mobilePlatform.css';

// import { useRef, useState } from 'react';
// import { useRecoilValue } from 'recoil';
// import { userConfigState } from '../../recoil/atoms/userConfigState';
// import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
// import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

// import { PlayerOverview, PlayerOverviews } from '../../types';
// import { InputField } from '../../components/input/InputField';
// import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../services/server/application/playerOverviews';
// import { translate } from '../../../common/language/translations';


export const MobileScouting: React.FC = () => {

  // const { currentUser } = useAuthContext() as AuthContextType;
  // const userConfig = useRecoilValue(userConfigState);

  // const selectedPlayer = useRecoilValue(selectedPlayerState);

  // const [playerData, setPlayerData] = useState<PlayerOverviews | undefined>(undefined);
  // const [tableData, setTableData] = useState<PlayerOverview[]>([]);

  // const [searchString, setSearchString] = useState('');
  // const [debouncedSearchString, setDebouncedSearchString] = useState('');
  // const clearDebounceRef = useRef<() => void>(() => null);

  // const [currentPage, setCurrentPage] = useState(0);
  // const [totalHits, setTotalHits] = useState(0);
  // const [hitsPerPage, setHitsPerPage] = useState(50);

  // const [isLoading, setIsLoading] = useState(false);
  // const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  // const [currentSearchString, setCurrentSearchString] = useState('');


  // const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchString(event.target.value);
  // };


  // const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter' && searchString !== currentSearchString) {
  //     handleSearchButtonPressed(true, true);
  //   }
  // };


  // const handleSearchButtonPressed = async (isUserInitiated: boolean, isNewSearch: boolean) => {

  //   setIsLoading(true);

  //   setCurrentSearchString(searchString);

  //   if (isUserInitiated) {
  //     clearDebounceRef.current();
  //   }

  //   const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;

  //   const query = {
  //     name: searchString.toLowerCase(),
  //     sort: 'clubIndex',
  //     page: nextPageToQuery,
  //   };

  //   try {
  //     const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(query, currentUser);

  //     const totalHits = result?.total_hits;
  //     const page = result?.current_page;
  //     const players: PlayerOverview[] = result?.players ?? [];

  //     if (!result || totalHits === undefined || !page) {
  //       handleSearchReset();
  //     }
  //     else if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
  //       setIsEmptySearchResult(true);
  //       setPlayerData(undefined);
  //       setTableData([]);
  //       setTotalHits(0);
  //       setHitsPerPage(0);
  //       setCurrentPage(0);
  //     }
  //     else {
  //       const playersObject = players.reduce((acc: PlayerOverviews, player: PlayerOverview) => {
  //         acc[player['id']] = player;
  //         return acc;
  //       }, {});

  //       setIsEmptySearchResult(false);
  //       setPlayerData(isNewSearch ? playersObject : { ...playerData, ...playersObject });
  //       setTableData(isNewSearch ? players : [...tableData, ...players]);
  //       setTotalHits(totalHits);
  //       setHitsPerPage(hitsPerPage);
  //       setCurrentPage(page);
  //     }
  //   }
  //   catch (error) {
  //     console.log(error); // eslint-disable-line no-console
  //   }

  //   setIsLoading(false);
  // };


  // const handleSearchReset = () => {
  //   setPlayerData(undefined);
  //   setTableData([]);
  //   setCurrentPage(0);
  //   setTotalHits(0);
  //   setHitsPerPage(0);
  //   setIsEmptySearchResult(false);
  //   setCurrentSearchString('');
  //   setSearchString('');
  // };


  return (
    <div className='mobile-view-container'>
      <div className='mobile-home-view-background'>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='mobile-view-content-container'>
        &nbsp;

        {/* <div className='mobile-scouting-input-field fade-in'>
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={translate('searchForName', userConfig?.language)}
            showDefaultInput={true}
            style={{ boxShadow: '0px 0px 3px 1px #0000002d' }}
          />
        </div> */}
      </div>
    </div>
  );
};
