

// get a color ranging from none to green based on index
export const getRatingColor = (value: number, isWhiteBackground?: boolean) => {
  if (!value) value = 0;

  const r = isWhiteBackground ? 44 : 54;
  const g = isWhiteBackground ? 255 : 184;
  const b = isWhiteBackground ? 44 : 54;

  const alpha = (value / 10) * (isWhiteBackground ? 0.9 : 0.8);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


// get a color ranging from red to green based on index
export const getIndexColor = (index: number | undefined, lightness?: number, alpha?: number) => {
  if (index === undefined) return '#00000000';

  const startHue = 0; // red
  const endHue = 120; // green

  lightness = lightness ?? 50;
  const alphaToUse = alpha ?? 1;

  const hue = startHue + ((endHue - startHue) * (index / 10));

  const color = `hsla(${hue}, 100%, ${lightness}%, ${alphaToUse})`;

  return color;
};


// get color blind rating colot -> all colors are shades of blue that apparently could be slightly distinguishable for color blind people
export const getColorBlindRatingColor = (value: number, opacity: number) => {
  if (!value) value = 0;

  const baseBlue = 200;
  const maxBlue = 25;

  const blueComponent = baseBlue - (value / 10) * (baseBlue - maxBlue);

  const alpha = (value / 10) * opacity;

  return `rgba(${blueComponent}, ${blueComponent}, 255, ${alpha})`;
};


// get a value between 0 and 10 based on the contract expiration
export const getContractExpirationIndexEquivalent = (contractExpiration: string | undefined): number | undefined => {
  if (!contractExpiration) return undefined;

  let daysLeft = 0;

  if (contractExpiration) {
    const today = new Date();
    const expirationDate = new Date(contractExpiration);

    const timeDiff = Math.max(expirationDate.getTime() - today.getTime(), 0);
    daysLeft = Math.min(timeDiff / (1000 * 3600 * 24), 1400);
  }

  const contractOffset = 180;

  daysLeft = Math.max(0, daysLeft - contractOffset);

  const percent = daysLeft / (1400 - contractOffset);
  return percent * 10;
};


// get a value between 0 and 10 based on the age
export const getAgeIndexEquivalent = (age: number, isAcademyTeam?: boolean): number | undefined => {
  if (!age) return undefined;

  const maxAge = isAcademyTeam ? 25 : 33;
  const minAge = isAcademyTeam ? 13 : 17;

  age = Math.min(maxAge, Math.max(minAge, age));

  const percent = 1 - (age - minAge) / (maxAge - minAge);
  return percent * 10;
};
