import { db } from '../../../firebase';

import {
  doc,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';

import { StringToAnyMap, UserSettings } from '../../types';


// get and set settings for club and
export const getUserSettings = (setUserSettings: (userSettings: UserSettings) => void, userEmail: string, club: string) => {

  const userSettingsDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const unsubscribe = onSnapshot(userSettingsDocRef, (doc) => {
    const userSettingsObject = doc.data();

    const userSettings: UserSettings = {
      academyTeamMenu: userSettingsObject?.academyTeamMenu ?? {},
      ownTeamMenu: userSettingsObject?.ownTeamMenu ?? {},
      teamMenu: userSettingsObject?.teamMenu ?? {},
      seasonStatsToggles: userSettingsObject?.seasonStatsToggles ?? {}
    };

    setUserSettings(userSettings);
  });

  return unsubscribe;
};


// Update teamMenu or ownTeamMenu settings
export const updateTeamMenu = async (menu: StringToAnyMap, teamOrSquadId: string, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const menuField = teamOrSquadId === 'ownTeam'
    ? 'ownTeamMenu'
    : teamOrSquadId === 'academyTeam'
      ? 'academyTeamMenu'
      : 'teamMenu';

  try {
    await setDoc(userDocRef, {
      [menuField]: menu
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Update season stats toggles
export const updateSeasonStatsToggles = async (newSeasonStatsToggles: StringToAnyMap, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  try {
    await setDoc(userDocRef, {
      ['seasonStatsToggles']: newSeasonStatsToggles
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};
