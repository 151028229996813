import { db } from '../../../firebase';

import {
  collection,
  addDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  orderBy,
} from 'firebase/firestore';

import { StringToAnyMap, PlayerClause } from '../../types';


// Get all clauses
export const getAllClauses = async (club: string): Promise<PlayerClause[]> => {

  const clauseDocRef = collection(db, 'configs', club, 'clauses');

  const q = query(clauseDocRef, orderBy('date', 'desc'));

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as PlayerClause }));
  }
  catch (error) {
    return [];
  }
};


// Add a new clause and return the document id as the new clause id
export const addClause = async (clause: PlayerClause, club: string): Promise<string | undefined> => {

  const clauseDocRef = collection(db, 'configs', club, 'clauses');

  try {
    const docRef = await addDoc(clauseDocRef, clause);
    return docRef.id;
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return undefined;
  }
};


// Update a clause
export const updateClause = async (clauseId: string, newValues: StringToAnyMap, club: string): Promise<boolean> => {

  const clauseDocRef = doc(db, 'configs', club, 'clauses', clauseId);

  try {
    await updateDoc(clauseDocRef, newValues);
    return true;
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return false;
  }
};
