import '../../platform.css';
import './accordions.css';

import { Dispatch, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import { Dialog } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap, SeasonCondition, SeasonConditionStatus, SeasonSubCondition } from '../../types';
import { getDisplayEconomicValue } from '../../utils/currencyUtils';
import { SeasonSubConditionStatusModal } from '../modals/clauseModals/SeasonSubConditionStatusModal';
import { SeasonConditionStatusModal } from '../modals/clauseModals/SeasonConditionStatusModal';
import { ClauseAction } from '../documents/clause/clauseReducer';
import { getOrdinalSuffix } from '../../utils/utils';
import { ClauseState } from '../documents/clause/clauseInitialState';


const isSubConditionReached = (
  subCondition: SeasonSubCondition,
  seasonStartedOverAYearAgo: boolean,
  seasonData: StringToAnyMap | undefined
) => {

  if (subCondition.condition === 'other') return seasonStartedOverAYearAgo;

  if (!seasonData) return false;

  if (subCondition.condition === 'tablePosition') {
    const threshold = Number(subCondition.threshold);
    const position = seasonData.tablePosition;

    if (position === undefined) return false;

    const leagueIsOver = seasonData.leagueMatchesPlayed >= seasonData.totalPossibleLeagueMatches;
    return position <= threshold && leagueIsOver;
  }

  const isPercentage =
    (
      subCondition.condition === 'matchesPlayed'
      || subCondition.condition === 'matchesStarted'
      || subCondition.condition === 'minutesPlayed'
    ) && subCondition.threshold.toString().includes('%');

  // if minMinutesPlayed is set, the seasonData accessor will be matchesPlayed<minMinutesPlayed>
  const accessor = subCondition.minMinutesPlayed
    ? (subCondition.condition + subCondition.minMinutesPlayed)
    : subCondition.condition;

  const absoluteValue = seasonData[accessor] ?? 0;
  const possibleValue = isPercentage
    ? accessor === 'minutesPlayed'
      ? seasonData['totalPossibleMinutes']
      : seasonData['totalPossibleMatches']
    : undefined;

  const currentValue = isPercentage
    ? Math.round((absoluteValue / possibleValue) * 100)
    : absoluteValue;

  const maxThreshold = Number(subCondition.threshold.toString().replace('%', ''));
  const isReached = currentValue >= maxThreshold && (!isPercentage || seasonStartedOverAYearAgo);

  return isReached;
};


// decides if a notification should be displayed for a season condition
// a notification is displayed if all common subconditions and all subconditions of one orCondition are either achieved or potentially reached
// a subcondition is potentially reached if either the data suggest it is achieved, or if the season is over and the data cannot tell if it is achieved
export const allSubConditionsAreAchievedOrPotentiallyReachedForSeason = (
  seasonCondition: SeasonCondition,
  seasonString: string,
  seasonStartedOverAYearAgo: boolean
) => {

  // if the maxAmount is reached, we dont want to show any notification
  if (seasonCondition.maxAmount !== undefined && (seasonCondition.totalResolvedAmount ?? 0) >= seasonCondition.maxAmount) return false;

  const seasonData = (seasonCondition.seasonToCurrentValues ?? {})[seasonString];
  const seasonStatus = seasonCondition.seasonToCurrentStatuses[seasonString];
  const commonSubConditionStatues = (seasonStatus ?? {})['commonSubConditionStatuses'];
  const orConditionStatuses = (seasonStatus ?? {})['orConditionStatuses'];

  const allSubConditionsAreAchievedOrPotentiallyReached = seasonCondition.commonSubConditions && seasonCondition.commonSubConditions.every(
    (commonSubCondition, commonSubConditionIndex) => {

      const subConditionStatus = commonSubConditionStatues ? commonSubConditionStatues[commonSubConditionIndex] : null;

      if (subConditionStatus === 'achieved') return true;
      if (subConditionStatus === 'notAchieved') return false;

      return isSubConditionReached(commonSubCondition, seasonStartedOverAYearAgo, seasonData);
    }
  );

  if (seasonCondition.commonSubConditions && !allSubConditionsAreAchievedOrPotentiallyReached) return false;

  if (seasonCondition.orConditions) {

    const atLeastOneOrConditionIsAchievedOrPotentiallyReached = seasonCondition.orConditions.some((orCondition, orConditionIndex) => {

      const orConditionStatus = orConditionStatuses ? orConditionStatuses[orConditionIndex] : null;

      if (orConditionStatus === 'achieved') return true;
      if (orConditionStatus === 'notAchieved') return false;

      return orCondition.subConditions.every(orSubCondition => {
        return isSubConditionReached(orSubCondition, seasonStartedOverAYearAgo, seasonData);
      });
    });

    return atLeastOneOrConditionIsAchievedOrPotentiallyReached;
  }

  return true;
};


interface SeasonAccordionProps {
  state: ClauseState;
  seasonString: string;
  seasonCondition: SeasonCondition;
  shouldStartExpanded: boolean;
  seasonStartedOverAYearAgo: boolean;
  isActiveSubClause: boolean;
  dispatch: Dispatch<ClauseAction>;
}


export const SeasonAccordion: React.FC<SeasonAccordionProps> = ({
  state,
  seasonString,
  seasonCondition,
  shouldStartExpanded,
  seasonStartedOverAYearAgo,
  isActiveSubClause,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [isExpanded, setIsExpanded] = useState(false);

  const [isResolveSeasonConditionModalOpen, setIsResolveSeasonConditionModalOpen] = useState(false);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [orConditionIndexClicked, setOrConditionIndexClicked] = useState<number | undefined>(undefined);
  const [commonSubConditionIndexClicked, setCommonSubConditionIndexClicked] = useState<number | undefined>(undefined);

  const [currentStatusOfConditionClicked, setCurrentStatusOfConditionClicked] = useState<string | null>(null);
  const [statusSuggestionForCommonSubConditionClicked, setStatusSuggestionForCommonSubConditionClicked] = useState<string | undefined>(undefined);

  const [conditionDescriptions, setConditionDescriptions] = useState<string[]>([]);


  const handleAccordionClick = (forceExpansion?: boolean) => {
    const element = document.getElementById('accordion-' + seasonCondition.conditionId + '-' + seasonString);
    if (element) {
      if (isExpanded && !forceExpansion) {
        element.style.height = '34px';
      }
      else {
        element.style.height = element.scrollHeight + 'px';
      }
    }
    setIsExpanded(forceExpansion ? true : !isExpanded);
  };


  const handleSeasonIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsResolveSeasonConditionModalOpen(true);
  };


  const getSubConditionDescription = (subCondition: SeasonSubCondition | undefined) => {
    let conditionDescription = subCondition ? subCondition.threshold.toString() : '';

    if (userConfig && subCondition && subCondition.condition !== 'other') {
      if (subCondition.condition === 'tablePosition') {
        const ordinalSuffix = getOrdinalSuffix(Number(subCondition.threshold), userConfig.language);
        conditionDescription += ordinalSuffix + ' ' + translate('placeInTheLeague', userConfig.language, true);
      }
      else {
        const isPercentage = subCondition.threshold.toString().includes('%');
        if (isPercentage) {
          conditionDescription += ' ' + translate('<percentage>of', userConfig.language);
        }

        conditionDescription += ' ' + translate(subCondition.condition, userConfig.language, true);
      }

      conditionDescription += ' ' + translate('in<season>', userConfig?.language) + ' ' + seasonString;
    }

    return conditionDescription;
  };


  const handleConditionIconClick = (
    event: React.MouseEvent,
    currentStatus: string | null,
    commonSubConditionIndex?: number,
    orConditionIndex?: number,
    statusSuggestion?: string
  ) => {
    event.stopPropagation();
    setIsStatusModalOpen(true);

    setCommonSubConditionIndexClicked(commonSubConditionIndex);
    setOrConditionIndexClicked(orConditionIndex);

    setCurrentStatusOfConditionClicked(currentStatus);
    setStatusSuggestionForCommonSubConditionClicked(statusSuggestion);

    if (commonSubConditionIndex !== undefined) {
      const subCondition = seasonCondition.commonSubConditions ? seasonCondition.commonSubConditions[commonSubConditionIndex] : undefined;
      const conditionDescription = getSubConditionDescription(subCondition);
      setConditionDescriptions([conditionDescription]);
    }

    else if (orConditionIndex !== undefined) {
      const orCondition = seasonCondition.orConditions ? seasonCondition.orConditions[orConditionIndex] : undefined;
      const descriptions = orCondition ? orCondition.subConditions.map(subCondition => getSubConditionDescription(subCondition)) : [];
      setConditionDescriptions(descriptions);
    }
  };


  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
    setTimeout(() => {
      setCommonSubConditionIndexClicked(undefined);
      setOrConditionIndexClicked(undefined);
      setCurrentStatusOfConditionClicked(null);
      setStatusSuggestionForCommonSubConditionClicked(undefined);
      setConditionDescriptions([]);
    }, 150);
  };


  const handleUpdateSeasonSubConditionStatus = (newStatus: string | null) => {
    const payload = {
      conditionId: seasonCondition.conditionId,
      seasonString: seasonString,
      commonSubConditionIndex: commonSubConditionIndexClicked,
      orConditionIndex: orConditionIndexClicked,
      newStatus: newStatus
    };
    dispatch({ type: 'UPDATE_SEASON_SUB_CONDITION_STATUS', payload });
    handleCloseStatusModal();
  };


  useEffect(() => {
    if (shouldStartExpanded) {
      handleAccordionClick(true);
    }
  }, [shouldStartExpanded, seasonCondition]); // eslint-disable-line react-hooks/exhaustive-deps


  // The overall season status (as stored on the clause) is either
  //
  // - resolved
  //   display: resolved icon with the correct amount
  //   actions:
  //     reactivate (set status to 'null' and delete payment)
  //     discard (set status to 'discarded' and delete payment)
  //
  // - discarded
  //   display: discarded icon
  //   actions:
  //     reactivate (set status to 'null' and delete payment)
  //     resolve (set status to 'resolved' and add payment)
  //
  // - null (not existing)
  //   display:
  //     if all subconditions are resolved, or if all subconditions are either reached or they are manual and the season is over: notification icon
  //       for orConditions, the entire is orCondition is treated as a subcondition, where only one of the subconditions of the orCondition must be reached
  //     else: pending icon
  //   actions:
  //     resolve (set status to 'resolved' and add payment)
  //     discard (set status to 'discarded' and delete payment)
  //
  //
  // A common subcondition can be achieved, notAchieved or null
  //   display:
  //     if the subcondition is achieved: green resolved icon
  //     if the subcondition is notAchieved: red discarded icon
  //     if the subcondition status is null and it is manual: question mark icon (pending to be confirmed resolved or discarded)
  //     if the subcondition status is null and it is reached: gray resolved icon (pending to be confirmed resolved)
  //     if the subcondition status is null and it is not reached and season is ongoing: pending icon (wating to be reached)
  //     if the subcondition status is null and it is not reached and the season is over: gray discarded icon (pending to be confirmed discarded)
  //
  //   actions:
  //     icon click will open modal where the user can set resolved, discarded or pending (null)
  //
  // An orCondition will be a union/superset of all its subconditions
  //   display:
  //     if there is only one subcondition: display as if it was a common subcondition
  //     if there are multiple and at least one is manual: display as if it was a manual common subcondition
  //     if there are multiple and all are automatic: display as if it was a common subcondition
  //
  // Dependencies (automatic status updates)
  // - whenever a subcondition is discarded
  //   - set season status to discarded if this subcondition is common, or if it is an orCondition and every other orCondition is discarded
  // - whenever an orCondition is resolved
  //   - discard all other orConditions
  // - whenever a season is resolved
  //   if an orCondition is resolved, use that amount
  //   if no orCondition is resolved, allow the user to select amount/condition when resolving and then update orCondition statuses accordingly
  // - whenever a season is discarded or reactivated
  //   - reset all subcondition statuses

  const seasonData = (seasonCondition.seasonToCurrentValues ?? {})[seasonString];
  const seasonStatus: SeasonConditionStatus = seasonCondition.seasonToCurrentStatuses[seasonString];
  const overallStatus = (seasonStatus ?? {})['overallStatus'];
  const commonSubConditionStatues = (seasonStatus ?? {})['commonSubConditionStatuses'];
  const orConditionStatuses = (seasonStatus ?? {})['orConditionStatuses'];

  const allSubConditionsAreAchievedOrPotentiallyReached = allSubConditionsAreAchievedOrPotentiallyReachedForSeason(
    seasonCondition,
    seasonString,
    seasonStartedOverAYearAgo
  );

  const showSeasonNotification = !overallStatus && allSubConditionsAreAchievedOrPotentiallyReached && isActiveSubClause;

  return (
    <div
      key={seasonCondition.conditionId + '-' + seasonString}
      id={'accordion-' + seasonCondition.conditionId + '-' + seasonString}
      className='season-accordion-container'
      onClick={() => !isStatusModalOpen && !isResolveSeasonConditionModalOpen ? handleAccordionClick() : null}>

      <Dialog
        open={isStatusModalOpen}
        onClose={() => handleCloseStatusModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <SeasonSubConditionStatusModal
          currentStatus={currentStatusOfConditionClicked}
          newStatusSuggestion={statusSuggestionForCommonSubConditionClicked}
          conditionDescriptions={conditionDescriptions}
          handleUpdateSeasonSubConditionStatus={handleUpdateSeasonSubConditionStatus}
          closeModal={() => handleCloseStatusModal()}
        />
      </Dialog>

      <Dialog
        open={isResolveSeasonConditionModalOpen}
        onClose={() => setIsResolveSeasonConditionModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <SeasonConditionStatusModal
          state={state}
          isResolveSeasonConditionModalOpen={isResolveSeasonConditionModalOpen}
          seasonCondition={seasonCondition}
          seasonString={seasonString}
          dispatch={dispatch}
          closeModal={() => setIsResolveSeasonConditionModalOpen(false)}
        />
      </Dialog>

      <div className='season-accordion-title'>
        <div style={{ width: 40 }}>
          {seasonString}
        </div>

        <div
          className={'season-accordion-subcondition-icon' + (showSeasonNotification ? ' season-accordion-subcondition-notification-icon' : '')}
          title={
            showSeasonNotification
              ? translate('resolveCondition', userConfig?.language)
              : translate(overallStatus ? 'editConditionStatus' : 'provideConditionStatus', userConfig?.language)
          }
          style={{
            width: showSeasonNotification ? 18 : undefined,
            height: showSeasonNotification ? 18 : undefined,
            marginLeft: showSeasonNotification ? 5 : 3,
            marginRight: showSeasonNotification ? 8 : 6,

          }}
          onClick={(event) => handleSeasonIconClick(event)}>
          {overallStatus === 'resolved' && <TaskAltIcon style={{ fontSize: 14, color: '#9df78b' }} />}
          {overallStatus === 'discarded' && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
          {showSeasonNotification && <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />}
          {!overallStatus && !allSubConditionsAreAchievedOrPotentiallyReached && isActiveSubClause && <HourglassTopIcon style={{ fontSize: 14 }} />}
          {!overallStatus && !isActiveSubClause && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
        </div>

        {!overallStatus && seasonCondition.amount && isActiveSubClause && (
          <div style={{ fontSize: 11, color: '#ffffffaa' }}>
            {getDisplayEconomicValue(seasonCondition.amount, true)}
          </div>
        )}

        {overallStatus === 'resolved' && seasonStatus.resolvedAmount !== null && seasonStatus.resolvedAmount !== undefined && (
          <div style={{ fontSize: 11, color: '#ffffffd0' }}>
            {getDisplayEconomicValue(seasonStatus.resolvedAmount, true)}
          </div>
        )}
      </div>

      <div className={'season-accordion-section' + (isExpanded ? ' season-accordion-section-expanded' : '')}>
        {seasonCondition.commonSubConditions && seasonCondition.commonSubConditions.map((commonSubCondition, commonSubConditionIndex) => {

          const subConditionStatus = commonSubConditionStatues ? commonSubConditionStatues[commonSubConditionIndex] : null;

          // seasonData will not exist when adding a new clause, it will be calculated when the clause is added/saved
          if (!seasonData) {
            return (
              <div key={commonSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: commonSubConditionIndex === 0 ? 2 : undefined }}>
                <div className='season-accordion-subcondition-title'>
                  {commonSubCondition.condition === 'other'
                    ? commonSubCondition.threshold
                    : translate(commonSubCondition.condition, userConfig?.language)}
                  {commonSubCondition.condition !== 'other'
                    ? ': ' + commonSubCondition.threshold
                    : ''}
                </div>
              </div>
            );
          }

          if (commonSubCondition.condition === 'other') {
            return (
              <div key={commonSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: commonSubConditionIndex === 0 ? 2 : undefined }}>
                <div
                  className='season-accordion-subcondition-icon'
                  onClick={(event) => handleConditionIconClick(event, subConditionStatus, commonSubConditionIndex)}
                >
                  {subConditionStatus === 'achieved' && <TaskAltIcon style={{ fontSize: 14, color: '#9df78b' }} />}
                  {subConditionStatus === 'notAchieved' && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
                  {!subConditionStatus && <QuestionMarkIcon style={{ fontSize: 14 }} />}
                </div>
                <div className='season-accordion-subcondition-title'>
                  {commonSubCondition.threshold}
                </div>
              </div>
            );
          }

          if (commonSubCondition.condition === 'tablePosition') {

            const threshold = Number(commonSubCondition.threshold);
            let progressValue = 0;
            let displayValue = seasonData.tablePosition;

            if (seasonData.tablePosition === undefined || !seasonData.leagueMatchesPlayed) {
              progressValue = 0;
              displayValue = '-';
            }
            else if (seasonData.tablePosition <= threshold) {
              progressValue = 100;
            }
            else {
              const barWidth = seasonData.totalTeamsInLeague - threshold;
              const barPosition = seasonData.tablePosition - threshold;
              progressValue = 100 - Math.min((barPosition / barWidth) * 100, 100);
            }

            const progress = progressValue + '%';

            const leagueIsOver = seasonData.leagueMatchesPlayed >= seasonData.totalPossibleLeagueMatches;
            const isReached = progressValue === 100 && leagueIsOver;

            const newStatusSuggestion = isReached && leagueIsOver
              ? 'achieved'
              : !isReached && leagueIsOver
                ? 'notAchieved'
                : undefined;

            return (
              <div key={commonSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: commonSubConditionIndex === 0 ? 2 : undefined }}>
                <div
                  className='season-accordion-subcondition-icon'
                  onClick={(event) => handleConditionIconClick(event, subConditionStatus, commonSubConditionIndex, undefined, newStatusSuggestion)}
                >
                  {subConditionStatus === 'achieved' && <TaskAltIcon style={{ fontSize: 14, color: '#9df78b' }} />}
                  {subConditionStatus === 'notAchieved' && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
                  {!subConditionStatus && isReached && <TaskAltIcon style={{ fontSize: 14 }} />}
                  {!subConditionStatus && !isReached && !seasonStartedOverAYearAgo && isActiveSubClause && <HourglassTopIcon style={{ fontSize: 14 }} />}
                  {!subConditionStatus && !isReached && (seasonStartedOverAYearAgo || !isActiveSubClause) && <CancelOutlinedIcon style={{ fontSize: 14 }} />}
                </div>
                <div className='season-accordion-subcondition-title'>
                  {translate(commonSubCondition.condition, userConfig?.language)}
                  :
                  <div className='season-accordion-subcondition-display-value'>
                    {displayValue}
                  </div>
                  {seasonData.tablePosition !== undefined && displayValue !== '-' && (
                    <div style={{ fontSize: 10, marginTop: 1.5 }}>
                      {' (' + seasonData.leagueMatchesPlayed + '/' + seasonData.totalPossibleLeagueMatches + ')'}
                    </div>
                  )}
                </div>

                <div className='season-accordion-subcondition-progress-bar'>
                  <div
                    className='season-accordion-subcondition-progress'
                    style={{ width: progress }}>
                    &nbsp;
                  </div>
                  <div className='season-accordion-subcondition-max-threshold-line'>
                    &nbsp;
                  </div>
                  <div className='season-accordion-subcondition-max-threshold'>
                    {commonSubCondition.threshold}
                  </div>
                </div>
              </div>
            );
          }

          const isPercentage =
            (
              commonSubCondition.condition === 'matchesPlayed'
              || commonSubCondition.condition === 'matchesStarted'
              || commonSubCondition.condition === 'minutesPlayed'
            ) && commonSubCondition.threshold.toString().includes('%');

          // if minMinutesPlayed is set, the seasonData accessor will be matchesPlayed/matchesStarted<minMinutesPlayed>
          const accessor = commonSubCondition.minMinutesPlayed
            ? (commonSubCondition.condition + commonSubCondition.minMinutesPlayed)
            : commonSubCondition.condition;

          const absoluteValue = seasonData[accessor] ?? 0;

          const possibleValue = isPercentage
            ? commonSubCondition.condition === 'minutesPlayed'
              ? seasonData['totalPossibleMinutes']
              : seasonData['totalPossibleMatches']
            : undefined;

          const currentValue = isPercentage
            ? possibleValue ? Math.round((absoluteValue / possibleValue) * 100) : 0
            : absoluteValue;

          const maxThreshold = Number(commonSubCondition.threshold.toString().replace('%', ''));

          const isMissingData = isPercentage && absoluteValue > possibleValue;

          const isReached = currentValue >= maxThreshold && (!isPercentage || seasonStartedOverAYearAgo);

          const newStatusSuggestion = isReached
            ? 'achieved'
            : !isReached && seasonStartedOverAYearAgo
              ? 'notAchieved'
              : undefined;

          let progressValue = Math.min((currentValue / maxThreshold) * 100, 100);
          progressValue = isNaN(progressValue) ? 0 : progressValue;
          const progress = progressValue + '%';

          const displayValue = isPercentage
            ? ((isNaN(currentValue) || !possibleValue) ? '-' : currentValue + '%')
            : currentValue;

          return (
            <div key={commonSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: commonSubConditionIndex === 0 ? 2 : undefined }}>
              <div
                className='season-accordion-subcondition-icon'
                onClick={(event) => handleConditionIconClick(event, subConditionStatus, commonSubConditionIndex, undefined, newStatusSuggestion)}
              >
                {isMissingData && <QuestionMarkIcon style={{ fontSize: 14 }} />}
                {!isMissingData && subConditionStatus === 'achieved' && <TaskAltIcon style={{ fontSize: 14, color: '#9df78b' }} />}
                {!isMissingData && subConditionStatus === 'notAchieved' && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
                {!isMissingData && !subConditionStatus && isReached && <TaskAltIcon style={{ fontSize: 14 }} />}

                {!isMissingData
                  && !subConditionStatus
                  && !isReached
                  && !seasonStartedOverAYearAgo
                  && isActiveSubClause
                  && <HourglassTopIcon style={{ fontSize: 14 }} />
                }
                {!isMissingData
                  && !subConditionStatus
                  && !isReached
                  && (seasonStartedOverAYearAgo || !isActiveSubClause)
                  && <CancelOutlinedIcon style={{ fontSize: 14 }} />
                }
              </div>
              <div className='season-accordion-subcondition-title'>
                {translate(commonSubCondition.condition, userConfig?.language)}

                {commonSubCondition.minMinutesPlayed && userConfig && (
                  <div style={{ marginLeft: 2, fontSize: 10, marginTop: 1.5 }}>
                    {' (' + translate('atLeast<threshold>', userConfig?.language) + ' ' + commonSubCondition.minMinutesPlayed + ' min)'}
                  </div>
                )}

                :
                <div className='season-accordion-subcondition-display-value' style={{ color: isMissingData ? '#ffffffaa' : undefined }}>
                  {isMissingData ? translate('missingData', userConfig?.language) : displayValue}
                </div>
                {isPercentage && displayValue !== '-' && (
                  <div style={{ fontSize: 10, marginTop: 1.5 }}>
                    {' (' + absoluteValue + '/' + (isMissingData ? '?' : possibleValue) + ')'}
                  </div>
                )}
              </div>

              <div className='season-accordion-subcondition-progress-bar'>
                <div
                  className='season-accordion-subcondition-progress'
                  style={{ width: progress }}>
                  &nbsp;
                </div>
                <div className='season-accordion-subcondition-max-threshold-line'>
                  &nbsp;
                </div>
                <div className='season-accordion-subcondition-max-threshold'>
                  {commonSubCondition.threshold}
                </div>
              </div>
            </div>
          );
        })}

        {seasonCondition.commonSubConditions && seasonCondition.orConditions && (
          <div style={{ height: 26 }}>
            &nbsp;
          </div>
        )}

        {!seasonCondition.commonSubConditions && seasonCondition.orConditions && (
          <div style={{ height: 6 }}>
            &nbsp;
          </div>
        )}

        {seasonCondition.orConditions && seasonCondition.orConditions.map((orCondition, orConditionIndex) => {

          const orConditionStatus = orConditionStatuses ? orConditionStatuses[orConditionIndex] : null;

          // there are 4 options for icons to display
          // - achieved: green checkmark
          // - notAchieved: red cross
          // - null && season is ongoing: hourglass
          // - null && season is over && clause is active: question mark
          // - null && season is over && clause is inactive: gray cross

          // todo: if clause is active and this season is over, we currenlty show a question mark if the status is null
          // instead, we should have the same behaviour here as for common subconditions
          // if at least one subcondition is 'other', we show a question mark
          // if not, we show a gray cross if at least one is not reached and a gray task if all are reached

          return (
            <div key={orConditionIndex} className='season-accordion-or-condition'>
              <div className='season-accordion-or-condition-title-row'>
                <div
                  className='season-accordion-subcondition-icon'
                  onClick={(event) => handleConditionIconClick(event, orConditionStatus, undefined, orConditionIndex)}
                >
                  {orConditionStatus === 'achieved' && <TaskAltIcon style={{ fontSize: 14, color: '#9df78b' }} />}
                  {orConditionStatus === 'notAchieved' && <CancelOutlinedIcon style={{ fontSize: 14, color: '#ff6363' }} />}
                  {!orConditionStatus && !seasonStartedOverAYearAgo && <HourglassTopIcon style={{ fontSize: 14 }} />}
                  {!orConditionStatus && seasonStartedOverAYearAgo && isActiveSubClause && <QuestionMarkIcon style={{ fontSize: 14 }} />}
                  {!orConditionStatus && seasonStartedOverAYearAgo && !isActiveSubClause && <CancelOutlinedIcon style={{ fontSize: 14 }} />}
                </div>
                {getDisplayEconomicValue(orCondition.amount, true)}
              </div>

              <div>
                {orCondition.subConditions.map((orSubCondition, orSubConditionIndex) => {

                  // seasonData will not exist when adding a new clause, it will be calculated when the clause is added/saved
                  if (!seasonData) {
                    return (
                      <div key={orConditionIndex + '-' + orSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: 3 }}>
                        <div className='season-accordion-subcondition-title'>
                          {orSubCondition.condition === 'other'
                            ? orSubCondition.threshold
                            : translate(orSubCondition.condition, userConfig?.language)}
                          {orSubCondition.condition !== 'other'
                            ? ': ' + orSubCondition.threshold
                            : ''}
                        </div>
                      </div>
                    );
                  }

                  if (orSubCondition.condition === 'other') {
                    return (
                      <div key={orConditionIndex + '-' + orSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: 3 }}>
                        <div className='season-accordion-subcondition-title'>
                          {orSubCondition.threshold}
                        </div>
                      </div>
                    );
                  }

                  if (orSubCondition.condition === 'tablePosition') {

                    const threshold = Number(orSubCondition.threshold);
                    let progressValue = 0;
                    let displayValue = seasonData.tablePosition;

                    if (seasonData.tablePosition === undefined || !seasonData.leagueMatchesPlayed) {
                      progressValue = 0;
                      displayValue = '-';
                    }
                    else if (seasonData.tablePosition <= threshold) {
                      progressValue = 100;
                    }
                    else {
                      const barWidth = seasonData.totalTeamsInLeague - threshold;
                      const barPosition = seasonData.tablePosition - threshold;
                      progressValue = 100 - Math.min((barPosition / barWidth) * 100, 100);
                    }

                    const progress = progressValue + '%';

                    return (
                      <div key={orSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: 3 }}>

                        <div className='season-accordion-subcondition-title'>
                          {translate(orSubCondition.condition, userConfig?.language)}
                          :
                          <div className='season-accordion-subcondition-display-value'>
                            {displayValue}
                          </div>
                          {seasonData.tablePosition !== undefined && displayValue !== '-' && (
                            <div style={{ fontSize: 10, marginTop: 1.5 }}>
                              {' (' + seasonData.leagueMatchesPlayed + '/' + seasonData.totalPossibleLeagueMatches + ')'}
                            </div>
                          )}
                        </div>

                        <div className='season-accordion-subcondition-progress-bar'>
                          <div
                            className='season-accordion-subcondition-progress'
                            style={{ width: progress }}>
                            &nbsp;
                          </div>
                          <div className='season-accordion-subcondition-max-threshold-line'>
                            &nbsp;
                          </div>
                          <div className='season-accordion-subcondition-max-threshold'>
                            {orSubCondition.threshold}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  const isPercentage =
                    (
                      orSubCondition.condition === 'matchesPlayed'
                      || orSubCondition.condition === 'matchesStarted'
                      || orSubCondition.condition === 'minutesPlayed'
                    ) && orSubCondition.threshold.toString().includes('%');

                  // if minMinutesPlayed is set, the seasonData accessor will be matchesPlayed<minMinutesPlayed>
                  const accessor = orSubCondition.minMinutesPlayed ? (orSubCondition.condition + orSubCondition.minMinutesPlayed) : orSubCondition.condition;

                  const absoluteValue = seasonData[accessor] ?? 0;

                  const possibleValue = isPercentage
                    ? orSubCondition.condition === 'minutesPlayed'
                      ? seasonData['totalPossibleMinutes']
                      : seasonData['totalPossibleMatches']
                    : undefined;

                  const currentValue = isPercentage
                    ? possibleValue ? Math.round((absoluteValue / possibleValue) * 100) : 0
                    : absoluteValue;

                  const maxThreshold = Number(orSubCondition.threshold.toString().replace('%', ''));

                  const isMissingData = isPercentage && absoluteValue > possibleValue;

                  let progressValue = isMissingData ? 0 : Math.min((currentValue / maxThreshold) * 100, 100);
                  progressValue = isNaN(progressValue) ? 0 : progressValue;
                  const progress = progressValue + '%';

                  const displayValue = isPercentage
                    ? ((isNaN(currentValue) || !possibleValue) ? '-' : currentValue + '%')
                    : currentValue;

                  return (
                    <div key={orConditionIndex + '-' + orSubConditionIndex} className='season-accordion-subcondition' style={{ marginTop: 3 }}>

                      <div className='season-accordion-subcondition-title'>
                        {translate(orSubCondition.condition, userConfig?.language)}

                        {orSubCondition.minMinutesPlayed && userConfig && (
                          <div style={{ marginLeft: 2, fontSize: 10, marginTop: 1.5 }}>
                            {' (' + translate('atLeast<threshold>', userConfig?.language) + ' ' + orSubCondition.minMinutesPlayed + ' min)'}
                          </div>
                        )}

                        :
                        <div className='season-accordion-subcondition-display-value' style={{ color: isMissingData ? '#ffffffaa' : undefined }}>
                          {isMissingData ? translate('missingData', userConfig?.language) : displayValue}
                        </div>
                        {isPercentage && displayValue !== '-' && (
                          <div style={{ fontSize: 10, marginTop: 1.5 }}>
                            {' (' + absoluteValue + '/' + (isMissingData ? '?' : possibleValue) + ')'}
                          </div>
                        )}
                      </div>

                      <div className='season-accordion-subcondition-progress-bar'>
                        <div
                          className='season-accordion-subcondition-progress'
                          style={{ width: progress }}>
                          &nbsp;
                        </div>
                        <div className='season-accordion-subcondition-max-threshold-line'>
                          &nbsp;
                        </div>
                        <div className='season-accordion-subcondition-max-threshold'>
                          {orSubCondition.threshold}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {seasonCondition.orConditions && orConditionIndex < seasonCondition.orConditions.length - 1 && (
                <div className='season-accordion-or-condition-divider'>
                  &nbsp;
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={'season-accordion-expand-icon-container' + (isExpanded ? ' accordion-icon-container-rotated' : '')}>
        {<KeyboardArrowDownIcon style={{ fontSize: 16 }} />}
      </div>
    </div >
  );
};
