import '../../platform.css';
import './ownTeam.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { playerCareersState } from '../../recoil/atoms/playerCareersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { forceSimulation, forceX, forceY, forceCollide } from 'd3-force';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap, PlayerId, PlayerMap, Iteration } from '../../types';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { DropDownSelect } from '../../components/input/DropDownSelect';
import { ClubIterationDropDown } from '../../components/input/ClubIterationDropDown';
import { getContractExpirationIndexEquivalent, getIndexColor } from '../../utils/colorUtils';
import { userHasEconomyAccess } from '../../utils/userUtils';
import { getDisplayEconomicValue, getLogairthmicValue } from '../../utils/currencyUtils';
import { useGetAndAddPlayerCareer } from '../../recoil/hooks/useGetAndAddPlayerCareer';
import { getDisplayPlayerName } from '../../utils/playerUtils';


interface OwnTeamMatrixDataPoint {
  id: PlayerId;
  fullname: string;
  firstAndLastName: string;

  left: number; // percentage, based on age
  top: number; // percentage, based on percentage of minutes played

  image_url: string;
  contract_expiration: string | undefined;
  skill_index: number | undefined;
  market_value: number | undefined;

  salaryPlusBonus: number | undefined;
  signingCost: number | undefined;
  locality: string | undefined;

  // ABC: string | undefined;
  // homegrown: boolean | undefined;
}


interface OwnTeamMatrixViewProps {
  ownTeamPlayers: PlayerMap;
}

export const OwnTeamMatrixView: React.FC<OwnTeamMatrixViewProps> = ({ ownTeamPlayers }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const competitions = useRecoilValue(competitionsState);

  const playerCareers = useRecoilValue(playerCareersState);
  const getAndAddClubIterationMap = useGetAndAddPlayerCareer();
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const [data, setData] = useState<OwnTeamMatrixDataPoint[]>([]);
  const [simulationNodes, setSimulationNodes] = useState<OwnTeamMatrixDataPoint[]>([]);

  const [iterationDropDownOptions, setIterationDropDownOptions] = useState<Iteration[]>([]);
  const [selectedIterations, setSelectedIterations] = useState<number[]>([]);
  const [isIterationsExpanded, setIsIterationsExpanded] = useState(false);

  const [isPercentageMinutesToggled, setIsPercentageMinutesToggled] = useState(false);

  const [selectedThirdDimension, setSelectedThirdDimension] = useState<string[]>(['contractLength']);
  const [isThirdDimensionExpanded, setIsThirdDimensionExpanded] = useState(false);

  const [minAge, setMinAge] = useState<number | undefined>(undefined);
  const [maxAge, setMaxAge] = useState<number | undefined>(undefined);
  const [ageThresholdLeft, setAgeThresholdLeft] = useState<number | undefined>(undefined);

  const [maxMinutes, setMaxMinutes] = useState<string>('100%');

  const [minAndMaxThirdDimensionValuesAreSet, setMinAndMaxThirdDimensionValuesAreSet] = useState(false);

  const [maxSalaryPlusBonus, setMaxSalaryPlusBonus] = useState<number | undefined>(undefined);
  const [minSalaryPlusBonus, setMinSalaryPlusBonus] = useState<number | undefined>(undefined);

  const [maxSigningCost, setMaxSigningCost] = useState<number | undefined>(undefined);
  const [minSigningCost, setMinSigningCost] = useState<number | undefined>(undefined);

  const [maxMarketValue, setMaxMarketValue] = useState<number | undefined>(undefined);
  const [minMarketValue, setMinMarketValue] = useState<number | undefined>(undefined);

  const [showPlayerNames, setShowPlayerNames] = useState(false);

  const thirdDimensionOptions = (userHasEconomyAccess(userConfig)
    ? ['contractLength', 'skillIndex', 'marketValue', 'salaryPlusBonus', 'signingCostLong', 'locality']
    : ['contractLength', 'skillIndex', 'marketValue', 'locality']);
  // settings?.clubSettings.countryCode === 'JP'
  //   ? (userHasEconomyAccess(userConfig)
  //     ? ['contractLength', 'skillIndex', 'marketValue', 'salaryPlusBonus', 'signingCostLong', 'ABC', 'homegrown']
  //     : ['contractLength', 'skillIndex', 'marketValue', 'ABC', 'homegrown'])


  const handlePlayerClick = (player: OwnTeamMatrixDataPoint) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: !isNaN(Number(player.id)) ? playerOverviews[Number(player.id)] : undefined
    });
  };


  const removeDropDownExpansions = () => {
    setIsIterationsExpanded(false);
    setIsThirdDimensionExpanded(false);
  };


  const getThirdDimensionIndexEquivalent = (player: OwnTeamMatrixDataPoint): number | undefined => {

    if (selectedThirdDimension.includes('contractLength')) {
      return getContractExpirationIndexEquivalent(player.contract_expiration);
    }

    else if (selectedThirdDimension.includes('skillIndex')) {
      return player.skill_index;
    }

    else if (selectedThirdDimension.includes('salaryPlusBonus')) {
      if (maxSalaryPlusBonus !== undefined && minSalaryPlusBonus !== undefined) {

        if (maxSalaryPlusBonus === minSalaryPlusBonus && player.salaryPlusBonus !== undefined) return 5;

        return player.salaryPlusBonus
          ? 10 - (((player.salaryPlusBonus - minSalaryPlusBonus) / (maxSalaryPlusBonus - minSalaryPlusBonus)) * 10)
          : undefined;
      }
    }

    else if (selectedThirdDimension.includes('signingCostLong')) {
      if (maxSigningCost !== undefined && minSigningCost !== undefined) {

        if (maxSigningCost === minSigningCost && player.signingCost !== undefined) return 5;

        return player.signingCost
          ? 10 - (((player.signingCost - minSigningCost) / (maxSigningCost - minSigningCost)) * 10)
          : undefined;
      }
    }

    else if (selectedThirdDimension.includes('marketValue')) {
      if (maxMarketValue !== undefined && minMarketValue !== undefined) {

        if (maxMarketValue === minMarketValue && player.market_value !== undefined) return 5;

        const logarithmicMarketValue = getLogairthmicValue(player.market_value);
        const logarithmicMaxMarketValue = getLogairthmicValue(maxMarketValue);

        return logarithmicMarketValue !== undefined && logarithmicMaxMarketValue !== undefined
          ? ((logarithmicMarketValue - minMarketValue) / (logarithmicMaxMarketValue - minMarketValue)) * 10
          : undefined;
      }
    }

    else if (selectedThirdDimension.includes('locality')) {
      return player.locality === 'local'
        ? 10
        : player.locality === 'national'
          ? 5
          : player.locality === 'international'
            ? 0
            : undefined;
    }

    // else if (selectedThirdDimension.includes('ABC')) {
    //   return player.ABC === 'A'
    //     ? 10
    //     : player.ABC === 'B'
    //       ? 5
    //       : player.ABC === 'C'
    //         ? 0
    //         : undefined;
    // }

    // else if (selectedThirdDimension.includes('homegrown')) {
    //   return player.homegrown
    //     ? 10
    //     : 0;
    // }

    return undefined;
  };


  const getColorBarDescription = (isLeft: boolean): string => {
    if (!userConfig) return '';

    if (selectedThirdDimension.includes('contractLength')) {
      return isLeft
        ? '< 6 ' + translate('monthsShort', userConfig.language)
        : '4+ ' + translate('years', userConfig.language, true);
    }

    else if (selectedThirdDimension.includes('skillIndex')) {
      return isLeft ? '0' : '10';
    }

    else if (selectedThirdDimension.includes('salaryPlusBonus')) {
      return isLeft
        ? maxSalaryPlusBonus !== undefined
          ? getDisplayEconomicValue(maxSalaryPlusBonus, true)
          : ''
        : minSalaryPlusBonus !== undefined
          ? getDisplayEconomicValue(minSalaryPlusBonus, true)
          : '';
    }

    else if (selectedThirdDimension.includes('signingCostLong')) {
      return isLeft
        ? maxSigningCost !== undefined
          ? getDisplayEconomicValue(maxSigningCost, true)
          : ''
        : minSigningCost !== undefined
          ? getDisplayEconomicValue(minSigningCost, true)
          : '';
    }

    else if (selectedThirdDimension.includes('marketValue')) {
      return isLeft
        ? minMarketValue !== undefined
          ? getDisplayEconomicValue(minMarketValue, true)
          : ''
        : maxMarketValue !== undefined
          ? getDisplayEconomicValue(maxMarketValue, true)
          : '';
    }

    else if (selectedThirdDimension.includes('locality')) {
      return translate(isLeft ? 'internationalLocality' : 'localLocality', userConfig.language);
    }

    // else if (selectedThirdDimension.includes('ABC')) {
    //   return isLeft ? 'C' : 'A';
    // }

    // else if (selectedThirdDimension.includes('homegrown')) {
    //   return translate(isLeft ? 'notHomegrown' : 'homegrown', userConfig.language);
    // }

    return '';
  };


  useEffect(() => {
    if (clubSettings && competitions) {
      const iterations = clubSettings.iterations;

      let currentIteration: number | undefined = undefined;
      iterations.forEach((iteration: Iteration) => {
        const competitionId = Number(iteration.competitionId);
        if (!currentIteration && competitions[competitionId] && competitions[competitionId].type === 'League') {
          currentIteration = iteration.iterationId;
        }
      });
      if (currentIteration) {
        setSelectedIterations([currentIteration]);
      }

      setIterationDropDownOptions(iterations);
    }
  }, [clubSettings, competitions]);


  useEffect(() => {
    if (ownTeamPlayers) {

      let min: number | undefined = undefined;
      let max: number | undefined = undefined;

      Object.keys(ownTeamPlayers).forEach(key => {
        const age = ownTeamPlayers[key]['age'];
        if (age) {
          if (min === undefined || age < min) min = age;
          if (max === undefined || age > max) max = age;
        }
      });

      let minFloor = min ? Math.floor(min) : min;
      let maxCeil = max ? Math.ceil(max) : max;

      if (min && minFloor && max && maxCeil) {
        if (min - minFloor < 0.5) minFloor--;
        if (maxCeil - max < 0.5) maxCeil++;
      }

      setMinAge(minFloor);
      setMaxAge(maxCeil);
    }
  }, [ownTeamPlayers]);


  useEffect(() => {
    if (ownTeamPlayers && playerCareers && maxAge && minAge && iterationDropDownOptions.length > 0 && clubSettings) {

      const clubId = clubSettings.clubId;

      const ageThreshold = 24;
      setAgeThresholdLeft(((ageThreshold - minAge) / (maxAge - minAge)) * 100);

      // calculate y-axis values
      let maxTotalMinutes = 0;
      const playerToYAxisValues: { [key: number | string]: StringToAnyMap } = {};

      Object.keys(ownTeamPlayers).forEach((playerId: PlayerId) => {

        let minutesPlayed = 0;
        let possibleMinutes = 0;

        const numberId = Number(playerId);

        if (!isNaN(numberId)) {
          let playerCareer = playerCareers[numberId];

          if (!playerCareer) {
            // should not reach this path - all players on own team should always have playerCareer loaded
            console.log('ClubIterationMap not found in matrix for player ' + playerId); // eslint-disable-line no-console
            getAndAddClubIterationMap(numberId, currentUser).then(newClubIterationMap => {
              if (newClubIterationMap) {
                playerCareer = newClubIterationMap;
              }
            });
          }

          if (playerCareer) {
            selectedIterations.forEach(iteration => {
              const clubIterationKey = `${clubId},${iteration}`;
              const clubIteration = playerCareer[clubIterationKey];

              if (clubIteration && clubIteration.event_data_exist) {
                minutesPlayed += clubIteration.basic_stats.minutes_played;
                possibleMinutes += clubIteration.basic_stats.total_possible_minutes;
              }
            });

            if (minutesPlayed > maxTotalMinutes) maxTotalMinutes = minutesPlayed;
          }
        }

        playerToYAxisValues[playerId] = {
          minutesPlayed: minutesPlayed,
          percentageMinutesPlayed: possibleMinutes ? (minutesPlayed / possibleMinutes) * 100 : 0
        };
      });

      const adjustedMaxMinutes = Math.ceil(maxTotalMinutes / 100) * 100;
      if (!isPercentageMinutesToggled) {
        setMaxMinutes(adjustedMaxMinutes.toString());
      }

      // note: playerId below will be a string, while player.id will be correct
      const matrixData: OwnTeamMatrixDataPoint[] = Object.keys(ownTeamPlayers).map((playerId: PlayerId) => {
        const player = ownTeamPlayers[playerId];

        const left = player.age ? ((player.age - minAge) / (maxAge - minAge)) * 100 : 2;

        const top = isPercentageMinutesToggled
          ? 100 - playerToYAxisValues[player.id].percentageMinutesPlayed
          : (adjustedMaxMinutes > 0 ? 100 - ((playerToYAxisValues[playerId].minutesPlayed / adjustedMaxMinutes) * 100) : 100);

        const matrixDataPoint: OwnTeamMatrixDataPoint = {
          id: player.id,
          fullname: player.fullname,
          firstAndLastName: getDisplayPlayerName(player.fullname, 10),

          left: left,
          top: top,

          image_url: player.image_url ?? 'https://media.api-sports.io/football/players/0.png',
          contract_expiration: player.contract_expiration,
          skill_index: player.skill_index,

          salaryPlusBonus: (player.salary !== undefined || player.bonuses !== undefined)
            ? (player.salary ?? 0) + (player.bonuses ?? 0)
            : undefined,
          signingCost: player.signingCost,
          market_value: player.market_value,
          locality: player.locality,

          // ABC: player.ABC,
          // homegrown: player.homegrown
        };

        return matrixDataPoint;
      });


      setData(matrixData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ownTeamPlayers,
    playerCareers,
    minAge,
    maxAge,
    selectedIterations,
    isPercentageMinutesToggled,
    iterationDropDownOptions,
    selectedThirdDimension,
    clubSettings,
    currentUser,
  ]);


  useEffect(() => {

    const nodes = data.map(player => ({
      ...player,
      x: player.left || 0,
      y: player.top || 0,
    }));

    const simulation = forceSimulation(nodes)
      .force('x', forceX(d => d.x as number).strength(0.1))
      .force('y', forceY(d => d.y as number).strength(0.1))
      .force('collide', forceCollide(userConfig?.isColorBlind ? 2.5 : 1.5))
      .stop();

    for (let i = 0; i < 100; i++) simulation.tick();

    setSimulationNodes(nodes.map(d => ({ ...d, left: d.x, top: d.y })));
  }, [data, userConfig?.isColorBlind]);


  useEffect(() => {
    if (data.length > 0) {
      const timer = setTimeout(() => {
        setShowPlayerNames(true);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [data]);


  useEffect(() => {
    if (data.length > 0 && !minAndMaxThirdDimensionValuesAreSet) {

      let currentMaxSalaryPlusBonus: number | undefined = undefined;
      let currentMinSalaryPlusBonus: number | undefined = undefined;
      let currentMaxSigningCost: number | undefined = undefined;
      let currentMinSigningCost: number | undefined = undefined;
      let currentMaxMarketValue: number | undefined = undefined;
      let currentMinMarketValue: number | undefined = undefined;

      data.forEach(player => {
        if (player.salaryPlusBonus !== undefined) {
          if (currentMaxSalaryPlusBonus === undefined) currentMaxSalaryPlusBonus = player.salaryPlusBonus;
          else if (player.salaryPlusBonus > currentMaxSalaryPlusBonus) currentMaxSalaryPlusBonus = player.salaryPlusBonus;

          if (currentMinSalaryPlusBonus === undefined) currentMinSalaryPlusBonus = player.salaryPlusBonus;
          else if (player.salaryPlusBonus < currentMinSalaryPlusBonus) currentMinSalaryPlusBonus = player.salaryPlusBonus;
        }
        if (player.signingCost !== undefined) {
          if (currentMaxSigningCost === undefined) currentMaxSigningCost = player.signingCost;
          else if (player.signingCost > currentMaxSigningCost) currentMaxSigningCost = player.signingCost;

          if (currentMinSigningCost === undefined) currentMinSigningCost = player.signingCost;
          else if (player.signingCost < currentMinSigningCost) currentMinSigningCost = player.signingCost;
        }
        if (player.market_value !== undefined) {
          if (currentMaxMarketValue === undefined) currentMaxMarketValue = player.market_value;
          else if (player.market_value > currentMaxMarketValue) currentMaxMarketValue = player.market_value;

          if (currentMinMarketValue === undefined) currentMinMarketValue = player.market_value;
          else if (player.market_value < currentMinMarketValue) currentMinMarketValue = player.market_value;
        }
      });

      setMaxSalaryPlusBonus(currentMaxSalaryPlusBonus);
      setMinSalaryPlusBonus(currentMinSalaryPlusBonus);
      setMaxSigningCost(currentMaxSigningCost);
      setMinSigningCost(currentMinSigningCost);
      setMaxMarketValue(currentMaxMarketValue);
      setMinMarketValue(currentMinMarketValue);

      setMinAndMaxThirdDimensionValuesAreSet(true);
    }
  }, [data, minAndMaxThirdDimensionValuesAreSet]);


  const getPlayerNameContainerStyle = (index: number, diameter: number) => {
    const element = document.getElementById('own-team-matrix-view-player-name-container-' + index);
    return {
      marginLeft: element ? ((diameter / 2) - element.clientWidth / 2) : 0,
    };
  };


  return (
    <div className='own-team-view-container'>

      <div className='own-team-background'>
        <OwnTeamBackgoundSvg />
      </div>

      {(isIterationsExpanded || isThirdDimensionExpanded) && <div className='filter-section-empty-background' onClick={removeDropDownExpansions} />}

      <div className='own-team-matrix-view-filter-section'>
        <div className='own-team-matrix-view-iterations-drop-down-title'>
          {translate(isPercentageMinutesToggled ? 'percentageMinutesPlayed' : 'numberOfMinutesPlayed', userConfig?.language)}
        </div>
        <div className='own-team-matrix-view-iterations-drop-down'>
          <ClubIterationDropDown
            id={'own-team-matrix-view-iterations-drop-down'}
            dropDownOptions={iterationDropDownOptions}
            selectedIterations={selectedIterations}
            setSelectedIterations={setSelectedIterations}
            isDropDownExpanded={isIterationsExpanded}
            setIsDropDownExpanded={setIsIterationsExpanded}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'75vh'}
            isToggled={isPercentageMinutesToggled}
            setIsToggled={setIsPercentageMinutesToggled}
            toggleText={'showPercentageMinutesPlayed'}
          />
        </div>

        <div
          style={{ left: userConfig && userConfig.isColorBlind ? '32%' : '30.5%' }}
          className='own-team-matrix-view-color-bar-description own-team-matrix-view-color-bar-description-left'
        >
          {getColorBarDescription(true)}
        </div>

        {userConfig && !userConfig.isColorBlind && <div className='own-team-matrix-view-color-bar'>&nbsp;</div>}

        {userConfig && userConfig.isColorBlind && (
          <div className='own-team-matrix-view-size-bar'>
            <img
              src={'https://media.api-sports.io/football/players/0.png'}
              className='own-team-matrix-view-size-bar-left'
              draggable={false} />
            <img
              src={'https://media.api-sports.io/football/players/0.png'}
              className='own-team-matrix-view-size-bar-middle'
              draggable={false} />
            <img
              src={'https://media.api-sports.io/football/players/0.png'}
              className='own-team-matrix-view-size-bar-right'
              draggable={false} />
          </div>
        )}

        <div
          style={{ right: userConfig && userConfig.isColorBlind ? '32%' : '30.5%' }}
          className='own-team-matrix-view-color-bar-description own-team-matrix-view-color-bar-description-right'
        >
          {getColorBarDescription(false)}
        </div>

        <div className='own-team-matrix-view-color-data-drop-down-title'>
          {translate('colorBasis', userConfig?.language)}
        </div>
        <div className='own-team-matrix-view-color-data-drop-down'>
          <DropDownSelect
            id={'own-team-matrix-view-color-data-drop-down'}
            dropDownOptions={thirdDimensionOptions}
            isDropDownExpanded={isThirdDimensionExpanded}
            setIsDropDownExpanded={setIsThirdDimensionExpanded}
            selectedOptions={selectedThirdDimension}
            setSelectedOptions={setSelectedThirdDimension}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'75vh'}
            optionsWithIcon={['salaryPlusBonus', 'signingCostLong']}
            icon={<AdminPanelSettingsIcon style={{ fontSize: 20, color: '#000000aa', position: 'absolute', marginLeft: -24, marginTop: -2.5 }} />}
          />
        </div>
      </div>

      <div className='own-team-matrix-view-matrix-section'>

        {ageThresholdLeft !== undefined && minAge !== undefined && maxAge !== undefined && (
          <div>
            <div className='own-team-matrix-view-matrix-vertical-line' style={{ left: ageThresholdLeft + '%', top: 0 }}>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-vertical-label' style={{ left: ageThresholdLeft + '%' }}>
              {'24 ' + translate('years', userConfig?.language, true)}
            </div>

            <div className='own-team-matrix-view-matrix-vertical-line own-team-matrix-view-matrix-age-line-left'>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-vertical-label' style={{ left: 0 }}>
              {(minAge) + ' ' + translate('years', userConfig?.language, true)}
            </div>

            <div className='own-team-matrix-view-matrix-vertical-line own-team-matrix-view-matrix-age-line-right'>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-vertical-label' style={{ left: '100%' }}>
              {(maxAge) + ' ' + translate('years', userConfig?.language, true)}
            </div>


            <div className='own-team-matrix-view-matrix-horizontal-line' style={{ top: '50%' }}>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-horizontal-label'>
              {isPercentageMinutesToggled ? '100%' : maxMinutes}
            </div>

            <div className='own-team-matrix-view-matrix-horizontal-line own-team-matrix-view-matrix-horizontal-line-top'>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-horizontal-label' style={{ top: '50%' }}>
              {isPercentageMinutesToggled ? '50%' : (Number(maxMinutes) / 2)}
            </div>

            <div className='own-team-matrix-view-matrix-horizontal-line own-team-matrix-view-matrix-horizontal-line-bottom'>&nbsp;</div>
            <div className='own-team-matrix-view-matrix-label own-team-matrix-view-matrix-horizontal-label' style={{ top: '100%' }}>
              {0 + (isPercentageMinutesToggled ? '%' : '')}
            </div>
          </div>
        )}

        {userConfig && simulationNodes.map((player, index) => {

          const thirdDimensionIndexEquivalent = getThirdDimensionIndexEquivalent(player);

          const borderColor = userConfig.isColorBlind
            ? '#ffffff'
            : (thirdDimensionIndexEquivalent === undefined ? '#ffffff33' : getIndexColor(thirdDimensionIndexEquivalent));
          const border = (userConfig.isColorBlind ? '1px ' : '3px ') + 'solid ' + borderColor;

          const scaleFactor = selectedThirdDimension.includes('locality') ? 2 : 2.5;
          const diameter = userConfig.isColorBlind
            ? (25 + (thirdDimensionIndexEquivalent ?? 5) * scaleFactor)
            : 40;

          const marginTop = -(diameter / 2) - 2;
          const marginLeft = -(diameter / 2);

          return (
            <div
              key={index + player.fullname + (selectedThirdDimension.length > 0 ? selectedThirdDimension[0] : '')}
              className='own-team-matrix-view-player'
              style={{
                left: player.left + '%',
                top: player.top + '%',
                color: showPlayerNames ? '#ffffff69' : '#ffffff00',
                height: diameter,
                width: diameter,
                marginTop: marginTop,
                marginLeft: marginLeft
              }}
              onClick={() => handlePlayerClick(player)}
            >

              <img
                src={player['image_url']}
                alt=''
                className='own-team-matrix-view-player-image'
                style={{ border: border, height: diameter, width: diameter }}
                draggable={false}
              />

              <div
                id={'own-team-matrix-view-player-name-container-' + index}
                className='own-team-matrix-view-player-name-container'
                style={getPlayerNameContainerStyle(index, diameter)}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className='own-team-matrix-view-player-name'
                  onClick={() => handlePlayerClick(player)}
                >
                  {player.firstAndLastName}
                </div>
              </div>

            </div>
          );
        })}

      </div>
    </div>
  );
};
