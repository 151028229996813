import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { translate } from '../../../../common/language/translations';
import { Toggle } from '../../input/Toggle';
import { useSetAndTrackNavigationPropsState } from '../../../recoil/hooks/useSetAndTrackNavigationPropsState';


interface RoleIndexInTeamsInfoModalProps {
  closeModal: () => void;
  doNotShowRoleIndexInfoModalAgain: boolean;
  setDoNotShowRoleIndexInfoModalAgain: (value: boolean) => void;
}

export const RoleIndexInTeamsInfoModal: React.FC<RoleIndexInTeamsInfoModalProps> = ({
  closeModal,
  doNotShowRoleIndexInfoModalAgain,
  setDoNotShowRoleIndexInfoModalAgain,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();


  const onButtonClick = () => {
    setAndTrackNavigationProps({
      activeTab: 'settings',
      activeSubTab: 2,
    });
    closeModal();
  };


  const textArray = userConfig
    ? translate('roleIndexInTeamInfo', userConfig?.language).split('\n')
    : [];


  return (
    <div
      className='modal-root-container text-modal-root'
      style={{ width: 550 }}
    >

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {translate('roleIndexInTeam', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div
        className='text-modal-content-section'
        style={{
          fontSize: 15,
          textAlign: 'left',
        }}
      >
        {textArray.map((textLine: string, index: number) => {
          const isEmptySpace = textLine === '&nbsp;';

          if (isEmptySpace) {
            return (
              <div
                key={index}
                style={{ height: 20 }}
              />
            );
          }

          return (
            <div
              key={index}
              style={{
                marginTop: index > 0 ? 12 : undefined,
              }}>
              {textLine}
            </div>
          );
        })}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50,
            height: 32,
          }}
        >
          {translate('doNotShowThisInfoAgain', userConfig?.language)}

          <div style={{ height: 11, width: 30, marginLeft: 12 }}>
            <Toggle isToggled={doNotShowRoleIndexInfoModalAgain} setIsToggled={setDoNotShowRoleIndexInfoModalAgain} />
          </div>
        </div>

        <div
          className='modal-button role-index-info-modal-button'
          onClick={() => onButtonClick()}
        >
          {translate('goToRoleSettings', userConfig?.language)}

          <div className='role-index-info-modal-button-icon'>
            <ArrowRightAltIcon style={{ fontSize: 20 }} />
          </div>
        </div>


      </div>

    </div>
  );
};
