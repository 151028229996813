import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseState } from '../../documents/clause/clauseInitialState';
import { ClauseAction } from '../../documents/clause/clauseReducer';


interface ResolveSellOnConditionModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}

export const ResolveSellOnConditionModal: React.FC<ResolveSellOnConditionModalProps> = ({
  closeModal,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const minPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() - 10));
  const maxPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() + 10));

  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);

  // the amount is newTransferFee - yourTransferFee (if isNet) * sellOnPercentage / 100
  const [newTransferFee, setNewTransferFee] = useState('');
  const [yourTransferFee, setYourTransferFee] = useState(state.additionalClauseState.activeSubClauseTab !== undefined ? '' : state.totalTransferFee.toString());
  const [amount, setAmount] = useState('');


  // feeType: 'newTransferFee' | 'yourTransferFee' | 'amount'
  const onChangeInputField = (event: React.ChangeEvent<HTMLInputElement>, feeType: string) => {
    const newFee = event.target.value.replaceAll('.', '');
    const newFeeNumber = Number(newFee);

    if (isNaN(newFeeNumber) || newFeeNumber < 0) {
      setMessageKey('invalidAmount');
      return;
    }
    setMessageKey(undefined);

    if (feeType === 'newTransferFee') {
      if (state.sellOnCondition?.isNet) {
        const yourTransferFeeNumber = yourTransferFee ? Number(yourTransferFee) : 0;
        const sellOnPercentage = state.sellOnCondition?.sellOnPercentage ?? 0;
        const amount = Math.round((newFeeNumber - yourTransferFeeNumber) * (sellOnPercentage / 100));
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      else {
        const amount = Math.round(newFeeNumber * (state.sellOnCondition?.sellOnPercentage ?? 0) / 100);
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      setNewTransferFee(newFee);
    }

    else if (feeType === 'yourTransferFee') {
      if (newTransferFee && state.sellOnCondition?.isNet) {
        const newTransferFeeNumber = Number(newTransferFee);
        const sellOnPercentage = state.sellOnCondition?.sellOnPercentage ?? 0;
        const amount = Math.round((newTransferFeeNumber - newFeeNumber) * (sellOnPercentage / 100));
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      setYourTransferFee(newFee);
    }

    else if (feeType === 'amount') {
      if (!state.sellOnCondition?.isNet) {
        const newTransferFee = Math.round(newFeeNumber / (state.sellOnCondition?.sellOnPercentage ?? 0) * 100);
        const nonNegativeNewTransferFee = newTransferFee < 0 ? 0 : newTransferFee;
        setNewTransferFee(nonNegativeNewTransferFee.toString());
      }
      else {
        const yourTransferFeeNumber = yourTransferFee ? Number(yourTransferFee) : 0;
        const newTransferFee = Math.round(newFeeNumber / (state.sellOnCondition?.sellOnPercentage ?? 0) * 100) + yourTransferFeeNumber;
        const nonNegativeNewTransferFee = newTransferFee < 0 ? 0 : newTransferFee;
        setNewTransferFee(nonNegativeNewTransferFee.toString());
      }
      setAmount(newFee);
    }
  };


  const handleSetPaymentDate = (date: Date | null) => {
    if (!date) {
      setPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setPaymentDate(adjustedDate);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleResolveCondition();
    }
  };


  const handleResolveCondition = () => {
    const amountNumber = Number(amount.replaceAll('.', ''));

    if (isNaN(amountNumber) || amountNumber < 0) {
      setMessageKey('invalidAmount');
      return;
    }

    dispatch({ type: 'RESOLVE_SELL_ON_CONDITION', payload: { amount: amountNumber, date: paymentDate } });
    closeModal();
  };


  const getYourPaymentDescription = () => {
    if (!userConfig
      || !state.subClauses
      || state.additionalClauseState.activeSubClauseTab === undefined
    ) return;

    const totalPercentage = state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage;

    let description = translate('youWillReceive', userConfig.language);
    description += ' ' + totalPercentage + '% ';
    description += translate('ofTheFinalAmount', userConfig?.language, true);

    return description;
  };


  return (
    <div
      className='modal-root-container'
      style={{ width: state.sellOnCondition?.isNet ? 600 : 500, height: state.additionalClauseState.activeSubClauseTab !== undefined ? 540 : 500 }}
    >

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {translate('resolveCondition', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      {state.sellOnCondition && userConfig && (
        <div className='modal-content-section'>

          {messageKey && (
            <div className='payment-modal-message' style={{ marginTop: 315, width: '100%', textAlign: 'center' }}>
              {translate(messageKey, userConfig.language)}
            </div>
          )}

          <div className='resolve-condition-modal-info-row'>
            {translate('sellOnCondition', userConfig.language)}
            : {state.sellOnCondition.sellOnPercentage}%
            {' ('}
            {' (' + translate(state.sellOnCondition.isNet ? 'net' : 'gross', userConfig?.language, true) + ') '}
          </div>

          {state.additionalClauseState.activeSubClauseTab !== undefined && (
            <div className='resolve-condition-modal-info-row' style={{ height: 18, marginTop: 20, fontSize: 14, color: '#ffffffcc' }}>
              {getYourPaymentDescription()}
            </div>
          )}

          <div className='flex-row' style={{ marginTop: 30, width: '100%', justifyContent: 'center' }}>
            <DatePicker
              className='document-input-field'
              selected={paymentDate}
              onChange={(date: Date | null) => handleSetPaymentDate(date)}
              onKeyDown={(event) => onKeyDownInputField(event)}
              locale={locale}
              dateFormat={dateFormat}
              placeholderText={translate('paymentDate', userConfig?.language) + '?'}
              popperPlacement='bottom'

              minDate={minPaymentDate}
              maxDate={maxPaymentDate}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              showMonthDropdown
            />
          </div>

          {state.sellOnCondition.isNet && (
            <div className='flex-row' style={{ marginTop: 90, width: 540, marginLeft: 30 }}>
              <div style={{ fontSize: 20, marginTop: 29 }}>
                {'('}
              </div>

              <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: 12, fontSize: 13 }}>
                  {translate('newTransferFee', userConfig.language)}
                </div>
                <input
                  className='document-input-field'
                  style={{ width: 130 }}
                  name='resolve-modal-input-field-sell-on-total-fee'
                  type='text'
                  autoComplete='off'
                  value={getDisplayEconomicValue(newTransferFee)}
                  placeholder={translate('amount', userConfig.language)}
                  onChange={(event) => onChangeInputField(event, 'newTransferFee')}
                  onKeyDown={(event) => onKeyDownInputField(event)}
                />
              </div>

              <div style={{ fontSize: 20, marginTop: 29 }}>
                -
              </div>

              <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: 12, fontSize: 13 }}>
                  {translate('yourTransferFee', userConfig.language)}
                </div>
                <input
                  className='document-input-field'
                  style={{ width: 130 }}
                  name='resolve-modal-input-field-sell-on-total-fee'
                  type='text'
                  autoComplete='off'
                  value={getDisplayEconomicValue(yourTransferFee)}
                  placeholder={translate('amount', userConfig.language) + '?'}
                  onChange={(event) => onChangeInputField(event, 'yourTransferFee')}
                  onKeyDown={(event) => onKeyDownInputField(event)}
                />
              </div>

              <div style={{ fontSize: 20, marginTop: 29 }}>
                {')'}
              </div>

              <div style={{ fontSize: 18, marginTop: 35, marginLeft: 10, marginRight: 10 }}>
                *
              </div>

              <div style={{ fontSize: 14, marginTop: 34, marginRight: 12 }}>
                {(state.sellOnCondition?.sellOnPercentage ?? 0) / 100}
              </div>

              <div style={{ fontSize: 18, marginTop: 31, marginRight: 4 }}>
                {'='}
              </div>

              <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: 12, fontSize: 13 }}>
                  {translate('amount', userConfig.language)}
                </div>
                <input
                  className='document-input-field'
                  style={{ width: 130 }}
                  name='resolve-modal-input-field-sell-on-total-fee'
                  type='text'
                  autoComplete='off'
                  value={getDisplayEconomicValue(amount)}
                  placeholder={translate('amount', userConfig.language)}
                  onChange={(event) => onChangeInputField(event, 'amount')}
                  onKeyDown={(event) => onKeyDownInputField(event)}
                />
              </div>
            </div>
          )}

          {!state.sellOnCondition.isNet && (
            <div className='flex-row' style={{ marginTop: 90, width: 380, marginLeft: 60 }}>
              <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: 12, fontSize: 13 }}>
                  {translate('transferFee', userConfig.language)}
                </div>
                <input
                  className='document-input-field'
                  style={{ width: 130 }}
                  name='resolve-modal-input-field-sell-on-total-fee'
                  type='text'
                  autoComplete='off'
                  value={getDisplayEconomicValue(newTransferFee)}
                  placeholder={translate('amount', userConfig.language)}
                  onChange={(event) => onChangeInputField(event, 'newTransferFee')}
                  onKeyDown={(event) => onKeyDownInputField(event)}
                />
              </div>

              <div style={{ fontSize: 18, marginTop: 35, marginLeft: 8, marginRight: 12 }}>
                *
              </div>

              <div style={{ fontSize: 14, marginTop: 34, marginRight: 12 }}>
                {(state.sellOnCondition?.sellOnPercentage ?? 0) / 100}
              </div>

              <div style={{ fontSize: 18, marginTop: 31, marginRight: 4 }}>
                {'='}
              </div>

              <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: 12, fontSize: 13 }}>
                  {translate('amount', userConfig.language)}
                </div>
                <input
                  className='document-input-field'
                  style={{ width: 130 }}
                  name='resolve-modal-input-field-sell-on-total-fee'
                  type='text'
                  autoComplete='off'
                  value={getDisplayEconomicValue(amount)}
                  placeholder={translate('amount', userConfig.language)}
                  onChange={(event) => onChangeInputField(event, 'amount')}
                  onKeyDown={(event) => onKeyDownInputField(event)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className='modal-button modal-button-bottom'
        style={{ marginLeft: state.sellOnCondition?.isNet ? 200 : 150 }}
        onClick={() => handleResolveCondition()}>
        <div className='modal-button-text'>
          {translate('resolveCondition', userConfig?.language)}
        </div>

        <CheckIcon className='modal-button-icon' style={{ fontSize: 24 }} />
      </div>

    </div>
  );
};
