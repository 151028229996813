import { StringToAnyMap, StringToBooleanMap } from '../../../types';
import { getStateForPreviousEdit, ReportState } from './reportInitialState';


export type ReportAction =
  | { type: 'INITIALIZE_STATE'; payload: ReportState }
  | { type: 'SET_SCORE'; payload: { scoreKey: string, scoreValue: number } }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'SET_TEAM'; payload: string }
  | { type: 'SET_OPPONENT'; payload: string }
  | { type: 'SET_MATCH_DATE'; payload: Date | null }
  | { type: 'UPLOAD_FILE'; payload: File }
  | { type: 'REMOVE_FILE'; payload: { fileName: string, fileUrl: string } }
  | { type: 'SET_IS_EDIT_MODE'; payload: boolean }
  | { type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_ACTIVE_PREVIOUS_EDIT_INDEX'; payload: { index: number | undefined, reportFields: StringToAnyMap } }
  | { type: 'SET_REQUIRED_FIELDS_TO_SHOW'; payload: StringToBooleanMap };


const reportReducer = (state: ReportState, action: ReportAction): ReportState => {
  switch (action.type) {

    case 'INITIALIZE_STATE':
      return action.payload;

    case 'SET_SCORE':
      return getState_SET_SCORE(action.payload, state);

    case 'SET_COMMENT':
      return {
        ...state,
        comment: action.payload
      };

    case 'SET_TEAM':
      return getState_SET_TEAM(action.payload, state);

    case 'SET_OPPONENT':
      return getState_SET_OPPONENT(action.payload, state);

    case 'SET_MATCH_DATE':
      return getState_SET_MATCH_DATE(action.payload, state);

    case 'UPLOAD_FILE':
      return getState_UPLOAD_FILE(action.payload, state);

    case 'REMOVE_FILE':
      return getState_REMOVE_FILE(action.payload, state);

    case 'SET_IS_EDIT_MODE':
      return {
        ...state,
        additionalReportState: {
          ...state.additionalReportState,
          isEditMode: action.payload
        }
      };

    case 'SET_IS_EDIT_HISTORY_MODAL_OPEN':
      return {
        ...state,
        additionalReportState: {
          ...state.additionalReportState,
          isEditHistoryModalOpen: action.payload
        }
      };

    case 'SET_ACTIVE_PREVIOUS_EDIT_INDEX':
      return getState_SET_ACTIVE_PREVIOUS_EDIT_INDEX(action.payload, state);

    case 'SET_REQUIRED_FIELDS_TO_SHOW':
      return {
        ...state,
        additionalReportState: {
          ...state.additionalReportState,
          requiredFieldsToShow: action.payload
        }
      };

    default:
      return state;
  }
};


const getState_SET_SCORE = (
  payload: { scoreKey: string, scoreValue: number },
  state: ReportState
) => {

  const scores = {
    ...state.scores,
    [payload.scoreKey]: payload.scoreValue
  };

  const additionalReportState = { ...state.additionalReportState };

  if (additionalReportState.requiredFieldsToShow[payload.scoreKey + 'ShowRequired']) {
    additionalReportState.requiredFieldsToShow[payload.scoreKey + 'ShowRequired'] = false;
  }

  return { ...state, scores, additionalReportState };
};


const getState_SET_TEAM = (
  team: string,
  state: ReportState
) => {

  const matchReportInfo = {
    ...state.matchReportInfo,
    team
  };

  const additionalReportState = { ...state.additionalReportState };

  if (additionalReportState.requiredFieldsToShow.teamShowRequired) {
    additionalReportState.requiredFieldsToShow.teamShowRequired = false;
  }

  return { ...state, matchReportInfo, additionalReportState };
};


const getState_SET_OPPONENT = (
  opponent: string,
  state: ReportState
) => {

  const matchReportInfo = {
    ...state.matchReportInfo,
    opponent
  };

  const additionalReportState = { ...state.additionalReportState };

  if (additionalReportState.requiredFieldsToShow.opponentShowRequired) {
    additionalReportState.requiredFieldsToShow.opponentShowRequired = false;
  }

  return { ...state, matchReportInfo, additionalReportState };
};


const getState_SET_MATCH_DATE = (
  date: Date | null,
  state: ReportState
) => {

  let adjustedDateString: string | null = null;

  if (date) {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    adjustedDateString = adjustedDate.toISOString().split('T')[0];
  }

  const matchReportInfo = {
    ...state.matchReportInfo,
    matchDate: adjustedDateString
  };

  const additionalReportState = { ...state.additionalReportState };

  if (additionalReportState.requiredFieldsToShow.matchDateShowRequired) {
    additionalReportState.requiredFieldsToShow.matchDateShowRequired = false;
  }

  return { ...state, matchReportInfo, additionalReportState };
};


const getState_UPLOAD_FILE = (
  file: File,
  state: ReportState
) => {

  const existingUploadedFiles = state.additionalReportState.uploadedFiles ?? [];
  const newUploadedFiles = [...existingUploadedFiles, file];
  const additionalReportState = { ...state.additionalReportState, uploadedFiles: newUploadedFiles };

  const fileNames = [...state.fileNames, file.name];
  const fileUrls = [...state.fileUrls, URL.createObjectURL(file)];

  return { ...state, fileNames, fileUrls, additionalReportState };
};


const getState_REMOVE_FILE = (
  payload: { fileName: string, fileUrl: string },
  state: ReportState
) => {

  const existingUploadedFiles = state.additionalReportState.uploadedFiles ?? [];
  const newUploadedFiles = existingUploadedFiles.filter(file => file.name !== payload.fileName);
  const additionalReportState = { ...state.additionalReportState, uploadedFiles: newUploadedFiles };

  const fileNames = state.fileNames.filter(fileName => fileName !== payload.fileName);
  const fileUrls = state.fileUrls.filter(fileUrl => fileUrl !== payload.fileUrl);

  return { ...state, fileNames, fileUrls, additionalReportState };
};


const getState_SET_ACTIVE_PREVIOUS_EDIT_INDEX = (
  payload: { index: number | undefined, reportFields: StringToAnyMap },
  state: ReportState,
) => {

  const stateForPreviousEdit = getStateForPreviousEdit(state, payload.reportFields);

  const additionalReportState = {
    ...state.additionalReportState,
    activePreviousEditIndex: payload.index
  };

  return { ...state, ...stateForPreviousEdit, additionalReportState };
};


export default reportReducer;
