import './existingDocuments.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import NotificationsIcon from '@mui/icons-material/Notifications';

import { translate } from '../../../common/language/translations';
import { getClubColor } from '../../static/clubConfigs';
import { Toggle } from '../input/Toggle';
import { getDateLocaleFormat, getDisplayPlayerName } from '../../utils/playerUtils';
import { ClubMap, PlayerClause } from '../../types';


interface ExistingClausesProps {
  isSoldPlayer: boolean;
  clauses: PlayerClause[];
  allClubs: ClubMap;
  existingClauseSelected: PlayerClause | undefined;
  handleExistingClauseClicked: (clause: PlayerClause) => void;
  isClausesInitialized: boolean;
}


export const ExistingClauses: React.FC<ExistingClausesProps> = ({
  isSoldPlayer,
  clauses,
  allClubs,
  existingClauseSelected,
  handleExistingClauseClicked,
  isClausesInitialized,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const trackEvent = useTrackEvent();

  const [showDeletedClauses, setShowDeletedClauses] = useState(false);
  const [showResolvedClauses, setShowResolvedClauses] = useState(false);


  const handleSelectClauseAndTrackEvent = (clause: PlayerClause) => {
    trackEvent('ClauseEvent', { action: 'viewed', clauseType: clause.isSoldPlayer ? 'soldPlayer' : 'boughtPlayer' });
    handleExistingClauseClicked(clause);
  };


  const getExistingClauseClassName = (clause: PlayerClause) => {

    let baseClassName = 'existing-document existing-clause';

    if (clause['isResolved']) {
      baseClassName += ' existing-document-resolved';
    }

    if (clause['isDeleted']) {
      baseClassName += ' existing-document-deleted';
    }

    if (existingClauseSelected && clause['id'] === existingClauseSelected['id']) {
      baseClassName += ' existing-document-selected';
    }

    return baseClassName;
  };


  useEffect(() => {
    setShowDeletedClauses(false);
    setShowResolvedClauses(false);
  }, [isSoldPlayer]);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='existing-documents-container'>

      <div className='existing-documents-title'>
        {translate('existingClauses', userConfig?.language)}
      </div>

      <div className='existing-documents-divider existing-documents-top-divider'>&nbsp;</div>

      {!isClausesInitialized && (
        <div className='existing-documents-loading-container'>
          <div className='loading-spinner'>&nbsp;</div>
        </div>
      )}

      {isClausesInitialized && (
        <div className='fade-in'>
          <div key={isSoldPlayer ? 'soldPlayers' : 'boughtPlayers'} className='existing-documents-section fade-in'>
            {clauses.length === 0 && (
              <div className='no-existing-documents-title'>
                {translate('noExistingClauses', userConfig?.language)}
              </div>
            )}
            {clauses.map((clause: PlayerClause) => {
              if (!showDeletedClauses && clause.isDeleted) return null;
              if (!showResolvedClauses && clause.isResolved) return null;

              const club = isSoldPlayer
                ? allClubs[clause.buyingClubId]
                : allClubs[clause.sellingClubId];

              return (
                <div
                  key={clause.id}
                  className={getExistingClauseClassName(clause)}
                  onClick={() => handleSelectClauseAndTrackEvent(clause)}>
                  <div className='existing-clause-club-logo-container'>
                    {club && (
                      <img
                        style={{ maxHeight: 22, maxWidth: 22 }}
                        src={club.logo_url}
                        alt={club.name}
                        draggable={false}
                      />
                    )}
                  </div>

                  <div
                    className='existing-document-vertical-divider'
                    style={{ backgroundColor: clubColor }}>
                    &nbsp;
                  </div>

                  <div className='existing-document-info'>
                    <div className='existing-document-row'>
                      <div className='existing-document-title' style={{ fontSize: 12 }}>
                        {getDisplayPlayerName(clause.playerName, 22)}
                      </div>
                    </div>
                    <div className='existing-document-row' style={{ fontSize: 12, color: '#d1d1d1', marginTop: 2 }}>
                      {getDateLocaleFormat(clause.transferDate.split('T')[0])}
                    </div>
                  </div>

                  {clause.showNotification && (
                    <div className='existing-clause-notification-icon'>
                      <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className='existing-documents-divider existing-documents-bottom-divider'>&nbsp;</div>

          <div className='existing-document-toggle-container existing-document-toggle-top-container'>
            <div className='existing-document-toggle-section' style={{ width: 134 }}>
              <div className='existing-document-toggle-title'>
                {translate('showResolvedClauses', userConfig?.language)}
              </div>
              <div className='existing-document-toggle'>
                <Toggle isToggled={showResolvedClauses} setIsToggled={setShowResolvedClauses} />
              </div>
            </div>
          </div>

          <div className='existing-document-toggle-container existing-document-toggle-bottom-container'>
            <div className='existing-document-toggle-section' style={{ width: 134 }}>
              <div className='existing-document-toggle-title'>
                {translate('showDeletedClauses', userConfig?.language)}
              </div>
              <div className='existing-document-toggle'>
                <Toggle isToggled={showDeletedClauses} setIsToggled={setShowDeletedClauses} />
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
