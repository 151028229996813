import './playerView.css';

import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CallMergeIcon from '@mui/icons-material/CallMerge';

import { translate } from '../../../common/language/translations';
import { PlayerFinding } from '../../views/scouting/playerFinding/PlayerFinding';
import { connectPlayerWithoutData, deletePlayerFromPlatform } from '../../services/firestore/players';
import { PlayerOverview } from '../../types';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { FindPlayerTable } from '../tables/findPlayerTable/FindPlayerTable';


interface PlayerViewWithoutDataProps {
  setIsConnectingPlayer: (isConnectingPlayer: boolean) => void;
}

export const PlayerViewWithoutData: React.FC<PlayerViewWithoutDataProps> = ({ setIsConnectingPlayer }) => {

  const userConfig = useRecoilValue(userConfigState);

  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);

  const { openConfirmModal } = useOpenGlobalModal();

  const [selectedPlayerToMap, setSelectedPlayerToMap] = useState<PlayerOverview | undefined>(undefined);

  const [isAnyFilterExpanded, setIsAnyFilterExpanded] = useState(false);


  const confirmDeletion = () => {
    if (!selectedPlayer || !userConfig) return;
    deletePlayerFromPlatform(selectedPlayer.id, userConfig.email, userConfig.club);
    setSelectedPlayer(undefined);
  };


  const connectPlayer = async () => {
    if (!selectedPlayer || !userConfig || !selectedPlayerToMap || !playerTeamData) return;

    setIsConnectingPlayer(true);

    const currentPlayerId = getStrictPlayerId(selectedPlayer.id);
    const currentTeam = playerTeamData[currentPlayerId] ? playerTeamData[currentPlayerId].currentTeam : undefined;

    const newPlayerId = selectedPlayerToMap.id;
    const newPlayerFullname = selectedPlayerToMap.fullname;
    const newPlayerTeam = playerTeamData[selectedPlayerToMap.id] ? playerTeamData[selectedPlayerToMap.id].currentTeam : undefined;

    await connectPlayerWithoutData(
      currentPlayerId,
      currentTeam,
      newPlayerId,
      newPlayerFullname,
      newPlayerTeam,
      userConfig.email,
      userConfig.club
    );

    setSelectedPlayerToMap(undefined);
    setSelectedPlayer({
      id: newPlayerId,
      fullname: newPlayerFullname,
      playerOverview: selectedPlayerToMap
    });

    setIsConnectingPlayer(false);
  };


  return (
    <div className='player-view-main-section'>

      {isAnyFilterExpanded && <div className='filter-section-empty-background' onClick={() => setIsAnyFilterExpanded(false)} />}

      <div className='player-view-without-data-top-section'>
        <div className='player-view-without-data-description'>
          <div>
            {translate('playerWasAddedWithoutData', userConfig?.language)}
          </div>
          <div style={{ marginTop: '1vh' }}>
            {translate('playerWasAddedWithoutDataDescription', userConfig?.language)}
          </div>
        </div>

        <div
          className='player-view-without-data-delete-player-button'
          onClick={() => openConfirmModal(confirmDeletion, 'deletePlayer', 'deletePlayerInfo')}
        >
          {translate('deletePlayer', userConfig?.language)}
          <div className='player-view-without-data-delete-player-icon'>
            <DeleteForeverIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      </div>

      {!selectedPlayerToMap && (
        <div className='player-view-without-data-bottom-section'>
          <PlayerFinding
            isAnyFilterExpanded={isAnyFilterExpanded}
            setIsAnyFilterExpanded={setIsAnyFilterExpanded}
            handlePlayerClick={setSelectedPlayerToMap}
          />
        </div>
      )}

      {selectedPlayerToMap && (
        <div className='player-view-without-data-bottom-section'>
          <div className='player-view-without-data-bottom-section-table-container'>
            <FindPlayerTable
              data={[selectedPlayerToMap]}

              currentModuloPage={0}
              currentChapter={0}
              totalHits={1}

              isSelectedPlayer={true}
            />
          </div>

          <div
            className='player-view-without-data-connect-player-button'
            onClick={() => openConfirmModal(connectPlayer, 'connectPlayer', 'noRegretInfo')}
          >
            {translate('connectPlayer', userConfig?.language)}
            <div style={{ marginLeft: 10, marginBottom: -3 }}>
              <CallMergeIcon style={{ fontSize: 24 }} />
            </div>
          </div>

          <div className='player-view-without-data-connect-player-link' onClick={() => setSelectedPlayerToMap(undefined)}>
            {translate('searchAgain?', userConfig?.language)}
          </div>

        </div>
      )}

    </div>
  );
};
