import { db } from '../../../firebase';

import {
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { StringToStringMap } from '../../types';


// Subscribe to global settings in Firestore, controlling platform updates and maintaince
export const getGlobalSettings = (
  setLatestReactVersion: (version: string) => void,
  setVersionMessage: (versionMessage: StringToStringMap) => void,
  setIsMaintenance: (isMaintenance: boolean) => void,
  setMaintenanceMessage: (maintenanceMessage: StringToStringMap) => void,
) => {

  const docRef = doc(db, 'global', 'react');

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const docData = docSnap.data();

      setLatestReactVersion(docData.version);
      setVersionMessage(docData.versionMessage);

      setIsMaintenance(docData.isMaintenance);
      setMaintenanceMessage(docData.maintenanceMessage);
    }
  });

  return unsubscribe;
};
