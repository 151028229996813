import { StringToAnyMap } from '../types';
import { formationToPositionOptions } from '../static/propertyValues';
import { deepCloneObject } from './utils';


export const formationHasBackThree = (formation: string) => {
  return formation.startsWith('3');
};

export const formationHasBackFour = (formation: string) => {
  return formation.startsWith('4');
};

export const formationHasBackFive = (formation: string) => {
  return formation.startsWith('5');
};

export const formationHasCDM = (formation: string) => {
  return formationToPositionOptions[formation].includes('CDM');
};

export const formationHasCAM = (formation: string) => {
  return formationToPositionOptions[formation].includes('CAM');
};

export const formationHasWingers = (formation: string) => {
  return formationToPositionOptions[formation].includes('LW');
};

export const formationHasWideMidfielders = (formation: string) => {
  return formationToPositionOptions[formation].includes('LM');
};

export const formationHasCentralStriker = (formation: string) => {
  return formationToPositionOptions[formation].includes('S');
};

export const formationHasWideStrikers = (formation: string) => {
  return formationToPositionOptions[formation].includes('LS');
};


// Swap positions on each side (LW & RW to LM & RM, etc.)
const swapWidePosition = (oldTeam: StringToAnyMap, newTeam: StringToAnyMap, oldPositionKey: string, newPositionKey: string) => {
  ['L', 'R'].forEach(side => {
    newTeam[side + newPositionKey] = (newTeam[side + newPositionKey] ?? []).concat(oldTeam[side + oldPositionKey] ?? []);
    newTeam[side + oldPositionKey] = [];
  });
};

// Move multiple old positions to a single new position (RS & LS to S, etc.)
const moveMultiplePositions = (oldTeam: StringToAnyMap, newTeam: StringToAnyMap, oldPositions: string[], newPositionKey: string) => {
  oldPositions.forEach(position => {
    newTeam[newPositionKey] = (newTeam[newPositionKey] ?? []).concat(oldTeam[position] ?? []);
    newTeam[position] = [];
  });
};

// Move a single central position (CDM, CAM, S) to two alternating positions (LCM & RCM, LS & RS)
const alternateCentralPosition = (oldTeam: StringToAnyMap, newTeam: StringToAnyMap, oldPosition: string, newPositionKey: string) => {
  const newLeftSidePlayers = [];
  const newRightSidePlayers = [];

  if (oldTeam[oldPosition]) {
    for (let i = 0; i < oldTeam[oldPosition].length; i++) {
      if (i % 2 === 0) {
        newLeftSidePlayers.push(oldTeam[oldPosition][i]);
      }
      else {
        newRightSidePlayers.push(oldTeam[oldPosition][i]);
      }
    }
  }

  newTeam['L' + newPositionKey] = (newTeam['L' + newPositionKey] ?? []).concat(newLeftSidePlayers);
  newTeam['R' + newPositionKey] = (newTeam['R' + newPositionKey] ?? []).concat(newRightSidePlayers);
  newTeam[oldPosition] = [];
};


// Currently supports transition between these formations:
// 433, 442, 4312, 4231, 4141, 4411, 343, 3421, 541, 523, 532
//
// Possible positions are:
//
//               GK
// RWB, RB, RCB, CB, LCB, LB, LWB
//              CDM
//  RM,       RCM, LCM        LM
//              CAM,
//   RW,   RS,   S,   LS,    LW
//
// Every formation will have these positions:
//
//               GK
//            RCB, LCB
//            RCM, RCM
//
//
export const getTeamWithNewFormation = (teamData: StringToAnyMap, newFormation: string, currentFormation: string,) => {

  const newTeam: StringToAnyMap = deepCloneObject(teamData);

  // Centre backs
  // If moving from 3 centre backs to 2
  if (!formationHasBackFour(currentFormation) && formationHasBackFour(newFormation)) {
    // CB to LCB/RCB
    alternateCentralPosition(teamData, newTeam, 'CB', 'CB');
  }

  // Full backs
  // If moving from 4 at the back to 5
  if (formationHasBackFour(currentFormation) && formationHasBackFive(newFormation)) {
    swapWidePosition(teamData, newTeam, 'B', 'WB');
  }
  // If moving from 5 at the back to 4
  else if (formationHasBackFive(currentFormation) && formationHasBackFour(newFormation)) {
    swapWidePosition(teamData, newTeam, 'WB', 'B');
  }
  // If moving from 4/5 at the back to 3
  else if ((!formationHasBackThree(currentFormation)) && formationHasBackThree(newFormation)) {
    const oldFullBackKey = formationHasBackFour(currentFormation) ? 'B' : 'WB';
    swapWidePosition(teamData, newTeam, oldFullBackKey, 'M');
  }

  // Central midfielders
  // If current formation has CDM and new formation does not
  if (formationHasCDM(currentFormation) && !formationHasCDM(newFormation)) {
    // CDM to LCM/RCM
    alternateCentralPosition(teamData, newTeam, 'CDM', 'CM');
  }
  // If current formation has CAM and new formation does not
  if (formationHasCAM(currentFormation) && !formationHasCAM(newFormation)) {
    // CAM to LCM/RCM
    alternateCentralPosition(teamData, newTeam, 'CAM', 'CM');
  }

  // Wide midfielders and wingers
  // If current formation has wide midfielders and new formation does not
  if (formationHasWideMidfielders(currentFormation) && !formationHasWideMidfielders(newFormation)) {
    // Wide midfielders will be moved to wingers, if able, else to wide strikers
    const newPositionKey = formationHasWingers(newFormation) ? 'W' : 'S';
    swapWidePosition(teamData, newTeam, 'M', newPositionKey);
  }
  // If current formation has wingers and new formation does not
  if (formationHasWingers(currentFormation) && !formationHasWingers(newFormation)) {
    // Wingers will be moved to wide midfielders, if able, else to wide strikers
    const newPositionKey = formationHasWideMidfielders(newFormation) ? 'M' : 'S';
    swapWidePosition(teamData, newTeam, 'W', newPositionKey);
  }

  // Strikers
  // If moving from wide strikers to only central striker
  if (formationHasWideStrikers(currentFormation) && !formationHasWideStrikers(newFormation)) {
    // LS and RS to S
    moveMultiplePositions(teamData, newTeam, ['LS', 'RS'], 'S');
  }
  // If moving from central striker to only wide strikers
  else if (formationHasCentralStriker(currentFormation) && !formationHasCentralStriker(newFormation)) {
    // S to LS/RS
    alternateCentralPosition(teamData, newTeam, 'S', 'S');
  }

  newTeam['formation'] = newFormation;

  return newTeam;
};
