/* eslint-disable max-len */

import { StringToAnyMap, StringToStringArrayMap } from '../types';


const S_radarMetrics: string[] = [
  'np_xg_per_90',
  'np_postshot_xg_difference_per_90',
  'n_aerial_duels_won_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'open_play_xa_per_90',
];


const W_radarMetrics: string[] = [
  'np_xg_per_90',
  'np_postshot_xg_difference_per_90',
  'n_fouls_won_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'open_play_xa_per_90',
];


const CM_radarMetrics: string[] = [
  'np_xgi_per_90',
  'n_aerial_duels_won_per_90',
  'defending_obv_def_padj_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
];


const FB_radarMetrics: string[] = [
  'np_xgi_per_90',
  'n_aerial_duels_won_per_90',
  'defending_obv_def_padj_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
];


const CB_radarMetrics: string[] = [
  'np_xg_per_90',
  'n_aerial_duels_won_per_90',
  'defending_obv_def_padj_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
];


const GK_radarMetrics: string[] = [
  'successful_long_passes_percentage',
  'gk_n_defensive_actions_outside_box_per_90',
  'gk_saves_percentage',
  'gk_postshot_xg_prevented_difference_per_shot',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
  'successful_pass_percentage',
  'n_long_passes_per_90',
];


export const positionGroupToRadarMetrics: StringToStringArrayMap = {
  'S': S_radarMetrics,
  'W': W_radarMetrics,
  'CM': CM_radarMetrics,
  'FB': FB_radarMetrics,
  'CB': CB_radarMetrics,
  'GK': GK_radarMetrics,
};


export const outfieldCompareRadarMetrics: string[] = [
  'np_xg_per_90',
  'n_dribble_turnovers_per_90',
  'n_aerial_duels_won_per_90',
  'n_successful_interceptions_padj_per_90',
  'defending_obv_def_padj_per_90',
  'defending_obv_off_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
  'successful_pass_percentage',
  'open_play_xa_per_90',
];


export const skillcornerStats = [
  'skillcorner_psv-99_average',
  'skillcorner_distance_per_90',
  'skillcorner_distance_tip_per_90',
  'skillcorner_distance_otip_per_90',
  'skillcorner_sprinting_distance_per_90',
  'skillcorner_sprinting_distance_tip_per_90',
  'skillcorner_sprinting_distance_otip_per_90',
  'skillcorner_count_sprint_per_90',
  'skillcorner_count_sprint_tip_per_90',
  'skillcorner_count_sprint_otip_per_90',
  'skillcorner_hsr_distance_per_90',
  'skillcorner_hsr_distance_tip_per_90',
  'skillcorner_hsr_distance_otip_per_90',
];


export const outfieldSeasonTableMetricGroups: StringToStringArrayMap = {

  'obv': [
    'smart_pass_obv_per_90',
    'dribble_obv_per_90',
    'carry_obv_prog_per_90',
    'defending_obv_off_per_90',
    'defending_obv_def_padj_per_90',
  ],

  'attacking': [
    'n_goals_per_90',
    'n_np_goals_per_90',
    'np_xg_per_90',
    'n_shots_per_90',
    'np_postshot_xg_difference_per_90',
    'n_assists_per_90',
    'xa_per_90',
    'open_play_xa_per_90',
    'np_xgi_per_90',
  ],

  'possession': [
    'n_passes_per_90',
    'successful_pass_percentage',
    'n_long_passes_per_90',
    'successful_long_passes_percentage',
    'n_fouls_won_per_90',
    'n_dribble_turnovers_per_90',
    'n_successful_receptions_under_pressure_own_half_per_90',
    'successful_receptions_under_pressure_own_half_percentage',
  ],

  'defending': [
    'n_defensive_actions_padj_per_90',
    'n_presses_padj_per_90',
    'n_successful_interceptions_padj_per_90',
    'n_shot_blocks_padj_per_90',
    'n_defending_ground_duels_won_padj_per_90',
    'n_defending_ground_duels_won_in_own_box_padj_per_90',
    'n_fouls_per_90',
  ],

  'aerial': [
    'n_aerial_duels_won_per_90',
    'aerial_duels_won_percentage',
    'n_aerial_duels_won_in_own_box_per_90',
    'aerial_duels_won_in_own_box_percentage',
  ],
};


export const goalkeeperSeasonTableMetricGroups: StringToStringArrayMap = {

  'obv': [
    'smart_pass_obv_per_90',
  ],

  'shot_stopping': [
    'gk_n_saves_per_90',
    'gk_saves_percentage',
    'gk_postshot_xg_prevented_difference_per_shot',
    'gk_n_goals_conceded_per_90',
    'gk_penalty_saves_percentage',
  ],

  'distribution': [
    'n_passes_per_90',
    'successful_pass_percentage',
    'n_long_passes_per_90',
    'successful_long_passes_percentage',
  ],

  'aggressiveness': [
    'gk_n_defensive_actions_outside_box_per_90',
    'gk_average_defensive_actions_distance_from_own_goal',
  ],
};


export const commonSeasonTableMetrics = [
  'skill_rating',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
  'successful_pass_percentage',
  'n_long_passes_per_90',
  'successful_long_passes_percentage',
];


// all metrics used by the support page, where all goalkeeper metrics not part of the outfieldSeasonTableMetricGroups are merged into one metricGroup
export const supportPageMetricGroups = {
  ...outfieldSeasonTableMetricGroups,
  'Goalkeeper': goalkeeperSeasonTableMetricGroups.shot_stopping.concat(goalkeeperSeasonTableMetricGroups.aggressiveness)
};


export const outfieldSeasonTableMetrics = Object.values(outfieldSeasonTableMetricGroups).reduce((acc, current) => {
  return acc.concat(current);
}, []);


export const goalkeeperSeasonTableMetrics = Object.values(goalkeeperSeasonTableMetricGroups).reduce((acc, current) => {
  return acc.concat(current);
}, []);


export const getNormalizedMetricName = (rating: string) => {
  if (rating === 'skill_rating' || !metricToDisplayInfo[rating]) return rating;

  if (rating.endsWith('_normalized')) return rating;

  return rating + '_normalized';
};


// New paragraph: \n

export const metricToDisplayInfo: StringToAnyMap = {

  // indexes
  'skill_index': {
    'name': {
      'en': 'Skill index',
      'no': 'Skillindeks',
      'se': 'Skillindex',
      'dk': 'Skillindeks',
    },
    'description': {
      'en': 'Skill index is an overall measure of the performance level of the player.\nThe index is a weighted average of skill ratings from recent seasons, where more recent seasons and seasons with more playing time are weighted more. It will also take into account the level of the different competitions, such that ratings from tougher competitions will be rewarded, while ratings from weaker competitions will be penalized.\nThe value ranges from 0 to 10, and if you click on skill rating in a season table you will get more information on what the value means.',
      'no': 'Skillindeks er et overordnet mål på prestasjonsnivået til spilleren.\nIndeksen er et vektet snitt av skillratinger de siste sesongene, der nylige sesonger og sesonger med mye spilletid vektes mer. Den vil også ta hensyn til nivået på de ulike konkurransene, slik at ratinger fra tøffere konkurranser vil bli belønnet, mens ratinger fra svakere konkurranser vil bli straffet.\nVerdien går fra 0 til 10, og dersom du klikker på skillrating i en sesongtabell vil du få mer informasjon om hva verdien betyr.',
      'se': 'Skillindex är ett övergripande mått på spelarens prestandanivå.\nIndexet är ett viktat genomsnitt av skicklighetsbetyg från de senaste säsongerna, där de senaste säsongerna och säsongerna med mer speltid vägs tyngre. Det kommer också att ta hänsyn till nivån på de olika tävlingarna, så att betyg från tuffare tävlingar kommer att belönas, medan betyg från svagare tävlingar kommer att straffas.\nVärdet varierar från 0 till 10, och om du klickar på skicklighetsbetyg i en säsongstabell får du mer information om vad värdet betyder.',
      'dk': 'Skillindeks er et overordnet mål på spillerens præstationsniveau.\nIndekset er et vægtet gennemsnit af færdighedsvurderinger fra de seneste sæsoner, hvor de seneste sæsoner og sæsoner med mere spilletid vægtes mere. Det vil også tage højde for niveauet af de forskellige konkurrencer, således at vurderinger fra hårdere konkurrencer vil blive belønnet, mens vurderinger fra svagere konkurrencer vil blive straffet.\nVærdien spænder fra 0 til 10, og hvis du klikker på færdighedsvurdering i en sæsontabel, får du mere information om, hvad værdien betyder.',
    }
  },


  // ratings
  'skill_rating': {
    'name': {
      'en': 'Skill rating',
      'no': 'Skillrating',
      'se': 'Skillrating',
      'dk': 'Skillrating',
    },
    'description': {
      'en': 'Skill rating is an overall measure of the performance level of the player in a given season. The rating is a weighted sum of selected metrics, where the selection and weighting is based on the position group the player has played.\nA player\'s skill rating will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the value of the skill rating represents where the player ranks in this percentile ranking. A skill rating of 9, for example, means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.',
      'no': 'Skillrating er et overordnet mål på prestasjonsnivået til spilleren i en gitt sesong. Ratingen er en vektet sum av utvalgte metrikker, der utvalget og vektingen er basert på hvilken posisjonsgruppe spilleren har spilt.\nSkillratingen til en spiller vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdien på skillratingen representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en skillrating på 9 bety at spilleren denne sesongen er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.',
      'se': 'Skillrating är ett övergripande mått på spelarens prestandanivå under en given säsong. Betyget är en viktad summa av utvalda metriker, där urvalet och vikten baseras på den position grupp spelaren har spelat.\nEn spelares skillrating kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking av tusentals spelare, och värdet på skillratingen representerar var spelaren hamnar i denna percentil ranking. En skillrating på 9 betyder till exempel att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.',
      'dk': 'Skillrating er et overordnet mål på spillerens præstationsniveau i en given sæson. Vurderingen er en vægtet sum af udvalgte metrikker, hvor udvælgelsen og vægtningen er baseret på den position gruppe, spilleren har spillet.\nEn spillers skillrating vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdien af skillratingen repræsenterer, hvor spilleren rangerer i denne percentil rangering. En skillrating på 9 betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.',
    }
  },


  // confidence
  'confidence': {
    'name': {
      'en': 'Precision',
      'no': 'Presisjon',
      'se': 'Precision',
      'dk': 'Præcision',
    },
    'description': {
      'en': 'Precision is a measure of how much event data we have on a player, and represents the data foundation for all the indexes. Data from recent seasons will contribute more to the precision than data from many seasons ago, as recent data provides a better data foundation for evaluating a player than data far back in time.\nThe value ranges from 0 to 100, where 100 means we have at least two full seasons in the last two years.',
      'no': 'Presisjon er et mål på hvor mye event-data vi har på en spiller, og representerer datagrunnlaget for alle indeksene. Data fra nylige sesonger vil bidra mer til presisjonen enn data for mange sesonger siden, ettersom nylig data gir et bedre datagrunnlag for å vurdere en spiller enn data langt tilbake i tid.\nVerdien går fra 0 til 100, der 100 betyr at vi har omtrent minst to fulle sesonger de siste to årene.',
      'se': 'Precision är ett mått på hur mycket händelsedata vi har om en spelare, och representerar datagrunden för alla index. Data från de senaste säsongerna kommer att bidra mer till precisionen än data från många säsonger sedan, eftersom nylig data ger en bättre datagrund för att utvärdera en spelare än data långt tillbaka i tiden.\nVärdet varierar från 0 till 100, där 100 betyder att vi har minst två fulla säsonger de senaste två åren.',
      'dk': 'Præcision er et mål for, hvor mange eventdata vi har om en spiller, og repræsenterer datagrundlaget for alle indekserne. Data fra de seneste sæsoner vil bidrage mere til præcisionen end data fra mange sæsoner siden, da nylige data giver et bedre datagrundlag for at evaluere en spiller end data langt tilbage i tiden.\nVærdien spænder fra 0 til 100, hvor 100 betyder, at vi har mindst to fulde sæsoner i de sidste to år.',
    }
  },


  // metricGroups
  'ratings': {
    'name': {
      'en': 'Ratings',
      'no': 'Ratings',
      'se': 'Ratings',
      'dk': 'Ratings',
    },
    'description': {
      'en': 'Ratings are weighted sums of selected metrics for a given season.\nA player\'s ratings will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the value of the ratings represents where the player ranks in this percentile ranking. A rating of 9, for example, means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.',
      'no': 'Ratinger er vektede summer av utvalgte metrikker for en gitt sesong.\nRatingene til en spiller vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdien på ratingene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en rating på 9 bety at spilleren denne sesongen er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.',
      'se': 'Ratingar är viktade summor av utvalda metriker för en given säsong.\nEn spelares ratingar kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking av tusentals spelare, och värdet på ratingarna representerar var spelaren hamnar i denna percentil ranking. En rating på 9 betyder till exempel att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en posisjonsgrupp, och därmed topp 10%.',
      'dk': 'Ratinger er vægtede summer af udvalgte metrikker for en given sæson.\nEn spillers rating vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdien af ratingen repræsenterer, hvor spilleren rangerer i denne percentil rangering. En rating på 9 betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.',
    }
  },

  'indexes': {
    'name': {
      'en': 'Indexes',
      'no': 'Indekser',
      'se': 'Index',
      'dk': 'Indekser',
    },
    'description': {
      'en': 'Indexes are weighted averages of corresponding ratings from recent seasons, where more recent seasons and seasons with more playing time are weighted more. They will also take into account the level of the different competitions, such that ratings from tougher competitions will be rewarded, while ratings from weaker competitions will be penalized.\nThe value ranges from 0 to 10, and represents a ranking among all players in the database within a position group. If you click on a rating in a season table you will get more information on what the value means.',
      'no': 'Indekser er et vektet snitt av tilhørende ratinger de siste sesongene, der nylige sesonger og sesonger med mye spilletid vektes mer. De vil også ta hensyn til nivået på de ulike konkurransene, slik at ratinger fra tøffere konkurranser vil bli belønnet, mens ratinger fra svakere konkurranser vil bli straffet.\nVerdien på en indeks går fra 0 til 10, og representer en rangering blant alle spillere i databasen innenfor en posisjonsgruppe. Dersom du klikker på en rating i en sesongtabell vil du få mer informasjon om hva verdien betyr.',
      'se': 'Index är viktade genomsnitt av motsvarande betyg från de senaste säsongerna, där de senaste säsongerna och säsongerna med mer speltid vägs tyngre. De kommer också att ta hänsyn till nivån på de olika tävlingarna, så att betyg från tuffare tävlingar kommer att belönas, medan betyg från svagare tävlingar kommer att straffas.\nVärdet varierar från 0 till 10, och representerar en ranking bland alla spelare i databasen inom en position grupp. Om du klickar på en rating i en säsongstabell får du mer information om vad värdet betyder.',
      'dk': 'Indekser er vægtede gennemsnit af tilsvarende ratinger fra de seneste sæsoner, hvor de seneste sæsoner og sæsoner med mere spilletid vægtes mere. De vil også tage højde for niveauet af de forskellige konkurrencer, således at ratinger fra hårdere konkurrencer vil blive belønnet, mens ratinger fra svagere konkurrencer vil blive straffet.\nVærdien spænder fra 0 til 10, og repræsenterer en rangering blandt alle spillere i databasen inden for en position gruppe. Hvis du klikker på en rating i en sæsontabel, får du mere information om, hvad værdien betyder.',
    }
  },

  'obv': {
    'name': {
      'en': 'OBV',
      'no': 'OBV',
      'se': 'OBV',
      'dk': 'OBV',
    },
    'description': {
      'en': 'On-ball-value (OBV) is a Possession-Value model that uses machine learning techniques (AI) to evaluate all actions, and assigns each action a value based on how the action affects the team\'s chances of scoring and conceding.\nUnlike traditional metrics, OBV values are not based on the number of actions, but on the quality of the actions.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking among thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for an OBV metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nA more detailed explanation of OBV can be found in the Help section under Settings.',
      'no': 'On-ball-value (OBV) er en Possession-Value modell som bruker maskinlæringsteknikker (AI) til å vurdere alle handlinger, og tildeler hver handling en verdi basert på hvordan handlingen påvirker lagets sjanser for å score og slippe inn mål.\nI motsetning til tradisjonelle metrikker, er ikke OBV-verdier basert på antall handlinger, men på kvaliteten av handlingene.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 for en OBV-metrikk bety at spilleren denne sesongen er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nEn mer detaljert forklaring av OBV finnes i Hjelp-seksjonen under Innstillinger.',
      'se': 'On-ball-value (OBV) är en Possession-Value modell som använder maskininlärningstekniker (AI) för att utvärdera alla handlingar och tilldelar varje handling ett värde baserat på hur handlingen påverkar lagets chanser att göra mål och släppa in mål.\nTill skillnad från traditionella metriker är inte OBV-värden baserade på antalet handlingar, utan på kvaliteten på handlingarna.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en OBV-metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nEn mer detaljerad förklaring av OBV finns i Hjälp-sektionen under Inställningar.',
      'dk': 'On-ball-value (OBV) er en Possession-Value model, der bruger maskinlæringsteknikker (AI) til at evaluere alle handlinger og tildeler hver handling en værdi baseret på, hvordan handlingen påvirker holdets chancer for at score og indkassere mål.\nI modsætning til traditionelle metrikker er OBV-værdier ikke baseret på antallet af handlinger, men på kvaliteten af handlingerne.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en OBV-metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nEn mere detaljeret forklaring af OBV kan findes i Hjælp-sektionen under Indstillinger.',
    }
  },
  'attacking': {
    'name': {
      'en': 'Attacking',
      'no': 'Attacking',
      'se': 'Attacking',
      'dk': 'Attacking',
    },
    'description': {
      'en': 'Attacking actions related to scoring goals.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Offensive handlinger relatert til å score mål.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Offensiva handlingar relaterade till att göra mål.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Angrebsaktioner relateret til at score mål.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'possession': {
    'name': {
      'en': 'Possession',
      'no': 'Possession',
      'se': 'Possession',
      'dk': 'Possession',
    },
    'description': {
      'en': 'Actions related to possession.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Handlinger relatert til ballinnehav.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Handlingar relaterade till bollinnehav.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Handlinger relateret til boldbesiddelse.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'defending': {
    'name': {
      'en': 'Defending',
      'no': 'Defending',
      'se': 'Defending',
      'dk': 'Defending',
    },
    'description': {
      'en': 'Active defensive actions.\n Most metrics are adjusted for possession to give a fair comparison for players with fewer opportunities to perform defensive actions.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Aktive defensive handlinger.\nDe fleste metrikkene er justert for ballinnehav for å gi en rettferdig sammenligning for spillere med færre muligheter til å utføre defensive handlinger.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Aktiva defensiva handlingar.\n De flesta metriker är justerade för bollinnehav för att ge en rättvis jämförelse för spelare med färre möjligheter att utföra defensiva handlingar.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Aktive defensive handlinger.\nDe fleste metrikker er justeret for boldbesiddelse for at give en fair sammenligning for spillere med færre muligheder for at udføre defensive handlinger.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'aerial': {
    'name': {
      'en': 'Aerial',
      'no': 'Aerial',
      'se': 'Aerial',
      'dk': 'Aerial',
    },
    'description': {
      'en': 'Actions related to aerial duels.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Handlinger relatert til hodedueller.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Handlingar relaterade till luftdueller.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Handlinger relateret til luftdueller.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'shot_stopping': {
    'name': {
      'en': 'Shot stopping',
      'no': 'Shot stopping',
      'se': 'Shot stopping',
      'dk': 'Shot stopping',
    },
    'description': {
      'en': 'Actions related to stopping shots.\nGoalkeepers will always be compared against all goalkeepers in our database. This gives a ranking of thousands of goalkeepers, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all goalkeepers in the database, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Handlinger relatert til å stoppe skudd.\nKeepere vil alltid sammenlignes opp mot alle keepere i vår database. Dette gir en rangering blant tusenvis av keepere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle keepere i databasen, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Handlingar relaterade till att stoppa skott.\nMålvakter kommer alltid att jämföras med alla målvakter i vår databas. Detta ger en ranking bland tusentals målvakter, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla målvakter i databasen, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Handlinger relateret til at stoppe skud.\nMålmand vil altid blive sammenlignet med alle målmænd i vores database. Dette giver en rangering af tusindvis af målmænd, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle målmænd i databasen, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'distribution': {
    'name': {
      'en': 'Distribution',
      'no': 'Distribution',
      'se': 'Distribution',
      'dk': 'Distribution',
    },
    'description': {
      'en': 'Actions related to ball distribution.\nGoalkeepers will always be compared against all goalkeepers in our database. This gives a ranking of thousands of goalkeepers, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all goalkeepers in the database, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Handlinger relatert til å fordele ball.\nKeepere vil alltid sammenlignes opp mot alle keepere i vår database. Dette gir en rangering blant tusenvis av keepere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle keepere i databasen, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Handlingar relaterade till bollfördelning.\nMålvakter kommer alltid att jämföras med alla målvakter i vår databas. Detta ger en ranking bland tusentals målvakter, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla målvakter i databasen, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Handlinger relateret til boldfordeling.\nMålmand vil altid blive sammenlignet med alle målmænd i vores database. Dette giver en rangering af tusindvis af målmænd, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle målmænd i databasen, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'aggressiveness': {
    'name': {
      'en': 'Aggressiveness',
      'no': 'Aggressiveness',
      'se': 'Aggressiveness',
      'dk': 'Aggressiveness',
    },
    'description': {
      'en': 'Measures of aggressiveness of actions and positioning\nGoalkeepers will always be compared against all goalkeepers in our database. This gives a ranking of thousands of goalkeepers, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all goalkeepers in the database, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played.',
      'no': 'Mål på aggressivitet for handlinger og posisjonering.\nKeepere vil alltid sammenlignes opp mot alle keepere i vår database. Dette gir en rangering blant tusenvis av keepere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle keepere i databasen, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter.',
      'se': 'Mått på aggressivitet för handlingar och positionering.\nMålvakter kommer alltid att jämföras med alla målvakter i vår databas. Detta ger en ranking bland tusentals målvakter, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla målvakter i databasen, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter.',
      'dk': 'Mål på aggressivitet for handlinger og positionering.\nMålmand vil altid blive sammenlignet med alle målmænd i vores database. Dette giver en rangering af tusindvis af målmænd, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle målmænd i databasen, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter.',
    }
  },

  'skillcorner': {
    'name': {
      'en': 'SkillCorner',
      'no': 'SkillCorner',
      'se': 'SkillCorner',
      'dk': 'SkillCorner',
    },
    'description': {
      'en': 'Physical metrics from SkillCorner.\nPlayers will always be compared against all players in our database within a position group. This gives a ranking of thousands of players, and the values represent where the player ranks in this percentile ranking. For example, a value of 9 for a given metric means the player this season is better than 90% of all seasons for all players in the database within a position group, and thus top 10%.\nThe toggle to the right determines if you see the normalized values between 0 and 10, or the actual values per 90 minutes played. The absolute values may differ from more accurate GPS measurements due to the way the data is collected. However, the relative values are still valid for comparison.\nMore information on physical metrics from SkillCorner can be found in the Help section under Settings.',
      'no': 'Fysiske metrikker fra SkillCorner.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor en posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdiene representerer hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren denne sesongen for en gitt metrikk er bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed topp 10%.\nBryterknappen til høyre bestemmer om du ser de normaliserte verdiene mellom 0 og 10, eller de faktiske verdiene per 90 spilte minutter. De absolutte verdiene kan avvike fra mer nøyaktige GPS-målinger på grunn av måten dataen samles inn på. Imidlertid er de relative verdiene fortsatt gyldige for sammenligning.\nMer informasjon om fysiske metrikker fra SkillCorner finnes i Hjelp-seksjonen under Innstillinger.',
      'se': 'Fysiska metriker från SkillCorner.\nSpelare kommer alltid att jämföras med alla spelare i vår databas inom en position grupp. Detta ger en ranking bland tusentals spelare, och värdena representerar var spelaren hamnar i denna percentil ranking. Till exempel betyder ett värde på 9 för en given metrik att spelaren denna säsong är bättre än 90% av alla säsonger för alla spelare i databasen inom en position grupp, och därmed topp 10%.\nBryterknappen till höger bestämmer om du ser de normaliserade värdena mellan 0 och 10, eller de faktiska värdena per 90 spelade minuter. De absoluta värdena kan skilja sig från mer exakta GPS-mätningar på grund av hur data samlas in. Dock är de relativa värdena fortfarande giltiga för jämförelse.\nMer information om fysiska metriker från SkillCorner finns i Hjälp-sektionen under Inställningar.',
      'dk': 'Fysiske metrikker fra SkillCorner.\nSpillere vil altid blive sammenlignet med alle spillere i vores database inden for en position gruppe. Dette giver en rangering af tusindvis af spillere, og værdierne repræsenterer, hvor spilleren rangerer i denne percentil rangering. En værdi på 9 for en given metrik betyder f.eks., at spilleren i denne sæson er bedre end 90% af alle sæsoner for alle spillere i databasen inden for en position gruppe, og dermed top 10%.\nBryderknappen til højre bestemmer, om du ser de normaliserede værdier mellem 0 og 10, eller de faktiske værdier per 90 spillede minutter. De absolutte værdier kan afvige fra mere præcise GPS-målinger på grund af den måde, data indsamles på. Dog er de relative værdier stadig gyldige til sammenligning.\nMere information om fysiske metrikker fra SkillCorner kan findes i Hjælp-sektionen under Indstillinger.',
    }
  },


  // obv
  'smart_pass_obv_per_90': {
    'name': {
      'en': 'Bypass OBV',
      'no': 'Bypass OBV',
      'se': 'Bypass OBV',
      'dk': 'Bypass OBV',
    },
    'description': {
      'en': 'On-ball-value of passes surpassing at least one opponent, per 90 minutes played.\nThe values do not represent the number of passes, but the quality of the passes and the sum of their contributions. Higher values indicate more direct and value-creating passing play.',
      'no': 'On-ball-value for pasninger som går forbi minst en motstander, per 90 spilte minutter.\nVerdiene representerer ikke antall pasninger, men kvaliteten på pasningene og summen av hvor mye verdi de bidrar med. Høyere verdier indikerer mer direkte og verdiskapende pasningsspill.',
      'se': 'On-ball-value för passningar som passerar minst en motståndare, per 90 spelade minuter.\nVärdena representerar inte antalet passningar, utan kvaliteten på passningarna och summan av deras bidrag. Högre värden indikerar mer direkt och värdeskapande passningsspel.',
      'dk': 'On-ball-value for pasninger, der passerer mindst en modstander, per 90 spillede minutter.\nVærdierne repræsenterer ikke antallet af pasninger, men kvaliteten af pasningerne og summen af deres bidrag. Højere værdier indikerer mere direkte og værdiskabende pasningsspil.',
    }
  },

  'dribble_obv_per_90': {
    'name': {
      'en': 'Dribble OBV',
      'no': 'Dribble OBV',
      'se': 'Dribble OBV',
      'dk': 'Dribble OBV',
    },
    'description': {
      'en': 'On-ball-value of dribbles surpassing at least one opponent, per 90 minutes played.\nThe values do not represent the number of dribbles, but the quality of the dribbles and the sum of their contributions. Higher values indicate the ability to dribble past opponents, often in situations where this leads to goal-scoring opportunities or instability for the opponent.',
      'no': 'On-ball-value for driblinger som passerer minst en motstander, per 90 spilte minutter.\nVerdiene representerer ikke antall driblinger, men kvaliteten på driblingene og summen av hvor mye verdi de bidrar med. Høyere verdier indikerer evnen til å kunne dra av mann, gjerne i situasjoner hvor dette skaper overtall eller målsjanser.',
      'se': 'On-ball-value för dribblingar som passerar minst en motståndare, per 90 spelade minuter.\nVärdena representerar inte antalet dribblingar, utan kvaliteten på dribblingarna och summan av deras bidrag. Högre värden indikerar förmågan att driva förbi motståndare, ofta i situationer där detta leder till målchanser eller instabilitet för motståndaren.',
      'dk': 'On-ball-value for driblinger, der passerer mindst en modstander, per 90 spillede minutter.\nVærdierne repræsenterer ikke antallet af driblinger, men kvaliteten af driblingerne og summen af deres bidrag. Højere værdier indikerer evnen til at drible forbi modstandere, ofte i situationer, hvor dette fører til målchancer eller ustabilitet for modstanderen.',
    }
  },

  'carry_obv_prog_per_90': {
    'name': {
      'en': 'Carry OBV',
      'no': 'Carry OBV',
      'se': 'Carry OBV',
      'dk': 'Carry OBV',
    },
    'description': {
      'en': 'On-ball-value of progressive carries, per 90 minutes played.\nA progressive carry is a forward carry that does not pass any opponents. The values do not represent the number of carries, but the quality of the carries and the sum of their contributions. Higher values indicate a stronger ability and tendency to carry the ball forward into more valuable positions.',
      'no': 'On-ball-value for progressive ballføringer, per 90 spilte minutter.\nEn progressiv ballføring er en ballføring fremover i banen som ikke passerer noen motstandere. Verdiene representerer ikke antall ballføringer, men kvaliteten på ballføringene og summen av hvor mye verdi de bidrar med. Høyere verdier indikerer en sterkere evne og tendens til å føre ballen fremover inn i mer verdifulle posisjoner.',
      'se': 'On-ball-value för progressiva bollföringar, per 90 spelade minuter.\nEn progressiv bollföring är en framåtbollföring som inte passerar några motståndare. Värdena representerar inte antalet bollföringar, utan kvaliteten på bollföringarna och summan av deras bidrag. Högre värden indikerar en starkare förmåga och tendens att föra bollen framåt till mer värdefulla positioner.',
      'dk': 'On-ball-value for progressive bæringer, per 90 spillede minutter.\nEn progressiv bæring er en fremadrettet bæring, der ikke passerer nogen modstandere. Værdierne repræsenterer ikke antallet af bæringer, men kvaliteten af bæringerne og summen af deres bidrag. Højere værdier indikerer en stærkere evne og tendens til at bære bolden fremad til mere værdifulde positioner.',
    }
  },

  'defending_obv_per_90': {
    'name': {
      'en': 'Defending OBV',
      'no': 'Defending OBV',
      'se': 'Defending OBV',
      'dk': 'Defending OBV',
    },
    'description': {
      'en': 'On-ball-value of active defensive actions, per 90 minutes played.\nActive defensive actions involve duels, interceptions, tackles, and blocks. The values do not represent the number of active defensive actions, but the quality of the actions and the sum of their contributions. Higher values indicate stronger active defensive abilities, often in decisive situations that prevent goal-scoring chances, or in situations that create attacking transitions.\n Note that this metric does not directly capture positioning or the ability to prevent dangerous situations from arising in the first place without active involvement.',
      'no': 'On-ball-value for aktive defensive handlinger, per 90 spilte minutter.\nAktive defensive handlinger involverer dueller, pasningsbrudd, taklinger og blokkeringer. Verdiene representerer ikke antall aktive defensive handlinger, men kvaliteten på handlingene og summen av hvor mye verdi de bidrar med. Høyere verdier indikerer sterkere aktivt forsvar, gjerne i avgjørende situasjoner som avverger målsjanser, eller i situasjoner som skaper overganger for eget lag.\nMerk at denne metricen ikke direkte fanger opp posisjonering eller egenskapen til å avverge farlige situasjoner fra å oppstå i utgangspunktet uten aktive involveringer.',
      'se': 'On-ball-value för aktiva defensiva handlingar, per 90 spelade minuter.\nAktiva defensiva handlingar involverar dueller, passningsavbrott, tacklar och blockeringar. Värdena representerar inte antalet aktiva defensiva handlingar, utan kvaliteten på handlingarna och summan av deras bidrag. Högre värden indikerar starkare aktiva defensiva förmågor, ofta i avgörande situationer som förhindrar målchanser, eller i situationer som skapar anfallande övergångar.\nObservera att denna metrik inte direkt fångar upp positionering eller förmågan att förhindra farliga situationer från att uppstå från första början utan aktivt deltagande.',
      'dk': 'On-ball-value for aktive defensive handlinger, per 90 spillede minutter.\nAktive defensive handlinger involverer dueller, interceptions, taklinger og blokeringer. Værdierne repræsenterer ikke antallet af aktive defensive handlinger, men kvaliteten af handlingerne og summen af deres bidrag. Højere værdier indikerer stærkere aktive defensive evner, ofte i afgørende situationer, der forhindrer målchancer, eller i situationer, der skaber angribende overgange.\nBemærk, at denne metrik ikke direkte fanger positionering eller evnen til at forhindre farlige situationer i at opstå i første omgang uden aktiv involvering.',
    }
  },

  'defending_obv_off_per_90': {
    'name': {
      'en': 'Recovery OBV',
      'no': 'Recovery OBV',
      'se': 'Recovery OBV',
      'dk': 'Recovery OBV',
    },
    'description': {
      'en': 'On-ball-value of recoveries, per 90 minutes played.\nRecovering actions involve duels, interceptions, tackles, blocks, and fouls.\nUnlike Defending OBV, Recovery OBV measures how much a player’s defensive actions increase the player\'s own team’s chance of scoring. For example, a player winning the ball high up the pitch will likely receive a high value.\nThe values do not represent the number of recovering defensive actions, but the quality and overall impact of those actions in creating goal-scoring opportunities. Higher values indicate more active recovery and ball-winning abilities.\nNote that Recovery OBV does not measure the defensive contributions of actions such as preventing dangerous goal-scoring chances from the opposition team. The defensive contributions can be found in the metric Defending OBV.',
      'no': 'On-ball-value for gjenvinningsforsøk, per 90 spilte minutter.\n Gjenvinninger involverer dueller, pasningsbrudd, taklinger, blokkeringer og forseelser.\nI motsetning til Defending OBV, måler Recovery OBV hvor mye en spiller sine defensive handlinger øker eget lags sjanse for å score. For eksempel vil en spiller som vinner ballen høyt oppe på banen sannsynligvis motta en høy verdi. \nVerdiene representerer ikke antall gjenvinninger, men den totale effekten disse handlingene har på å skape målsjanser umiddelbart etterpå. Høyere verdier indikerer mer aktiv evne til å gjenvinne ballen i gunstige situasjoner.\nMerk at Recovery OBV ikke måler de defensive bidragene til handlinger, som å forhindre farlige målsjanser fra motstanderlaget. De defensive bidragene representeres i metrikken Defending OBV.',
      'se': 'On-ball-value för återvinningar, per 90 spelade minuter.\nÅtervinningshandlingar involverar dueller, passningsavbrott, tacklar, blockeringar och förseelser.\nTill skillnad från Defending OBV, mäter Recovery OBV hur mycket en spelares defensiva handlingar ökar det egna lagets chans att göra mål. Till exempel kommer en spelare som vinner bollen högt upp på planen sannolikt att få ett högt värde.\nVärdena representerar inte antalet återvinnande defensiva handlingar, utan kvaliteten och den övergripande effekten av dessa handlingar för att skapa målchanser. Högre värden indikerar mer aktiv förmåga att återvinna bollen och vinna boll.\nObservera att Recovery OBV inte mäter de defensiva bidragen till handlingar som att förhindra farliga målchanser från motståndarlaget. De defensiva bidragen finns i metriken Defending OBV.',
      'dk': 'On-ball-value for genopretninger, per 90 spillede minutter.\nGenoprettende handlinger involverer dueller, interceptions, taklinger, blokeringer og forseelser.\nI modsætning til Defending OBV måler Recovery OBV, hvor meget en spillers defensive handlinger øger eget holds chance for at score. For eksempel vil en spiller, der vinder bolden højt oppe på banen, sandsynligvis modtage en høj værdi.\nVærdierne repræsenterer ikke antallet af genoprettende defensive handlinger, men kvaliteten og den samlede indvirkning af disse handlinger på at skabe målchancer. Højere værdier indikerer mere aktiv evne til at vinde bolden og genoprette spillet.\nBemærk, at Recovery OBV ikke måler de defensive bidrag til handlinger som at forhindre farlige målchancer fra modstanderholdet. De defensive bidrag findes i metrikken Defending OBV.',
    }
  },

  'defending_obv_def_padj_per_90': {
    'name': {
      'en': 'Defending OBV',
      'no': 'Defending OBV',
      'se': 'Defending OBV',
      'dk': 'Defending OBV',
    },
    'description': {
      'en': 'On-ball-value from active defensive actions adjusted for possession, per 90 minutes played.\nActive defensive actions involve duels, interceptions, tackles, blocks, and fouls.\nUnlike most other OBV metrics, Defending OBV measures specifically how much a player’s defensive actions reduce or increase the opposition’s chance of scoring. For example, a player clearing a ball off the goal line will likely receive a high value, whereas committing a penalty when the opposition has a low chance of scoring will be penalized heavily.\nThe values do not represent the number of active defensive actions, but the quality and overall impact of those actions in either preventing or allowing scoring opportunities. Higher values indicate stronger active defensive abilities, particularly in situations where a player actively prevents dangerous goal-scoring chances. It is worth mentioning that this metric does not directly capture positioning or the ability to prevent dangerous situations from arising in the first place without active involvement.\nNote that Defending OBV does not capture the offensive contributions of actions, such as winning the ball high up on the pitch. These contributions are included in the metric Recovery OBV.',
      'no': 'On-ball-value for aktive defensive handlinger justert for ballinnehav, per 90 spilte minutter.\nAktive defensive handlinger involverer dueller, pasningsbrudd, taklinger, blokkeringer og forseelser.\nI motsetning til de fleste andre OBV-metrikker, måler Defending OBV spesifikt hvor mye en spiller sine defensive handlinger reduserer eller øker motstanderens sjanse for å score. For eksempel vil en spiller som klarerer ballen fra streken sannsynligvis motta en høy verdi, mens å lage straffespark når motstanderen har lav sjanse for å score vil bli straffet hardt.\nVerdiene representerer ikke antall aktive defensive handlinger, men kvaliteten og den totale effekten av disse handlingene i enten å forhindre eller tillate målsjanser. Høyere verdier indikerer sterkere evne til å involvere seg i forsvarspillet, spesielt i situasjoner der en spiller aktivt forhindrer farlige målsjanser. Det er verdt å nevne at denne metrikken ikke direkete fanger opp posisjonering eller evnen til å forhindre farlige situasjoner fra å oppstå i utgangspunktet uten aktiv involvering.\nMerk at Defending OBV ikke måler de offensive bidragene til handlinger, som å vinne ballen høyt oppe på banen. Disse bidragene er inkludert i metrikken Recovery OBV.',
      'se': 'On-ball-value från aktiva defensiva handlingar justerat för bollinnehav, per 90 spelade minuter.\nAktiva defensiva handlingar involverar dueller, passningsavbrott, tacklar, blockeringar och förseelser.\nTill skillnad från de flesta andra OBV-metrikerna mäter Defending OBV specifikt hur mycket en spelares defensiva handlingar minskar eller ökar motståndarens chans att göra mål. Till exempel kommer en spelare som rensar bollen från mållinjen sannolikt att få ett högt värde, medan att begå straffspark när motståndaren har låg chans att göra mål kommer att straffas hårt.\nVärdena representerar inte antalet aktiva defensiva handlingar, utan kvaliteten och den totala effekten av dessa handlingar för att antingen förhindra eller tillåta målchanser. Högre värden indikerar starkare aktiva defensiva förmågor, särskilt i situationer där en spelare aktivt förhindrar farliga målchanser. Det är värt att nämna att denna metrik inte direkt fångar upp positionering eller förmågan att förhindra farliga situationer från att uppstå från första början utan aktiv involvering.\nObservera att Defending OBV inte fångar upp de offensiva bidragen till handlingar, som att vinna bollen högt upp på planen. Dessa bidrag finns i metriken Recovery OBV.',
      'dk': 'On-ball-value fra aktive defensive handlinger justeret for boldbesiddelse, per 90 spillede minutter.\nAktive defensive handlinger involverer dueller, interceptions, taklinger, blokeringer og forseelser.\nI modsætning til de fleste andre OBV-metriker måler Defending OBV specifikt, hvor meget en spillers defensive handlinger reducerer eller øger modstanderens chance for at score. For eksempel vil en spiller, der rydder bolden fra målstregen, sandsynligvis modtage en høj værdi, mens at begå straffespark, når modstanderen har lav chance for at score, vil blive straffet hårdt.\nVærdierne repræsenterer ikke antallet af aktive defensive handlinger, men kvaliteten og den samlede indvirkning af disse handlinger på enten at forhindre eller tillade målchancer. Højere værdier indikerer stærkere evne til at involvere sig i forsvarsspillet, især i situationer, hvor en spiller aktivt forhindrer farlige målchancer. Det er værd at nævne, at denne metrik ikke direkte fanger positionering eller evnen til at forhindre farlige situationer i at opstå fra første færd uden aktiv involvering.\nBemærk, at Defending OBV ikke fanger de offensive bidrag til handlinger, som at vinde bolden højt oppe på banen. Disse bidrag er inkluderet i metrikken Recovery OBV.',
    }
  },


  // 'total_open_play_obv_per_90': {
  //   'name': {
  //     'en': 'Total OBV',
  //     'no': 'Total OBV',
  //     'se': 'Total OBV',
  //     'dk': 'Total OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of all actions per 90 minutes played.',
  //     'no': 'On-ball-value for alle handlinger per 90 spilte minutter.',
  //     'se': 'On-ball-value för alla handlingar per 90 spelade minuter.',
  //     'dk': 'On-ball-value for alle handlinger per 90 spillede minutter.',
  //   }
  // },

  // 'ground_duel_obv_per_90': {
  //   'name': {
  //     'en': 'Ground duel OBV',
  //     'no': 'Ground duel OBV',
  //     'se': 'Ground duel OBV',
  //     'dk': 'Ground duel OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of ground duels per 90 minutes played.',
  //     'no': 'On-ball-value for dueller på bakke per 90 spilte minutter.',
  //     'se': 'On-ball-value för markdueller per 90 spelade minuter.',
  //     'dk': 'On-ball-value for markdueller per 90 spillede minutter.',
  //   }
  // },

  // 'aerial_duel_obv_per_90': {
  //   'name': {
  //     'en': 'Aerial duel OBV',
  //     'no': 'Aerial duel OBV',
  //     'se': 'Aerial duel OBV',
  //     'dk': 'Aerial duel OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of aerial duels per 90 minutes played.',
  //     'no': 'On-ball-value for hodedueller per 90 spilte minutter.',
  //     'se': 'On-ball-value för luftdueller per 90 spelade minuter.',
  //     'dk': 'On-ball-value for luftdueller per 90 spillede minutter.',
  //   }
  // },

  // 'cross_obv_per_90': {
  //   'name': {
  //     'en': 'Cross OBV',
  //     'no': 'Cross OBV',
  //     'se': 'Cross OBV',
  //     'dk': 'Cross OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of crosses per 90 minutes played.',
  //     'no': 'On-ball-value for innlegg per 90 spilte minutter.',
  //     'se': 'On-ball-value för inlägg per 90 spelade minuter.',
  //     'dk': 'On-ball-value for indlæg per 90 spillede minutter.',
  //   }
  // },

  // 'receival_obv_per_90': {
  //   'name': {
  //     'en': 'Receival OBV',
  //     'no': 'Receival OBV',
  //     'se': 'Receival OBV',
  //     'dk': 'Receival OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of receptions per 90 minutes played.',
  //     'no': 'On-ball-value for mottak per 90 spilte minutter.',
  //     'se': 'On-ball-value för mottagningar per 90 spelade minuter.',
  //     'dk': 'On-ball-value for modtagelser per 90 spillede minutter.',
  //   }
  // },

  // 'defending_obv_padj_per_90': {
  //   'name': {
  //     'en': 'Defending OBV',
  //     'no': 'Defending OBV',
  //     'se': 'Defending OBV',
  //     'dk': 'Defending OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of defensive actions adjusted for possession per 90 minutes played.',
  //     'no': 'On-ball-value for defensive handlinger justert for ballinnehav per 90 spilte minutter.',
  //     'se': 'On-ball-value för defensiva handlingar justerat för bollinnehav per 90 spelade minuter.',
  //     'dk': 'On-ball-value for defensive handlinger justeret for boldbesiddelse per 90 spillede minutter.',
  //   }
  // },

  // 'interception_obv_per_90': {
  //   'name': {
  //     'en': 'Interception OBV',
  //     'no': 'Interception OBV',
  //     'se': 'Interception OBV',
  //     'dk': 'Interception OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of interceptions per 90 minutes played.',
  //     'no': 'On-ball-value for pasningsbrudd per 90 spilte minutter.',
  //     'se': 'On-ball-value för passningsavbrott per 90 spelade minuter.',
  //     'dk': 'On-ball-value for interceptions per 90 spillede minutter.',
  //   }
  // },

  // 'interception_obv_padj_per_90': {
  //   'name': {
  //     'en': 'Interception OBV',
  //     'no': 'Interception OBV',
  //     'se': 'Interception OBV',
  //     'dk': 'Interception OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of interceptions adjusted for possession per 90 minutes played.',
  //     'no': 'On-ball-value for pasningsbrudd justert for ballinnehav per 90 spilte minutter.',
  //     'se': 'On-ball-value för passningsavbrott justerat för bollinnehav per 90 spelade minuter.',
  //     'dk': 'On-ball-value for interceptions justeret for boldbesiddelse per 90 spillede minutter.',
  //   }
  // },

  // 'clearance_obv_per_90': {
  //   'name': {
  //     'en': 'Clearance OBV',
  //     'no': 'Clearance OBV',
  //     'se': 'Clearance OBV',
  //     'dk': 'Clearance OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of clearances per 90 minutes played.',
  //     'no': 'On-ball-value for klareringer per 90 spilte minutter.',
  //     'se': 'On-ball-value för rensningar per 90 spelade minuter.',
  //     'dk': 'On-ball-value for clearinger per 90 spillede minutter.',
  //   }
  // },

  // 'clearance_obv_padj_per_90': {
  //   'name': {
  //     'en': 'Clearance OBV',
  //     'no': 'Clearance OBV',
  //     'se': 'Clearance OBV',
  //     'dk': 'Clearance OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of clearances adjusted for possession per 90 minutes played.',
  //     'no': 'On-ball-value for klareringer justert for ballinnehav, per 90 spilte minutter.',
  //     'se': 'On-ball-value för rensningar justerat för bollinnehav, per 90 spelade minuter.',
  //     'dk': 'On-ball-value for clearinger justeret for boldbesiddelse per 90 spillede minutter.',
  //   }
  // },

  // 'block_obv_per_90': {
  //   'name': {
  //     'en': 'Block OBV',
  //     'no': 'Block OBV',
  //     'se': 'Block OBV',
  //     'dk': 'Block OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of blocks per 90 minutes played.',
  //     'no': 'On-ball-value for blokkeringer, per 90 spilte minutter.',
  //     'se': 'On-ball-value för blockeringar per 90 spelade minuter.',
  //     'dk': 'On-ball-value for blokeringer per 90 spillede minutter.',
  //   }
  // },

  // 'block_obv_padj_per_90': {
  //   'name': {
  //     'en': 'Block OBV',
  //     'no': 'Block OBV',
  //     'se': 'Block OBV',
  //     'dk': 'Block OBV',
  //   },
  //   'description': {
  //     'en': 'On-ball-value of blocks adjusted for possession per 90 minutes played.',
  //     'no': 'On-ball-value for blokkeringer justert for ballinnehav, per 90 spilte minutter.',
  //     'se': 'On-ball-value för blockeringar justerat för bollinnehav, per 90 spelade minuter.',
  //     'dk': 'On-ball-value for blokeringer justeret for boldbesiddelse per 90 spillede minutter.',
  //   }
  // },


  // attacking
  'n_goals_per_90': {
    'name': {
      'en': 'Goals',
      'no': 'Goals',
      'se': 'Goals',
      'dk': 'Goals',
    },
    'description': {
      'en': 'Number of goals scored per 90 minutes played.',
      'no': 'Antall mål scoret per 90 spilte minutter.',
      'se': 'Antal mål per 90 spelade minuter.',
      'dk': 'Antal mål per 90 spillede minutter.',
    }
  },

  'n_np_goals_per_90': {
    'name': {
      'en': 'NP goals',
      'no': 'NP goals',
      'se': 'NP goals',
      'dk': 'NP goals',
    },
    'description': {
      'en': 'Number of non-penalty goals scored per 90 minutes played.',
      'no': 'Antall mål scoret utenom straffespark per 90 spilte minutter.',
      'se': 'Antal mål utan straffespark per 90 spelade minuter.',
      'dk': 'Antal mål uden straffespark per 90 spillede minutter.',
    }
  },

  // 'xg_per_90': {
  //   'name': {
  //     'en': 'xG',
  //     'no': 'xG',
  //     'se': 'xG',
  //     'dk': 'xG',
  //   },
  //   'description': {
  //     'en': 'Expected goals per 90 minutes played.',
  //     'no': 'Forventede mål per 90 spilte minutter.',
  //     'se': 'Förväntade mål per 90 spelade minuter.',
  //     'dk': 'Forventede mål per 90 spillede minutter.',
  //   }
  // },

  'np_xg_per_90': {
    'name': {
      'en': 'NP xG',
      'no': 'NP xG',
      'se': 'NP xG',
      'dk': 'NP xG',
    },
    'description': {
      'en': 'Non-penalty expected goals per 90 minutes played.',
      'no': 'Forventede mål utenom straffespark per 90 spilte minutter.',
      'se': 'Förväntade mål utan straffespark per 90 spelade minuter.',
      'dk': 'Forventede mål uden straffespark per 90 spillede minutter.',
    }
  },

  'n_shots_per_90': {
    'name': {
      'en': 'Shots',
      'no': 'Shots',
      'se': 'Shots',
      'dk': 'Shots',
    },
    'description': {
      'en': 'Number of shots per 90 minutes played.',
      'no': 'Antall skudd per 90 spilte minutter.',
      'se': 'Antal skott per 90 spelade minuter.',
      'dk': 'Antal skud per 90 spillede minutter.',
    }
  },

  'np_postshot_xg_difference_per_90': {
    'name': {
      'en': 'SGA',
      'no': 'SGA',
      'se': 'SGA',
      'dk': 'SGA',
    },
    'description': {
      'en': 'Shooting Goals Added (SGA) measures the difference between Expected goals on target (xGOT) and Expected goals (xG) per 90 minutes played.\nSGA indicates how well the player\'s shot placements compare to the underlying quality of the chances.\nUnlike an xG model, which is a pre-shot model, the xGOT model measures the likelihood of an on-target shot resulting in a goal, based on the combination of the underlying chance quality (xG) and the end location of the shot within the goalmouth.\n A more detailed explanation can be found in the Help section under Settings.',
      'no': 'Mål tilført fra skudd (Shooting Goals Added) måler differansen mellom Expected goals on target (xGOT) og Expected goals (xG) per 90 spilte minutter.\nSGA indikerer hvor godt spilleren plasserer skuddene på mål, sammenlignet med kvaliteten på sjansene.\nI motsetning til et xG-modell, som er en pre-shot modell, måler xGOT-modellen sannsynligheten for at et skudd mot mål resulterer i et mål, basert på kombinasjonen av den underliggende sjansens kvalitet (xG) og skuddets endelige plassering innenfor målet.\nEn mer detaljert forklaring finnes i Hjelp-seksjonen under Innstillinger.',
      'se': 'Shooting Goals Added (SGA) mäter skillnaden mellan Expected goals on target (xGOT) och Expected goals (xG) per 90 spelade minuter.\nSGA indikerar hur väl spelarens skottplaceringar jämförs med chansernas kvalitet.\nTill skillnad från en xG-modell, som är en pre-shot-modell, mäter xGOT-modellen sannolikheten för att ett skott på mål resulterar i ett mål, baserat på kombinationen av chansens kvalitet (xG) och skottets slutliga placering inom målet.\nEn mer detaljerad förklaring finns i Hjälp-sektionen under Inställningar.',
      'dk': 'Shooting Goals Added (SGA) måler forskellen mellem Expected goals on target (xGOT) og Expected goals (xG) per 90 spillede minutter.\nSGA indikerer, hvor godt spillerens skudplaceringer sammenlignes med chancernes kvalitet.\nI modsætning til en xG-model, som er en pre-shot-model, måler xGOT-modellen sandsynligheden for, at et skud på mål resulterer i et mål, baseret på kombinationen af chancens kvalitet (xG) og skuddets endelige placering inden for målet.\nEn mere detaljeret forklaring kan findes i Hjælp-sektionen under Indstillinger.',
    }
  },

  'n_assists_per_90': {
    'name': {
      'en': 'Assists',
      'no': 'Assists',
      'se': 'Assists',
      'dk': 'Assists',
    },
    'description': {
      'en': 'Number of assists per 90 minutes played.',
      'no': 'Antall assists per 90 spilte minutter.',
      'se': 'Antal assists per 90 spelade minuter.',
      'dk': 'Antal assists per 90 spillede minutter.',
    }
  },

  // 'n_open_play_assists_per_90': {
  //   'name': {
  //     'en': 'OP assists',
  //     'no': 'OP assists',
  //     'se': 'OP assists',
  //     'dk': 'OP assists',
  //   },
  //   'description': {
  //     'en': 'Number of assists from open play (OP) per 90 minutes played.',
  //     'no': 'Antall assists fra åpent spill (Open Play) per 90 spilte minutter.',
  //     'se': 'Antal assists från öppet spel (OP) per 90 spelade minuter.',
  //     'dk': 'Antal assists fra åbent spil (OP) per 90 spillede minutter.',
  //   }
  // },

  'xa_per_90': {
    'name': {
      'en': 'xA',
      'no': 'xA',
      'se': 'xA',
      'dk': 'xA',
    },
    'description': {
      'en': 'Expected assists per 90 minutes played.',
      'no': 'Forventede assists per 90 spilte minutter.',
      'se': 'Förväntade assists per 90 spelade minuter.',
      'dk': 'Forventede assists per 90 spillede minutter.',
    }
  },

  'open_play_xa_per_90': {
    'name': {
      'en': 'OP xA',
      'no': 'OP xA',
      'se': 'OP xA',
      'dk': 'OP xA',
    },
    'description': {
      'en': 'Expected assists from open play (OP) per 90 minutes played.',
      'no': 'Forventede assists fra åpent spill (Open Play) per 90 spilte minutter.',
      'se': 'Förväntade assists från öppet spel (OP) per 90 spelade minuter.',
      'dk': 'Forventede assists fra åbent spil (OP) per 90 spillede minutter.',
    }
  },

  'np_xgi_per_90': {
    'name': {
      'en': 'NP xGI',
      'no': 'NP xGI',
      'se': 'NP xGI',
      'dk': 'NP xGI',
    },
    'description': {
      'en': 'Non-penalty expected goal involvements per 90 minutes played.',
      'no': 'Forventede målinvolveringer utenom straffespark per 90 spilte minutter.',
      'se': 'Förväntade målinvolveringar utan straffespark per 90 spelade minuter.',
      'dk': 'Forventede målinvolveringer uden straffespark per 90 spillede minutter.',
    }
  },

  // 'n_receptions_in_box_per_90': {
  //   'name': {
  //     'en': 'Receptions IB',
  //     'no': 'Receptions IB',
  //     'se': 'Receptions IB',
  //     'dk': 'Receptions IB',
  //   },
  //   'description': {
  //     'en': "Number of receptions in opponent's box per 90 minutes played.",
  //     'no': 'Antall mottak i motstanders boks per 90 spilte minutter.',
  //     'se': 'Antal mottagningar i motståndarens straffområde per 90 spelade minuter.',
  //     'dk': 'Antal modtagelser i modstanderens felt per 90 spillede minutter.',
  //   }
  // },


  // possession
  'n_passes_per_90': {
    'name': {
      'en': 'Passes',
      'no': 'Passes',
      'se': 'Passes',
      'dk': 'Passes',
    },
    'description': {
      'en': 'Number of passes per 90 minutes played.',
      'no': 'Antall pasninger per 90 spilte minutter.',
      'se': 'Antal passningar per 90 spelade minuter.',
      'dk': 'Antal afleveringer per 90 spillede minutter.',
    }
  },

  'successful_pass_percentage': {
    'name': {
      'en': 'Pass %',
      'no': 'Pass %',
      'se': 'Pass %',
      'dk': 'Pass %',
    },
    'description': {
      'en': 'Success rate of all passes.',
      'no': 'Treffprosent for alle pasninger.',
      'se': 'Treffprocent för alla passningar.',
      'dk': 'Treffprocent for alle afleveringer.',
    }
  },

  'n_long_passes_per_90': {
    'name': {
      'en': 'Long passes',
      'no': 'Long passes',
      'se': 'Long passes',
      'dk': 'Long passes',
    },
    'description': {
      'en': 'Number of passes over 34 meters per 90 minutes played.',
      'no': 'Antall pasninger over 34 meter per 90 spilte minutter.',
      'se': 'Antal passningar över 34 meter per 90 spelade minuter.',
      'dk': 'Antal afleveringer over 34 meter per 90 spillede minutter.',
    }
  },

  'successful_long_passes_percentage': {
    'name': {
      'en': 'Long pass %',
      'no': 'Long pass %',
      'se': 'Long pass %',
      'dk': 'Long pass %',
    },
    'description': {
      'en': 'Success rate of long passes.',
      'no': 'Treffprosent for langpasninger.',
      'se': 'Treffprocent för långpassningar.',
      'dk': 'Treffprocent for langafleveringer.',
    }
  },

  // 'n_bypassed_opponents_per_90': {
  //   'name': {
  //     'en': 'Bypassed opponents',
  //     'no': 'Bypassed opponents',
  //     'se': 'Bypassed opponents',
  //     'dk': 'Bypassed opponents',
  //   },
  //   'description': {
  //     'en': 'Number of dribbles/passes surpassing opponents per 90 minutes played.',
  //     'no': 'Antall driblinger/pasninger forbi motstandere per 90 spilte minutter.',
  //     'se': 'Antal dribblingar/passningar förbi motståndare per 90 spelade minuter.',
  //     'dk': 'Antal driblinger/afleveringer forbi modstandere per 90 spillede minutter.',
  //   }
  // },

  // 'n_smart_passes_per_90': {
  //   'name': {
  //     'en': 'Bypasses',
  //     'no': 'Bypasses',
  //     'se': 'Bypasses',
  //     'dk': 'Bypasses',
  //   },
  //   'description': {
  //     'en': 'Number of passes surpassing opponents per 90 minutes played.',
  //     'no': 'Antall pasninger forbi motstandere per 90 spilte minutter.',
  //     'se': 'Antal passningar förbi motståndare per 90 spelade minuter.',
  //     'dk': 'Antal afleveringer forbi modstandere per 90 spillede minutter.',
  //   }
  // },

  // 'n_successful_passes_per_90': {
  //   'name': {
  //     'en': 'Passes completed',
  //     'no': 'Passes completed',
  //     'se': 'Passes completed',
  //     'dk': 'Passes completed',
  //   },
  //   'description': {
  //     'en': 'Number of successful passes per 90 minutes played.',
  //     'no': 'Antall vellykkede pasninger per 90 spilte minutter.',
  //     'se': 'Antal lyckade passningar per 90 spelade minuter.',
  //     'dk': 'Antal vellykkede afleveringer per 90 spillede minutter.',
  //   }
  // },

  // 'n_successful_long_passes_per_90': {
  //   'name': {
  //     'en': 'Long passes completed',
  //     'no': 'Long passes completed',
  //     'se': 'Long passes completed',
  //     'dk': 'Long passes completed',
  //   },
  //   'description': {
  //     'en': 'Number of successful long passes per 90 minutes played.',
  //     'no': 'Antall vellykkede langpasninger per 90 spilte minutter.',
  //     'se': 'Antal lyckade långpassningar per 90 spelade minuter.',
  //     'dk': 'Antal vellykkede langafleveringer per 90 spillede minutter.',
  //   }
  // },

  // 'n_deep_progressions_per_90': {
  //   'name': {
  //     'en': 'Deep progressions',
  //     'no': 'Deep progressions',
  //     'se': 'Deep progressions',
  //     'dk': 'Deep progressions',
  //   },
  //   'description': {
  //     'en': 'Number of carries/passes into the final third per 90 minutes played.',
  //     'no': 'Antall føringer/pasninger inn i siste tredjedel per 90 spilte minutter.',
  //     'se': 'Antal framsteg/passningar in i sista tredjedelen per 90 spelade minuter.',
  //     'dk': 'Antal føringer/afleveringer ind i sidste tredjedel per 90 spillede minutter.',
  //   }
  // },

  // 'n_dribbles_per_90': {
  //   'name': {
  //     'en': 'Dribbles',
  //     'no': 'Dribbles',
  //     'se': 'Dribbles',
  //     'dk': 'Dribbles',
  //   },
  //   'description': {
  //     'en': 'Number of dribbles surpassing opponents per 90 minutes played.',
  //     'no': 'Antall driblinger forbi motstandere per 90 spilte minutter.',
  //     'se': 'Antal dribblingar förbi motståndare per 90 spelade minuter.',
  //     'dk': 'Antal driblinger forbi modstandere per 90 spillede minutter.',
  //   }
  // },

  // 'n_hold_up_plays_per_90': {
  //   'name': {
  //     'en': 'Hold-up plays',
  //     'no': 'Hold-up plays',
  //     'se': 'Hold-up plays',
  //     'dk': 'Hold-up plays',
  //   },
  //   'description': {
  //     'en': 'Number of hold-up plays per 90 minutes played.',
  //     'no': 'Antall hold-up plays per 90 spilte minutter.',
  //     'se': 'Antal hold-up plays per 90 spelade minuter.',
  //     'dk': 'Antal hold-up plays per 90 spillede minutter.',
  //   }
  // },

  // 'successful_hold_up_plays_percentage': {
  //   'name': {
  //     'en': 'Hold-up plays %',
  //     'no': 'Hold-up plays %',
  //     'se': 'Hold-up plays %',
  //     'dk': 'Hold-up plays %',
  //   },
  //   'description': {
  //     'en': 'Success rate of hold-up plays',
  //     'no': 'Suksess-rate for hold-up plays',
  //     'se': 'Framgångsgrad för hold-up plays',
  //     'dk': 'Succesrate for hold-up plays',
  //   }
  // },

  'n_fouls_won_per_90': {
    'name': {
      'en': 'Fouls won',
      'no': 'Fouls won',
      'se': 'Fouls won',
      'dk': 'Fouls won',
    },
    'description': {
      'en': 'Number of fouls won per 90 minutes played.',
      'no': 'Antall frispark vunnet per 90 spilte minutter.',
      'se': 'Antal frispark vunnet per 90 spelade minuter.',
      'dk': 'Antal frispark vundet per 90 spillede minutter.',
    }
  },

  'n_dribble_turnovers_per_90': {
    'name': {
      'en': 'Dispossessions',
      'no': 'Dispossessions',
      'se': 'Dispossessions',
      'dk': 'Dispossessions',
    },
    'description': {
      'en': 'Number of balls lost per 90 minutes played, either by being tackled or by losing control of the ball. Passing the ball away is not included in this metric.\nHigher values could indicate a player with a strong ability to keep possession and control of the ball, but it could also indicate a player that do not take many risks with the ball.\nHigher normalized values represent fewer dispossesions, which means that the normalized values for this metric are reversed. A normalized value of 9, for example, means that the player is good at not being dispossessed, while a normalized value of 1 means that the player has many dispossesions.',
      'no': 'Antall balltap per 90 spilte minutter, enten ved å bli taklet eller ved å miste kontroll over ballen. Feilpasninger er ikke inkludert som balltap i denne metrikken.\nHøyere verdier kan indikere en spiller med sterk evne til å ta vare på ballen, men det kan også indikere en spiller som tar lavere risiko med ballen.\nHøyere normaliserte verdier representerer færre balltap, som betyr at de normaliserte verdiene for denne metrikken er reversert. En normalisert verdi på 9, for eksempel, betyr at spilleren er flink til å ikke miste ballen, mens en normalisert verdi på 1 betyr at spilleren har mange balltap.',
      'se': 'Antal bolltapp per 90 spelade minuter, antingen genom att bli tacklad eller genom att tappa kontrollen över bollen. Felpassningar räknas inte som bolltapp i denna mätning.\nHöga värden kan indikera en spelare med stark förmåga att behålla bollen, men det kan också indikera en spelare som tar färre risker med bollen.\nHöga normaliserade värden representerar färre bolltapp, vilket betyder att de normaliserade värdena för denna mätning är omvända. Ett normaliserat värde på 9, till exempel, betyder att spelaren är bra på att inte tappa bollen, medan ett normaliserat värde på 1 betyder att spelaren har många bolltapp.',
      'dk': 'Antal boldtab per 90 spillede minutter, enten ved at blive tacklet eller ved at miste kontrollen over bolden. Fejlafleveringer er ikke inkluderet som boldtab i denne metrik.\nHøjere værdier kan indikere en spiller med stærk evne til at holde på bold, men det kan også indikere en spiller, der tager færre risici med bolden.\nHøjere normaliserede værdier repræsenterer færre boldtab, hvilket betyder, at de normaliserede værdier for denne metrik er omvendt. En normaliseret værdi på 9, for eksempel, betyder, at spilleren er god til ikke at miste bolden, mens en normaliseret værdi på 1 betyder, at spilleren har mange boldtab.',
    }
  },

  // 'n_pass_turnovers_per_90': {
  //   'name': {
  //     'en': 'Turnovers',
  //     'no': 'Turnovers',
  //     'se': 'Turnovers',
  //     'dk': 'Turnovers',
  //   },
  //   'description': {
  //     'en': 'Number of passes failed per 90 minutes played.',
  //     'no': 'Antall feilpasninger per 90 spilte minutter.',
  //     'se': 'Antal misslyckade passningar per 90 spelade minuter.',
  //     'dk': 'Antal fejlafleveringer per 90 spillede minutter.',
  //   }
  // },

  'n_successful_receptions_under_pressure_own_half_per_90': {
    'name': {
      'en': 'Retentions OH',
      'no': 'Retentions OH',
      'se': 'Retentions OH',
      'dk': 'Retentions OH',
    },
    'description': {
      'en': 'Number of successful receptions under pressure in own half (OH), per 90 minutes played.\nA successful reception is defined as one where the player retains possession after receiving the ball under pressure, either through a successful dribble or pass.\nHigher values could indicate a player that seeks the ball and takes responsibility in the build-up play, even under pressure.',
      'no': 'Antall vellykkede involveringer under press på egen halvdel (Own Half), per 90 spilte minutter.\nEn vellykket involvering er definert som en handling der spilleren mottar ballen under press, men evner å beholde ballen i laget i etterkant, enten gjennom en vellykket dribbling eller pasning.\n Høyere verdier kan indikere en spiller som søker ballen og tar ansvar i det oppbyggende spillet, selv under press.',
      'se': 'Antal lyckade mottagningar under press i egen planhalva (OH), per 90 spelade minuter.\nEn lyckad mottagning definieras som en handling där spelaren behåller bollen efter att ha mottagit den under press, antingen genom en lyckad dribbling eller passning.\nHöga värden kan indikera en spelare som söker bollen och tar ansvar i uppspelsfasen, även under press.',
      'dk': 'Antal vellykkede modtagelser under pres i egen halvdel (OH), per 90 spillede minutter.\nEn vellykket modtagelse er defineret som en handling, hvor spilleren beholder bolden efter at have modtaget den under pres, enten gennem en vellykket dribling eller aflevering.\nHøjere værdier kan indikere en spiller, der søger bolden og tager ansvar i opspilsfasen, selv under pres.',
    }
  },

  'successful_receptions_under_pressure_own_half_percentage': {
    'name': {
      'en': 'Retentions OH %',
      'no': 'Retentions OH %',
      'se': 'Retentions OH %',
      'dk': 'Retentions OH %',
    },
    'description': {
      'en': 'Success rate for receptions under pressure in own half (OH), per 90 minutes played.\nA successful action is defined as one where the player retains possession after receiving the ball under pressure, either through a successful dribble or pass.\nHigher values could indicate a player that is comfortable in the build-up play, even under pressure.',
      'no': 'Suksess-rate for involveringer under press på egen halvdel (Own Half), per 90 spilte minutter.\nEn vellykket involvering er definert som en handling der spilleren mottar ballen under press, men evner å beholde ballen i laget i etterkant, enten gjennom en vellykket dribbling eller pasning.\n Høyere verdier kan indikere en spiller som er trygg i det oppbyggende spillet, selv under press.',
      'se': 'Framgångsgrad för mottagningar under press i egen planhalva (OH), per 90 spelade minuter.\nEn lyckad mottagning definieras som en handling där spelaren behåller bollen efter att ha mottagit den under press, antingen genom en lyckad dribbling eller passning.\nHöga värden kan indikera en spelare som är bekväm i uppspelsfasen, även under press.',
      'dk': 'Succesrate for modtagelser under pres i egen halvdel (OH), per 90 spillede minutter.\nEn vellykket handling er defineret som en handling, hvor spilleren beholder bolden efter at have modtaget den under pres, enten gennem en vellykket dribling eller aflevering.\nHøjere værdier kan indikere en spiller, der er komfortabel i opspilsfasen, selv under pres.',
    }
  },


  // defensive
  'n_defensive_actions_padj_per_90': {
    'name': {
      'en': 'Defensive actions',
      'no': 'Defensive actions',
      'se': 'Defensive actions',
      'dk': 'Defensive actions',
    },
    'description': {
      'en': 'Number of active defensive actions adjusted for possession, per 90 minutes played.\nActive defensive actions include tackles, interceptions and blocks.',
      'no': 'Antall aktive defensive handlinger justert for ballinnehav, per 90 spilte minutter.\nAktive defensive handlinger inkluderer taklinger, pasningsbrudd og blokkeringer.',
      'se': 'Antal aktiva defensiva handlingar justerat för bollinnehav, per 90 spelade minuter.\nAktiva defensiva handlingar inkluderar tacklingar, interceptioner och blockeringar.',
      'dk': 'Antal aktive defensive handlinger justeret for ballinnehav, per 90 spillede minutter.\nAktive defensive handlinger inkluderer taklinger, pasningsbrud og blokeringer.',
    }
  },

  'n_presses_padj_per_90': {
    'name': {
      'en': 'Presses',
      'no': 'Presses',
      'se': 'Presses',
      'dk': 'Presses',
    },
    'description': {
      'en': 'Number of pressing attempts adjusted for possession, per 90 minutes played.\nA pressing attempt is defined as an action where the player attempts to win the ball back from the opponent, regardless of whether the player succeeds or whether there was contact at all.',
      'no': 'Antall pressforsøk justert for ballinnehav, per 90 spilte minutter.\nEt pressforsøk er definert som en handling der en spiller prøver å vinne ballen tilbake fra motstanderen, uavhengig av om spilleren lykkes eller om det var kontakt eller ikke.',
      'se': 'Antal pressförsök justerat för bollinnehav, per 90 spelade minuter.\nEtt pressförsök definieras som en handling där spelaren försöker vinna tillbaka bollen från motståndaren, oavsett om spelaren lyckas eller om det var kontakt eller inte.',
      'dk': 'Antal pressforsøg justeret for ballinnehav, per 90 spillede minutter.\nEt pressforsøg er defineret som en handling, hvor spilleren forsøger at vinde bolden tilbage fra modstanderen, uanset om spilleren lykkes eller om der var kontakt eller ej.',
    }
  },

  'n_successful_interceptions_padj_per_90': {
    'name': {
      'en': 'Interceptions',
      'no': 'Interceptions',
      'se': 'Interceptions',
      'dk': 'Interceptions',
    },
    'description': {
      'en': 'Number of successful interceptions adjusted for possession, per 90 minutes played.\nA successful interception is defined as an action where the player intercepts a pass and keeps possession after the interception.',
      'no': 'Antall vellykkete pasningsbrudd justert for ballinnehav, per 90 spilte minutter.\nEt vellykket pasningsbrudd er definert som en handling der spilleren bryter en pasning og beholder ballen i laget i neste handling.',
      'se': 'Antal lyckade interceptioner justerat för bollinnehav, per 90 spelade minuter.\nEn lyckad interception definieras som en handling där spelaren avbryter en passning och behåller bollen i laget i nästa handling.',
      'dk': 'Antal vellykkede interceptioner justeret for ballinnehav, per 90 spillede minutter.\nEn vellykket interception er defineret som en handling, hvor spilleren afbryder en aflevering og beholder bolden i holdet i næste handling.',
    }
  },

  // 'n_clearances_padj_per_90': {
  //   'name': {
  //     'en': 'Clearances',
  //     'no': 'Clearances',
  //     'se': 'Clearances',
  //     'dk': 'Clearances',
  //   },
  //   'description': {
  //     'en': 'Number of clearances under pressure adjusted for possession, per 90 minutes played.',
  //     'no': 'Antall klareringer under press justert for ballinnehav, per 90 spilte minutter.',
  //     'se': 'Antal rensningar under press justerat för bollinnehav, per 90 spelade minuter.',
  //     'dk': 'Antal clearences under pres justeret for ballinnehav, per 90 spillede minutter.',
  //   }
  // },

  // 'n_blocks_padj_per_90': {
  //   'name': {
  //     'en': 'Blocks',
  //     'no': 'Blocks',
  //     'se': 'Blocks',
  //     'dk': 'Blocks',
  //   },
  //   'description': {
  //     'en': 'Number of blocks adjusted for possession, per 90 minutes played.',
  //     'no': 'Antall blokkeringer justert for ballinnehav, per 90 spilte minutter.',
  //     'se': 'Antal blockeringar justerat för bollinnehav, per 90 spelade minuter.',
  //     'dk': 'Antal blokeringer justeret for ballinnehav, per 90 spillede minutter.',
  //   }
  // },

  'n_shot_blocks_padj_per_90': {
    'name': {
      'en': 'Shot blocks',
      'no': 'Shot blocks',
      'se': 'Shot blocks',
      'dk': 'Shot blocks',
    },
    'description': {
      'en': 'Number of shots blocked adjusted for possession, per 90 minutes played.',
      'no': 'Antall skuddblokkeringer justert for ballinnehav per 90 spilte minutter.',
      'se': 'Antal skottblockeringar justerat för bollinnehav per 90 spelade minuter.',
      'dk': 'Antal skud blokeret justeret for ballinnehav per 90 spillede minutter.',
    }
  },

  'n_defending_ground_duels_won_padj_per_90': {
    'name': {
      'en': 'Tackles',
      'no': 'Tackles',
      'se': 'Tackles',
      'dk': 'Tackles',
    },
    'description': {
      'en': 'Number of successful tackles adjusted for possession, per 90 minutes played.\nA successful tackle is defined as an action on the ground where a player wins the ball from the opponent, without the opponent attempting to release the ball through a pass or a shot.',
      'no': 'Antall vellykkede taklinger justert for ballinnehav, per 90 spilte minutter.\nEn vellykket takling er definert som en handling på bakken der en spiller vinner ballen fra mostanderen, uten at motstanderen forsøker å kvitte seg med ballen gjennom en pasning eller et skudd.',
      'se': 'Antal lyckade tacklingar justerat för bollinnehav, per 90 spelade minuter.\nEn lyckad tackling definieras som en handling på marken där en spelare vinner bollen från motståndaren, utan att motståndaren försöker släppa bollen genom en passning eller ett skott.',
      'dk': 'Antal vellykkede taklinger justeret for ballinnehav, per 90 spillede minutter.\nEn vellykket tackling er defineret som en handling på jorden, hvor en spiller vinder bolden fra modstanderen, uden at modstanderen forsøger at slippe af med bolden gennem en aflevering eller et skud.',
    }
  },

  'n_defending_ground_duels_won_in_own_box_padj_per_90': {
    'name': {
      'en': 'Tackles OB',
      'no': 'Tackles OB',
      'se': 'Tackles OB',
      'dk': 'Tackles OB',
    },
    'description': {
      'en': 'Number of successful tackles in own box (OB) adjusted for possession, per 90 minutes played.\nA successful tackle is defined as an action on the ground where a player wins the ball from the opponent, without the opponent attempting to release the ball through a pass or a shot.',
      'no': 'Antall vellykkede taklinger i egen boks (Own Box) justert for ballinnehav, per 90 spilte minutter.\nEn vellykket takling er definert som en handling på bakken der en spiller vinner ballen fra mostanderen, uten at motstanderen forsøker å kvitte seg med ballen gjennom en pasning eller et skudd.',
      'se': 'Antal lyckade tacklingar i egen boks (Own Box) justerat för bollinnehav, per 90 spelade minuter.\nEn lyckad tackling definieras som en handling på marken där en spelare vinner bollen från motståndaren, utan att motståndaren försöker släppa bollen genom en passning eller ett skott.',
      'dk': 'Antal vellykkede taklinger i egen boks (Own Box) justeret for ballinnehav, per 90 spillede minutter.\nEn vellykket tackling er defineret som en handling på jorden, hvor en spiller vinder bolden fra modstanderen, uden at modstanderen forsøger at slippe af med bolden gennem en aflevering eller et skud.',
    }
  },

  'n_fouls_per_90': {
    'name': {
      'en': 'Fouls',
      'no': 'Fouls',
      'se': 'Fouls',
      'dk': 'Fouls',
    },
    'description': {
      'en': 'Number of fouls committed per 90 minutes played.\nHigher normalized values represent fewer fouls committed, which means that the normalized values for this metric are reversed. A normalized value of 9, for example, means that the player is good at not committing fouls, while a normalized value of 1 means that the player commits many fouls.',
      'no': 'Antall frispark laget per 90 spilte minutter.\nHøyere normaliserte verdier representerer færre frispark laget, som betyr at de normaliserte verdiene for denne metrikken er reversert. En normalisert verdi på 9, for eksempel, betyr at spilleren er flink til å ikke lage frispark, mens en normalisert verdi på 1 betyr at spilleren lager mange frispark.',
      'se': 'Antal fouls begångna per 90 spelade minuter.\nHöga normaliserade värden representerar färre fouls begångna, vilket betyder att de normaliserade värdena för denna mätning är omvända. Ett normaliserat värde på 9, till exempel, betyder att spelaren är bra på att inte begå fouls, medan ett normaliserat värde på 1 betyder att spelaren begår många fouls.',
      'dk': 'Antal frispark begået per 90 spillede minutter.\nHøjere normaliserede værdier repræsenterer færre frispark begået, hvilket betyder, at de normaliserede værdier for denne metrik er omvendt. En normaliseret værdi på 9, for eksempel, betyder, at spilleren er god til ikke at begå frispark, mens en normaliseret værdi på 1 betyder, at spilleren begår mange frispark.',
    }
  },


  // aerial
  'n_aerial_duels_won_per_90': {
    'name': {
      'en': 'Aerial wins',
      'no': 'Aerial wins',
      'se': 'Aerial wins',
      'dk': 'Aerial wins',
    },
    'description': {
      'en': 'Number of aerial duels won per 90 minutes played.',
      'no': 'Antall hodedueller vunnet per 90 spilte minutter.',
      'se': 'Antal hodedueller vunnet per 90 spelade minuter.',
      'dk': 'Antal hodedueller vundet per 90 spillede minutter.',
    }
  },

  'aerial_duels_won_percentage': {
    'name': {
      'en': 'Aerial win %',
      'no': 'Aerial win %',
      'se': 'Aerial win %',
      'dk': 'Aerial win %',
    },
    'description': {
      'en': 'Success rate of aerial duels.',
      'no': 'Suksess-rate for hodedueller.',
      'se': 'Sucessrate för hodedueller.',
      'dk': 'Succesrate for hodedueller.',
    }
  },

  // 'n_aerial_duels_per_90': {
  //   'name': {
  //     'en': 'Aerial duels',
  //     'no': 'Aerial duels',
  //     'se': 'Aerial duels',
  //     'dk': 'Aerial duels',
  //   },
  //   'description': {
  //     'en': 'Number of aerial duels per 90 minutes played.',
  //     'no': 'Antall hodedueller per 90 spilte minutter.',
  //     'se': 'Antal hodedueller per 90 spelade minuter.',
  //     'dk': 'Antal hodedueller per 90 spillede minutter.',
  //   }
  // },

  // 'n_aerial_duels_in_own_box_per_90': {
  //   'name': {
  //     'en': 'Aerial duels OB',
  //     'no': 'Aerial duels OB',
  //     'se': 'Aerial duels OB',
  //     'dk': 'Aerial duels OB',
  //   },
  //   'description': {
  //     'en': 'Number of aerial duels in own box per 90 minutes played.',
  //     'no': 'Antall hodedueller i egen boks per 90 spilte minutter.',
  //     'se': 'Antal hodedueller i egen boks per 90 spelade minuter.',
  //     'dk': 'Antal hodedueller i egen boks per 90 spillede minutter.',
  //   }
  // },

  'n_aerial_duels_won_in_own_box_per_90': {
    'name': {
      'en': 'Aerial wins OB',
      'no': 'Aerial wins OB',
      'se': 'Aerial wins OB',
      'dk': 'Aerial wins OB',
    },
    'description': {
      'en': 'Number of aerial duels won in own box (OB) per 90 minutes played.',
      'no': 'Antall hodedueller vunnet i egen boks (Own Box) per 90 spilte minutter.',
      'se': 'Antal hodedueller vunnet i egen boks (Own Box) per 90 spelade minuter.',
      'dk': 'Antal hodedueller vundet i egen boks (Own Box) per 90 spillede minutter.',
    }
  },

  'aerial_duels_won_in_own_box_percentage': {
    'name': {
      'en': 'Aerial win % OB',
      'no': 'Aerial win % OB',
      'se': 'Aerial win % OB',
      'dk': 'Aerial win % OB',
    },
    'description': {
      'en': 'Success rate of aerial duels in own box (OB).',
      'no': 'Suksess-rate for hodedueller i egen boks (Own Box).',
      'se': 'Sucessrate för hodedueller i egen boks (Own Box).',
      'dk': 'Succesrate for hodedueller i egen boks (Own Box).',
    }
  },


  // skillcorner
  'skillcorner_psv-99_average': {
    'name': {
      'en': 'Pace',
      'no': 'Pace',
      'se': 'Pace',
      'dk': 'Pace',
    },
    'description': {
      'en': 'The peak speed of a player, and the ability to reach it multiple times and sustain it.\nThe metric represents an average of the highest speeds recorded for a player, excluding the very highest.\nMore precisely, the metric is calculated by first removing the 1% highest speeds recorded per match, to avoid outliers, and then taking the average of the 5 highest speeds from 5 different matches. This means that the metric is not equal to the highest speed recorded for a player, and it is therefore natural to expect that the actual values are somewhat lower than what would be measured with GPS. However, the relative values are more robust and still valid for comparison.',
      'no': 'Topphastigheten til en spiller, og evnen til å nå den flere ganger og opprettholde den.\nMetrikken representerer et gjennomsnitt av de høyeste hastighetene som er målt for en spiller, sett bort ifra de aller høyeste.\nMer presist er metrikken regnet ut ved å først fjerne de 1% høyeste hastighetene som er målt per kamp, for å unngå feilmålinger, og derretter ta gjennomsnittet av de 5 høyeste hastighetene fra 5 forskjellige kamper. Metrikken tilsvarer altså ikke den høyeste hastigheten som er målt for en spiller, og det er derfor naturlig å forvente at de faktiske verdiene er noe lavere enn det man ville målt med GPS. Derimot er de relative verdiene mer robuste og fortsatt gyldige for sammenligning.',
      'se': 'Spelarens topphastighet och förmågan att nå den flera gånger och hålla den.\nMåttet representerar ett genomsnitt av de högsta hastigheterna som registrerats för en spelare, exklusive de allra högsta.\nMer precist beräknas måttet genom att först ta bort de 1% högsta hastigheterna som registrerats per match, för att undvika extremvärden, och sedan ta genomsnittet av de 5 högsta hastigheterna från 5 olika matcher. Detta innebär att måttet inte motsvarar den högsta hastigheten som registrerats för en spelare, och det är därför naturligt att förvänta sig att de faktiska värdena är något lägre än vad som skulle mätas med GPS. De relativa värdena är dock mer robusta och fortfarande giltiga för jämförelse.',
      'dk': 'Spillerens topfart og evnen til at nå den flere gange og holde den.\nMålet repræsenterer et gennemsnit af de højeste hastigheder registreret for en spiller, eksklusive de allerhøjeste.\nMere præcist beregnes målet ved først at fjerne de 1% højeste hastigheder registreret per kamp, for at undgå ekstremværdier, og derefter tage gennemsnittet af de 5 højeste hastigheder fra 5 forskellige kampe. Dette betyder, at målet ikke svarer til den højeste hastighed registreret for en spiller, og det er derfor naturligt at forvente, at de faktiske værdier er lidt lavere end hvad der ville blive målt med GPS. De relative værdier er dog mere robuste og stadig gyldige for sammenligning.',
    }
  },

  'skillcorner_distance_per_90': {
    'name': {
      'en': 'Distance',
      'no': 'Distance',
      'se': 'Distance',
      'dk': 'Distance',
    },
    'description': {
      'en': 'Total distance covered per 90 minutes played.',
      'no': 'Antall kilometre løpt per 90 spilte minutter.',
      'se': 'Total distans som täckts per 90 spelade minuter.',
      'dk': 'Total distance løbet per 90 spillede minutter.',
    }
  },

  'skillcorner_sprinting_distance_per_90': {
    'name': {
      'en': 'Sprint distance',
      'no': 'Sprint distance',
      'se': 'Sprint distance',
      'dk': 'Sprint distance',
    },
    'description': {
      'en': 'Total sprint distance above 25 km/h per 90 minutes played.',
      'no': 'Antall kilometre sprintet over 25 km/t per 90 spilte minutter.',
      'se': 'Total sprintdistans över 25 km/h per 90 spelade minuter.',
      'dk': 'Total sprint distance over 25 km/t per 90 spillede minutter.',
    }
  },

  'skillcorner_count_sprint_per_90': {
    'name': {
      'en': 'Sprints',
      'no': 'Sprints',
      'se': 'Sprints',
      'dk': 'Sprints',
    },
    'description': {
      'en': 'Number of sprints above 25 km/h per 90 minutes played.',
      'no': 'Antall sprinter over 25 km/t per 90 spilte minutter.',
      'se': 'Antal spurter över 25 km/h per 90 spelade minuter.',
      'dk': 'Antal sprints over 25 km/t per 90 spillede minutter.',
    }
  },

  'skillcorner_distance_tip_per_90': {
    'name': {
      'en': 'Distance TIP',
      'no': 'Distance TIP',
      'se': 'Distance TIP',
      'dk': 'Distance TIP',
    },
    'description': {
      'en': 'Total distance covered when the player\'s team is in possession (TIP), per 90 minutes played.',
      'no': 'Antall kilometre løpt når eget lag har ball (Team In Possession), per 90 spilte minutter.',
      'se': 'Total distans som täckts när spelarens lag har bollen (Team In Possession), per 90 spelade minuter.',
      'dk': 'Total distance løbet når spillerens hold har bolden (Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_distance_otip_per_90': {
    'name': {
      'en': 'Distance OTIP',
      'no': 'Distance OTIP',
      'se': 'Distance OTIP',
      'dk': 'Distance OTIP',
    },
    'description': {
      'en': 'Total distance covered when the opponent team is in possession (OTIP), per 90 minutes played.',
      'no': 'Antall kilometre løpt når motstanderlaget har ball (Opponent Team In Possession), per 90 spilte minutter.',
      'se': 'Total distans som täckts när motståndarlaget har bollen (Opponent Team In Possession), per 90 spelade minuter.',
      'dk': 'Total distance løbet når modstanderholdet har bolden (Opponent Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_sprinting_distance_tip_per_90': {
    'name': {
      'en': 'Sprint distance TIP',
      'no': 'Sprint distance TIP',
      'se': 'Sprint distance TIP',
      'dk': 'Sprint distance TIP',
    },
    'description': {
      'en': 'Total sprint distance above 25 km/h when the player\'s team is in possession (TIP), per 90 minutes played.',
      'no': 'Antall kilometre sprintet når eget lag har ball (Team In Possession), per 90 spilte minutter.',
      'se': 'Total sprintdistans över 25 km/h när spelarens lag har bollen (Team In Possession), per 90 spelade minuter.',
      'dk': 'Total sprint distance over 25 km/t når spillerens hold har bolden (Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_sprinting_distance_otip_per_90': {
    'name': {
      'en': 'Sprint distance OTIP',
      'no': 'Sprint distance OTIP',
      'se': 'Sprint distance OTIP',
      'dk': 'Sprint distance OTIP',
    },
    'description': {
      'en': 'Total sprint distance above 25 km/h when the opponent team is in possession (OTIP), per 90 minutes played.',
      'no': 'Antall kilometre sprintet når motstanderlaget har ball (Opponent Team In Possession), per 90 spilte minutter.',
      'se': 'Total sprintdistans över 25 km/h när motståndarlaget har bollen (Opponent Team In Possession), per 90 spelade minuter.',
      'dk': 'Total sprint distance over 25 km/t når modstanderholdet har bolden (Opponent Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_count_sprint_tip_per_90': {
    'name': {
      'en': 'Sprints TIP',
      'no': 'Sprints TIP',
      'se': 'Sprints TIP',
      'dk': 'Sprints TIP',
    },
    'description': {
      'en': 'Number of sprints above 25 km/h when the player\'s team is in possession (TIP), per 90 minutes played.',
      'no': 'Antall spurter over 25 km/t når eget lag har ball (Team In Possession), per 90 spilte minutter.',
      'se': 'Antal spurter över 25 km/h när spelarens lag har bollen (Team In Possession), per 90 spelade minuter.',
      'dk': 'Antal sprints over 25 km/t når spillerens hold har bolden (Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_count_sprint_otip_per_90': {
    'name': {
      'en': 'Sprints OTIP',
      'no': 'Sprints OTIP',
      'se': 'Sprints OTIP',
      'dk': 'Sprints OTIP',
    },
    'description': {
      'en': 'Number of sprints above 25 km/h when the opponent team is in possession (OTIP), per 90 minutes played.',
      'no': 'Antall spurter over 25 km/t når motstanderlaget har ball (Opponent Team In Possession), per 90 spilte minutter.',
      'se': 'Antal spurter över 25 km/h när motståndarlaget har bollen (Opponent Team In Possession), per 90 spelade minuter.',
      'dk': 'Antal sprints over 25 km/t når modstanderholdet har bolden (Opponent Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_hsr_distance_per_90': {
    'name': {
      'en': 'HSR distance',
      'no': 'HSR distance',
      'se': 'HSR distance',
      'dk': 'HSR distance',
    },
    'description': {
      'en': 'Distance covered by High Speed Running (HSR) between 20 and 25 km/h, per 90 minutes played.',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t (High Speed Running), per 90 spilte minutter.',
      'se': 'Distans löpt i hög fart mellan 20 och 25 km/h (High Speed Running), per 90 spelade minuter.',
      'dk': 'Distance løbet i høj fart mellem 20 og 25 km/t (High Speed Running), per 90 spillede minutter.',
    }
  },

  'skillcorner_hsr_distance_tip_per_90': {
    'name': {
      'en': 'HSR distance TIP',
      'no': 'HSR distance TIP',
      'se': 'HSR distance TIP',
      'dk': 'HSR distance TIP',
    },
    'description': {
      'en': 'Distance covered by High Speed Running (HSR) between 20 and 25 km/h when the player\'s team is in possession (TIP), per 90 minutes played.',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t (High Speed Running) når eget lag har ball (Team In Possession), per 90 spilte minutter.',
      'se': 'Distans löpt i hög fart mellan 20 och 25 km/h (High Speed Running) när spelarens lag har bollen (Team In Possession), per 90 spelade minuter.',
      'dk': 'Distance løbet i høj fart mellem 20 og 25 km/t (High Speed Running) når spillerens hold har bolden (Team In Possession), per 90 spillede minutter.',
    }
  },

  'skillcorner_hsr_distance_otip_per_90': {
    'name': {
      'en': 'HSR distance OTIP',
      'no': 'HSR distance OTIP',
      'se': 'HSR distance OTIP',
      'dk': 'HSR distance OTIP',
    },
    'description': {
      'en': 'Distance covered by High Speed Running (HSR) between 20 and 25 km/h when the opponent team is in possession (OTIP), per 90 minutes played.',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t (High Speed Running) når motstanderlaget har ball (Opponent Team In Possession), per 90 spilte minutter.',
      'se': 'Distans löpt i hög fart mellan 20 och 25 km/h (High Speed Running) när motståndarlaget har bollen (Opponent Team In Possession), per 90 spelade minuter.',
      'dk': 'Distance løbet i høj fart mellem 20 og 25 km/t (High Speed Running) når modstanderholdet har bolden (Opponent Team In Possession), per 90 spillede minutter.',
    }
  },


  // goalkeeping
  'gk_n_saves_per_90': {
    'name': {
      'en': 'Saves',
      'no': 'Saves',
      'se': 'Saves',
      'dk': 'Saves',
    },
    'description': {
      'en': 'Number of saves per 90 minutes played.',
      'no': 'Antall redninger per 90 spilte minutter.',
      'se': 'Antal räddningar per 90 spelade minuter.',
      'dk': 'Antal redninger per 90 spillede minutter.',
    }
  },

  'gk_saves_percentage': {
    'name': {
      'en': 'Save %',
      'no': 'Save %',
      'se': 'Save %',
      'dk': 'Save %',
    },
    'description': {
      'en': 'Percentage of shots saved.',
      'no': 'Redningsprosent.',
      'se': 'Räddningsprocent.',
      'dk': 'Redningsprocent.',
    }
  },

  'gk_postshot_xg_prevented_difference_per_shot': {
    'name': {
      'en': 'Goals prevented',
      'no': 'Goals prevented',
      'se': 'Goals prevented',
      'dk': 'Goals prevented',
    },
    'description': {
      'en': 'Expected goals prevented per shot.\nThis metric represents the difference between the number of goals a goalkeeper is expected to concede given the quality of shots faced (xGOT), and the number of goals the goalkeeper has actually conceded, per shot. This means that the metric will neither reward nor punish goalkeepers based on the number of shots they face, but the metric will be more robust for goalkeepers who have faced many shots.',
      'no': 'Antall forventede mål forhindret per skudd.\nDenne metrikken representerer differansen mellom antall mål en keeper er forventet å slippe inn gitt kvaliteten på skuddene mot seg (xGOT), og antall mål keeperen faktisk har sluppet inn, per skudd. Dette betyr at metrikken hverken vil belønne eller straffe keepere basert på antall skudd de får på seg, men metrikken vil være mer robust for keepere som har fått mange skudd på seg.',
      'se': 'Antal förväntade mål förhindrade per skott.\nDenna mätning representerar skillnaden mellan antalet mål en målvakt förväntas släppa in med tanke på skottens kvalitet (xGOT), och antalet mål som målvakten faktiskt har släppt in, per skott. Detta innebär att mätningen varken belönar eller straffar målvakter baserat på antalet skott de möter, men mätningen är mer robust för målvakter som har mött många skott.',
      'dk': 'Antal forventede mål forhindret per skud.\nDenne metrik repræsenterer forskellen mellem antallet af mål en målmand forventes at lukke ind givet kvaliteten af skudene han har stået overfor (xGOT), og antallet af mål målmanden faktisk har lukket ind, per skud. Dette betyder, at metrikken hverken belønner eller straffer målmænd baseret på antallet af skud de har, men metrikken er mere robust for målmænd, der har stået overfor mange skud.',
    }
  },

  'gk_n_goals_conceded_per_90': {
    'name': {
      'en': 'Goals conceded',
      'no': 'Goals conceded',
      'se': 'Goals conceded',
      'dk': 'Goals conceded',
    },
    'description': {
      'en': 'Number of goals conceded per 90 minutes played.\nThis metric does not necessarily indicate how good a goalkeeper is at stopping shots, and is primarily included to provide context to the other metrics.',
      'no': 'Antall mål sluppet inn per 90 spilte minutter.\nDenne metrikken trenger ikke å indikere hvor god en keeper er til å stoppe skudd, og er først og fremst inkludert for å gi kontekst til de andre metrikkene.',
      'se': 'Antal mål släppta in per 90 spelade minuter.\nDenna mätning indikerar inte nödvändigtvis hur bra en målvakt är på att stoppa skott, och är främst inkluderad för att ge kontext till de andra mätningarna.',
      'dk': 'Antal mål lukket ind per 90 spillede minutter.\nDenne metrik indikerer ikke nødvendigvis hvor god en målmand er til at stoppe skud, og er primært inkluderet for at give kontekst til de andre metrikker.',
    }
  },

  // 'gk_n_catch_per_90': {
  //   'name': {
  //     'en': 'Claims',
  //     'no': 'Claims',
  //     'se': 'Claims',
  //     'dk': 'Claims',
  //   },
  //   'description': {
  //     'en': 'Number of catches per 90 minutes played.',
  //     'no': 'Antall baller holdt per 90 spilte minutter.',
  //     'se': 'Antal bollar hållna per 90 spelade minuter.',
  //     'dk': 'Antal bolde holdt per 90 spillede minutter.',
  //   }
  // },

  // 'gk_n_penalties_saved': {
  //   'name': {
  //     'en': 'Penalties saved',
  //     'no': 'Penalties saved',
  //     'se': 'Penalties saved',
  //     'dk': 'Penalties saved',
  //   },
  //   'description': {
  //     'en': 'Number of penalties saved.',
  //     'no': 'Antall straffer reddet.',
  //     'se': 'Antal straffar räddade.',
  //     'dk': 'Antal straffespark reddet.',
  //   }
  // },

  // 'gk_n_penalties_faced': {
  //   'name': {
  //     'en': 'Penalties faced',
  //     'no': 'Penalties faced',
  //     'se': 'Penalties faced',
  //     'dk': 'Penalties faced',
  //   },
  //   'description': {
  //     'en': 'Number of penalties faced.',
  //     'no': 'Antall straffer møtt.',
  //     'se': 'Antal straffar mötta.',
  //     'dk': 'Antal straffespark mødt.',
  //   }
  // },

  'gk_penalty_saves_percentage': {
    'name': {
      'en': 'Penalties saved %',
      'no': 'Penalties saved %',
      'se': 'Penalties saved %',
      'dk': 'Penalties saved %',
    },
    'description': {
      'en': 'Percentage of penalties saved.\nIf you choose to see the actual values, you will also see how many penalties the goalkeeper has faced in total and how many of those that have been saved.',
      'no': 'Redningsprosent for straffer.\nDersom du velger å se de faktiske verdiene vil du også se hvor mange straffer keeperen har møtt totalt og hvor mange av de som har blitt reddet.',
      'se': 'Räddningsprocent för straffar.\nOm du väljer att se de faktiska värdena kommer du också att se hur många straffar målvakten har mött totalt och hur många av dessa som har räddats.',
      'dk': 'Redningsprocent for straffespark.\nHvis du vælger at se de faktiske værdier, vil du også se, hvor mange straffespark målmanden har mødt i alt og hvor mange af dem, der er blevet reddet.',
    }
  },

  'gk_n_defensive_actions_outside_box_per_90': {
    'name': {
      'en': 'Aggressive actions',
      'no': 'Aggressive actions',
      'se': 'Aggressive actions',
      'dk': 'Aggressive actions',
    },
    'description': {
      'en': 'Number of defensive actions outside the own box per 90 minutes played.',
      'no': 'Antall defensive handlinger utenfor egen boks per 90 spilte minutter.',
      'se': 'Antal defensiva handlingar utanför egen boll per 90 spelade minuter.',
      'dk': 'Antal defensive handlinger udenfor egen boks per 90 spillede minutter.',
    }
  },

  'gk_average_defensive_actions_distance_from_own_goal': {
    'name': {
      'en': 'Aggressive positioning',
      'no': 'Aggressive positioning',
      'se': 'Aggressive positioning',
      'dk': 'Aggressive positioning',
    },
    'description': {
      'en': 'Average distance from own goal for defensive actions outisde the own box.',
      'no': 'Gjennomsnittlig avstand fra eget mål for defensive handlinger utenfor egen boks.',
      'se': 'Genomsnittligt avstånd från eget mål för defensiva handlingar utanför egen boll.',
      'dk': 'Gennemsnitlig afstand fra eget mål for defensive handlinger udenfor egen boks.',
    }
  },
};


// const allMetrics = [
//   'total_obv_per_90',
//   'total_open_play_obv_per_90',
//   'dribble_obv_per_90',
//   'dribble_obv_prog_per_90',
//   'carry_obv_per_90',
//   'carry_obv_prog_per_90',
//   'dc_obv_per_90',
//   'pass_obv_per_90',
//   'pass_obv_prog_per_90',
//   'smart_pass_obv_per_90',
//   'smart_pass_obv_prog_per_90',
//   'cross_obv_per_90',
//   'shot_obv_per_90',
//   'receival_obv_per_90',
//   'ground_duel_obv_per_90',
//   'defending_ground_duel_obv_per_90',
//   'defending_ground_duel_obv_padj_per_90',
//   'aerial_duel_obv_per_90',
//   'interception_obv_per_90',
//   'interception_obv_padj_per_90',
//   'clearance_obv_per_90',
//   'clearance_obv_padj_per_90',
//   'block_obv_per_90',
//   'block_obv_padj_per_90',
//   'free_kick_obv_per_90',
//   'corner_obv_per_90',
//   'recovery_obv_per_90',
//   'recovery_obv_padj_per_90',
//   'defending_obv_per_90',
//   'defending_obv_padj_per_90',
//   'gk_save_obv_per_90',
//   'gk_catch_obv_per_90',

//   'total_obv_per_90_normalized',
//   'total_open_play_obv_per_90_normalized',
//   'dribble_obv_per_90_normalized',
//   'dribble_obv_prog_per_90_normalized',
//   'carry_obv_per_90_normalized',
//   'carry_obv_prog_per_90_normalized',
//   'dc_obv_per_90_normalized',
//   'pass_obv_per_90_normalized',
//   'pass_obv_prog_per_90_normalized',
//   'smart_pass_obv_per_90_normalized',
//   'smart_pass_obv_prog_per_90_normalized',
//   'cross_obv_per_90_normalized',
//   'shot_obv_per_90_normalized',
//   'receival_obv_per_90_normalized',
//   'ground_duel_obv_per_90_normalized',
//   'defending_ground_duel_obv_per_90_normalized',
//   'defending_ground_duel_obv_padj_per_90_normalized',
//   'aerial_duel_obv_per_90_normalized',
//   'interception_obv_per_90_normalized',
//   'interception_obv_padj_per_90_normalized',
//   'clearance_obv_per_90_normalized',
//   'clearance_obv_padj_per_90_normalized',
//   'block_obv_per_90_normalized',
//   'block_obv_padj_per_90_normalized',
//   'free_kick_obv_per_90_normalized',
//   'corner_obv_per_90_normalized',
//   'recovery_obv_per_90_normalized',
//   'recovery_obv_padj_per_90_normalized',
//   'defending_obv_per_90_normalized',
//   'defending_obv_padj_per_90_normalized',
//   'gk_save_obv_per_90_normalized',
//   'gk_catch_obv_per_90_normalized',
//   'n_gk_shots_faced_per_90_normalized',
//   'n_gk_penalties_saved_per_90_normalized',
//   'n_gk_penalties_faced_per_90_normalized',


//   'n_goals',
//   'n_assists',

//   'n_goals_per_90',
//   'n_np_goals_per_90',
//   'n_shots_per_90',
//   'n_shots_on_goal_per_90',
//   'xg_per_90',
//   'postshot_xg_per_90',
//   'np_xg_per_90',
//   'np_postshot_xg_per_90',
//   'np_xg_difference_per_90',
//   'np_postshot_xg_difference_per_90',
//   'xa_per_90',
//   'open_play_xa_per_90',
//   'np_xgi_per_90',
//   'n_assists_per_90',
//   'n_receptions_in_box_per_90',
//   'n_receptions_in_box_pp_padj',
//   'n_bypassed_opponents_per_90',
//   'n_dribbles_per_90',
//   'n_dribbles_pp_padj',
//   'n_successful_dribbles_per_90',
//   'n_carries_per_90',
//   'n_carries_pp_padj',
//   'n_successful_carries_per_90',
//   'n_passes_per_90',
//   'n_passes_pp_padj',
//   'n_successful_passes_per_90',
//   'n_smart_passes_per_90',
//   'n_smart_passes_pp_padj',
//   'n_successful_smart_passes_per_90',
//   'n_long_passes_per_90',
//   'n_long_passes_pp_padj',
//   'n_successful_long_passes_per_90',
//   'n_crosses_per_90',
//   'n_crosses_pp_padj',
//   'n_successful_crosses_per_90',
//   'n_deep_progressions_per_90',
//   'n_deep_progressions_pp_padj',
//   'n_hold_up_plays_per_90',
//   'n_successful_hold_up_plays_per_90',
//   'n_fouls_won_per_90',
//   'n_dribble_turnovers_per_90',
//   'n_dribble_turnovers_pp_padj',
//   'n_pass_turnovers_per_90',
//   'n_pass_turnovers_pp_padj',
//   'n_successful_receptions_under_pressure_own_half_per_90',
//   'n_actions_under_pressure_per_90',
//   'n_successful_actions_under_pressure_per_90',
//   'n_free_kicks_per_90',
//   'n_corners_per_90',
//   'n_recoveries_per_90',
//   'n_recoveries_padj_per_90',
//   'n_interceptions_per_90',
//   'n_interceptions_padj_per_90',
//   'n_clearances_per_90',
//   'n_clearances_padj_per_90',
//   'n_blocks_per_90',
//   'n_blocks_padj_per_90',
//   'n_ground_duels_per_90',
//   'n_ground_duels_won_per_90',
//   'n_defending_ground_duels_won_per_90',
//   'n_defending_ground_duels_won_padj_per_90',
//   'n_defending_ground_duels_won_in_own_box_per_90',
//   'n_defending_ground_duels_won_in_own_box_padj_per_90',
//   'n_aerial_duels_per_90',
//   'n_aerial_duels_won_per_90',
//   'n_aerial_duels_in_own_box_per_90',
//   'n_aerial_duels_in_own_box_padj_per_90',
//   'n_aerial_duels_won_in_own_box_per_90',
//   'n_aerial_duels_won_in_own_box_padj_per_90',
//   'n_presses_per_90',
//   'n_presses_padj_per_90',
//   'n_defensive_actions_per_90',
//   'n_defensive_actions_padj_per_90',
//   'n_fouls_per_90',
//   'gk_n_saves_per_90',
//   'gk_n_catch_per_90',
//   'gk_postshot_xg_saved_per_90',
//   'gk_postshot_xg_faced_per_90',
//   'gk_postshot_xg_conceded_per_90',
//   'gk_n_goals_conceded_per_90',
//   'gk_postshot_xg_prevented_difference_per_90',
//   'gk_n_shots_faced_per_90',
//   'gk_n_penalties_saved_per_90',
//   'gk_n_penalties_faced_per_90',
//   'gk_n_defensive_actions_outside_box_per_90',

//   'n_goals_per_90_normalized',
//   'n_np_goals_per_90_normalized',
//   'n_shots_per_90_normalized',
//   'n_shots_on_goal_per_90_normalized',
//   'xg_per_90_normalized',
//   'postshot_xg_per_90_normalized',
//   'np_xg_per_90_normalized',
//   'np_postshot_xg_per_90_normalized',
//   'np_xg_difference_per_90_normalized',
//   'np_postshot_xg_difference_per_90_normalized',
//   'xa_per_90_normalized',
//   'open_play_xa_per_90_normalized',
//   'np_xgi_per_90_normalized',
//   'n_assists_per_90_normalized',
//   'n_receptions_in_box_per_90_normalized',
//   'n_receptions_in_box_pp_padj_normalized',
//   'n_bypassed_opponents_per_90_normalized',
//   'n_dribbles_per_90_normalized',
//   'n_dribbles_pp_padj_normalized',
//   'n_successful_dribbles_per_90_normalized',
//   'n_carries_per_90_normalized',
//   'n_carries_pp_padj_normalized',
//   'n_successful_carries_per_90_normalized',
//   'n_passes_per_90_normalized',
//   'n_passes_pp_padj_normalized',
//   'n_successful_passes_per_90_normalized',
//   'n_smart_passes_per_90_normalized',
//   'n_smart_passes_pp_padj_normalized',
//   'n_successful_smart_passes_per_90_normalized',
//   'n_long_passes_per_90_normalized',
//   'n_long_passes_pp_padj_normalized',
//   'n_successful_long_passes_per_90_normalized',
//   'n_crosses_per_90_normalized',
//   'n_crosses_pp_padj_normalized',
//   'n_successful_crosses_per_90_normalized',
//   'n_deep_progressions_per_90_normalized',
//   'n_deep_progressions_pp_padj_normalized',
//   'n_hold_up_plays_per_90_normalized',
//   'n_successful_hold_up_plays_per_90_normalized',
//   'n_fouls_won_per_90_normalized',
//   'n_dribble_turnovers_per_90_normalized',
//   'n_dribble_turnovers_pp_padj_normalized',
//   'n_pass_turnovers_per_90_normalized',
//   'n_pass_turnovers_pp_padj_normalized',
//   'n_successful_receptions_under_pressure_own_half_per_90_normalized',
//   'n_actions_under_pressure_per_90_normalized',
//   'n_successful_actions_under_pressure_per_90_normalized',
//   'n_free_kicks_per_90_normalized',
//   'n_corners_per_90_normalized',
//   'n_recoveries_per_90_normalized',
//   'n_recoveries_padj_per_90_normalized',
//   'n_interceptions_per_90_normalized',
//   'n_interceptions_padj_per_90_normalized',
//   'n_clearances_per_90_normalized',
//   'n_clearances_padj_per_90_normalized',
//   'n_blocks_per_90_normalized',
//   'n_blocks_padj_per_90_normalized',
//   'n_ground_duels_per_90_normalized',
//   'n_ground_duels_won_per_90_normalized',
//   'n_defending_ground_duels_won_per_90_normalized',
//   'n_defending_ground_duels_won_padj_per_90_normalized',
//   'n_defending_ground_duels_won_in_own_box_per_90_normalized',
//   'n_defending_ground_duels_won_in_own_box_padj_per_90_normalized',
//   'n_aerial_duels_per_90_normalized',
//   'n_aerial_duels_won_per_90_normalized',
//   'n_aerial_duels_in_own_box_per_90_normalized',
//   'n_aerial_duels_in_own_box_padj_per_90_normalized',
//   'n_aerial_duels_won_in_own_box_per_90_normalized',
//   'n_aerial_duels_won_in_own_box_padj_per_90_normalized',
//   'n_presses_per_90_normalized',
//   'n_presses_padj_per_90_normalized',
//   'n_defensive_actions_per_90_normalized',
//   'n_defensive_actions_padj_per_90_normalized',
//   'n_fouls_per_90_normalized',
//   'gk_n_saves_per_90_normalized',
//   'gk_n_catch_per_90_normalized',
//   'gk_postshot_xg_saved_per_90_normalized',
//   'gk_postshot_xg_faced_per_90_normalized',
//   'gk_postshot_xg_conceded_per_90_normalized',
//   'gk_n_goals_conceded_per_90_normalized',
//   'gk_postshot_xg_prevented_difference_per_90_normalized',

//   'successful_dribble_percentage',
//   'successful_carry_percentage',
//   'successful_pass_percentage',
//   'successful_smart_pass_percentage',
//   'successful_long_passes_percentage',
//   'successful_hold_up_plays_percentage',
//   'aerial_duels_won_percentage',
//   'aerial_duels_won_in_own_box_percentage',
//   'ground_duels_won_percentage',
//   'successful_actions_under_pressure_percentage',
//   'successful_receptions_under_pressure_own_half_percentage',
//   'gk_saves_percentage',
//   'gk_penalty_saves_percentage',
//   'gk_postshot_xg_prevented_difference_per_shot',
//   'gk_average_defensive_actions_distance_from_own_goal',
//   'gk_n_defensive_actions_outside_box_per_90_normalized',


//   'successful_dribble_percentage_normalized',
//   'successful_carry_percentage_normalized',
//   'successful_pass_percentage_normalized',
//   'successful_smart_pass_percentage_normalized',
//   'successful_long_passes_percentage_normalized',
//   'successful_hold_up_plays_percentage_normalized',
//   'aerial_duels_won_percentage_normalized',
//   'aerial_duels_won_in_own_box_percentage_normalized',
//   'ground_duels_won_percentage_normalized',
//   'successful_actions_under_pressure_percentage_normalized',
//   'successful_receptions_under_pressure_own_half_percentage_normalized',
//   'gk_saves_percentage_normalized',
//   'gk_penalty_saves_percentage_normalized',
//   'gk_postshot_xg_prevented_difference_per_shot_normalized',
//   'gk_average_defensive_actions_distance_from_own_goal_normalized',
// ];
