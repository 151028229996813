import './../modals.css';

import CloseIcon from '@mui/icons-material/Close';

import { FormOrPlayingTimeTable } from '../../visualization/FormOrPlayingTimeTable';


interface TextModalProps {
  closeModal: () => void;
  textTitle: string;
  text: string;
}

export const TextModal: React.FC<TextModalProps> = ({
  closeModal,
  textTitle,
  text,
}) => {

  const textArray = text.split('\n');
  const textAlign = textArray.length === 1 ? 'center' : 'left';

  const fontSize = 14;

  const paragraphMargin = 16;

  return (
    <div
      className='modal-root-container text-modal-root'
      style={{
        maxWidth: 650,
        minWidth: 400,
      }}
    >

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {textTitle}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div
        className='text-modal-content-section'
        style={{
          fontSize: fontSize,
          textAlign: textAlign,
        }}
      >
        {textArray.map((textLine: string, index: number) => {

          const isEmptySpace = textLine === '&nbsp;';
          if (isEmptySpace) {
            return (
              <div
                key={index}
                style={{ height: paragraphMargin }}
              />
            );
          }

          const isFormTable = textLine === '<FormTable/>';
          const isPlayingTimeTable = textLine === '<PlayingTimeTable/>';
          if (isFormTable || isPlayingTimeTable) {
            return (
              <div
                key={index}
                style={{
                  marginTop: index > 0 ? paragraphMargin : undefined,
                }}>
                <FormOrPlayingTimeTable isFormTable={isFormTable} />
              </div>
            );
          }

          return (
            <div
              key={index}
              style={{
                marginTop: index > 0 ? paragraphMargin : undefined,
              }}>
              {textLine}
            </div>
          );
        })}
      </div>

    </div>
  );
};
