import './input.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';

import Fuse from 'fuse.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../common/language/translations';
import { InputField } from './InputField';
import { countryCodeToCountryInfo } from '../../static/countries';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { League } from '../../types';


interface LeagueDropDownProps {
  id: string;

  selectedLeagues: string[];
  setSelectedLeagues: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling
}


export const LeagueDropDown: React.FC<LeagueDropDownProps> = ({
  id,

  selectedLeagues,
  setSelectedLeagues,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);
  const competitions = useRecoilValue(competitionsState);

  const [searchString, setSearchString] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [fuse, setFuse] = useState<Fuse<League> | null>(null);
  const [sortedStaticLeagues, setSortedStaticLeagues] = useState<League[]>([]);
  const [leaguesToShow, setLeaguesToShow] = useState<League[]>([]);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setLeaguesToShow(sortedStaticLeagues);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCompetitions(searchString);
    }
  };


  const handleSearchForCompetitions = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setLeaguesToShow(searchResult);
  }, [fuse]);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (leagueId: string, isOptionAlreadySelected: boolean) => {
    let newSelectedCompetitions = selectedLeagues.slice();

    if (isOptionAlreadySelected) {
      newSelectedCompetitions = newSelectedCompetitions.filter(item => item !== leagueId);
    }
    else {
      newSelectedCompetitions.push(leagueId);
    }

    setSelectedLeagues(newSelectedCompetitions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const showSelectedCompetitions = () => {
    if (selectedLeagues.length === 0 || !(Number(selectedLeagues[0]) in competitions)) return '';

    let display = competitions[Number(selectedLeagues[0])].name;

    for (let i = 1; i < selectedLeagues.length; i++) {
      display += ', ' + competitions[Number(selectedLeagues[i])].name;
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setLeaguesToShow(sortedStaticLeagues);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setLeaguesToShow(sortedStaticLeagues);
    }
    else {
      handleSearchForCompetitions(searchString);
    }
  }, [searchString, handleSearchForCompetitions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const searchKeys = [
      'name',
      'countryName_' + (userConfig?.language ?? 'en'),
    ];

    const options = {
      keys: searchKeys,
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(sortedStaticLeagues, options);
    setFuse(fuseInstance);
  }, [sortedStaticLeagues, userConfig?.language]);


  useEffect(() => {
    if (!clubScopes) return;

    const leagueList: League[] = Object.values(competitions).filter(competition => competition.type === 'League') as League[];

    // sort leagues based on access first, then orderIndex
    leagueList.sort((a, b) => {
      const aCompetition = competitions[Number(a.id)];
      const bCompetition = competitions[Number(b.id)];

      const aIsInScope = aCompetition &&
        aCompetition.iterationsWithEventData &&
        aCompetition.iterationsWithEventData.length > 0 &&
        (clubScopes.hasFullCoverage || clubScopes.competitionIds.includes(a.id));

      const bIsInScope = bCompetition &&
        bCompetition.iterationsWithEventData &&
        bCompetition.iterationsWithEventData.length > 0 &&
        (clubScopes.hasFullCoverage || clubScopes.competitionIds.includes(b.id));

      if (aIsInScope && !bIsInScope) return -1;
      if (!aIsInScope && bIsInScope) return 1;

      return a.orderIndex - b.orderIndex;
    });

    setSortedStaticLeagues(leagueList);
    setLeaguesToShow(leagueList);

  }, [clubScopes, competitions]);


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedLeagues.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedLeagues.length > 0 && <div className='drop-down-select-button-overlay fast-fade-in'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>

          {selectedLeagues.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          )}

          {selectedLeagues.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate('selectedLeagues', userConfig?.language)}
            </div>
          )}

          {selectedLeagues.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCompetitions()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='league-drop-down-section' style={{ maxHeight: maxHeight }}>

        <div className='league-drop-down-selected-leagues'>
          {selectedLeagues.map((leagueId: string) => {
            const league = competitions[Number(leagueId)] as League;
            return (
              <div
                className={'league-drop-down-selected-league'}
                key={leagueId + '-selected'}
              >
                <div className='league-drop-down-selected-league-close-icon'>
                  <CloseIcon style={{ fontSize: 16 }} onClick={() => handleOptionSelect(leagueId, true)} />
                </div>

                <img
                  className='league-flag league-drop-down-selected-league-flag'
                  src={countryCodeToCountryInfo[league.countryCode].flagUrl}
                  alt={league.countryCode}
                  draggable={false}
                />

                <div className='league-drop-down-selected-league-name'>
                  {league.name}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className='league-drop-down-input-container'
          style={{ marginTop: 12 + (22 * Math.max(selectedLeagues.length, 2)) }}>
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={translate('searchForLeague', userConfig?.language)}
            showDefaultInput={true}
            style={{ boxShadow: '0px 0px 2px 1px #00000020', backgroundColor: '#ffffff' }}
            ref={inputRef}
          />
        </div>

        {leaguesToShow.length > 0 &&
          leaguesToShow.map((league: League) => {
            const isSelected = selectedLeagues.some(item => item === league.id);
            return (
              <div
                className={'drop-down-select-option league-drop-down-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={league.id}
                onClick={() => handleOptionSelect(league.id, isSelected)}
              >
                {league.name}

                <div className='league-drop-down-option-info-row'>
                  <div>
                    <img
                      className='league-flag league-drop-down-option-flag'
                      src={countryCodeToCountryInfo[league.countryCode].flagUrl}
                      alt={league.countryCode}
                      draggable={false}
                    />
                  </div>
                  <div className='league-drop-down-option-info-text'>
                    {countryCodeToCountryInfo[league.countryCode].name[userConfig ? userConfig.language : 'en']}
                  </div>
                  <div className='league-drop-down-option-info-text'>
                    {league.tier}
                  </div>
                  {clubScopes
                    && league.iterationsWithEventData
                    && league.iterationsWithEventData.length > 0
                    && (clubScopes.hasFullCoverage || (clubScopes.competitionIds && clubScopes.competitionIds.includes(league.id)))
                    && (
                      <div className='league-drop-down-option-data-access'>
                        <KeyIcon style={{ fontSize: 15, marginBottom: -4 }} />
                      </div>
                    )}
                </div>
              </div>
            );
          })}

        {leaguesToShow.length === 0 && (
          <div className='league-drop-down-no-search-result'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

      </div>

    </div>
  );
};
