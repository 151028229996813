import { FirebaseUser } from '../../../firebase';


export const getBearerToken = async (user: FirebaseUser): Promise<string> => {
  if (!user) {
    throw new Error('User is not authenticated');
  }

  const token = await user.getIdToken();
  return `Bearer ${token}`;
};
