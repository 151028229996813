import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import '../mobilePlatform.css';


export const MobileHome: React.FC = () => {

  return (
    <div className='mobile-view-container'>
      <div className='mobile-home-view-background'>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='mobile-view-content-container'>
        <div className='mobile-home-view-content-box' style={{ height: 500 }}>
          Aktivitet siste 14 dager
        </div>

        <div className='mobile-home-view-content-box' style={{ height: 1000 }}>
          Tabell
        </div>
      </div>
    </div>
  );
};
