import '../../platform.css';
import './settings.css';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { translate } from '../../../common/language/translations';

import Fuse from 'fuse.js';

import UpdateIcon from '@mui/icons-material/Update';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import NO from 'country-flag-icons/react/3x2/NO';
import GB from 'country-flag-icons/react/3x2/GB';

import { DropDownSelect } from '../../components/input/DropDownSelect';
import { formationOptions } from '../../static/propertyValues';
import { Dialog } from '@mui/material';
import { updateClubSettingsFormation } from '../../services/firestore/clubSettings';
import { Competition, UserConfig } from '../../types';
import { InputField } from '../../components/input/InputField';
import { countryCodeToCountryInfo } from '../../static/countries';
import { getSortDistanceAccess } from '../../utils/playerUtils';
import { AccountModal } from '../../components/modals/accounModals/AccountModal';
import { getAccessIcon } from '../../utils/iconUtils';
import { AddAccountModal } from '../../components/modals/accounModals/AddAccountModal';
import { userHasFullAccess } from '../../utils/userUtils';


export const ClubSettings: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const hasFullAccess = userHasFullAccess(userConfig);

  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const clubScopes = useRecoilValue(clubScopesState);
  const competitions = useRecoilValue(competitionsState);

  const { openConfirmModal } = useOpenGlobalModal();

  const clubSettings = useRecoilValue(clubSettingsState);
  const clubFormation = clubSettings?.formation;

  const [allUsersListSorted, setAllUsersListSorted] = useState<UserConfig[]>([]);

  const [selectedFormation, setSelectedFormation] = useState<string[]>(clubFormation ? [clubFormation] : []);
  const [isFormationDropDownExpanded, setIsFormationDropDownExpanded] = useState(false);

  const [accountToEdit, setAccountToEdit] = useState<UserConfig | undefined>(undefined);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  const [searchString, setSearchString] = useState('');
  const [sortedStaticCompetitions, setSortedStaticCompetitions] = useState<Competition[]>([]);
  const [yourLeaguesAndCups, setYourLeaguesAndCups] = useState<Competition[]>([]);
  const [leaguesAndCupsToShow, setLeaguesAndCupsToShow] = useState<Competition[]>([]);
  const [fuse, setFuse] = useState<Fuse<Competition> | null>(null);

  const [expandedCompetitionIds, setExpandedCompetitionIds] = useState<string[]>([]);
  const [yourExpandedCompetitionIds, setYourExpandedCompetitionIds] = useState<string[]>([]);


  const handleChangeFormation = () => {
    if (userConfig && clubSettings && clubFormation && selectedFormation.length > 0 && selectedFormation[0] !== clubFormation && hasFullAccess) {

      const newFormation = selectedFormation[0];

      updateClubSettingsFormation(
        newFormation,
        clubFormation,
        clubSettings.positionToRoleIndexConfig,
        userConfig.email,
        userConfig.club,
      );
    }
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setLeaguesAndCupsToShow(sortedStaticCompetitions);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCompetitions(searchString);
    }
  };


  const handleSearchForCompetitions = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setLeaguesAndCupsToShow(searchResult);
  }, [fuse]);


  const handleEditAccountClick = (user: UserConfig) => {
    setAccountToEdit(user);
    setIsEditAccountModalOpen(true);
  };


  useEffect(() => {
    if (!isEditAccountModalOpen) {
      const timer = setTimeout(() => {
        setAccountToEdit(undefined);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [isEditAccountModalOpen]);


  useEffect(() => {
    if (allUsersWithSameClub) {
      const usersList: UserConfig[] = [];

      Object.entries(allUsersWithSameClub).map(([email, user]) => {
        if (email.split('@')[0] !== 'fokus') {
          const userWithEmail: UserConfig = { ...user, email: email };
          usersList.push(userWithEmail);
        }
      });

      usersList.sort((a, b) => getSortDistanceAccess(a, b));
      setAllUsersListSorted(usersList);
    }
  }, [userConfig, allUsersWithSameClub]);


  useEffect(() => {
    setExpandedCompetitionIds([]);
    if (searchString.length === 0) {
      setLeaguesAndCupsToShow(sortedStaticCompetitions);
    }
    else {
      handleSearchForCompetitions(searchString);
    }
  }, [searchString, handleSearchForCompetitions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const searchKeys = [
      'name',
      'countryName_' + (userConfig?.language ?? 'en'),
    ];

    const options = {
      keys: searchKeys,
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(sortedStaticCompetitions, options);
    setFuse(fuseInstance);
  }, [sortedStaticCompetitions, userConfig?.language]);


  useEffect(() => {
    if (!clubScopes) return;

    const competitionsList: Competition[] = Object.values(competitions)
      .filter(competition => competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0);

    competitionsList.sort((a, b) => a.orderIndex - b.orderIndex);

    // if club does not have full access, we want to sort the leagues such that leagues with access come first
    if (clubScopes && !clubScopes.hasFullCoverage) {
      competitionsList.sort((a, b) => {
        const aIsInScope = clubScopes.competitionIds.includes(a.id);
        const bIsInScope = clubScopes.competitionIds.includes(b.id);

        if (aIsInScope && !bIsInScope) return -1;
        if (!aIsInScope && bIsInScope) return 1;
        return 0;
      });
    }

    const competitionsInScope: Competition[] = clubScopes.hasFullCoverage
      ? competitionsList
        .filter(
          competition =>
            competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0
        )
      : competitionsList
        .filter(
          competition =>
            competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0 && clubScopes.competitionIds.includes(competition.id)
        );

    setSortedStaticCompetitions(competitionsList);
    setLeaguesAndCupsToShow(competitionsList);
    setYourLeaguesAndCups(competitionsInScope);
  }, [clubScopes, competitions]);


  const getCompetitions = (isYourCompetitions: boolean) => {

    const leaguesAndCups = isYourCompetitions ? yourLeaguesAndCups : leaguesAndCupsToShow;

    return (
      <div className={'club-settings-view-league-or-cup-column' + (isYourCompetitions ? ' club-settings-view-your-league-or-cup-column' : '')}>

        {leaguesAndCups.length > 0 &&
          leaguesAndCups.map((competition: Competition) => {

            const seasons = competition.iterationsWithEventData
              ? competition.iterationsWithEventData.map(iteration => iteration.season)
              : undefined;

            const isExpanded = isYourCompetitions
              ? yourExpandedCompetitionIds.includes(competition.id)
              : expandedCompetitionIds.includes(competition.id);

            return (
              <div
                className={'club-settings-view-league-or-cup' + (isExpanded ? ' club-settings-view-league-or-cup-expanded' : '')}
                key={competition.id + '-all-column'}
                style={{
                  paddingTop: competition.countryCode ? 5 : 14,
                  paddingBottom: competition.countryCode ? 5 : 14,
                  height: 35 + (isExpanded && seasons ? (6 + (seasons.length * 22)) : 0),
                }}
                onClick={() => {
                  if (isYourCompetitions) {
                    setYourExpandedCompetitionIds(isExpanded
                      ? yourExpandedCompetitionIds.filter(id => id !== competition.id)
                      : [...yourExpandedCompetitionIds, competition.id]);
                  }
                  else {
                    setExpandedCompetitionIds(isExpanded
                      ? expandedCompetitionIds.filter(id => id !== competition.id)
                      : [...expandedCompetitionIds, competition.id]);
                  }
                }}
              >
                {competition.name}

                {competition.countryCode && (
                  <div className='league-drop-down-option-info-row'>
                    <div>
                      <img
                        className='league-flag league-drop-down-option-flag'
                        src={countryCodeToCountryInfo[competition.countryCode].flagUrl}
                        alt={competition.countryCode}
                        draggable={false}
                      />
                    </div>
                    <div className='league-drop-down-option-info-text club-settings-view-league-or-cup-info-text'>
                      {countryCodeToCountryInfo[competition.countryCode].name[userConfig ? userConfig.language : 'en']}
                    </div>

                    {competition.tier && (
                      <div className='league-drop-down-option-info-text club-settings-view-league-or-cup-info-text'>
                        {competition.tier}
                      </div>
                    )}
                  </div>
                )}

                {isExpanded && seasons && seasons.length > 0 && (
                  <div className='fade-in' style={{ marginTop: 8 }}>
                    {seasons.map((season, index) => {
                      return (
                        <div key={index} style={{ fontSize: 14, marginBottom: 4, color: '#ffffffdd' }}>
                          {season}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };


  return (
    <div className='settings-view-container club-settings-view-container'>

      <Dialog
        open={isEditAccountModalOpen}
        onClose={() => setIsEditAccountModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <AccountModal
          user={accountToEdit}
          closeModal={() => setIsEditAccountModalOpen(false)}
        />
      </Dialog>

      <Dialog
        open={isAddAccountModalOpen}
        onClose={() => setIsAddAccountModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <AddAccountModal
          closeModal={() => setIsAddAccountModalOpen(false)}
        />
      </Dialog>

      {isFormationDropDownExpanded && <div className='filter-section-empty-background' onClick={() => setIsFormationDropDownExpanded(false)} />}

      <div className='club-settings-view-column club-settings-view-left-section'>
        <div className='club-settings-view-formation-section'>
          <div className='club-settings-view-section-title'>
            {translate('formation', userConfig?.language)}
          </div>

          <div className='club-settings-view-formation-drop-down-container'>
            <div className='club-settings-view-formation-drop-down'>
              <DropDownSelect
                id={'club-settings-view-formation-drop-down'}
                dropDownOptions={formationOptions}
                selectedOptions={selectedFormation}
                setSelectedOptions={setSelectedFormation}
                isDropDownExpanded={isFormationDropDownExpanded}
                setIsDropDownExpanded={setIsFormationDropDownExpanded}
                defaultDropDownText={'selectFormation'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
              />
            </div>
          </div>

          {selectedFormation.length > 0 && selectedFormation[0] !== clubFormation && (
            <div
              className={'account-logout-button club-settings-view-edit-formation-button' + (!hasFullAccess ? ' account-logout-button-disabled' : '')}
              onClick={() => hasFullAccess ? openConfirmModal(handleChangeFormation, 'editFormation', 'editFormationInfo') : null}
            >
              <div className='account-logout-button-text'>
                {translate('editFormation', userConfig?.language)}
              </div>
              <div className='account-logout-button-icon'>
                <UpdateIcon style={{ fontSize: 22 }} />
              </div>
            </div>
          )}
        </div>

        <div className='club-settings-view-divider club-settings-view-horizontal-divider'>&nbsp;</div>

        <div className='club-settings-view-accounts-section'>
          <div className='club-settings-view-section-title' style={{ position: 'relative' }}>
            {translate('accounts', userConfig?.language)}

            {userConfig && userConfig.access === 'admin' && (
              <div
                className='club-settings-add-account-icon'
                title={translate('addNewAccount', userConfig.language)}
                onClick={() => setIsAddAccountModalOpen(true)}
              >
                <AddCircleOutlineIcon style={{ fontSize: 22 }} />
              </div>
            )}
          </div>

          <div className='club-settings-view-accounts-column'>
            {allUsersListSorted.map((user, index) => {

              const accountIcon = getAccessIcon(user['access'], 24);

              return (
                <div key={index} className='club-settings-view-account' style={{ paddingRight: userConfig && userConfig.access === 'admin' ? 46 : undefined }}>
                  <div className='club-settings-view-account-icon'>
                    {accountIcon}
                  </div>
                  <div className='club-settings-view-account-info-rows'>
                    <div className='club-settings-view-account-name-row'>
                      {user['name']}
                      <div className='club-settings-view-account-flag'>
                        {user['language'] === 'no' ? <NO /> : <GB />}
                      </div>
                    </div>
                    <div className='club-settings-view-account-email'>
                      {user.email}
                    </div>
                  </div>
                  {userConfig && userConfig.access === 'admin' && user.access !== 'admin' && (
                    <div
                      className='club-settings-view-menu-icon'
                      title={translate('editAccess', userConfig.language)}
                      onClick={() => handleEditAccountClick(user)}>
                      <MoreVertIcon style={{ fontSize: 22 }} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='club-settings-view-divider club-settings-view-vertical-divider'>&nbsp;</div>

      <div className='club-settings-view-column club-settings-view-right-section'>
        <div className='club-settings-view-section-title'>
          {translate('leaguesAndCupsWithEventData', userConfig?.language)}
        </div>

        <div className='club-settings-view-divider club-settings-view-horizontal-divider club-settings-view-horizontal-divider-short'>&nbsp;</div>

        <div className='club-settings-view-league-coverage-section'>
          <div className='club-settings-view-league-coverage-column'>
            <div className='club-settings-view-league-coverage-column-title'>
              {translate('yourLeaguesAndCups', userConfig?.language)}
            </div>

            <div className='club-settings-view-league-or-cup-column-container club-settings-view-your-league-or-cup-column-container'>
              {getCompetitions(true)}
            </div>
          </div>

          <div className='club-settings-view-league-coverage-column'>
            <div className='club-settings-view-league-coverage-column-title'>
              {translate('allLeaguesAndCups', userConfig?.language)}
            </div>

            <div className='club-settings-view-input-container'>
              <div className='club-settings-view-input'>
                <InputField
                  searchString={searchString}
                  onChangeInputField={onChangeSearchField}
                  onKeyDownInputField={onKeyDownSearchField}
                  resetSearchString={() => setSearchString('')}
                  defaultInput={translate('searchForLeagueOrCup', userConfig?.language)}
                  showDefaultInput={true}
                  style={{ boxShadow: '0px 0px 2px 1px #00000020', backgroundColor: '#ffffff' }}
                />
              </div>
            </div>

            <div className='club-settings-view-league-or-cup-column-container'>
              {getCompetitions(false)}
            </div>

            {leaguesAndCupsToShow.length === 0 && (
              <div className='club-settings-view-no-search-result'>
                {translate('noResult', userConfig?.language)}
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};
