import { useSetRecoilState } from 'recoil';
import { selectedPlayerState } from '../atoms/selectedPlayerState';

import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';
import { SelectedPlayer } from '../../types';


export const useSetAndTrackSelectedPlayerState = () => {

  const setSelectedPlayerState = useSetRecoilState(selectedPlayerState);
  const trackEvent = useTrackEvent();

  const setAndTrackSelectedPlayerState = (selectedPlayer: SelectedPlayer | undefined) => {

    if (selectedPlayer) {
      trackEvent('PlayerClicked', { playerId: selectedPlayer.id, playerName: selectedPlayer.fullname });
    }

    setSelectedPlayerState(selectedPlayer);
  };

  return setAndTrackSelectedPlayerState;
};
