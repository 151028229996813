import '../../platform.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { squadsState } from '../../recoil/atoms/squadsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import { DynamicTabLine } from '../../components/tabLines/DynamicTabLine';
import { StringToAnyMap } from '../../types';
import { staticTeamKeys } from '../../static/propertyValues';
import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';


interface TeamOrSquadBuilderProps {
  isSquad: boolean;
}

export const TeamOrSquadBuilder: React.FC<TeamOrSquadBuilderProps> = ({ isSquad }) => {

  const squads = useRecoilValue(squadsState);
  const teams = useRecoilValue(teamsState);
  const navigationProps = useRecoilValue(navigationPropsState);
  const trackEvent = useTrackEvent();

  const [logisticTeamViewToggle, setLogisticTeamViewToggle] = useState(false);


  const getDynamicTeams = (squadsOrTeams: StringToAnyMap | null) => {
    if (!squadsOrTeams) {
      return null;
    }

    const dynamicTeams: StringToAnyMap = {};

    // filter out static teams
    Object.keys(squadsOrTeams)
      .filter(key => !(staticTeamKeys.includes(key)))
      .forEach(key => {
        dynamicTeams[key] = squadsOrTeams[key];
      });

    return dynamicTeams;
  };

  const dynamicTeamsOrSquads = isSquad ? squads : getDynamicTeams(teams);

  const [activeTab, setActiveTab] = useState<string | undefined>(
    navigationProps?.initialSquadOrTeamId ?? (
      dynamicTeamsOrSquads && Object.keys(dynamicTeamsOrSquads).length > 0 ? Object.keys(dynamicTeamsOrSquads)[0] : undefined
    )
  );


  const setTabAndResetExpansions = (tab: string | undefined) => {
    setActiveTab(tab);
    if (tab !== activeTab) {
      trackEvent(
        isSquad
          ? 'SquadClicked'
          : 'ScoutTeamClicked',
        isSquad
          ? {
            squadId: tab,
            squadName: tab && squads ? squads[tab].name : 'undefined'
          }
          : {
            teamId: tab,
            teamName: tab && teams ? teams[tab].name : 'undefined'
          }
      );
      setLogisticTeamViewToggle(!logisticTeamViewToggle);
    }
  };


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <DynamicTabLine
        teamsOrSquads={dynamicTeamsOrSquads}
        activeTab={activeTab}
        setActiveTab={setTabAndResetExpansions}
        isSquad={isSquad}
      />

      <LogisticTeamView
        teamOrSquadId={activeTab}
        teamOrSquad={dynamicTeamsOrSquads && activeTab ? dynamicTeamsOrSquads[activeTab] : undefined}
        isTabLine={true}
        isSquad={isSquad}
        logisticTeamViewToggle={logisticTeamViewToggle}
      />

    </div>
  );
};
