import '@react-pdf-viewer/core/lib/styles/index.css';
import './../modals.css';

import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { useState } from 'react';


interface PdfModalProps {
  closeModal: () => void;
  fileUrl: string;
  fileName: string;
}


export const getShortenedFileName = (fileName: string, maxLength?: number) => {
  maxLength = maxLength ?? 32;
  if (fileName.length > maxLength) {
    const halfMaxLength = Math.round(maxLength / 2) - 1;
    return fileName.slice(0, halfMaxLength) + '...' + fileName.slice(fileName.length - halfMaxLength, fileName.length);
  } else {
    return fileName;
  }
};


export const PdfModal: React.FC<PdfModalProps> = ({ closeModal, fileUrl, fileName }) => {

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  // const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  // const handleError = (e: Error) => {
  //   setErrorMessage('Failed to load PDF. The file might be corrupted.');
  // };

  return (
    <div className='pdf-modal-root-container' onClick={() => closeModal()}>

      <div className='pdf-modal-toolbar-container' onClick={(event) => event.stopPropagation()}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {
              CurrentPageLabel,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Zoom,
              ZoomIn,
              ZoomOut,
              EnterFullScreen,
              Download,
            } = props;
            return (
              <div className='pdf-modal-toolbar fade-in'>
                <div className='pdf-modal-toolbar-section pdf-modal-toolbar-title-section'>
                  <div className='pdf-modal-toolbar-icon' onClick={() => closeModal()}>
                    <ArrowBackIcon style={{ fontSize: 24 }} />
                  </div>

                  <div style={{ marginLeft: 12 }}>
                    {getShortenedFileName(fileName)}
                  </div>
                </div>

                <div className='pdf-modal-toolbar-section pdf-modal-toolbar-pagination-section'>
                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon pdf-modal-toolbar-svg-icon-rotate'>
                    <GoToPreviousPage />
                  </div>

                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon' style={{ color: '#ffffffaa', width: 40, pointerEvents: 'none' }}>
                    <CurrentPageLabel />
                  </div>

                  <div style={{ color: '#ffffffaa', fontSize: 15, marginTop: -1 }}>
                    /
                  </div>

                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon' style={{ color: '#ffffffaa', width: 40, pointerEvents: 'none' }}>
                    <NumberOfPages />
                  </div>

                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon pdf-modal-toolbar-svg-icon-rotate'>
                    <GoToNextPage />
                  </div>
                </div>

                <div className='pdf-modal-toolbar-section pdf-modal-toolbar-zoom-section'>
                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon'>
                    <ZoomOut />
                  </div>

                  <div className='pdf-modal-toolbar-icon' style={{ width: 60, pointerEvents: 'none' }}>
                    <Zoom />
                  </div>

                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon'>
                    <ZoomIn />
                  </div>
                </div>

                <div className='pdf-modal-toolbar-section pdf-modal-toolbar-tool-section'>
                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon'>
                    <EnterFullScreen />
                  </div>

                  <div className='pdf-modal-toolbar-icon pdf-modal-toolbar-svg-icon'>
                    <Download />
                  </div>
                </div>


              </div>
            );
          }}
        </Toolbar>
      </div>

      <div className='pdf-modal-content-container' onClick={(event) => event.stopPropagation()}>
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
          <Viewer
            fileUrl={fileUrl}
            plugins={[toolbarPluginInstance]}
          // onError={handleError}
          />
        </Worker>
      </div>

    </div>
  );
};
