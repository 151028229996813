import { useRecoilValue, useSetRecoilState } from 'recoil';
import { teamsState } from '../atoms/teamsState';
import { squadsState } from '../atoms/squadsState';
import { playerOverviewsState } from '../atoms/playerOverviewsState';
import { getPlayerOverviewsForAllTeamsAndSquads } from '../../services/server/application/playerOverviews';
import { FirebaseUser } from '../../../firebase';


export const useRefreshPlayerOverviews = () => {

  const setPlayerOverviews = useSetRecoilState(playerOverviewsState);
  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  // this will initialize the playerOverviews atom with player overviews for all players in any team and squad
  const refreshPlayerOverviews = async (currentUser: FirebaseUser): Promise<void> => {
    if (!teams || !squads) return;

    const newPlayerOverviews = await getPlayerOverviewsForAllTeamsAndSquads(teams, squads, currentUser);
    setPlayerOverviews(newPlayerOverviews);
  };

  return refreshPlayerOverviews;
};
