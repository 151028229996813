import '../../platform.css';
import './ownTeam.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap, PlayerId, PlayerMap } from '../../types';
import { OwnTeamTable } from '../../components/tables/ownTeamTable/OwnTeamTable';
import { getAge, getMonthsLeftOfContract } from '../../utils/playerUtils';
import { getRoundedEconomicValue } from '../../utils/currencyUtils';
import { DropDownSelect } from '../../components/input/DropDownSelect';
import { updateSeasonStatsToggles } from '../../services/firestore/userSettings';
import { DropDownSelectWithIcons } from '../../components/input/DropDownSelectWithIcons';

import {
  academyRoleKeysToSortOrder,
  ageKeys,
  localityKeysToSortOrder,
  roleKeysToSortOrder
} from '../../static/propertyValues';


interface OwnTeamTableViewProps {
  ownTeamPlayers: PlayerMap;
  academyTeamPlayers: PlayerMap;
}

export const OwnTeamTableView: React.FC<OwnTeamTableViewProps> = ({ ownTeamPlayers, academyTeamPlayers }) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const [tableData, setTableData] = useState<StringToAnyMap[]>([]);

  const [tableSortBy, setTableSortBy] = useState<StringToAnyMap | undefined>(undefined);
  const [lastTableSortBy, setLastTableSortBy] = useState<StringToAnyMap | undefined>(undefined);
  const [lastTableSortedOrder, setLastTableSortedOrder] = useState<PlayerId[] | undefined>(undefined);


  const [showFilters, setShowFilters] = useState(userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded);

  const [countryDropDownOptions, setCountryDropDownOptions] = useState<string[]>([]);

  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<string[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedLocalities, setSelectedLocalities] = useState<string[]>([]);
  const [selectedTeamRoles, setSelectedTeamRoles] = useState<string[]>([]);

  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isContractStatusExpanded, setIsContractStatusExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isLocalitiesExpanded, setIsLocalitiesExpanded] = useState(false);
  const [isTeamRolesExpanded, setIsTeamRolesExpanded] = useState(false);


  const removeDropDownExpansions = () => {
    setIsMaxAgeExpanded(false);
    setIsContractStatusExpanded(false);
    setIsNationalitiesExpanded(false);
    setIsLocalitiesExpanded(false);
    setIsTeamRolesExpanded(false);
  };


  const resetFilters = () => {
    setSelectedMaxAge([]);
    setSelectedContractStatus([]);
    setSelectedNationalities([]);
    setSelectedLocalities([]);
    setSelectedTeamRoles([]);
  };


  const handleToggleShowFilters = async () => {
    if (!userConfig) return;

    const newSeasonStatsToggles = { isOwnTeamTableFilterSectionExpanded: !userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded };
    await updateSeasonStatsToggles(newSeasonStatsToggles, userConfig.email, userConfig.club);
  };


  const prepareTableData = () => {

    let players = { ...ownTeamPlayers, ...academyTeamPlayers };

    // sort data
    let newTableData: StringToAnyMap[] = [];
    if (!lastTableSortedOrder || tableSortBy !== lastTableSortBy) {
      const roleKeysSortOrder = { ...roleKeysToSortOrder, ...academyRoleKeysToSortOrder };

      newTableData = Object.values(players).sort((a: StringToAnyMap, b: StringToAnyMap) => {
        const tableSortByToUse = tableSortBy ?? { id: 'role', desc: true };

        const sortProperty = tableSortByToUse.id;
        const oneValue = tableSortByToUse.desc ? 1 : -1;
        const minusOneValue = tableSortByToUse.desc ? -1 : 1;

        if (!a[sortProperty]) return oneValue;
        if (!b[sortProperty]) return minusOneValue;

        const aValue = tableSortByToUse.id === 'role'
          ? (a[sortProperty] in roleKeysSortOrder ? roleKeysSortOrder[a[sortProperty]] : 0)
          : tableSortByToUse.id === 'locality'
            ? (a[sortProperty] in localityKeysToSortOrder ? localityKeysToSortOrder[a[sortProperty]] : 0)
            : a[sortProperty];

        const bValue = tableSortByToUse.id === 'role'
          ? (b[sortProperty] in roleKeysSortOrder ? roleKeysSortOrder[b[sortProperty]] : 0)
          : tableSortByToUse.id === 'locality'
            ? (b[sortProperty] in localityKeysToSortOrder ? localityKeysToSortOrder[b[sortProperty]] : 0)
            : b[sortProperty];

        return aValue > bValue ? minusOneValue : oneValue;
      });
    }
    else {
      newTableData = lastTableSortedOrder.filter((id: PlayerId) => players[id]).map((id: PlayerId) => players[id]);
    }


    setLastTableSortedOrder(newTableData.map((player: StringToAnyMap) => player.id));
    setLastTableSortBy(tableSortBy);


    // first apply filters
    players = userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable
      ? academyTeamPlayers
      : ownTeamPlayers;

    newTableData = newTableData.filter(player => players[player.id]);

    if (selectedMaxAge.length > 0) {
      const maxAge = Number(selectedMaxAge[0]);
      newTableData = newTableData.filter(
        player => {
          const age = getAge(player.birth_date);
          return age && age <= maxAge;
        }
      );
    }

    if (selectedContractStatus.length > 0) {
      newTableData = newTableData.filter(
        player => {
          if (!player.contract_expiration) return false;

          const contractExpirationYear = player.contract_expiration.split('-')[0];
          return selectedContractStatus.includes(contractExpirationYear);
        }
      );
    }

    if (selectedNationalities.length > 0) {
      newTableData = newTableData.filter(
        player => selectedNationalities.includes(player.country_code)
      );
    }

    if (selectedLocalities.length > 0) {
      newTableData = newTableData.filter(
        player => selectedLocalities.includes(player.locality)
      );
    }

    if (selectedTeamRoles.length > 0) {
      newTableData = newTableData.filter(
        player => selectedTeamRoles.includes(player.role)
      );
    }


    // compute summary data
    let totalNumberOfPlayers = 0;
    let [totalAge, playersWithAge]: [number, number] = [0, 0];
    let [totalMonthsLeftOfContract, playersWithMonthsLeftOfContract] = [0, 0];

    let totalMarketValue = 0;
    let totalSigningCost = 0;
    let totalSalary = 0;
    let totalBonuses = 0;

    const countryCodeMap: { [key: string]: number } = {};
    const localityMap: { [key: string]: number } = {};
    const roleMap: { [key: string]: number } = {};

    newTableData.forEach((player: StringToAnyMap) => {
      totalNumberOfPlayers++;

      if (player.age) {
        totalAge += player.age;
        playersWithAge++;
      }

      if (player.contract_expiration) {
        totalMonthsLeftOfContract += getMonthsLeftOfContract(player.contract_expiration) as number;
        playersWithMonthsLeftOfContract++;
      }

      if (player.market_value) totalMarketValue += player.market_value;
      if (player.signingCost) totalSigningCost += player.signingCost;
      if (player.salary) totalSalary += player.salary;
      if (player.bonuses) totalBonuses += player.bonuses;

      if (player.country_code) {
        if (countryCodeMap[player.country_code]) {
          countryCodeMap[player.country_code]++;
        }
        else {
          countryCodeMap[player.country_code] = 1;
        }
      }

      if (player.locality) {
        if (localityMap[player.locality]) {
          localityMap[player.locality]++;
        }
        else {
          localityMap[player.locality] = 1;
        }
      }

      if (player.role) {
        if (roleMap[player.role]) {
          roleMap[player.role]++;
        }
        else {
          roleMap[player.role] = 1;
        }
      }
    });

    const nationalCountryCode = clubSettings?.countryCode ?? 'NO';
    let numberOfNatives = 0;
    let numberOfForeigners = 0;
    for (const code in countryCodeMap) {
      if (code === nationalCountryCode) {
        numberOfNatives += countryCodeMap[code];
      }
      else {
        numberOfForeigners += countryCodeMap[code];
      }
    }

    if (newTableData.length > 0) {
      newTableData.push({
        'fullname': 'tableSummary',

        'averageAge': playersWithAge ? (Math.round((totalAge / playersWithAge) * 10) / 10) : undefined,
        'averageMonthsLeftOfContract': playersWithMonthsLeftOfContract ? Math.round(totalMonthsLeftOfContract / playersWithMonthsLeftOfContract) : undefined,

        'market_valueTotal': totalMarketValue ? getRoundedEconomicValue(totalMarketValue) : undefined,
        'signingCostTotal': totalSigningCost ? getRoundedEconomicValue(totalSigningCost) : undefined,
        'salaryTotal': totalSalary ? getRoundedEconomicValue(totalSalary) : undefined,
        'bonusesTotal': totalBonuses ? getRoundedEconomicValue(totalBonuses) : undefined,

        'numberOfLocalPlayers': localityMap['local'] ?? 0,
        'numberOfNationalPlayers': localityMap['national'] ?? 0,
        'numberOfInternationalPlayers': localityMap['international'] ?? 0,

        'nationalCountryCode': nationalCountryCode,
        'numberOfNatives': numberOfNatives,
        'numberOfForeigners': numberOfForeigners,

        'roleMap': roleMap,

        'numberOfPlayers': totalNumberOfPlayers,
      });
    }

    setTableData(newTableData);
  };


  useEffect(() => {
    prepareTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ownTeamPlayers,
    academyTeamPlayers,
    userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable,
    tableSortBy,
    selectedMaxAge,
    selectedContractStatus,
    selectedNationalities,
    selectedLocalities,
    selectedTeamRoles
  ]);


  useEffect(() => {
    const countryCodeMap: { [key: string]: number } = {};
    Object.values(ownTeamPlayers).forEach((player: StringToAnyMap) => {
      if (player.country_code) {
        if (countryCodeMap[player.country_code]) {
          countryCodeMap[player.country_code]++;
        }
        else {
          countryCodeMap[player.country_code] = 1;
        }
      }
    });
    Object.values(academyTeamPlayers).forEach((player: StringToAnyMap) => {
      if (player.country_code) {
        if (countryCodeMap[player.country_code]) {
          countryCodeMap[player.country_code]++;
        }
        else {
          countryCodeMap[player.country_code] = 1;
        }
      }
    });
    const countryCodes = Object.keys(countryCodeMap).sort((a: string, b: string) => countryCodeMap[b] - countryCodeMap[a]);
    setCountryDropDownOptions(countryCodes);
  }, [ownTeamPlayers, academyTeamPlayers]);


  useEffect(() => {
    setSelectedTeamRoles([]);
  }, [userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable]);


  useEffect(() => {
    if (!userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded) {
      setShowFilters(false);
    }
    else {
      const timer = setTimeout(() => {
        setShowFilters(true);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded]);


  const getNextYears = (count = 8) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: count }, (_, index) => String(currentYear + index));
  };


  return (
    <div className='own-team-view-container'>

      {(isMaxAgeExpanded || isContractStatusExpanded || isNationalitiesExpanded || isLocalitiesExpanded || isTeamRolesExpanded) && (
        <div className='filter-section-empty-background' onClick={() => removeDropDownExpansions()}>
          &nbsp;
        </div>
      )}

      <div
        className='own-team-table-icon-container own-team-table-filter-icon-container'
        onClick={() => handleToggleShowFilters()}
        title={translate('showFilters', userConfig?.language)}
      >
        {!userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded && <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} />}
        {userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded && <CloseIcon style={{ fontSize: 20 }} />}
      </div>

      {userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded && showFilters && (
        <div className='own-team-table-filter-section'>
          <div className='filter-section-drop-down-title own-team-table-filter-section-age'>
            {translate('maxAge', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down own-team-table-filter-section-age'>
            <DropDownSelect
              id={'own-team-table-filter-section-age'}
              dropDownOptions={ageKeys}
              selectedOptions={selectedMaxAge}
              setSelectedOptions={setSelectedMaxAge}
              isDropDownExpanded={isMaxAgeExpanded}
              setIsDropDownExpanded={setIsMaxAgeExpanded}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'84vh'}
            />
          </div>

          <div className='filter-section-drop-down-title own-team-table-filter-section-contract'>
            {translate('contractExpiration', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down own-team-table-filter-section-contract'>
            <DropDownSelect
              id={'own-team-table-filter-section-contract'}
              dropDownOptions={getNextYears(8)}
              selectedOptions={selectedContractStatus}
              setSelectedOptions={setSelectedContractStatus}
              isDropDownExpanded={isContractStatusExpanded}
              setIsDropDownExpanded={setIsContractStatusExpanded}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'84vh'}
              multiSelect={true}
            />
          </div>

          <div className='filter-section-drop-down-title own-team-table-filter-section-country'>
            {translate('country', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down own-team-table-filter-section-country'>
            <DropDownSelectWithIcons
              id={'own-team-table-filter-section-country'}
              dropDownOptions={countryDropDownOptions}
              selectedOptions={selectedNationalities}
              setSelectedOptions={setSelectedNationalities}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={setIsNationalitiesExpanded}
              multiSelect={true}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'84vh'}
              useCountryFlags={true}
            />
          </div>

          <div className='filter-section-drop-down-title own-team-table-filter-section-locality'>
            {translate('locality', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down own-team-table-filter-section-locality'>
            <DropDownSelectWithIcons
              id={'own-team-table-filter-section-locality'}
              dropDownOptions={Object.keys(localityKeysToSortOrder)}
              selectedOptions={selectedLocalities}
              setSelectedOptions={setSelectedLocalities}
              isDropDownExpanded={isLocalitiesExpanded}
              setIsDropDownExpanded={setIsLocalitiesExpanded}
              multiSelect={true}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'84vh'}
              useLocalityIcons={true}
            />
          </div>

          <div className='filter-section-drop-down-title own-team-table-filter-section-role'>
            {translate('teamRole', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down own-team-table-filter-section-role'>
            <DropDownSelectWithIcons
              id={'own-team-table-filter-section-role'}
              dropDownOptions={Object.keys(
                userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable
                  ? academyRoleKeysToSortOrder
                  : roleKeysToSortOrder
              )}
              selectedOptions={selectedTeamRoles}
              setSelectedOptions={setSelectedTeamRoles}
              isDropDownExpanded={isTeamRolesExpanded}
              setIsDropDownExpanded={setIsTeamRolesExpanded}
              multiSelect={true}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'84vh'}
              useRoleIcons={true}
            />
          </div>

          <div
            className='own-team-table-icon-container own-team-table-reset-icon-container'
            onClick={() => resetFilters()}
            title={translate('resetFilters', userConfig?.language)}
          >
            <ReplayIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      )}

      <div className='own-team-table-container' style={{ top: userSettings?.seasonStatsToggles?.isOwnTeamTableFilterSectionExpanded ? 86 : 16 }}>
        <OwnTeamTable
          data={tableData}
          tableSortBy={tableSortBy}
          setTableSortBy={setTableSortBy}
        />
      </div>

    </div>
  );
};
