import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../../common/language/translations';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AggregationRule } from '../../../types';


const aggregationOptions: AggregationRule[] = [
  'priority',
  'max',
  'average',
  'confidence',
];


interface RoleConfigAggregationModalProps {
  closeModal: () => void;
  currentAggregation: AggregationRule;
  confirmNewAggregation: (newAggregation: AggregationRule) => void;
}

export const RoleConfigAggregationModal: React.FC<RoleConfigAggregationModalProps> = ({
  closeModal,
  currentAggregation,
  confirmNewAggregation,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openTextModal } = useOpenGlobalModal();

  const [selectedAggregation, setSelectedAggregation] = useState<AggregationRule>(currentAggregation);

  return (
    <div className='modal-root-container role-config-aggregation-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {translate('editRule', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div style={{ margin: 'auto', marginTop: 34 }}>
        {aggregationOptions.map((aggregation: AggregationRule, index: number) => {

          return (
            <div key={aggregation} style={{ position: 'relative', marginTop: index === 0 ? 2 : 18 }}>
              <div
                className='modal-access-option-info-icon'
                onClick={() => openTextModal(
                  translate(aggregation + 'Aggregation', userConfig?.language),
                  translate(aggregation + 'AggregationInfo', userConfig?.language),
                )}
              >
                <InfoOutlinedIcon style={{ fontSize: 16 }} />
              </div>
              <div
                className={'edit-player-info-modal-role-option' + (aggregation === selectedAggregation ? ' edit-player-info-modal-role-option-selected' : '')}
                style={{ boxSizing: 'border-box', height: 32, paddingLeft: 12 }}
                onClick={() => setSelectedAggregation(aggregation)}
              >
                {translate(aggregation + 'Aggregation', userConfig?.language)}
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={'modal-button account-modal-button' + (selectedAggregation === currentAggregation ? ' modal-button-disabled' : '')}
        onClick={() => confirmNewAggregation(selectedAggregation)}>
        <div className='modal-button-text'>
          {translate('confirmNewRule', userConfig?.language)}
        </div>

        <CheckIcon
          style={{ fontSize: 22 }}
        />
      </div>

    </div>
  );
};
