/* eslint-disable max-len */
import './visualization.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import { formKeys, playingTimeKeys } from '../../static/propertyValues';
import { getFormIcon, getPlayingTimeIcon } from '../../utils/iconUtils';
import { translate } from '../../../common/language/translations';


interface FormOrPlayingTimeTableProps {
  isFormTable: boolean; // either form or playing time
}

export const FormOrPlayingTimeTable: React.FC<FormOrPlayingTimeTableProps> = ({ isFormTable }) => {

  const userConfig = useRecoilValue(userConfigState);

  const keys = isFormTable ? formKeys : playingTimeKeys;
  const getIcon = isFormTable ? getFormIcon : getPlayingTimeIcon;

  const titleWidth = isFormTable ? 120 : 135;


  return (
    <div className='form-or-playing-time-table-container'>

      <div className='form-or-playing-time-table-divider' style={{ left: titleWidth + 10 }} />

      {keys.map((key, index) => {

        return (
          <div
            key={key}
            className={
              'form-or-playing-time-table-row' +
              (index === 0 ? '' : ' form-or-playing-time-table-row-border-top')
            }
          >

            <div className='form-or-playing-time-table-title' style={{ width: titleWidth }}>
              {getIcon(key, '#ffffffee', 20)}
              {translate(key, userConfig?.language)}
            </div>

            <div className='form-or-playing-time-table-description' style={{ width: 'calc(100% - ' + titleWidth + 'px)' }}>
              {translate(key + '_info', userConfig?.language)}
            </div>

          </div>
        );
      })}

    </div>
  );
};
