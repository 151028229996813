import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { globalModalPropsState } from '../../recoil/atoms/globalModalPropsState';

import { StringToAnyMap } from '../../types';
import { Dialog } from '@mui/material';
import { ConfirmModal } from './globalModals/ConfirmModal';
import { TextModal } from './globalModals/TextModal';
import { PdfModal } from './globalModals/PdfModal';
import { PlayerViewModal } from './globalModals/PlayerViewModal';
import { RoleInfoModal } from './globalModals/RoleInfoModal';


interface GlobalModalsProps {
  isMobileView: boolean;
}

export const GlobalModals: React.FC<GlobalModalsProps> = ({ isMobileView }) => {

  const [globalModalProps, setGlobalModalProps] = useRecoilState(globalModalPropsState);
  const [isGlobalConfirmModalOpen, setIsGlobalConfirmModalOpen] = useState(false);
  const [isGlobalTextModalOpen, setIsGlobalTextModalOpen] = useState(false);
  const [isGlobalPdfModalOpen, setIsGlobalPdfModalOpen] = useState(false);
  const [isGlobalPlayerViewModalOpen, setIsGlobalPlayerViewModalOpen] = useState(false);
  const [isRoleInfoModalOpen, setIsRoleInfoModalOpen] = useState(false);

  const modalTypeToSetter: StringToAnyMap = {
    'ConfirmModal': setIsGlobalConfirmModalOpen,
    'TextModal': setIsGlobalTextModalOpen,
    'PdfModal': setIsGlobalPdfModalOpen,
    'PlayerViewModal': setIsGlobalPlayerViewModalOpen,
    'RoleInfoModal': setIsRoleInfoModalOpen,
  };


  useEffect(() => {
    if (globalModalProps !== undefined) {
      globalModalProps.globalModalTypes.forEach(modalType => {
        const setIsModalOpen = modalTypeToSetter[modalType];

        if (globalModalProps.delay !== undefined) {
          const timer = setTimeout(() => {
            setIsModalOpen(true);
          }, globalModalProps.delay);

          return () => clearTimeout(timer);
        }

        else {
          setIsModalOpen(true);
        }
      });

    }
    else {
      Object.values(modalTypeToSetter).forEach(setIsModalOpen => setIsModalOpen(false));
    }
  }, [globalModalProps]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setGlobalModalProps(undefined);
  }, [isMobileView]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>

      {globalModalProps !== undefined && isGlobalPdfModalOpen && globalModalProps.fileUrl !== undefined && globalModalProps.fileName !== undefined && (
        <PdfModal
          closeModal={globalModalProps.closeGlobalModal}
          fileUrl={globalModalProps.fileUrl}
          fileName={globalModalProps.fileName}
        />
      )}

      {globalModalProps !== undefined && isGlobalPlayerViewModalOpen && globalModalProps.playerId && globalModalProps.playerName && (
        <Dialog
          open={isGlobalPlayerViewModalOpen}
          onClose={() => globalModalProps.closeGlobalModal()}
          PaperProps={{ sx: { borderRadius: '0px' } }}>
          <PlayerViewModal
            closeModal={(feedback?: boolean) => {
              globalModalProps.closeGlobalModal();
              if (feedback !== undefined && globalModalProps.onCloseFeedback) {
                globalModalProps.onCloseFeedback();
              }
            }}
            playerId={globalModalProps.playerId}
            playerName={globalModalProps.playerName}
            squadId={globalModalProps.squadId}
            showPlayerNameInTitle={globalModalProps.showPlayerNameInTitle}
          />
        </Dialog>
      )}

      {globalModalProps !== undefined && isRoleInfoModalOpen && globalModalProps.roleConfig && (
        <Dialog
          open={isRoleInfoModalOpen}
          onClose={() => globalModalProps.closeGlobalModal(false)}
          maxWidth='md'
          PaperProps={{ sx: { borderRadius: '0px' } }}>
          <RoleInfoModal
            closeModal={globalModalProps.closeGlobalModal}
            roleConfig={globalModalProps.roleConfig}
          />
        </Dialog>
      )}

      {globalModalProps !== undefined && globalModalProps.confirm !== undefined && globalModalProps.confirmTitleKey !== undefined && (
        <Dialog
          open={isGlobalConfirmModalOpen}
          onClose={() => globalModalProps.closeGlobalModal()}
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          <ConfirmModal
            confirm={globalModalProps.confirm}
            cancel={() => globalModalProps.closeGlobalModal()}
            confirmTitleKey={globalModalProps.confirmTitleKey}
            confirmInfoKey={globalModalProps.confirmInfoKey}
            confirmInfo={globalModalProps.confirmInfo}
            confirmKey={globalModalProps.confirmKey}
            cancelKey={globalModalProps.cancelKey}
          />
        </Dialog>
      )}

      {globalModalProps !== undefined && globalModalProps.textTitle !== undefined && globalModalProps.text !== undefined && (
        <Dialog
          open={isGlobalTextModalOpen}
          onClose={() => globalModalProps.closeGlobalModal()}
          maxWidth='md'
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          <TextModal
            closeModal={globalModalProps.closeGlobalModal}
            textTitle={globalModalProps.textTitle}
            text={globalModalProps.text}
          />
        </Dialog>
      )}

    </div>
  );
};
