import '../../platform.css';
import './searchPlayers.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import SwipeRightIcon from '@mui/icons-material/SwipeRight';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap, PlayerEntry, PlayerId, PlayerOverview, PlayerOverviewsQueryOptions } from '../../types';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { InputField } from '../input/InputField';
import { debounce } from '../../utils/utils';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../services/server/application/playerOverviews';
import { teamsState } from '../../recoil/atoms/teamsState';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { getSortDistanceRole } from '../../utils/playerUtils';
import { Toggle } from '../input/Toggle';
import { playerSimpleTablePageSize } from '../../views/scouting/Scouting';


interface AddPlayerViewProps {
  searchString: string;
  setSearchString: (value: string) => void;

  squadId: string | undefined;
  isOwnTeam: boolean;
  isTabLine: boolean;

  isAddPlayerWithoutData: boolean;
  setIsAddPlayerWithoutData: (value: boolean) => void;

  draggedPlayer: StringToAnyMap | undefined;
  setDraggedPlayer: (player: StringToAnyMap | undefined) => void;
}

export const AddPlayerView: React.FC<AddPlayerViewProps> = ({
  searchString,
  setSearchString,

  squadId,
  isOwnTeam,
  isTabLine,

  isAddPlayerWithoutData,
  setIsAddPlayerWithoutData,

  draggedPlayer,
  setDraggedPlayer,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();
  const teams = useRecoilValue(teamsState);

  const [ownTeamPlayers, setOwnTeamPlayers] = useState<StringToAnyMap[]>([]);
  const [academyPlayers, setAcademyPlayers] = useState<StringToAnyMap[]>([]);
  const [showAcademyPlayers, setShowAcademyPlayers] = useState(false);

  const [existingPlayersWithoutData, setExistingPlayersWithoutData] = useState<StringToAnyMap[]>([]);

  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [activeSearchString, setActiveSearchString] = useState('');
  const [shouldShowSearchResult, setShouldShowSearchResult] = useState(false);
  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);

  const [tableData, setTableData] = useState<StringToAnyMap[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [showText, setShowText] = useState(false);


  const onPlayerClick = (player: StringToAnyMap, playerContainsPlayerOverview?: boolean) => {
    const playerOverview = playerOverviews[player.id] ?? (
      playerContainsPlayerOverview
        ? player
        : undefined
    );
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverview,
      squadId: squadId,
    });
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!isAddPlayerWithoutData) {
        handleSearchForPlayers(true, true);
      }
    }
  };


  const setEmptyTableData = () => {
    setTableData([]);
    setCurrentPage(0);
    setTotalHits(0);
  };


  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    if (searchString !== '') {

      const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;
      const querySearchString = searchString;
      const queryOptions: PlayerOverviewsQueryOptions = {
        name: querySearchString.toLowerCase(),
        page: nextPageToQuery,
        pageSize: playerSimpleTablePageSize,
      };

      try {
        const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(queryOptions, currentUser);
        setActiveSearchString(querySearchString);

        const totalHits = result?.total_hits;
        const page = result?.current_page;
        const players: PlayerOverview[] = result?.players ?? [];

        if (!result || totalHits === undefined || page === undefined) {
          setEmptyTableData();
          console.log('Invalid response for searchPlayerOverviews with option: ', queryOptions); // eslint-disable-line no-console
        }
        else if (totalHits === 0 || (isNewSearch && players.length === 0)) {
          setIsSearchWithoutResult(true);
          setEmptyTableData();
        }
        else {
          setIsSearchWithoutResult(false);
          setTableData(isNewSearch ? players : [...tableData, ...players]);
          setCurrentPage(page);
          setTotalHits(totalHits);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }

    setIsLoading(false);
  };


  const handleMessageLinkClick = () => {
    setSearchString('');
    if (isAddPlayerWithoutData) {
      setIsAddPlayerWithoutData(false);
    }
    else {
      setIsAddPlayerWithoutData(true);
    }
  };


  useEffect(() => {
    const playersWithoutData: StringToAnyMap[] = [];
    Object.keys(players).forEach((playerId: PlayerId) => {
      if (isNaN(Number(playerId))) {
        playersWithoutData.push(players[playerId]);
      }
    });
    setExistingPlayersWithoutData(playersWithoutData);
  }, [players]);


  useEffect(() => {
    if (teams && players && playerOverviews && (squadId || isOwnTeam)) {
      const ownTeam: StringToAnyMap[] = [];
      const academy: StringToAnyMap[] = [];

      positionOptionsPlatform.forEach(positionKey => {

        if (squadId && teams['ownTeam'] && positionKey in teams['ownTeam']) {
          teams['ownTeam'][positionKey].forEach((player: PlayerEntry) => {

            // could push { ...player, ...playerOverviews[Number(player['id'])], ...players[player['id']] }, but this approach is more efficient (?)
            let playerCopy: StringToAnyMap = { ...player };
            if (player['id'] in playerOverviews) {
              playerCopy['image_url'] = playerOverviews[Number(player['id'])].image_url;
              playerCopy['primary_positions'] = playerOverviews[Number(player['id'])].primary_positions;
            }
            if (player['id'] in players) {
              playerCopy = { ...playerCopy, ...players[player['id']] };
            }
            ownTeam.push(playerCopy);
          });
        }

        if (teams['academyTeam'] && positionKey in teams['academyTeam']) {
          teams['academyTeam'][positionKey].forEach((player: PlayerEntry) => {

            // could push { ...player, ...playerOverviews[Number(player['id'])], ...players[player['id']] }, but this approach is more efficient (?)
            let playerCopy: StringToAnyMap = { ...player };
            if (player['id'] in playerOverviews) {
              playerCopy['image_url'] = playerOverviews[Number(player['id'])].image_url;
              playerCopy['primary_positions'] = playerOverviews[Number(player['id'])].primary_positions;
            }
            if (player['id'] in players) {
              playerCopy = { ...playerCopy, ...players[player['id']] };
            }
            academy.push(playerCopy);
          });
        }

      });

      ownTeam.sort((a: StringToAnyMap, b: StringToAnyMap) => getSortDistanceRole(a, b));
      academy.sort((a: StringToAnyMap, b: StringToAnyMap) => getSortDistanceRole(a, b, true));

      setOwnTeamPlayers(ownTeam);
      setAcademyPlayers(academy);
    }
  }, [teams, players, playerOverviews, squadId, isOwnTeam]);


  const resetSearch = () => {
    setDebouncedSearchString('');
    setShouldShowSearchResult(false);
    setEmptyTableData();
    setIsSearchWithoutResult(false);
  };


  useEffect(() => {
    if (searchString.length === 0) {
      resetSearch();
    }

    else if (isAddPlayerWithoutData) {
      setShouldShowSearchResult(true);
      setIsSearchWithoutResult(false);
      setTableData(
        [{
          id: 'newPlayerWithoutData',
          fullname: searchString,
        }]
      );
    }

    if (!isAddPlayerWithoutData) {
      const [debounceFilter, clearDebounce] = debounce(() => {
        if (searchString !== debouncedSearchString) {
          setDebouncedSearchString(searchString);
        }
      }, 500);

      debounceFilter();

      clearDebounceRef.current = clearDebounce;

      return () => {
        clearDebounce();
      };
    }
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (searchString !== '' && searchString === activeSearchString) {
      setShouldShowSearchResult(true);
    }
  }, [searchString, activeSearchString]);


  return (
    <div className='add-player-view-container'>

      {showText && tableData.length > 0 && shouldShowSearchResult && (
        <div className='add-player-view-swipe-icon' style={{ top: isAddPlayerWithoutData ? '21vh' : '19vh' }}>
          <SwipeRightIcon style={{ fontSize: 22 }} />
        </div>
      )}

      <div className='add-player-view-message-link fade-in' onClick={handleMessageLinkClick}>
        {showText && translate(isAddPlayerWithoutData ? 'searchForPlayer?' : 'addPlayerWithoutData?', userConfig?.language)}
      </div>

      {isAddPlayerWithoutData && (
        <div className='add-player-view-title'>
          {showText && translate('addPlayerWithoutData', userConfig?.language)}
        </div>
      )}

      {showText && (
        <div
          style={{ marginTop: isAddPlayerWithoutData ? '9vh' : '4vh' }}
          className='add-player-view-input-field-container'
        >
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={translate(isAddPlayerWithoutData ? 'providePlayerName' : 'searchForName', userConfig?.language)}
            showDefaultInput={showText}
            style={{ boxShadow: '0px 0px 4px 2px #0e101324' }}
          />
        </div>
      )}

      {shouldShowSearchResult && (
        <div className='add-player-view-result-section'>

          {isSearchWithoutResult && (
            <div className='add-player-view-no-result fade-in'>
              {translate('noResult', userConfig?.language)}
            </div>
          )}

          {!isSearchWithoutResult && (
            <div className='add-player-view-table'>
              <PlayerSimpleTable
                data={tableData}
                onPlayerClick={(player: StringToAnyMap) => onPlayerClick(player, !isAddPlayerWithoutData)}
                tableType={isAddPlayerWithoutData ? 'addPlayerSimpleViewTeamIcon' : 'addPlayerView'}
                maxHeight={isTabLine ? '70vh' : '74vh'}
                positionKey={'addPlayerTable'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}

                isSearchResultTable={true}
                isLoading={isLoading}
                handleSearchForPlayers={handleSearchForPlayers}
                currentPage={currentPage}
                totalHits={totalHits}
              />
            </div>
          )}

        </div>
      )}

      {showText
        && ownTeamPlayers.length > 0
        && squadId
        && !isAddPlayerWithoutData
        && !shouldShowSearchResult
        && searchString === ''
        && (
          <div className='add-player-view-result-section'>

            {academyPlayers.length > 0 && (
              <div className='add-player-view-academy-toggle-section'>
                {translate('showAcademyPlayers', userConfig?.language)}
                <div className='add-player-view-academy-toggle'>
                  <Toggle isToggled={showAcademyPlayers} setIsToggled={setShowAcademyPlayers} boxShadow={'0px 0px 2px 1px #181a2366'} />
                </div>
              </div>
            )}

            <div
              style={{ marginTop: academyPlayers.length === 0 ? '3vh' : undefined }}
              className='add-player-view-table'>
              <PlayerSimpleTable
                data={showAcademyPlayers ? academyPlayers : ownTeamPlayers}
                onPlayerClick={(player: StringToAnyMap) => onPlayerClick(player, false)}
                tableType={'addPlayerSimpleViewRoleIcon'}
                maxHeight={academyPlayers.length === 0 ? '67vh' : '64vh'}
                positionKey={'addPlayerTableExistingPlayers'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
              />
            </div>

          </div>
        )}


      {showText
        && academyPlayers.length > 0
        && isOwnTeam
        && !isAddPlayerWithoutData
        && !shouldShowSearchResult
        && searchString === ''
        && (
          <div
            className='add-player-view-existing-players'
            style={{ height: isTabLine ? '67vh' : '71vh', marginTop: '14vh' }}>
            <div className='add-player-view-existing-players-title'>
              {translate('academyPlayers', userConfig?.language)}
            </div>

            <div className='add-player-view-players-without-data-table'>
              <PlayerSimpleTable
                data={academyPlayers}
                onPlayerClick={(player: StringToAnyMap) => onPlayerClick(player, !isAddPlayerWithoutData)}
                tableType={'addPlayerSimpleViewRoleIcon'}
                maxHeight={isTabLine ? '62vh' : '66vh'}
                positionKey={'addPlayerTableExistingPlayers'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
              />
            </div>
          </div>
        )}


      {isAddPlayerWithoutData && existingPlayersWithoutData.length > 0 && (
        <div
          className='add-player-view-existing-players'
          style={{ height: isTabLine ? '55vh' : '59vh', marginTop: '27vh' }}>
          <div className='add-player-view-existing-players-title'>
            {translate('existingPlayersWithoutData', userConfig?.language)}
          </div>

          <div className='add-player-view-players-without-data-table'>
            <PlayerSimpleTable
              data={existingPlayersWithoutData}
              onPlayerClick={(player: StringToAnyMap) => onPlayerClick(player, !isAddPlayerWithoutData)}
              tableType={'addPlayerSimpleViewTeamIcon'}
              maxHeight={isTabLine ? '50vh' : '54vh'}
              positionKey={'addPlayerTableExistingPlayers'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
            />
          </div>
        </div>
      )}

    </div>
  );
};
