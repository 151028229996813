import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import { translate } from '../../../../common/language/translations';
import { ClauseState } from '../../documents/clause/clauseInitialState';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { Club, ClubMap } from '../../../types';
import { ClubDropDown } from '../../input/ClubDropDown';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { DropDownSelect } from '../../input/DropDownSelect';
import { clauseCurrencyKeys } from '../../../static/propertyValues';


const getFormattedPercentage = (percentage: number) => {
  const roundedValue = Math.round(percentage * 1000) / 1000;
  return parseFloat(roundedValue.toFixed(3));
};


interface SubClauseModalProps {
  closeModal: (forceClose: boolean) => void;
  subClauseIndexToEdit: number | undefined;
  setAllClubs: (clubs: ClubMap) => void;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}

export const SubClauseModal: React.FC<SubClauseModalProps> = ({
  closeModal,
  subClauseIndexToEdit,
  setAllClubs,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  state,
  dispatch
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();

  // if this is a new sub clause to be added, we extract some data from the state of the previous sub clause
  const previousSubClauseIndex: number | undefined = state.subClauses ? state.subClauses.length - 1 : undefined;
  const previousSubClauseStateAccessor = previousSubClauseIndex !== undefined && state.subClauses
    ? state.subClauses[previousSubClauseIndex]
    : state;

  const stateAccessor = subClauseIndexToEdit !== undefined && state.subClauses
    ? state.subClauses[subClauseIndexToEdit]
    : null;

  const sellingClubId = stateAccessor
    ? stateAccessor.sellingClubId
    : previousSubClauseStateAccessor?.buyingClubId;
  const sellingClub = sellingClubId !== undefined ? state.additionalClauseState.allClubs[sellingClubId] : undefined;

  const buyingClubId = stateAccessor
    ? stateAccessor.buyingClubId
    : previousSubClauseStateAccessor?.nextClubId;
  const buyingClub = buyingClubId !== undefined ? state.additionalClauseState.allClubs[buyingClubId] : undefined;


  const subClausesSellOnPercentages = state.subClauses ? state.subClauses.map(subClause => subClause.sellOnPercentage) : [];

  const sellOnPercentage = stateAccessor?.sellOnPercentage ?? previousSubClauseStateAccessor?.sellOnCondition?.sellOnPercentage ?? 0;

  const allSellOnPercentages = subClauseIndexToEdit !== undefined && subClausesSellOnPercentages.length > 0
    ? subClausesSellOnPercentages.slice(0, subClauseIndexToEdit + 1)
    : [...subClausesSellOnPercentages, sellOnPercentage];

  const totalSellOnPercentage = stateAccessor?.totalSellOnPercentage
    ?? getFormattedPercentage(allSellOnPercentages.reduce((acc, percentage) => acc * (percentage / 100), 1) * 100);


  const [selectedBuyingClub, setSelectedBuyingClub] = useState<Club | undefined>(buyingClub);
  const [transferDate, setTransferDate] = useState<string | null>(stateAccessor?.transferDate ?? null);
  const [selectedCurrency, setSelectedCurrency] = useState<string[]>(stateAccessor && stateAccessor.currency ? [stateAccessor.currency] : []);

  const [isClubsExpanded, setIsClubsExpanded] = useState<boolean>(false);
  const [isCurrencyExpanded, setIsCurrencyExpanded] = useState<boolean>(false);

  const removeDropDownExpansions = () => {
    setIsClubsExpanded(false);
    setIsCurrencyExpanded(false);
  };


  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const today = new Date();

  const minTransferDate = new Date((new Date()).setFullYear((today).getFullYear() - 10));
  const maxTransferDate = new Date((new Date()).setFullYear((today).getFullYear() + 10));


  const setSelectedClub = (clubs: Club[]) => {
    if (clubs.length > 0) {
      setSelectedBuyingClub(clubs[0]);
    }
    else {
      setSelectedBuyingClub(undefined);
    }
    setHasUnsavedChanges(true);
  };


  const handleSetTransferDate = (date: Date | null) => {
    if (date) {
      const adjustedDate = new Date(date);
      adjustedDate.setHours(12);
      setTransferDate(adjustedDate.toISOString().split('T')[0]);
    }
    else {
      setTransferDate(null);
    }
    setHasUnsavedChanges(true);
  };


  const handleSetSelectedCurrency = (currency: string[]) => {
    setSelectedCurrency(currency);
    setHasUnsavedChanges(true);
  };


  const addOrEditSubClause = () => {
    if (!sellingClub || !selectedBuyingClub || !transferDate) return;

    const payload = {
      sellingClubId: sellingClub.id,
      buyingClub: selectedBuyingClub,
      transferDate: transferDate,
      currency: selectedCurrency.length > 0 ? selectedCurrency[0] : null,
      sellOnPercentage: sellOnPercentage,
      totalSellOnPercentage: totalSellOnPercentage,
      subClauseIndexToEdit: subClauseIndexToEdit
    };

    if (selectedBuyingClub.id in state.additionalClauseState.allClubs) {
      setAllClubs({ ...state.additionalClauseState.allClubs, [selectedBuyingClub.id]: selectedBuyingClub });
    }

    dispatch({ type: 'ADD_OR_EDIT_SUB_CLAUSE', payload: payload });
    closeModal(true);
  };


  const deleteSubClause = () => {
    if (subClauseIndexToEdit === undefined) return;

    dispatch({ type: 'DELETE_SUB_CLAUSE', payload: subClauseIndexToEdit });
    closeModal(true);
  };


  const isButtonDisabled = () => {
    return !(selectedBuyingClub && transferDate && (subClauseIndexToEdit === undefined || hasUnsavedChanges));
  };


  return (
    <div className='modal-root-container' style={{ width: 550, height: 660 }}>

      {(isClubsExpanded || isCurrencyExpanded) && (
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()}>
          &nbsp;
        </div>
      )}

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal(false)}
      />

      <div className='modal-root-title'>
        {translate(subClauseIndexToEdit === undefined ? 'addFurtherClauses' : 'editSubClause', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      {(stateAccessor || previousSubClauseStateAccessor) && (
        <div className='modal-content-section' style={{ top: 80, bottom: 0 }}>

          {sellingClub && (
            <div className='sub-clause-modal-input-section'>
              <div style={{ marginBottom: 6 }}>
                {translate('soldFrom', userConfig?.language)}
              </div>

              <div className='clause-input-display-field'>

                <div className='clause-selected-club-logo-container'>
                  <img
                    style={{ maxHeight: 16, maxWidth: 16 }}
                    src={sellingClub.logo_url}
                    alt={sellingClub.name}
                    draggable={false}
                  />
                </div>

                {sellingClub.name}
              </div>
            </div>
          )}

          <div className='sub-clause-modal-input-section' style={{ top: 70 }}>
            <div style={{ marginBottom: 6 }}>
              {translate('soldTo', userConfig?.language)}
            </div>

            {!selectedBuyingClub && (
              <div className='clause-club-drop-down' style={{ width: 250, marginTop: 4 }}>
                <ClubDropDown
                  id={'clause-clubs-drop-down'}
                  isDropDownExpanded={isClubsExpanded}
                  setIsDropDownExpanded={setIsClubsExpanded}
                  selectedClubs={[]}
                  setSelectedClubs={setSelectedClub}
                  defaultDropDownText={'selectClub'}
                  defaultDropDownTextColor={'#00000080'}
                  marginBetweenOptions={3}
                  maxHeight={'47vh'}
                  singleSelect={true}
                />
              </div>
            )}

            {selectedBuyingClub && (
              <div className='clause-input-display-field'>

                <div className='clause-remove-icon-container'>
                  <div className='document-content-icon' onClick={() => setSelectedClub([])}>
                    <CloseIcon style={{ fontSize: 18 }} />
                  </div>
                </div>

                <div className='clause-selected-club-logo-container'>
                  <img
                    style={{ maxHeight: 16, maxWidth: 16 }}
                    src={selectedBuyingClub.logo_url}
                    alt={selectedBuyingClub.name}
                    draggable={false}
                  />
                </div>

                {selectedBuyingClub.name}
              </div>
            )}
          </div>

          <div className='sub-clause-modal-input-section' style={{ top: 145, zIndex: 2 }}>
            <div style={{ marginBottom: 6 }}>
              {translate('transferDate', userConfig?.language)}
            </div>

            {!transferDate && (
              <div style={{ marginTop: 4 }}>
                <DatePicker
                  className='document-input-field'
                  selected={null}
                  onChange={(date: Date | null) => handleSetTransferDate(date)}
                  locale={locale}
                  dateFormat={dateFormat}
                  popperPlacement='bottom'

                  minDate={minTransferDate}
                  maxDate={maxTransferDate}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                />
              </div>
            )}

            {transferDate && (
              <div className='clause-input-display-field'>
                <div className='clause-remove-icon-container'>
                  <div className='document-content-icon' onClick={() => handleSetTransferDate(null)}>
                    <CloseIcon style={{ fontSize: 18 }} />
                  </div>
                </div>

                {getDateLocaleFormat(transferDate)}
              </div>
            )}
          </div>

          {state.currency && (
            <div className='sub-clause-modal-input-section' style={{ top: 225 }}>
              <div style={{ marginBottom: 6 }}>
                {translate('currency', userConfig?.language)}
              </div>

              {selectedCurrency.length === 0 && (
                <div style={{ width: 150 }}>
                  <DropDownSelect
                    id={'sub-clause-modal-currency-select'}
                    dropDownOptions={clauseCurrencyKeys}
                    selectedOptions={selectedCurrency}
                    setSelectedOptions={handleSetSelectedCurrency}
                    isDropDownExpanded={isCurrencyExpanded}
                    setIsDropDownExpanded={setIsCurrencyExpanded}
                    defaultDropDownText={'currency'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    forceSelection={true}
                    height={24}
                    maxHeight={'30vh'}
                  />
                </div>
              )}

              {selectedCurrency.length > 0 && (
                <div className='clause-input-display-field'>
                  <div className='clause-remove-icon-container'>
                    <div className='document-content-icon' onClick={() => handleSetSelectedCurrency([])}>
                      <CloseIcon style={{ fontSize: 18 }} />
                    </div>
                  </div>

                  {selectedCurrency[0]}
                </div>
              )}
            </div>
          )}

          <div className='sub-clause-modal-input-section-container' style={{ top: state.currency ? 280 : 240, bottom: 80 }}>
            <div className='sub-clause-modal-input-section'>
              <div style={{ marginBottom: 7 }}>
                {translate('yourPercentageOfAmounts', userConfig?.language)}
              </div>

              <div className='flex-row' style={{ fontSize: 15, alignItems: 'center' }}>
                {totalSellOnPercentage}%
                {allSellOnPercentages.length > 1 && allSellOnPercentages.map((percentage, index) => {
                  return (
                    <div
                      key={index}
                      className='flex-row'
                      style={{ marginLeft: index === 0 ? 10 : 4, fontSize: 13, color: '#ffffffaa', alignItems: 'center' }}
                    >
                      {index === 0 ? '(' : ''}

                      {percentage}%

                      {index < allSellOnPercentages.length - 1 && translate('<percentage>of', userConfig?.language)}

                      {index === allSellOnPercentages.length - 1 ? ')' : ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={'modal-button modal-button-bottom' + (isButtonDisabled() ? ' modal-button-disabled' : '')}
        style={{ marginLeft: subClauseIndexToEdit === undefined ? 175 : 50, bottom: 40 }}
        onClick={() => !isButtonDisabled() ? addOrEditSubClause() : null}
      >
        <div className='modal-button-text'>
          {translate(subClauseIndexToEdit === undefined ? 'addSubClause' : 'editSubClause', userConfig?.language)}
        </div>

        {subClauseIndexToEdit === undefined && <AddIcon className='modal-button-icon' style={{ fontSize: 24 }} />}
        {subClauseIndexToEdit !== undefined && <CheckIcon className='modal-button-icon' style={{ fontSize: 24 }} />}
      </div>

      {subClauseIndexToEdit !== undefined && (
        <div
          className='modal-button modal-button-bottom'
          style={{ marginLeft: 300, bottom: 40 }}
          onClick={() => openConfirmModal(deleteSubClause, 'deleteSubClause', 'deleteSubClauseInfo')}
        >
          <div className='modal-button-text'>
            {translate('deleteSubClause', userConfig?.language)}
          </div>

          <DeleteOutlineIcon className='modal-button-icon' style={{ fontSize: 24 }} />
        </div>
      )}

    </div>
  );
};
