import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import '../mobilePlatform.css';


export const MobileReports: React.FC = () => {


  return (
    <div className='mobile-view-container'>
      <div className='mobile-home-view-background'>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='mobile-view-content-container'>
        &nbsp;
      </div>
    </div>
  );
};
