import { db } from '../../../firebase';
import { countryCodeToCountryInfo } from '../../static/countries';

import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import { Competition, CompetitionMap, FirestoreCompetition } from '../../types';


// Get all competitions
export const getCompetitions = (setCompetitionsState: (competitionMap: CompetitionMap) => void) => {

  const competitionsRef = collection(db, 'competitions');

  const q = query(competitionsRef, orderBy('orderIndex', 'asc'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {

    const competitionMap: CompetitionMap = {};

    querySnapshot.forEach((doc) => {
      const firestoreCompetition = doc.data() as FirestoreCompetition;

      let countryName_en: string | undefined = undefined;
      let countryName_no: string | undefined = undefined;
      let countryName_se: string | undefined = undefined;
      let countryName_dk: string | undefined = undefined;

      if (firestoreCompetition.countryCode) {
        const countryInfo = countryCodeToCountryInfo[firestoreCompetition.countryCode];
        if (countryInfo) {
          countryName_en = countryInfo.name['en'];
          countryName_no = countryInfo.name['no'];
          countryName_se = countryInfo.name['se'];
          countryName_dk = countryInfo.name['dk'];
        }
      }

      const competition: Competition = {
        ...firestoreCompetition,
        id: doc.id,
        countryName_en: countryName_en,
        countryName_no: countryName_no,
        countryName_se: countryName_se,
        countryName_dk: countryName_dk,
      };

      competitionMap[Number(doc.id)] = competition;
    });

    setCompetitionsState(competitionMap);
  });

  return unsubscribe;
};
