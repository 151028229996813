import '../../static/clubConfigs.css';
import '../mobilePlatform.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import { translate } from '../../../common/language/translations';
import { MobileNavigationTab } from './MobileNavigation';
import { getClubLogo, getClubLogoBackgroundColor, getClubLogoBorderRadius, getClubLogoShadow, getClubLogoSize } from '../../static/clubConfigs';


interface MobileHeaderProps {
  activeTab: MobileNavigationTab;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ activeTab }) => {

  const userConfig = useRecoilValue(userConfigState);

  const logoShadow = getClubLogoShadow(userConfig?.club ?? '');
  const logoSize = getClubLogoSize(userConfig?.club ?? '');
  const logoBackgroundColor = getClubLogoBackgroundColor(userConfig?.club ?? '');
  const logoBorderRadius = getClubLogoBorderRadius(userConfig?.club ?? '');


  return (

    <div className='mobile-header-container'>

      <div className='mobile-header-logo-container'>
        <div className={'mobile-header-logo-shadow logo-shadow-' + logoShadow} />

        <img
          src={getClubLogo(userConfig?.club ?? '')}
          alt=''
          className='mobile-header-logo'
          draggable={false}
          style={{
            maxWidth: logoSize ?? '100%',
            maxHeight: logoSize ?? '100%',
            backgroundColor: logoBackgroundColor,
            borderRadius: logoBorderRadius ?? (logoBackgroundColor ? 8 : undefined),
            overflow: logoBorderRadius !== undefined ? 'hidden' : undefined,
            padding: logoBackgroundColor ? '5%' : undefined,
          }}
        />
      </div>

      {translate(activeTab, userConfig?.language)}
    </div >

  );
};
