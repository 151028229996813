import { useRecoilState } from 'recoil';
import { playerCareersState } from '../atoms/playerCareersState';
import { getPlayerCareers } from '../../services/server/application/playerCareers';
import { FirebaseUser } from '../../../firebase';
import { PlayerCareer, PlayerId } from '../../types';


export const useGetAndAddPlayerCareer = () => {

  const [playerCareers, setPlayerCareers] = useRecoilState(playerCareersState);


  const getAndAddPlayerCareer = async (playerId: PlayerId, currentUser: FirebaseUser): Promise<PlayerCareer | undefined> => {

    let playerCareer: PlayerCareer | undefined = undefined;

    if (!isNaN(Number(playerId))) {

      playerCareer = playerCareers[Number(playerId)];

      if (!playerCareer) {
        const newPlayerCareer = await getPlayerCareers([Number(playerId)], currentUser);

        if (newPlayerCareer) {
          setPlayerCareers(currentPlayerCareers => ({
            ...currentPlayerCareers,
            ...newPlayerCareer
          }));

          playerCareer = newPlayerCareer[Number(playerId)];
        }
      }
    }

    return playerCareer;
  };


  return getAndAddPlayerCareer;
};
