import '../../platform.css';
import './scouting.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
// import { scoutingConfigState } from '../../recoil/atoms/scoutingConfigState';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { PlayerScouting } from './playerScouting/PlayerScouting';
import { SeasonScouting } from './seasonScouting/SeasonScouting';
// import { Alerts } from './alerts/Alerts';

import { userConfigState } from '../../recoil/atoms/userConfigState';
import { translate } from '../../../common/language/translations';


export const playerTablePageSize = 25;
export const playerTableChapterSize = 4;

export const playerSeasonTablePageSize = 50;
export const playerSeasonTableChapterSize = 1;

export const alertTablePageSize = 100;

export const playerSimpleTablePageSize = 50;
export const playerSimpleTableChapterSize = 4;


export const Scouting = () => {

  const userConfig = useRecoilValue(userConfigState);
  // const scoutingConfig = useRecoilValue(scoutingConfigState);

  const [activeTab, setActiveTab] = useState(0);
  const tabOptions = [
    'playerSearch',
    'seasonSearch',
    'alerts'
  ];
  const tabIcons = [
    <PersonSearchIcon key={'person-search-icon'} style={{ fontSize: 21 }} />,
    <QueryStatsIcon key={'query-stats-icon'} style={{ fontSize: 21 }} />,
    <NotificationsIcon key={'notifications-icon'} style={{ fontSize: 21 }} />
  ];


  // const numberOfNewPlayersSinceLastViewed = scoutingConfig !== null && scoutingConfig.alerts
  //   ? scoutingConfig.alerts.reduce((acc, alert) => acc + alert.numberOfNewPlayersSinceLastViewed, 0)
  //   : 0;


  return (
    <div className='platform-view-section'>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabIcons={tabIcons}
      // numberOfNewPlayersSinceLastViewed={numberOfNewPlayersSinceLastViewed}
      />

      {activeTab === 0 && <PlayerScouting />}

      {activeTab === 1 && <SeasonScouting />}

      {/* {activeTab === 2 && <Alerts />} */}

      {activeTab === 2 && (
        <div className='scouting-view-content-container'>
          <div style={{ color: '#ffffffd7', margin: 'auto', fontSize: 24 }}>
            {translate('underDevelopment', userConfig?.language)}
          </div>
        </div>
      )}

    </div >
  );
};
