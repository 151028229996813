import './visualization.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import {
  goalkeeperSeasonTableMetricGroups,
  goalkeeperSeasonTableMetrics,
  metricToDisplayInfo,
  outfieldSeasonTableMetricGroups,
  outfieldSeasonTableMetrics
} from '../../static/playerMetrics';

import { RoleConfig } from '../../types';
import { Slider } from '../input/Slider';
import { getClubSecondaryColor } from '../../static/clubConfigs';


interface RoleConfigWeightsProps {
  roleConfig: RoleConfig;
  isEditMode?: boolean;
  setRoleConfig?: (roleConfig: RoleConfig) => void;
  setInputMessageKey?: (messageKey: string | undefined) => void;
}

export const RoleConfigWeights: React.FC<RoleConfigWeightsProps> = ({
  roleConfig,
  isEditMode,
  setRoleConfig,
  setInputMessageKey,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openTextModal } = useOpenGlobalModal();

  const [sumOfWeightsOfSelectedRoleConfig, setSumOfWeightsOfSelectedRoleConfig] = useState(0);

  const metricGroupsOfSelectedRoleConfig = roleConfig.positionGroup === 'GK' ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const metricsOfSelectedRoleConfig = roleConfig.positionGroup === 'GK' ? goalkeeperSeasonTableMetrics : outfieldSeasonTableMetrics;


  const handleSliderOnChange = (metric: string, newValue: number) => {
    const weights = { ...roleConfig.weights };

    // we allow a maximum of 10 weights
    if (!(metric in weights) && Object.keys(weights).length === 10 && newValue > 0) {
      if (setInputMessageKey) {
        setInputMessageKey('max10MetricsInRole');
      }
      return;
    }

    if (setInputMessageKey) {
      setInputMessageKey(undefined);
    }

    if (newValue === 0) {
      delete weights[metric];
    }
    else {
      weights[metric] = newValue;
    }
    if (setRoleConfig) {
      setRoleConfig({
        ...roleConfig,
        weights: weights,
      });
    }
  };


  useEffect(() => {
    let sumOfWeights = 0;

    metricsOfSelectedRoleConfig.forEach(metric => {
      if (roleConfig.weights[metric]) {
        sumOfWeights += roleConfig.weights[metric];
      }
    });

    setSumOfWeightsOfSelectedRoleConfig(sumOfWeights);
  }, [metricsOfSelectedRoleConfig, roleConfig]);


  const [isInitialized, setIsInitialized] = useState(false);
  const [shouldShowEditRow, setShouldShowEditRow] = useState(false);
  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      setShouldShowEditRow(isEditMode ?? false);
    }
    else {
      if (!isEditMode) {
        setShouldShowEditRow(false);
      }
      else {
        setTimeout(() => {
          setShouldShowEditRow(true);
        }, 300);
      }
    }
  }, [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='role-config-weights-container'>
      {Object.entries(metricGroupsOfSelectedRoleConfig).map(([metricGroupKey, metrics]) => {

        const metricGroupName = metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en'];
        const metricGroupDescription = metricToDisplayInfo[metricGroupKey].description[userConfig?.language ?? 'en'];

        return (
          <div
            key={metricGroupKey}
            className='role-config-weights-metric-group'
          >
            <div
              className='role-config-weights-metric-group-title player-view-metric-label'
              onClick={() => openTextModal(metricGroupName, metricGroupDescription)}
            >
              {metricGroupName}
            </div>

            <div className='role-config-weights-metrics'>
              {metrics.map(metric => {

                // for now, we don't allow presses or fouls won in roles, becuase they don't exist for all seasons
                if (metric === 'n_presses_padj_per_90' || metric === 'n_fouls_won_per_90') {
                  return null;
                }

                const metricName = metricToDisplayInfo[metric].name[userConfig?.language ?? 'en'];
                const metricDescription = metricToDisplayInfo[metric].description[userConfig?.language ?? 'en'];

                const isMetricIncluded = metric in roleConfig.weights;

                return (
                  <div
                    key={metric}
                    className={
                      'role-config-weights-metric'
                      + (isEditMode ? ' role-config-weights-metric-expanded' : '')
                      + (isMetricIncluded ? ' role-config-weights-metric-included' : '')
                    }
                    style={{ height: isEditMode ? 52 : 28 }}
                  >
                    <div
                      className='role-config-weights-metric-row'
                      style={{ height: 28 }}
                    >
                      <div
                        className='player-view-metric-label'
                        onClick={() => openTextModal(metricName, metricDescription)}
                      >
                        {metricName}
                      </div>

                      {isEditMode && (
                        <div className='role-config-weights-metric-value-container'>
                          <div className='role-config-weights-metric-value'>
                            {roleConfig.weights[metric] === undefined ? 0 : roleConfig.weights[metric]}
                          </div>

                          <div className='role-config-weights-metric-value-percentage'>
                            {' ('}
                            {roleConfig.weights[metric] !== undefined && Math.round(
                              (roleConfig.weights[metric] / sumOfWeightsOfSelectedRoleConfig) * 100
                            )}
                            {roleConfig.weights[metric] === undefined && '0'}
                            %
                            {')'}
                          </div>
                        </div>
                      )}

                      {!isEditMode && roleConfig.weights[metric] !== undefined && (
                        <div className='role-config-weights-metric-value-container'>
                          <div className='role-config-weights-metric-value'>
                            {roleConfig.weights[metric]}
                          </div>

                          <div className='role-config-weights-metric-value-percentage'>
                            {' ('}
                            {Math.round(
                              (roleConfig.weights[metric] / sumOfWeightsOfSelectedRoleConfig) * 100
                            )}%
                            {')'}
                          </div>
                        </div>
                      )}

                      {!isEditMode && roleConfig.weights[metric] === undefined && (
                        <div className='role-config-weights-metric-value-empty'>
                          -
                        </div>
                      )}
                    </div>

                    {isEditMode && shouldShowEditRow && (
                      <div
                        className='role-config-weights-metric-row fast-fade-in'
                        style={{ height: 24, paddingRight: 4, boxSizing: 'border-box' }}
                      >
                        <Slider
                          value={roleConfig.weights[metric] ?? 0}
                          onChange={(newValue) => handleSliderOnChange(metric, newValue)}
                          color={getClubSecondaryColor(userConfig?.club ?? '')}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
