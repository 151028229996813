import '../tables.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';

import { useMemo } from 'react';
import { useTable, useBlockLayout } from 'react-table';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getEmptyTableWidth, getPlayerSimpleColumns } from './playerSimpleColumns';
import { StringToAnyMap, PlayerId, AggregationRule } from '../../../types';
import { getClubColor } from '../../../static/clubConfigs';
import { debounce } from '../../../utils/utils';
import { playerSimpleTableChapterSize } from '../../../views/scouting/Scouting';
import { translate } from '../../../../common/language/translations';
import { Dialog } from '@mui/material';
import { RoleConfigAggregationModal } from '../../modals/miscModals/RoleConfigAggregationModal';


interface PlayerSimpleTableProps {
  data: StringToAnyMap[];
  onPlayerClick: (player: StringToAnyMap) => void;
  tableType: string | undefined; // either a specific table type or a teamOrSquadKey (undefined -> team)

  positionKey?: string;
  draggedPlayer?: StringToAnyMap | undefined;
  setDraggedPlayer?: (player: StringToAnyMap | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void; // if provided, this table is a valid drop zone

  isSearchResultTable?: boolean;
  isLoading?: boolean;
  handleSearchForPlayers?: (isUserInitiated: boolean, isNewSearch: boolean) => Promise<void>;
  currentPage?: number;
  totalHits?: number;

  disablePlayersWithoutEventData?: boolean;
  isGoalkeeper?: boolean; // if provided -> if true, players who are not goalkeepers will be disabled, and vice versa

  maxHeight?: string; // if provided, scroll will be enabled
  scrollBarColor?: string;
  highlightedPlayerId?: PlayerId | undefined;

  confirmNewAggregationRule?: (newAggregation: AggregationRule, positionKey: string) => void;
  moveRoleConfigUp?: (currentIndex: number, positionKey: string) => void;
}

export const PlayerSimpleTable: React.FC<PlayerSimpleTableProps> = ({
  data,
  onPlayerClick,
  tableType, // team, ownTeam, academyTeam, bench, addPlayerView, addPlayerSimpleViewTeamIcon, addPlayerSimpleViewRoleIcon, archiveReportInfo

  positionKey,
  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,

  isSearchResultTable,
  isLoading,
  handleSearchForPlayers,
  currentPage,
  totalHits,

  disablePlayersWithoutEventData,
  isGoalkeeper,

  maxHeight,
  scrollBarColor,
  highlightedPlayerId,

  confirmNewAggregationRule,
  moveRoleConfigUp,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const [isRoleConfigAggregationModalOpen, setIsRoleConfigAggregationModalOpen] = useState(false);

  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const [tableHasOverflow, setTableHasOverflow] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const clubColor = getClubColor(userConfig?.club ?? '');


  const teamMenu = useMemo(() => {
    return (
      tableType === 'ownTeam'
        ? userSettings?.ownTeamMenu
        : tableType === 'academyTeam'
          ? userSettings?.academyTeamMenu
          : userSettings?.teamMenu
    ) ?? {};
  }, [tableType, userSettings?.ownTeamMenu, userSettings?.academyTeamMenu, userSettings?.teamMenu]);


  const columns = useMemo(
    () => getPlayerSimpleColumns(
      userConfig?.club ?? '',
      tableType,
      teamMenu,
      disablePlayersWithoutEventData,
      isGoalkeeper
    ),
    [userConfig?.club, tableType, teamMenu, disablePlayersWithoutEventData, isGoalkeeper]
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  );


  const getTableRowClassName = (player: StringToAnyMap) => {
    if (teamMenu.colored === 'role') {
      return 'player-simple-table-row player-simple-table-row-' + player.role;
    }

    if (
      (disablePlayersWithoutEventData && !player['event_data_available']) ||
      (isGoalkeeper !== undefined && (isGoalkeeper === (!player['primary_positions'].includes('GK'))))
    ) {
      return 'player-simple-table-row-disabled';
    }

    if (player.id === highlightedPlayerId) {
      return 'player-simple-table-row-highlighted';
    }

    return 'player-simple-table-row';
  };


  const handleDragStart = (player: StringToAnyMap) => {
    if (positionKey && setDraggedPlayer) {
      setDraggedPlayer({
        ...player,
        'positionKey': positionKey,
      });
    }
  };

  const handleDragEnd = () => {
    if (setDraggedPlayer) {
      setDraggedPlayer(undefined);
    }
    setIsDraggedOver(false);
  };

  const handleDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (handlePlayerDrop) {
      event.preventDefault();
      if (positionKey) {
        handlePlayerDrop(positionKey);
      }
      setIsDraggedOver(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (handlePlayerDrop) {
      event.preventDefault();
    }
  };

  const handleDragEnter = () => {
    setIsDraggedOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggedOver(false);
  };

  // goalkeeper (outfield) roles can only be dropped on the goalkeeper position (outfield positions)
  // we do not allow more than 3 roles per position
  // we do not allow duplicate roles
  const isValidRoleConfigsDropZone = data.length < 3 && data.every(role => role['id'] !== draggedPlayer?.['id']) && (
    (positionKey === 'GK' && draggedPlayer?.['positionGroup'] === 'GK') ||
    (positionKey !== 'GK' && draggedPlayer?.['positionGroup'] !== 'GK')
  );

  // players that we know are goalkeepers can not be dropped on outfield positions
  // players that we know are outfield players can not be dropped on the goalkeeper position if there are no goalkeepers in the team
  // players without a position can not be dropped on the goalkeeper position if they come from an outfield position and there are no goalkeepers in the team
  const playerIsGoalkeeper = draggedPlayer?.['primary_positions']?.includes('GK') || draggedPlayer?.['primary_positions']?.includes('G');
  const playerIsOutfielder = draggedPlayer?.['primary_positions'] && !playerIsGoalkeeper;

  const isInvalidOutfieldDropZone = playerIsGoalkeeper && positionKey !== 'GK';
  const isInvalidGoalkeeperDropZone = playerIsOutfielder && positionKey === 'GK' && data.length === 0;
  const isInvalidGoalkeeperDropZoneForPlayersWithoutPosition = !draggedPlayer?.['primary_positions']
    && positionKey === 'GK'
    && !draggedPlayer?.['positionKey']?.startsWith('addPlayerTable')
    && data.length === 0;

  const isValidTeamDropZone = !isInvalidOutfieldDropZone && !isInvalidGoalkeeperDropZone && !isInvalidGoalkeeperDropZoneForPlayersWithoutPosition;

  const isValidDropZone = draggedPlayer && handlePlayerDrop && draggedPlayer['positionKey'] !== positionKey && (
    (tableType === 'roleConfigs' && isValidRoleConfigsDropZone) ||
    (tableType !== 'roleConfigs' && isValidTeamDropZone)
  );


  const getBoxShadow = () => {
    if (isValidDropZone) {
      if (isDraggedOver) {
        return '0px 0px 0px 3px ' + clubColor;
      }
      return '0px 0px 0px 3px #cdd0e773';
    }
    return 'none';
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !isSearchResultTable || !handleSearchForPlayers || currentPage === undefined || totalHits === undefined) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentPage * 0.1;

    const isMoreDataToFetch = currentPage < playerSimpleTableChapterSize && data.length < totalHits;

    if (scrollPosition > threshold && !isLoading && isMoreDataToFetch) {
      await handleSearchForPlayers(true, false);
    }
  };


  useEffect(() => {
    if (isSearchResultTable && currentPage !== undefined && currentPage < playerSimpleTableChapterSize) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const checkOverflow = () => {
      const element = tableContainerRef.current;
      const isOverflowing = element !== null && element.scrollHeight > element.clientHeight;
      setTableHasOverflow(isOverflowing);
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [data]);


  document.documentElement.style.setProperty('--scroll-bar-color', scrollBarColor ?? '#919dcdc7');


  const showRoleConfigAggregationRule = tableType === 'roleConfigs' && data.length > 1 && !draggedPlayer;
  const roleConfigAggregationRule: AggregationRule = tableType === 'roleConfigs' && data.length > 1
    ? data[0]['aggregationRule']
    : undefined;


  return (
    <div>

      {isRoleConfigAggregationModalOpen && confirmNewAggregationRule && positionKey && (
        <Dialog
          open={isRoleConfigAggregationModalOpen}
          onClose={() => setIsRoleConfigAggregationModalOpen(false)}
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          <RoleConfigAggregationModal
            closeModal={() => setIsRoleConfigAggregationModalOpen(false)}
            currentAggregation={roleConfigAggregationRule}
            confirmNewAggregation={(newAggregation) => {
              confirmNewAggregationRule(newAggregation, positionKey);
              setIsRoleConfigAggregationModalOpen(false);
            }}
          />
        </Dialog>
      )}

      {data.length > 0 && (
        <div className={'player-simple-table-container' + (!maxHeight ? ' player-simple-table-container-with-shadow' : '')}>

          {showRoleConfigAggregationRule && (
            <div
              className='role-config-aggregation-rule-container'
              title={translate('editRule', userConfig?.language)}
              onClick={() => setIsRoleConfigAggregationModalOpen(true)}
            >
              {translate(roleConfigAggregationRule + 'Aggregation', userConfig?.language)}
            </div>
          )}

          {isValidDropZone && (
            <div
              className='player-simple-table-overlay'
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDragDrop}
            >
              &nbsp;
            </div>
          )}

          <div
            {...getTableProps()}
            ref={tableContainerRef}
            className={
              'player-simple-table'
              + (tableHasOverflow ? ' player-simple-table-overflowed' : '')
              + (showRoleConfigAggregationRule ? ' player-simple-table-role-config-with-aggregation-rule' : '')
            }
            style={{ maxHeight: maxHeight, boxShadow: getBoxShadow() }}
          >
            <div
              {...getTableBodyProps()}
              className='player-simple-table-body'
            >
              {rows.map((row, index) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <div
                    key={key}
                    {...restRowProps}
                    className={getTableRowClassName(row.original)}
                    onClick={() => onPlayerClick(row.original)}
                    draggable={positionKey !== undefined && setDraggedPlayer !== undefined}
                    onDragStart={() => handleDragStart(row.original)}
                    onDragEnd={() => handleDragEnd()}
                  >
                    {row.cells.map(cell => {
                      const { key: cellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <div
                          key={cellKey}
                          {...restCellProps}
                        >
                          {cell.render('Cell')}

                          {showRoleConfigAggregationRule
                            && (roleConfigAggregationRule === 'priority' || roleConfigAggregationRule === 'confidence')
                            && index !== 0
                            && positionKey
                            && moveRoleConfigUp
                            && (
                              <div
                                className='role-config-arrow-icon-container'
                                style={{ top: index * 28 }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  moveRoleConfigUp(index, positionKey);
                                }}
                              >
                                <div className='role-config-arrow-icon'>
                                  {<KeyboardArrowUpIcon style={{ fontSize: 20 }} />}
                                </div>
                              </div>
                            )
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      )}

      {data.length === 0 && !isValidDropZone && positionKey !== 'GK' && tableType !== 'roleConfigs' && (
        <div className='player-simple-table-empty-container'>
          &nbsp;
        </div>
      )}

      {data.length === 0 && !isValidDropZone && tableType === 'roleConfigs' && (
        <div
          className='player-simple-table-empty-container player-simple-table-empty-container-drag-active player-simple-table-empty-role-config-container'
          style={{ width: getEmptyTableWidth(tableType, teamMenu) - 4 }}
        >
          {translate('skillIndex', userConfig?.language)}
        </div>
      )}

      {data && data.length === 0 && isValidDropZone && (
        <div
          className={'player-simple-table-empty-container player-simple-table-empty-container-drag-active'}
          style={{
            boxShadow: isDraggedOver ? '0px 0px 0px 3px ' + clubColor : 'none',
            width: getEmptyTableWidth(tableType, teamMenu)
          }}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDragDrop}
        >
          &nbsp;
        </div>
      )}

    </div>
  );
};
