import './archive.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';

import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';

import { translate } from '../../../common/language/translations';
import { StringToStringMap, PlayerReport } from '../../types';
import { ReportHistoryLinePlot } from '../../components/plots/ReportHistoryLinePlot';
import { getSortDistanceAccess } from '../../utils/playerUtils';
import { accessHasScoutAccess, getShortUserName } from '../../utils/userUtils';
import { getClubColor } from '../../static/clubConfigs';


interface ReportStatisticsProps {
  reports: PlayerReport[];
  selectedUserEmail: string;
  setSelectedUserEmail: (userEmail: string) => void;
}


export const ReportStatistics: React.FC<ReportStatisticsProps> = ({ reports, selectedUserEmail, setSelectedUserEmail }) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const [allUsersList, setAllUsersList] = useState<StringToStringMap[]>([]);

  const clubColor = getClubColor(userConfig?.club ?? '');


  const [totalNumberOfReports, setTotalNumberOfReports] = useState(0);
  const [numberOfReportsThisYear, setNumberOfReportsThisYear] = useState(0);
  const [numberOfReportsLastMonth, setNumberOfReportsLastMonth] = useState(0);


  useEffect(() => {
    let usersList: StringToStringMap[] = [];
    if (allUsersWithSameClub) {
      Object.keys(allUsersWithSameClub).map((userEmail: string) => {
        if (userEmail.split('@')[0] !== 'fokus') {
          usersList.push({
            email: userEmail,
            name: allUsersWithSameClub[userEmail].name,
            access: allUsersWithSameClub[userEmail].access,
          });
        }
      });
    }

    usersList.sort((a, b) => getSortDistanceAccess(a, b));

    if (userConfig && usersList.length > 1) {
      const allUsersEntry = {
        email: 'all',
        name: translate('all', userConfig.language),
      };
      usersList = [allUsersEntry, ...usersList];
    }

    setAllUsersList(usersList);
  }, [userConfig, allUsersWithSameClub]);


  useEffect(() => {
    if (allUsersList.length > 0) {
      setSelectedUserEmail(allUsersList[0]['email']);
    }
    else {
      setSelectedUserEmail('all');
    }
  }, [allUsersList, setSelectedUserEmail]);


  useEffect(() => {
    let totalReports = 0;
    let reportsThisYear = 0;
    let reportsLastMonth = 0;

    reports.forEach((report: PlayerReport) => {
      totalReports += 1;

      const reportDate = new Date(report.date);
      const currentDate = new Date();
      const thisYear = currentDate.getFullYear();
      const thirtyDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 30));
      if (reportDate.getFullYear() === thisYear) {
        reportsThisYear += 1;
      }
      if (reportDate >= thirtyDaysAgo) {
        reportsLastMonth += 1;
      }
    });

    setTotalNumberOfReports(totalReports);
    setNumberOfReportsThisYear(reportsThisYear);
    setNumberOfReportsLastMonth(reportsLastMonth);
  }, [reports, selectedUserEmail]);


  return (
    <div className='archive-view-report-statistics-container'>

      <div className='archive-view-report-statistics-users-section-container'>
        <div className='archive-view-report-statistics-users-section'>
          {allUsersList.map((userInfo: StringToStringMap, index) => {
            if (userInfo['email']) {

              if (userInfo.email !== 'all' && !accessHasScoutAccess(userInfo.access)) return null;

              const isSelectedUser = userInfo['email'] === selectedUserEmail;
              return (
                <div key={index} className='archive-view-report-statistics-user-container'>
                  <div
                    className={'archive-view-report-statistics-user-section' + (isSelectedUser ? ' archive-view-report-statistics-user-section-selected' : '')}
                    style={{ boxShadow: isSelectedUser ? ('0px 0px 0px 1px ' + clubColor) : undefined }}
                    onClick={() => setSelectedUserEmail(userInfo['email'])}
                  >
                    <div className='archive-view-report-statistics-user-icon'>
                      {userInfo['email'] === 'all' ? <GroupsIcon style={{ fontSize: 24 }} /> : <PersonIcon style={{ fontSize: 24 }} />}
                    </div>
                    <div className='archive-view-report-statistics-user-name'>
                      {getShortUserName(userInfo['name'])}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className='archive-view-report-statistics-divider'>
        &nbsp;
      </div>

      <div className='archive-view-report-statistics-summary'>
        <div className='archive-view-report-statistics-summary-entry'>
          <div className='archive-view-report-statistics-summary-entry-title'>
            {translate('totalNumberOfReports', userConfig?.language)}
          </div>
          <div className='archive-view-report-statistics-summary-entry-value'>
            {totalNumberOfReports}
          </div>
        </div>

        <div className='archive-view-report-statistics-summary-entry'>
          <div className='archive-view-report-statistics-summary-entry-title'>
            {translate('numberOfReportsThisYear', userConfig?.language)}
          </div>
          <div className='archive-view-report-statistics-summary-entry-value'>
            {numberOfReportsThisYear}
          </div>
        </div>

        <div className='archive-view-report-statistics-summary-entry'>
          <div className='archive-view-report-statistics-summary-entry-title'>
            {translate('numberOfReportsLastMonth', userConfig?.language)}
          </div>
          <div className='archive-view-report-statistics-summary-entry-value'>
            {numberOfReportsLastMonth}
          </div>
        </div>
      </div>

      <div className='archive-view-report-statistics-label-section'>
        <div className='archive-view-report-statistics-label'>
          <div className='archive-view-report-statistics-label-line' style={{ backgroundColor: clubColor }}>&nbsp;</div>
          <div className='archive-view-report-statistics-label-text'>
            {translate('numberOfReportsPerMonth', userConfig?.language)}
          </div>
        </div>
      </div>

      <div className='archive-view-report-statistics-line-plot-container'>
        <ReportHistoryLinePlot
          reports={reports}
          clubColor={clubColor}
          language={userConfig?.language ?? 'en'}
        />
      </div>

    </div>
  );
};
