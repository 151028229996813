import './plots.css';

import { useEffect, useState } from 'react';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import { PlayerCareer } from '../../types';
import { metricToDisplayInfo, positionGroupToRadarMetrics } from '../../static/playerMetrics';
import { translate } from '../../../common/language/translations';


interface PlayerRadarPlotProps {
  playerCareer: PlayerCareer;
  selectedClubIterationId: string | undefined;
  selectedPositionGroupKey: string;
  clubColor: string;
  language: string;
}


// xPoint, yPoint, metric, xText, yText, textAnchor
type PlayerRadarPoint = [number, number, string, number, number, string];


export const PlayerRadarPlot: React.FC<PlayerRadarPlotProps> = ({
  playerCareer,
  selectedClubIterationId,
  selectedPositionGroupKey,
  clubColor,
  language
}) => {

  const { openTextModal } = useOpenGlobalModal();

  const [radarPoints, setRadarPoints] = useState<PlayerRadarPoint[]>([]);
  const [radarPath, setRadarPath] = useState<string>('');


  const createPathData = (points: PlayerRadarPoint[]) => {

    // need at least three points for a radar plot
    if (points.length < 3) {
      return '';
    }

    let d = `M ${points[0][0]},${points[0][1]}`;

    function catmullRomSegment(p0: PlayerRadarPoint, p1: PlayerRadarPoint, p2: PlayerRadarPoint, p3: PlayerRadarPoint, tension = 1): string {
      const cp1x = p1[0] + (p2[0] - p0[0]) / 6 * tension;
      const cp1y = p1[1] + (p2[1] - p0[1]) / 6 * tension;
      const cp2x = p2[0] - (p3[0] - p1[0]) / 6 * tension;
      const cp2y = p2[1] - (p3[1] - p1[1]) / 6 * tension;
      return `C ${cp1x},${cp1y} ${cp2x},${cp2y} ${p2[0]},${p2[1]}`;
    }

    for (let i = 0; i < points.length; i++) {
      const p0 = points[i === 0 ? points.length - 1 : i - 1];
      const p1 = points[i];
      const p2 = points[i + 1 === points.length ? 0 : i + 1];
      const p3 = points[i + 2 >= points.length ? (i + 2) % points.length : i + 2];
      d += catmullRomSegment(p0, p1, p2, p3, 0.3);
    }

    d += ' Z';

    return d;
  };


  const calculatePointCoordinates = (index: number, value: number): [number, number] => {
    const angleDeg = index * 45;
    const angleRad = angleDeg * (Math.PI / 180);

    const radius = 24 * value;

    const xOffset = radius * Math.cos(angleRad);
    const yOffset = radius * Math.sin(angleRad);

    return [xOffset + 500, yOffset + 350];
  };


  const calculateTextCoordinatesAndAnchor = (index: number): [number, number, string] => {
    const angleDeg = index * 45;
    const angleRad = angleDeg * (Math.PI / 180);

    const radius = 290;

    let xOffset = radius * Math.cos(angleRad);
    let yOffset = radius * Math.sin(angleRad);

    // text anchor
    let textAnchor = 'middle';
    if ([0, 1, 7].includes(index)) {
      textAnchor = 'start';
    }
    else if ([3, 4, 5].includes(index)) {
      textAnchor = 'end';
    }

    // text positioning
    yOffset += 8;
    if (index === 1 || index === 7) {
      xOffset -= 4;
    }
    else if (index === 3 || index === 5) {
      xOffset += 4;
    }
    else if (index === 2) {
      yOffset += 14;
    }
    else if (index === 6) {
      yOffset -= 4;
    }

    return [xOffset + 500, yOffset + 350, textAnchor];
  };


  useEffect(() => {

    if (!selectedClubIterationId) return;

    const clubIteration = playerCareer[selectedClubIterationId];
    const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration?.position_group_stats?.overall
      ? clubIteration?.position_groups[0]
      : selectedPositionGroupKey;

    const playerClubIterationPositionGroupData = clubIteration?.position_group_stats?.[selectedPositionGroupKeyForIteration];

    if (playerClubIterationPositionGroupData) {

      const metricPositionGroup = selectedPositionGroupKey === 'overall'
        ? playerCareer[selectedClubIterationId].position_groups[0]
        : selectedPositionGroupKey;

      const metrics = positionGroupToRadarMetrics[metricPositionGroup];

      const points: PlayerRadarPoint[] = [];

      metrics.forEach((metric, index) => {

        const metricValue = playerClubIterationPositionGroupData.metrics[metric + '_normalized'] ?? 0;
        const [xPoint, yPoint] = calculatePointCoordinates(index, metricValue);

        const [xText, yText, textAnchor] = calculateTextCoordinatesAndAnchor(index);

        points.push([
          xPoint,
          yPoint,
          metric,
          xText,
          yText,
          textAnchor,
        ]);
      });

      const path = createPathData(points);
      setRadarPath(path);
      setRadarPoints(points);
    }
    else {
      setRadarPath('');
      setRadarPoints([]);
    }

  }, [selectedPositionGroupKey, selectedClubIterationId, playerCareer]);


  return (
    <svg className='player-view-svg-plot' viewBox={'0 0 1000 700'} preserveAspectRatio={'xMidYMid meet'}>

      {/* <rect width='1000' height='700' style={{ fill: 'none', stroke: '#ffffff88', strokeWidth: 4 }} /> */}

      {/* lines */}
      <line x1='500' y1='100' x2='500' y2='600' style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
      <line x1='676.78' y1='526.78' x2='323.22' y2='173.22' style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
      <line x1='250' y1='350' x2='750' y2='350' style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
      <line x1='323.22' y1='526.78' x2='676.78' y2='173.22' style={{ stroke: '#ffffff55', strokeWidth: 1 }} />

      {/* circles */}
      <circle cx='500' cy='350' r='240' style={{ fill: '#8c90a100', stroke: '#ffffff66', strokeWidth: 1 }} />
      <circle cx='500' cy='350' r='180' style={{ fill: '#8c90a104', stroke: '#ffffff44' }} />
      <circle cx='500' cy='350' r='120' style={{ fill: '#8c90a103', stroke: '#ffffff33', strokeWidth: 1 }} />
      <circle cx='500' cy='350' r='60' style={{ fill: '#8c90a102', stroke: '#ffffff22', strokeWidth: 1 }} />

      {/* points */}
      {selectedClubIterationId && radarPoints.map(([xPoint, yPoint, metric, xText, yText, textAnchor]) => (
        <g key={metric}>
          <circle cx={xPoint} cy={yPoint} r={4} style={{ fill: clubColor, stroke: 'none' }} />
          <text
            className='radar-plot-stat-text'
            fontSize={32}
            x={xText}
            y={yText}
            textAnchor={textAnchor}
            onClick={() => openTextModal(
              metricToDisplayInfo[metric].name[language],
              metricToDisplayInfo[metric].description[language]
            )}
          >
            {metricToDisplayInfo[metric].name[language]}
            <title>{translate('showDescription', language)}</title>
          </text>
        </g>
      ))}

      {/* radar path */}
      {selectedClubIterationId &&
        <path d={radarPath} style={{ fill: clubColor + '33', stroke: clubColor, strokeWidth: 3 }} />}

    </svg>
  );
};
