import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';
import { ClubMap, ClubsQueryOptions } from '../../../types';

import { APPLICATION_API_URL } from './../constants';
import { getBearerToken } from './../token';


// Get clubs for all given clubIds
export const getClubs = async (
  clubIds: number[],
  user: FirebaseUser
): Promise<ClubMap | undefined> => {

  clubIds = clubIds.filter((clubId) => !isNaN(Number(clubId))).map((clubId) => Number(clubId));

  if (clubIds.length === 0) return undefined;

  const token = await getBearerToken(user);
  const clubIdsString = clubIds.join(',');

  const result = await axios.get(`${APPLICATION_API_URL}/clubs/${clubIdsString}`, {
    headers: { Authorization: token },
  });

  if (result === undefined) {
    return undefined;
  }

  return result.data;
};


// Search for clubs (only name is used currently as query parameter)
export const searchClubs = async (
  user: FirebaseUser,
  options: ClubsQueryOptions
): Promise<ClubMap[] | undefined> => {

  const token = await getBearerToken(user);

  const competitionsString = options.competitions ? options.competitions.join(',') : undefined;
  const countryCodesString = options.countryCodes ? options.countryCodes.join(',') : undefined;

  const result = await axios.get(`${APPLICATION_API_URL}/search/clubs/`, {
    headers: { Authorization: token },
    params: {
      name: options.name,
      competitions: competitionsString,
      country_codes: countryCodesString,
      page: options.page ?? 1,
    },
  });

  if (!result) return undefined;

  return result.data;
};
