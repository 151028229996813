import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


interface ProgressCircleProps {
  size: number;
  progress: number; // 0-100
  color: string;
  trailColor?: string;
  strokeWidth?: number;
  duration?: number; // seconds
  value?: number;
  fontSize?: number;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  size,
  progress,
  color,
  trailColor,
  strokeWidth,
  duration,
  value,
  fontSize
}) => {

  const trailColorToUse = trailColor ?? '#3e4459';
  const strokeWidthToUse = strokeWidth ?? 6;
  const durationToUse = duration ?? 0;
  const fontSizeToUse = fontSize ?? 11;

  return (
    <div
      style={{
        position: 'relative',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
      }}
    >

      <div style={{ position: 'absolute' }}>
        <CircularProgressbar
          value={progress}
          strokeWidth={strokeWidthToUse}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathTransitionDuration: durationToUse,
            pathColor: color,
            trailColor: trailColorToUse,
          })}
        />
      </div>

      {value !== undefined && (
        <div
          style={{
            position: 'absolute',
            textAlign: 'center',
            width: size,
            fontSize: fontSizeToUse,
            top: (size - fontSizeToUse - 2) / 2,
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};
