import './../modals.css';

import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';
import { squadsState } from '../../../recoil/atoms/squadsState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { navigationPropsState } from '../../../recoil/atoms/navigationPropsState';
import { useSetAndTrackNavigationPropsState } from '../../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';
import { playerSquadDataSelector } from '../../../recoil/selectors/playerSquadDataSelector';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import {
  addPlayerToSquad,
  addPlayerToTeam,
  changePlayerPositionInTeamOrSquad,
  removePlayerFromAllTeams,
  removePlayerFromTeamOrSquad,
} from '../../../services/firestore/teamsOrSquads';

import { translate } from '../../../../common/language/translations';
import { DropDownSelect } from '../../input/DropDownSelect';
import { formationToPositionOptions } from '../../../static/propertyValues';
import { addPlayerWithoutData, deleteOwnTeamAndAcademyPlayerData, deletePlayerDocument, setPlayerProperty } from '../../../services/firestore/players';
import { NavigationProps, PlayerEntry, PlayerId } from '../../../types';
import { userHasFullAccess } from '../../../utils/userUtils';


interface PlayerViewModalProps {
  closeModal: (feedback?: boolean) => void;
  playerId: PlayerId;
  playerName: string;
  squadId: string | undefined;
  showPlayerNameInTitle?: boolean;
}

export const PlayerViewModal: React.FC<PlayerViewModalProps> = ({
  closeModal,
  playerId,
  playerName,
  squadId,
  showPlayerNameInTitle,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const navigationProps = useRecoilValue(navigationPropsState);
  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();
  const { openConfirmModal } = useOpenGlobalModal();

  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  const playerSquadData = useRecoilValue(playerSquadDataSelector) ?? {};

  const currentTeam = playerTeamData && playerId in playerTeamData
    ? playerTeamData[playerId].currentTeam
    : undefined;

  const currentTeamPosition = !squadId && playerTeamData && playerId in playerTeamData
    ? playerTeamData[playerId].currentPosition
    : undefined;

  const currentSquadPosition = squadId && playerSquadData && playerId in playerSquadData
    ? playerSquadData[playerId][squadId]
    : undefined;

  const [selectedPosition, setSelectedPosition] = useState<string[]>(
    currentSquadPosition
      ? [currentSquadPosition]
      : currentTeamPosition ? [currentTeamPosition] : []);

  const [selectedTeam, setSelectedTeam] = useState<string[]>(currentTeam ? [currentTeam] : []);

  const [isPositionDropDownExpanded, setIsPositionDropDownExpanded] = useState(false);
  const [isTeamDropDownExpanded, setIsTeamDropDownExpanded] = useState(false);


  const [formationOfSelectedTeamOrSquad, setFormationOfSelectedTeamOrSquad] = useState<string | undefined>(
    squadId
      ? (squads && squadId in squads ? squads[squadId]['formation'] : undefined)
      : (teams && currentTeam && currentTeam in teams ? teams[currentTeam]['formation'] : undefined)
  );

  const [positionOptions, setPositionOptions] = useState<string[]>(
    formationOfSelectedTeamOrSquad ? formationToPositionOptions[formationOfSelectedTeamOrSquad] : []
  );


  useEffect(() => {
    if (!squadId && teams && selectedTeam.length > 0) {
      setFormationOfSelectedTeamOrSquad(
        teams && selectedTeam[0] && selectedTeam[0] in teams ? teams[selectedTeam[0]]['formation'] : undefined
      );
    }
  }, [selectedTeam, squadId, teams]);


  useEffect(() => {
    if (formationOfSelectedTeamOrSquad) {
      setPositionOptions(formationToPositionOptions[formationOfSelectedTeamOrSquad]);

      if (selectedPosition.length > 0 && !formationToPositionOptions[formationOfSelectedTeamOrSquad].includes(selectedPosition[0])) {
        setSelectedPosition([]);
      }
    }
  }, [formationOfSelectedTeamOrSquad]); // eslint-disable-line react-hooks/exhaustive-deps


  const removeDropDownExpansions = () => {
    setIsPositionDropDownExpanded(false);
    setIsTeamDropDownExpanded(false);
  };


  const closeModalAndResetSlections = (feedback: boolean, clauseModalTypeToOpen: string | undefined) => {
    closeModal(feedback);

    // There's a small delay before the modal is closed
    setTimeout(() => {
      setSelectedTeam([]);
      setSelectedPosition([]);

      if (clauseModalTypeToOpen) {
        handleOpenAddClauseModal(clauseModalTypeToOpen);
      }
    }, 250);
  };


  // will not be called if already on the economy tab
  const confirmAddClause = (wasAdded: boolean) => {
    const newNavigationProps: NavigationProps = {
      activeTab: 'economy',
      activeSubTab: wasAdded ? 1 : 0,
      selectedPlayerId: playerId,
    };
    setAndTrackNavigationProps(newNavigationProps);
    setSelectedPlayer(undefined);
  };


  const handleOpenAddClauseModal = (handleOpenAddClauseModal: string) => {
    if (navigationProps && navigationProps.activeTab === 'economy') return;

    const isBoughtPlayer = handleOpenAddClauseModal === 'boughtPlayer';

    openConfirmModal(
      () => confirmAddClause(isBoughtPlayer),
      'addClause?',
      isBoughtPlayer ? 'addClauseForBoughtPlayerInfo' : 'addClauseForSoldPlayerInfo',
      undefined,
      undefined,
      undefined,
      undefined,
      50,
    );
  };


  const handleRemovePlayerFromOwnTeamOrAcademyTeam = (playerId: PlayerId) => {
    if (!userConfig) return;

    // if this is a player in the database, we delete the player document
    if (!isNaN(Number(playerId))) {
      deletePlayerDocument(playerId, userConfig.club);
    }

    // if this is a player without data, we must only delete the ownTeam and academyTeam player data
    else {
      deleteOwnTeamAndAcademyPlayerData(playerId, userConfig.club);
    }
  };


  const handleAddOrEditPlayer = async (modalType: string) => {
    // modalType is one of [team, newPlayer, squad]

    if (!userConfig || !userConfig || !teams || !squads) return;

    let clauseModalTypeToOpen: string | undefined = undefined;

    let newPlayerId: PlayerId | undefined = playerId;
    if (playerId === 'newPlayerWithoutData') {
      newPlayerId = await addPlayerWithoutData(playerName, userConfig.club);
    }

    if (newPlayerId === undefined) return;

    const playerData: PlayerEntry = {
      id: newPlayerId,
      fullname: playerName,
    };

    if (modalType === 'newPlayer' || modalType === 'team') {
      const newTeamId = selectedTeam[0];

      if (newTeamId === currentTeam) {
        changePlayerPositionInTeamOrSquad(newPlayerId, newTeamId, selectedPosition[0], userConfig.club, false, currentTeamPosition);
      }

      else {
        addPlayerToTeam(playerData, selectedPosition[0], newTeamId, teams[newTeamId]['name'], userConfig.email, userConfig.club);

        // if player is added to ownTeam, modal to add clause be opened unless player is moved from academyTeam
        if (newTeamId === 'ownTeam' && currentTeam !== 'academyTeam' && !isNaN(Number(newPlayerId))) {
          clauseModalTypeToOpen = 'boughtPlayer';
        }

        // if player is removed from ownTeam, modal to add clause for the removed player will be opened
        if (currentTeam === 'ownTeam' && !isNaN(Number(newPlayerId))) {
          clauseModalTypeToOpen = 'soldPlayer';
        }

        // if player is moved from ownTeam or academyTeam, we need to reset parts or all of the player table data
        if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {

          // if player is moved between ownTeam and academyTeam, we only reset the role
          if (newTeamId === 'academyTeam' || newTeamId === 'ownTeam') {
            setPlayerProperty(newPlayerId, 'role', null, userConfig.club);
          }

          // if the player moves to another team, we reset the player table data
          else {
            handleRemovePlayerFromOwnTeamOrAcademyTeam(newPlayerId);
          }
        }
      }
    }

    else if (modalType === 'squad' && squadId) {
      if (newPlayerId in playerSquadData && squadId in playerSquadData[newPlayerId]) {
        changePlayerPositionInTeamOrSquad(newPlayerId, squadId, selectedPosition[0], userConfig.club, true, currentSquadPosition);
      }
      else {
        addPlayerToSquad(playerData, selectedPosition[0], squadId, userConfig.email, userConfig.club);
      }
    }

    closeModalAndResetSlections(true, clauseModalTypeToOpen);
  };


  const handleRemovePlayer = () => {
    if (!userConfig) return;

    let clauseModalTypeToOpen: string | undefined = undefined;

    if (squadId) {
      removePlayerFromTeamOrSquad(playerId, squadId, true, userConfig.email, userConfig.club);
    }
    else if (currentTeam) {
      removePlayerFromTeamOrSquad(playerId, currentTeam, false, userConfig.email, userConfig.club);

      if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {
        handleRemovePlayerFromOwnTeamOrAcademyTeam(playerId);

        if (currentTeam === 'ownTeam' && !isNaN(Number(playerId))) {
          clauseModalTypeToOpen = 'soldPlayer';
        }
      }
    }
    else {
      // should not reach this path
      console.log('Player without a current team was attempted to be removed from team: ', currentTeam); // eslint-disable-line no-console
      removePlayerFromAllTeams(playerId, userConfig.club);
    }

    setSelectedPlayer(undefined);
    closeModalAndResetSlections(false, clauseModalTypeToOpen);
  };


  const getModalTitle = () => {
    if (!userConfig) return '';

    if (showPlayerNameInTitle) return playerName;

    if (squadId) {
      if (playerId in playerSquadData && squadId in playerSquadData[playerId]) {
        return translate('editSquadStatus', userConfig.language);
      }

      const squadName = squads && squadId in squads ? squads[squadId].name : '';
      return translate('addPlayerTo', userConfig?.language) + ' ' + squadName;
    }

    return translate(currentTeam ? 'editTeamStatus' : 'addToTeam', userConfig.language);
  };


  const hasFullAccess = userHasFullAccess(userConfig);
  const shouldDisableNewTeam = currentTeam === 'ownTeam' && !hasFullAccess;
  const teamsToDisable = hasFullAccess ? [] : ['ownTeam'];


  // There are 5 different views:
  // 1. Player is on a team other than ownTeam and squadKey !== undefined
  // 2. Player is on own team and squadKey === undefined
  // 3. Player is not on any team and squadKey === undefined
  // 4. Add player to own team
  // 5. Edit player in own team


  return (
    <div className='modal-root-container player-view-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      {(isPositionDropDownExpanded || isTeamDropDownExpanded) && (
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()}>
          &nbsp;
        </div>
      )}

      <div className='modal-root-title'>
        {getModalTitle()}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='modal-content-section'>
        {!squadId && currentTeam && (
          <div>

            <div className='player-view-modal-select-row'>
              {!shouldDisableNewTeam && (
                <div className='player-view-modal-select-column'>
                  {translate('selectNewTeam', userConfig?.language)}
                </div>
              )}

              <div
                style={{ margin: shouldDisableNewTeam ? 'auto' : undefined }}
                className='player-view-modal-select-column'
              >
                {translate('selectNewPosition', userConfig?.language)}
              </div>
            </div>

            <div className='player-view-modal-drop-down-row'>
              {!shouldDisableNewTeam && (
                <div className='player-view-modal-select-column'>
                  <DropDownSelect
                    id={'player-view-modal-team-select'}
                    dropDownOptions={teams ? Object.keys(teams) : []}
                    selectedOptions={selectedTeam}
                    setSelectedOptions={setSelectedTeam}
                    isDropDownExpanded={isTeamDropDownExpanded}
                    setIsDropDownExpanded={setIsTeamDropDownExpanded}
                    defaultDropDownText={'selectTeam'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    maxHeight={'56vh'}
                    disabledOptions={teamsToDisable}
                  />
                </div>
              )}

              <div
                style={{ margin: shouldDisableNewTeam ? 'auto' : undefined }}
                className='player-view-modal-select-column'>
                <DropDownSelect
                  id={'player-view-modal-position-select'}
                  dropDownOptions={positionOptions}
                  selectedOptions={selectedPosition}
                  setSelectedOptions={setSelectedPosition}
                  isDropDownExpanded={isPositionDropDownExpanded}
                  setIsDropDownExpanded={setIsPositionDropDownExpanded}
                  defaultDropDownText={'selectPosition'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'56vh'}
                />
              </div>
            </div>

            <div
              className={'modal-button modal-button-top' + (
                selectedTeam.length > 0 && selectedPosition.length > 0 && (selectedTeam[0] !== currentTeam || selectedPosition[0] !== currentTeamPosition)
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedTeam.length > 0 && selectedPosition.length > 0 && (selectedTeam[0] !== currentTeam || selectedPosition[0] !== currentTeamPosition)
                  ? handleAddOrEditPlayer('team')
                  : null
              )}>
              <div className='modal-button-text'>
                {translate('movePlayer', userConfig?.language)}
              </div>

              <ArrowRightAltIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {!squadId && !currentTeam && (
          <div className='player-view-modal-select-section'>

            <div className='player-view-modal-select-row'>
              <div className='player-view-modal-select-column'>
                {translate('selectTeam', userConfig?.language)}
              </div>
              <div className='player-view-modal-select-column'>
                {selectedTeam.length > 0 ? translate('selectPosition', userConfig?.language) : ''}
              </div>
            </div>

            <div className='player-view-modal-drop-down-row'>
              <div className='player-view-modal-select-column'>
                <DropDownSelect
                  id={'player-view-modal-team-select'}
                  dropDownOptions={teams ? Object.keys(teams) : []}
                  selectedOptions={selectedTeam}
                  setSelectedOptions={setSelectedTeam}
                  isDropDownExpanded={isTeamDropDownExpanded}
                  setIsDropDownExpanded={setIsTeamDropDownExpanded}
                  defaultDropDownText={'selectTeam'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'56vh'}
                  disabledOptions={teamsToDisable}
                />
              </div>

              {selectedTeam.length > 0 && (
                <div className='player-view-modal-select-column'>
                  <DropDownSelect
                    id={'player-view-modal-position-select'}
                    dropDownOptions={positionOptions}
                    selectedOptions={selectedPosition}
                    setSelectedOptions={setSelectedPosition}
                    isDropDownExpanded={isPositionDropDownExpanded}
                    setIsDropDownExpanded={setIsPositionDropDownExpanded}
                    defaultDropDownText={'selectPosition'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    maxHeight={'56vh'}
                  />
                </div>
              )}
            </div>

            <div
              className={'modal-button modal-button-middle' + (
                selectedTeam.length > 0 && selectedPosition.length > 0
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedTeam.length > 0 && selectedPosition.length > 0
                  ? handleAddOrEditPlayer('newPlayer')
                  : null
              )}>
              <div className='modal-button-text'>
                {translate('addPlayer', userConfig?.language)}
              </div>

              <AddIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {squadId && (
          <div className='player-view-modal-select-section'>

            <div className='modal-section-title'>
              {translate(playerId in playerSquadData && squadId in playerSquadData[playerId] ? 'selectNewPosition' : 'selectPosition', userConfig?.language)}
            </div>

            <div className='player-view-modal-squad-position-select'>
              <DropDownSelect
                id={'player-view-modal-squad-position-select'}
                dropDownOptions={positionOptions}
                selectedOptions={selectedPosition}
                setSelectedOptions={setSelectedPosition}
                isDropDownExpanded={isPositionDropDownExpanded}
                setIsDropDownExpanded={setIsPositionDropDownExpanded}
                defaultDropDownText={'selectPosition'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'56vh'}
              />
            </div>

            <div
              className={'modal-button modal-button-top' + (
                selectedPosition.length > 0 && selectedPosition[0] !== currentSquadPosition
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedPosition.length > 0 && selectedPosition[0] !== currentSquadPosition
                  ? handleAddOrEditPlayer('squad')
                  : null
              )}>
              <div className='modal-button-text'>
                {translate(playerId in playerSquadData && squadId in playerSquadData[playerId] ? 'changePosition' : 'addToSquad', userConfig?.language)}
              </div>

              <ArrowRightAltIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {(
          (currentTeam && squadId === undefined) ||
          (squadId && playerSquadData && playerId in playerSquadData && squadId in playerSquadData[playerId])
        ) &&
          (
            <div>
              <div className='modal-divider modal-bottom-divider'>&nbsp;</div>
              <div
                className={'modal-button modal-button-bottom' + (shouldDisableNewTeam ? ' modal-button-disabled' : '')}
                style={{ marginLeft: 150 }}
                onClick={() => !shouldDisableNewTeam ? handleRemovePlayer() : null}
              >
                <div className='modal-button-text'>
                  {translate(squadId ? 'removePlayerFromSquad' : 'removePlayer', userConfig?.language)}
                </div>

                <PersonRemoveIcon
                  className='modal-button-icon'
                  style={{ fontSize: 24 }}
                />
              </div>
            </div>
          )
        }
      </div>

    </div>
  );
};
