import '../../../platform.css';
import './../scouting.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useTrackEvent } from '../../../services/server/analytics/useTrackEvent';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '../../../../common/language/translations';
import { SearchPlayerOverviewsResponse, searchPlayerOverviews } from '../../../services/server/application/playerOverviews';
import { Club, PlayerOverview, PlayerOverviewsQueryOptions } from '../../../types';
import { PlayerTable } from '../../../components/tables/playerTable/PlayerTable';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { debounce, stringArraysContainSameElements } from '../../../utils/utils';
import { PlayerScoutingFilters } from './PlayerScoutingFilters';
import { contractKeysToMonths } from '../../../static/propertyValues';
import { playerTableChapterSize, playerTablePageSize } from '../Scouting';


export const PlayerScouting: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const trackEvent = useTrackEvent();

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const [tableData, setTableData] = useState<PlayerOverview[]>([]);

  // currentModuloPage equals page % pagesPerChapter and is 1-indexed, while chapters are 0-indexed
  const [currentModuloPage, setCurrentModuloPage] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [nameSearchString, setNameSearchString] = useState('');
  const [debouncedNameSearchString, setDebouncedNameSearchString] = useState('');
  const clearNameDebounceRef = useRef<() => void>(() => null);

  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedMinConfidence, setSelectedMinConfidence] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedLeagues, setSelectedLeagues] = useState<string[]>([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState<string[]>(['skillIndex']);

  const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);
  const [selectedForm, setSelectedForm] = useState<string[]>([]);
  const [selectedPlayingTime, setSelectedPlayingTime] = useState<string[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<string[]>([]);
  const [isContractStatusToggled, setIsContractStatusToggled] = useState(false);

  const [currentNameSearchString, setCurrentNameSearchString] = useState<string>('');
  const [currentMinConfidence, setCurrentMinConfidence] = useState<string[]>([]);
  const [currentMaxAge, setCurrentMaxAge] = useState<string[]>([]);
  const [currentPositions, setCurrentPositions] = useState<string[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [currentLeagues, setCurrentLeagues] = useState<string[]>([]);
  const [currentOrderBy, setCurrentOrderBy] = useState<string[]>([]);

  const [currentClubs, setCurrentClubs] = useState<Club[]>([]);
  const [currentForm, setCurrentForm] = useState<string[]>([]);
  const [currentPlayingTime, setCurrentPlayingTime] = useState<string[]>([]);
  const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
  const [currentContractStatus, setCurrentContractStatus] = useState<string[]>([]);
  const [currentIsContractStatusToggled, setCurrentIsContractStatusToggled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  const [isAnyFilterExpanded, setIsAnyFilterExpanded] = useState(false);
  const [isAdvancedFiltersExpanded, setIsAdvancedFiltersExpanded] = useState(false);
  const [scoutingViewResultContainerTop, setScoutingViewResultContainerTop] = useState(85);

  const [searchWasLoaded, setSearchWasLoaded] = useState(false);


  const anyFilterExceptNameAndDefaultOrderBySelected = () => {
    const otherOrderByThanDefaultSelected = selectedOrderBy.length > 0 && selectedOrderBy[0] !== 'skillIndex';
    return (
      selectedMaxAge.length > 0
      || selectedPositions.length > 0
      || selectedRoles.length > 0
      || selectedLeagues.length > 0
      || otherOrderByThanDefaultSelected

      || selectedClubs.length > 0
      || selectedMinConfidence.length > 0
      || selectedForm.length > 0
      || selectedPlayingTime.length > 0
      || selectedNationalities.length > 0
      || selectedContractStatus.length > 0
    );
  };


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(true, true);
    }
  };


  const setCurrentFilters = (reset: boolean, newOverriddenOrderBy?: string | undefined) => {
    setCurrentNameSearchString(reset ? '' : nameSearchString);
    setDebouncedNameSearchString(reset ? '' : debouncedNameSearchString);
    setCurrentMaxAge(reset ? [] : selectedMaxAge);
    setCurrentPositions(reset ? [] : selectedPositions);
    setCurrentRoles(reset ? [] : selectedRoles);
    setCurrentLeagues(reset ? [] : selectedLeagues);
    setCurrentOrderBy(reset ? [] : newOverriddenOrderBy ? [newOverriddenOrderBy] : selectedOrderBy);

    setCurrentClubs(reset ? [] : selectedClubs);
    setCurrentMinConfidence(reset ? [] : selectedMinConfidence);
    setCurrentForm(reset ? [] : selectedForm);
    setCurrentPlayingTime(reset ? [] : selectedPlayingTime);
    setCurrentNationalities(reset ? [] : selectedNationalities);
    setCurrentContractStatus(reset ? [] : selectedContractStatus);
    setCurrentIsContractStatusToggled(isContractStatusToggled);
  };


  const handleTrackEvent = (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    searchQueryOptions: PlayerOverviewsQueryOptions,
  ) => {

    const type = searchQueryOptions.name
      ? anyFilterExceptNameAndDefaultOrderBySelected()
        ? 'nameAndFilterSearch'
        : 'nameSearch'
      : 'filterSearch';

    const initiator = nextChapter
      ? 'pagination'
      : !isNewSearch
        ? 'scroll'
        : isUserInitiated
          ? 'user'
          : 'changes';

    // name, orderBy and includeUnknownContracts are not included in the filtersUsed array
    // positions is only counted if either no roles are selected or not all positions of the selected positionGroup are selected
    const filtersUsed: string[] = [];
    if (searchQueryOptions.minConfidence) { filtersUsed.push('minConfidence'); }
    if (searchQueryOptions.maxAge) { filtersUsed.push('maxAge'); }
    if (searchQueryOptions.positionGroup) { filtersUsed.push('roles'); }
    if (searchQueryOptions.primaryPositions) { filtersUsed.push('primaryPositions'); }
    if (searchQueryOptions.competitions) { filtersUsed.push('leagues'); }
    if (searchQueryOptions.clubs) { filtersUsed.push('clubs'); }
    if (searchQueryOptions.form) { filtersUsed.push('form'); }
    if (searchQueryOptions.playingTime) { filtersUsed.push('playingTime'); }
    if (searchQueryOptions.countryCodes) { filtersUsed.push('nationalities'); }
    if (searchQueryOptions.maxContractLength) { filtersUsed.push('maxContract'); }

    if (filtersUsed.length === 0) { filtersUsed.push('noFilters'); }

    trackEvent('PlayerSearch', {
      type: type,
      initiator: initiator,
      isNewSearch: isNewSearch,
      filtersUsed: filtersUsed,

      name: searchQueryOptions.name,
      minConfidence: searchQueryOptions.minConfidence ? (searchQueryOptions.minConfidence * 100) : undefined,
      maxAge: searchQueryOptions.maxAge,
      primaryPositions: searchQueryOptions.primaryPositions,
      positionGroup: searchQueryOptions.positionGroup,
      leagues: searchQueryOptions.competitions,

      clubs: searchQueryOptions.clubs,
      form: searchQueryOptions.form,
      playingTime: searchQueryOptions.playingTime,
      formAndPlayingTime: (searchQueryOptions.form ?? []).concat(searchQueryOptions.playingTime ?? []),
      countryCodes: searchQueryOptions.countryCodes,
      maxContractLength: searchQueryOptions.maxContractLength,
      includeUnknownContracts: searchQueryOptions.includeUnknownContracts ?? false,

      sortBy: searchQueryOptions.sortBy && clubSettings?.roleConfigs[searchQueryOptions.sortBy]
        ? 'role_index'
        : searchQueryOptions.sortBy,
    });
  };


  const handleSearchButtonPressed = async (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter?: number,
    newOverriddenOrderBy?: string | undefined,
  ) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearNameDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * playerTableChapterSize) + 1)
        : ((currentChapter * playerTableChapterSize) + (currentModuloPage + 1)));

    const sortBy = newOverriddenOrderBy ??
      (nameSearchString.length > 0
        ? undefined
        : selectedOrderBy.length > 0
          ? selectedOrderBy[0]
          : undefined);

    const positionGroupOfSelectedRoles = selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : null;

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchString.length > 0 ? nameSearchString.toLowerCase() : undefined,
      minConfidence: selectedMinConfidence.length > 0 ? (parseInt(selectedMinConfidence[0]) / 100) : undefined,
      maxAge: selectedMaxAge.length > 0 ? parseInt(selectedMaxAge[0]) : undefined,
      primaryPositions: selectedPositions.length > 0 ? selectedPositions : undefined,
      positionGroup: positionGroupOfSelectedRoles,
      competitions: selectedLeagues.length > 0 ? selectedLeagues.map(league => parseInt(league)) : undefined,
      sortBy: sortBy,

      clubs: selectedClubs.length > 0 ? selectedClubs.map(club => club.id) : undefined,
      form: selectedForm.length > 0 ? selectedForm : undefined,
      playingTime: selectedPlayingTime.length > 0 ? selectedPlayingTime : undefined,
      countryCodes: selectedNationalities.length > 0 ? selectedNationalities : undefined,
      maxContractLength: selectedContractStatus.length > 0 ? contractKeysToMonths[selectedContractStatus[0]] : undefined,
      includeUnknownContracts: isContractStatusToggled,

      page: nextPageToQuery,
      pageSize: playerTablePageSize,
    };

    try {
      handleTrackEvent(isUserInitiated, isNewSearch, nextChapter, queryOptions);
      const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(queryOptions, currentUser);

      const totalHits = result?.total_hits;
      const page = result?.current_page;
      const players: PlayerOverview[] = result?.players ?? [];

      if (!result || totalHits === undefined || page === undefined) {
        trackEvent('PlayerSearchError', queryOptions);
        handleSearchReset();
        setIsLoading(false);
        return;
      }

      if (isNewSearch) { trackEvent('PlayerSearchSuccessResult', { totalHits: totalHits }); }
      setCurrentFilters(false, newOverriddenOrderBy);

      if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
        setEmptySearchData();
        setIsEmptySearchResult(true);
      }
      else {
        const isFirstPageOfChapter = isNewSearch || nextChapter !== undefined;
        setTableData(isFirstPageOfChapter ? players : [...tableData, ...players]);
        setTotalHits(totalHits);
        setCurrentModuloPage(isFirstPageOfChapter ? 1 : (page - (currentChapter * 4)));
        setCurrentChapter(isNewSearch ? 0 : (nextChapter !== undefined ? nextChapter : currentChapter));
        setIsEmptySearchResult(false);
      }
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    setIsLoading(false);
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    setTableData([]);
    const nextChapter = isIncrement ? currentChapter + 1 : currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  const handleSearchReset = () => {
    setEmptySearchData();
    setCurrentFilters(true);
    setIsEmptySearchResult(false);
  };


  const setEmptySearchData = () => {
    setTableData([]);
    setCurrentModuloPage(0);
    setCurrentChapter(0);
    setTotalHits(0);
  };


  const handleAdvancedFiltersExpansion = () => {
    if (isAdvancedFiltersExpanded) {
      setScoutingViewResultContainerTop(85);
      setIsAdvancedFiltersExpanded(false);
    }
    else {
      setScoutingViewResultContainerTop(155);
      setIsAdvancedFiltersExpanded(true);
    }
  };


  const handleFilterUnexpansion = (filter: string) => {
    if (tableData.length === 0 && !isEmptySearchResult) return;

    let shouldTriggerSearch = false;
    let rolesHaveChanged = false;

    switch (filter) {
      case 'positionsAndRoles':
        rolesHaveChanged = !stringArraysContainSameElements(currentRoles, selectedRoles);
        shouldTriggerSearch = rolesHaveChanged || !stringArraysContainSameElements(currentPositions, selectedPositions);
        break;
      case 'maxAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMaxAge, selectedMaxAge);
        break;
      case 'leagues':
        shouldTriggerSearch = !stringArraysContainSameElements(currentLeagues, selectedLeagues);
        break;
      case 'orderBy':
        shouldTriggerSearch = !stringArraysContainSameElements(currentOrderBy, selectedOrderBy);
        break;
      case 'clubs':
        shouldTriggerSearch = !stringArraysContainSameElements(currentClubs.map(club => String(club.id)), selectedClubs.map(club => String(club.id)));
        break;
      case 'minConfidence':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinConfidence, selectedMinConfidence);
        break;
      case 'form':
        shouldTriggerSearch = !stringArraysContainSameElements(currentForm, selectedForm);
        break;
      case 'playingTime':
        shouldTriggerSearch = !stringArraysContainSameElements(currentPlayingTime, selectedPlayingTime);
        break;
      case 'nationalities':
        shouldTriggerSearch = !stringArraysContainSameElements(currentNationalities, selectedNationalities);
        break;
      case 'contractStatus':
        shouldTriggerSearch = !stringArraysContainSameElements(currentContractStatus, selectedContractStatus)
          || currentIsContractStatusToggled !== isContractStatusToggled;
        break;
    }

    let newOverriddenOrderBy: string | undefined = undefined;

    // some changes to the selected roles will automatically set the orderBy
    if (rolesHaveChanged) {

      // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
      // we only want this if there are currently no selected roles - if there are, and the orderBy is not a role, the orderBy was manually set
      if (currentRoles.length === 0 && selectedRoles.length > 0 && (selectedOrderBy.length === 0 || selectedOrderBy[0] === 'skillIndex')) {
        const lastSelectedRole = selectedRoles[selectedRoles.length - 1];
        newOverriddenOrderBy = lastSelectedRole;
      }

      // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
      else if (selectedOrderBy.length > 0 && clubSettings?.roleConfigs[selectedOrderBy[0]] && !selectedRoles.includes(selectedOrderBy[0])) {
        if (selectedRoles.length > 0) {
          newOverriddenOrderBy = selectedRoles[0];
        }
        else {
          newOverriddenOrderBy = 'skillIndex';
        }
      }
    }

    if (newOverriddenOrderBy) {
      setSelectedOrderBy([newOverriddenOrderBy]);
    }

    if (shouldTriggerSearch) {
      handleSearchButtonPressed(false, true, undefined, newOverriddenOrderBy);
    }
  };


  // if search is not active, some changes to the selected roles will automatically set the orderBy
  useEffect(() => {
    if (tableData.length > 0 || isEmptySearchResult) return;

    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (selectedRoles.length > 0 && (selectedOrderBy.length === 0 || selectedOrderBy[0] === 'skillIndex')) {
      const lastSelectedRole = selectedRoles[selectedRoles.length - 1];
      setSelectedOrderBy([lastSelectedRole]);
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (selectedOrderBy.length > 0 && clubSettings?.roleConfigs[selectedOrderBy[0]] && !selectedRoles.includes(selectedOrderBy[0])) {
      if (selectedRoles.length > 0) {
        setSelectedOrderBy([selectedRoles[0]]);
      }
      else {
        setSelectedOrderBy(['skillIndex']);
      }
    }
  }, [selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchWasLoaded) {
      handleSearchButtonPressed(true, true);
    }
    setSearchWasLoaded(false);
  }, [searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (nameSearchString.length === 0) {
      if (anyFilterExceptNameAndDefaultOrderBySelected()) {
        handleSearchButtonPressed(false, true);
      }
      else {
        handleSearchReset();
      }
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (nameSearchString !== debouncedNameSearchString) {
        setDebouncedNameSearchString(nameSearchString);
      }
    }, 500);

    debounceFilter();

    clearNameDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedNameSearchString !== '' && debouncedNameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(false, true);
    }
  }, [debouncedNameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='scouting-view-content-container'>

      {isAnyFilterExpanded && <div className='filter-section-empty-background' onClick={() => setIsAnyFilterExpanded(false)} />}

      <PlayerScoutingFilters
        nameSearchString={nameSearchString}
        setNameSearchString={setNameSearchString}
        onKeyDownNameSearchField={onKeyDownNameSearchField}

        selectedMaxAge={selectedMaxAge}
        setSelectedMaxAge={setSelectedMaxAge}
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        selectedLeagues={selectedLeagues}
        setSelectedLeagues={setSelectedLeagues}
        selectedOrderBy={selectedOrderBy}
        setSelectedOrderBy={setSelectedOrderBy}

        selectedClubs={selectedClubs}
        setSelectedClubs={setSelectedClubs}
        selectedMinConfidence={selectedMinConfidence}
        setSelectedMinConfidence={setSelectedMinConfidence}
        selectedForm={selectedForm}
        setSelectedForm={setSelectedForm}
        selectedPlayingTime={selectedPlayingTime}
        setSelectedPlayingTime={setSelectedPlayingTime}
        selectedNationalities={selectedNationalities}
        setSelectedNationalities={setSelectedNationalities}
        selectedContractStatus={selectedContractStatus}
        setSelectedContractStatus={setSelectedContractStatus}
        isContractStatusToggled={isContractStatusToggled}
        setIsContractStatusToggled={setIsContractStatusToggled}

        isAnyFilterExpanded={isAnyFilterExpanded}
        setIsAnyFilterExpanded={setIsAnyFilterExpanded}
        isAdvancedFiltersExpanded={isAdvancedFiltersExpanded}
        handleAdvancedFiltersExpansion={handleAdvancedFiltersExpansion}

        handleFilterUnexpansion={handleFilterUnexpansion}
        handleSearchReset={handleSearchReset}
        setSearchWasLoaded={setSearchWasLoaded}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: scoutingViewResultContainerTop }}
      >
        {tableData.length > 0 && (
          <PlayerTable
            data={tableData}
            isLoading={isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={currentModuloPage}
            currentChapter={currentChapter}
            totalHits={totalHits}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={selectedOrderBy.length > 0 ? selectedOrderBy[0] : undefined}
            selectedRoles={selectedRoles}
          />
        )}

        {tableData.length === 0 && isEmptySearchResult && !isLoading && (
          <div className='scouting-info-title fade-in'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {tableData.length === 0 && !isEmptySearchResult && !isLoading && (
          <div className='scouting-info-button' onClick={() => handleSearchButtonPressed(true, true)}>
            <div className='scouting-info-button-text'>
              {translate('searchForPlayers', userConfig?.language)}
            </div>
            <div className='scouting-info-button-icon'>
              <SearchIcon style={{ fontSize: 24, marginLeft: 20, marginTop: 8 }} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
