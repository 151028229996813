import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../../common/language/translations';
import { PlayerHistory } from '../../history/PlayerHistory';
import { StringToAnyMap } from '../../../types';


interface PlayerHistoryModalProps {
  history: StringToAnyMap[];
  closeModal: () => void;
}

export const PlayerHistoryModal: React.FC<PlayerHistoryModalProps> = ({ history, closeModal }) => {

  const userConfig = useRecoilValue(userConfigState);

  return (
    <div className='modal-root-container player-history-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {translate('playerHistory', userConfig?.language)}
      </div>

      {history.length === 0 && <div className='modal-divider modal-root-title-divider'>&nbsp;</div>}

      {history.length > 0 && (
        <div className='player-history-modal-history-container'>
          <PlayerHistory
            history={history}
          />
        </div>
      )}

      {history.length === 0 && (
        <div className='player-history-modal-no-history'>
          {translate('noHistory', userConfig?.language)}
        </div>
      )}

    </div>
  );
};
