import './input.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BuildIcon from '@mui/icons-material/Build';

import { translate } from '../../../common/language/translations';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { positionGroupToPositions } from '../../static/propertyValues';


interface PositionAndRoleDropDownProps {
  id: string;

  selectedPositions: string[];
  setSelectedPositions: (value: string[]) => void;
  selectedRoles: string[];
  setSelectedRoles: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  maxHeight?: string;
  height?: number;
}

export const PositionAndRoleDropDown: React.FC<PositionAndRoleDropDownProps> = ({
  id,

  selectedPositions,
  setSelectedPositions,
  selectedRoles,
  setSelectedRoles,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  maxHeight,
  height,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const positionGroupToRoleIds = clubSettings?.positionGroupToRoleIds ?? {};
  const roleConfigs = clubSettings?.roleConfigs ?? {};


  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleSelectPosition = (position: string, isOptionAlreadySelected: boolean) => {

    const newSelectedPositions = selectedPositions.slice();

    if (isOptionAlreadySelected) {
      const i = newSelectedPositions.indexOf(position);
      newSelectedPositions.splice(i, 1);

      // if the goalkeeper position is deselected, we automatically deselect all roles
      if (position === 'GK') {
        setSelectedRoles([]);
      }
    }
    else {
      newSelectedPositions.push(position);
    }

    setSelectedPositions(newSelectedPositions);
  };


  const handleSelectRole = (roleId: string, isOptionAlreadySelected: boolean, positionGroup: string) => {

    const newSelectedRoles = selectedRoles.slice();

    if (isOptionAlreadySelected) {
      const i = newSelectedRoles.indexOf(roleId);
      newSelectedRoles.splice(i, 1);

      // if the last role within the goalkeeper group is deselected, we automatically deselect the GK position
      if (positionGroup === 'GK') {
        const rolesWithinPositionGroup = positionGroupToRoleIds[positionGroup] ?? [];
        const allRolesWithinGroupAreDeselected = rolesWithinPositionGroup.every(roleId => !newSelectedRoles.includes(roleId));
        if (allRolesWithinGroupAreDeselected) {
          setSelectedPositions([]);
        }
      }
    }
    else {
      newSelectedRoles.push(roleId);

      // if a goalkeeper role is selected, we automatically select the GK position
      if (positionGroup === 'GK') {
        setSelectedPositions(['GK']);
      }
    }

    const newSelectedRolesSorted: string[] = [];
    positionGroupToRoleIds[positionGroup]?.forEach(roleId => {
      if (newSelectedRoles.includes(roleId)) {
        newSelectedRolesSorted.push(roleId);
      }
    });

    setSelectedRoles(newSelectedRolesSorted);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = height ? height + 'px' : '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const showSelectedOptions = () => {
    if (!userConfig) return '';

    const selectedPositionsString = selectedPositions
      .map(option => translate(option, userConfig.language))
      .join(', ');

    const selectedRolesString = selectedRoles
      .filter(roleId => roleId in roleConfigs)
      .map(roleId => roleConfigs[roleId].name)
      .join(', ');

    // we first show the selected positions, then a divider, then the selected roles
    return (
      <div className='flex-row' style={{ color: '#000000' }}>
        {selectedPositionsString}

        {selectedPositionsString.length > 0 && selectedRolesString.length > 0 && (
          <div
            style={{
              height: '100%',
              width: 1,
              backgroundColor: '#000000cc',
              marginRight: 8,
              marginLeft: 8
            }}>
            &nbsp;
          </div>
        )}

        {selectedRolesString}
      </div>
    );
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  const positionGroupsOfSelectedPositions = selectedPositions.length > 0
    ? Object.keys(positionGroupToPositions).filter(positionGroup => {
      const positions = positionGroupToPositions[positionGroup];
      return selectedPositions.some(position => positions.includes(position));
    })
    : [];


  const positionGroupOfSelectedRole = selectedRoles.length > 0
    ? Object.keys(positionGroupToPositions).find(positionGroup => {
      const roles = positionGroupToRoleIds[positionGroup] ?? [];
      return selectedRoles.some(role => roles.includes(role));
    })
    : undefined;


  const positionGroupIndexOfSelectedRole = positionGroupOfSelectedRole
    ? Object.keys(positionGroupToPositions).indexOf(positionGroupOfSelectedRole)
    : undefined;


  const anyOptionIsSelected = selectedPositions.length > 0 || selectedRoles.length > 0;


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || anyOptionIsSelected) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ height: height, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-select-button'
        style={{ height: height }}
        onClick={() => handleDropDownClick()}>

        {anyOptionIsSelected && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {anyOptionIsSelected ? (
            showSelectedOptions()
          ) : (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>

          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div
        className='position-and-role-drop-down-divider'
        style={{ backgroundColor: positionGroupsOfSelectedPositions.length > 1 ? '#5c636f' : undefined }}
      >
        &nbsp;
      </div>

      <div
        ref={dropDownRef}
        className='position-and-role-drop-down-rows'
        style={{ maxHeight: maxHeight }}
      >
        {Object.keys(positionGroupToPositions).map((positionGroup, positionGroupIndex) => {

          // if any role is selected, we highlight the borders surrounding that role-row
          const shouldHighlightBorderColor = positionGroupIndexOfSelectedRole === positionGroupIndex ||
            positionGroupIndexOfSelectedRole === (positionGroupIndex - 1);

          return (
            <div
              className={
                'position-and-role-drop-down-row'
                // + (positionGroupIndexOfSelectedRole === positionGroupIndex
                //   ? ' position-and-role-drop-down-row-selected'
                //   : positionGroupIndexOfSelectedRole !== undefined
                //     ? ' position-and-role-drop-down-row-not-selected'
                //     : '')
              }
              style={{ borderColor: shouldHighlightBorderColor ? '#5c636f' : undefined }}
              key={positionGroup}
            >

              <div className='position-and-role-drop-down-position-column'>
                {positionGroupToPositions[positionGroup].map((position: string, positionIndex: number) => {

                  const positionIsSelected = selectedPositions.includes(position);

                  const goalKeeperIsSelected = selectedPositions.includes('GK');
                  const outfieldPositionIsSelected = selectedPositions.length > 0 && !goalKeeperIsSelected;

                  const positionIsDisabled = (positionGroupOfSelectedRole !== undefined && positionGroupOfSelectedRole !== positionGroup)
                    || (goalKeeperIsSelected && position !== 'GK')
                    || (outfieldPositionIsSelected && position === 'GK');

                  // we remove the border radiues on the top if the previous position within the group is selected
                  // we remove the border radius on the bottom if the next position within the group is selected
                  const previousPositionWithinGroup = positionIndex > 0
                    ? positionGroupToPositions[positionGroup][positionIndex - 1]
                    : undefined;

                  const previousPositionWithinGroupIsSelected = previousPositionWithinGroup !== undefined
                    && selectedPositions.includes(previousPositionWithinGroup);

                  const nextPositionWithinGroup = positionIndex < positionGroupToPositions[positionGroup].length - 1
                    ? positionGroupToPositions[positionGroup][positionIndex + 1]
                    : undefined;

                  const nextPositionWithinGroupIsSelected = nextPositionWithinGroup !== undefined
                    && selectedPositions.includes(nextPositionWithinGroup);

                  const borderTopRadius = previousPositionWithinGroupIsSelected ? '0' : '4px';
                  const borderBottomRadius = nextPositionWithinGroupIsSelected ? '0' : '4px';

                  const borderRadius = positionIsSelected
                    ? borderTopRadius + ' ' + borderTopRadius + ' ' + borderBottomRadius + ' ' + borderBottomRadius
                    : '4px';

                  return (
                    <div
                      style={{ borderRadius: borderRadius }}
                      className={
                        'position-and-role-drop-down-select-option position-and-role-drop-down-position'
                        + (positionIsSelected ? ' drop-down-select-option-selected' : '')
                        + (positionIsDisabled ? ' position-and-role-drop-down-select-option-disabled' : '')
                      }
                      onClick={() => !positionIsDisabled ? handleSelectPosition(position, positionIsSelected) : null}
                      key={position}
                    >
                      {translate(position, userConfig?.language)}
                    </div>
                  );
                })}
              </div>

              <div className='position-and-role-drop-down-roles'>
                {(positionGroupToRoleIds[positionGroup] ?? []).map(roleId => {

                  if (!(roleId in roleConfigs)) return null;

                  const roleIsSelected = selectedRoles.includes(roleId);

                  const roleConfig = roleConfigs[roleId];
                  const roleConfigIsReady = roleConfig
                    && roleConfig.weightsLastUpdated
                    && roleConfig.dataLastUpdated
                    && roleConfig.dataLastUpdated >= roleConfig.weightsLastUpdated;

                  // roles are disabled if not ready, or if positions from multiple groups are selected, or if a role or position from another group is selected
                  const roleIsDisabled = (!roleIsSelected && !roleConfigIsReady)
                    || positionGroupsOfSelectedPositions.length > 1
                    || (positionGroupsOfSelectedPositions.length > 0 && !positionGroupsOfSelectedPositions.includes(positionGroup))
                    || (positionGroupOfSelectedRole !== undefined && positionGroupOfSelectedRole !== positionGroup);

                  return (
                    <div
                      key={roleId}
                      className={
                        'position-and-role-drop-down-select-option position-and-role-drop-down-role'
                        + (roleIsSelected ? ' drop-down-select-option-selected' : '')
                        + (roleIsDisabled ? ' position-and-role-drop-down-select-option-disabled' : '')
                      }
                      onClick={() => !roleIsDisabled ? handleSelectRole(roleId, roleIsSelected, positionGroup) : null}
                    >
                      {!roleConfigIsReady && (
                        <BuildIcon style={{ fontSize: 12, marginTop: 1, marginRight: 3 }} />
                      )}
                      {roleConfigs[roleId].name}
                    </div>
                  );
                })
                }
              </div>

            </div>
          );
        })}

      </div>

    </div >
  );
};
