import '../tables.css';

import { useRecoilValue } from 'recoil';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
// import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
// import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';

import { CellProps } from 'react-table';
import { StringToAnyMap, RoleConfigMap, UserConfig, PositionGroupClubIteration, RoleConfig } from '../../../types';
import { getDateLocaleFormat, getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';
import { countryCodeToCountryInfo } from '../../../static/countries';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import AddIcon from '@mui/icons-material/Add';

import { translate } from '../../../../common/language/translations';
import { LazyImage } from '../../../../common/components/LazyImage';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayInfo, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { getMetricColumnWidth } from '../seasonTable/seasonColumns';
import { getColorBlindRatingColor, getIndexColor } from '../../../utils/colorUtils';
import { Toggle } from '../../input/Toggle';
import { ProgressCircle } from '../../visualization/ProgressCircle';
import { positionGroupToPositions } from '../../../static/propertyValues';
// import { getTeamIcon } from '../../../utils/iconUtils';


const InfoCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const positionGroupClubIteration: PositionGroupClubIteration = row.original;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);
  const competitionName = competitions[positionGroupClubIteration.competition_id]?.name;

  // const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  // const { openPlayerViewModal } = useOpenGlobalModal();
  // const currentTeam = playerTeamData[positionGroupClubIteration.player_id]?.currentTeam;
  // let currentTeamIcon = getTeamIcon(currentTeam, 'none', 18);
  // if (!currentTeamIcon) {
  //   currentTeamIcon = <AddIcon style={{ fontSize: 13, color: '#00000099' }} />;
  // }

  // position info
  const primaryPositions = positionGroupClubIteration.overall_primary_positions ?? [];
  const secondaryPositions = positionGroupClubIteration.overall_secondary_positions ?? [];

  const primaryPositionString = getDisplayPositions(primaryPositions.slice(0, 3), userConfig?.language);
  let secondaryPositionString: string | undefined = undefined;

  if (primaryPositions.length === 1 && secondaryPositions.length > 0) {
    secondaryPositionString = getDisplayPositions(secondaryPositions.slice(0, 2), userConfig?.language);
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={positionGroupClubIteration.image_url}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-column player-season-table-info-cell-column'>

        <div className='player-table-info-cell-row'>
          <div className='player-table-info-cell-fullname'>
            {getDisplayPlayerName(positionGroupClubIteration.fullname, 22)}
          </div>

          {positionGroupClubIteration.country_code in countryCodeToCountryInfo && (
            <img
              className='player-table-info-cell-flag'
              src={countryCodeToCountryInfo[positionGroupClubIteration.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        {(positionGroupClubIteration.club?.name || positionGroupClubIteration.club?.is_national_team) && competitionName && (
          <div>
            <div className='player-table-info-cell-info'>
              {positionGroupClubIteration.club?.logo_url && (
                <div className='player-table-club-logo-container'>
                  <img
                    className='player-table-club-logo'
                    src={positionGroupClubIteration.club?.logo_url}
                    alt=''
                    draggable={false}
                  />
                </div>
              )}
              <div className='player-table-info-cell-info-text'>
                {positionGroupClubIteration.club?.is_national_team && positionGroupClubIteration.club?.country_code in countryCodeToCountryInfo
                  ? countryCodeToCountryInfo[positionGroupClubIteration.club?.country_code]?.name[userConfig?.language ?? 'en']
                  : positionGroupClubIteration.club?.name
                }
              </div>
            </div>

            <div className='player-table-info-cell-info'>
              <div className='player-table-info-cell-info-text'>
                {competitionName}
              </div>
            </div>
          </div>
        )}

      </div>

      <div className='player-season-table-info-cell-position-column'>
        <div className='player-season-table-info-cell-position-row' style={{ fontSize: 13 }}>
          {positionGroupClubIteration.season}
        </div>
        <div className='player-season-table-info-cell-position-row' style={{ fontSize: 12 }}>
          {primaryPositionString ?? '-'}

          {secondaryPositionString && (
            <div style={{ fontSize: 11, color: '#000000bb' }}>
              {'(' + secondaryPositionString + ')'}
            </div>
          )}
        </div>
      </div>

      {/* <div
        className='player-table-info-cell-team-icon'
        title={translate(currentTeam ? 'editTeamStatus' : 'addToTeam', userConfig?.language)}
        onClick={(event) => {
          {
            event.stopPropagation();
            openPlayerViewModal(
              positionGroupClubIteration.player_id,
              positionGroupClubIteration.fullname,
              undefined,
              undefined,
              true
            );
          }
        }}
      >
        {currentTeamIcon}
      </div> */}
    </div>
  );
};


const MinutesCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const positionGroupClubIteration: PositionGroupClubIteration = row.original;

  // total minutes info
  const minutesPlayed = positionGroupClubIteration.minutes_played ?? 0;
  const percentagePlayed = Math.round((positionGroupClubIteration.minutes_played_percentage ?? 0) * 100) + '%';
  const minutesColorIndex = Math.min(minutesPlayed / 150, 10);
  const minutesColor = minutesColorIndex ? getIndexColor(minutesColorIndex, 50) : 'transparent';

  return (
    <div className='player-season-table-minutes-cell'>
      <ProgressCircle
        size={18}
        progress={minutesColorIndex * 10}
        color={minutesColor}
        strokeWidth={12}
        trailColor='#00000022'
      />

      <div className='player-season-table-minutes-row'>
        {minutesPlayed} min

        <div style={{ fontSize: 11, color: '#000000bb' }}>
          {'(' + percentagePlayed + ')'}
        </div>
      </div>
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  return <div className='player-season-table-cell'>{value ?? '-'}</div>;
};


const ContractCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {
  return <div className='player-season-table-cell'>{getDateLocaleFormat(row.original.contract_expiration)}</div>;
};


const renderMetricOrRatingCell = (
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean
) => {
  const MetricOrRatingCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <MetricOrRatingCell
        positionGroupClubIteration={row.original}
        property={property}
        metricGroupKey={metricGroupKey}
        metricGroupIsToggled={metricGroupIsToggled}
        isColorBlind={isColorBlind}
      />
    );
  };

  MetricOrRatingCellRenderer.displayName = 'MetricOrRatingCellRenderer';
  return MetricOrRatingCellRenderer;
};

const MetricOrRatingCell: React.FC<{
  positionGroupClubIteration: PositionGroupClubIteration,
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
}> = ({ positionGroupClubIteration, property, metricGroupKey, metricGroupIsToggled, isColorBlind }) => {

  const normalizedValue = metricGroupKey === 'ratings'
    ? property === 'skill_rating'
      ? positionGroupClubIteration.skill_rating
      : (positionGroupClubIteration.custom_ratings ? positionGroupClubIteration.custom_ratings[property + '_rating'] : undefined)
    : positionGroupClubIteration.metrics[property + '_normalized'];

  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricGroupIsToggled) {
    let actualValue = positionGroupClubIteration.metrics[property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (metricGroupKey === 'attacking' && property !== 'n_shots_per_90') || property === 'gk_postshot_xg_prevented_difference_per_shot'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_90', 'skillcorner_distance_otip_per_90'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }
    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_90',
      'skillcorner_sprinting_distance_otip_per_90',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_90',
      'skillcorner_hsr_distance_otip_per_90',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }
    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }
    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = positionGroupClubIteration.metrics['gk_n_penalties_saved'] + '/' + positionGroupClubIteration.metrics['gk_n_penalties_faced']
        + ' (' + displayValue + ')';
    }
  }

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(normalizedValue ?? 0, 0.3)
    : getIndexColor(normalizedValue, 74);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='player-season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500
        }}
      >
        {displayValue}
      </div>
    </div>
  );
};


export const getPlayerSeasonColumns = (
  userConfig: UserConfig | null,
  isGoalkeeperSelected: boolean,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  handleChangeCurrentChapter: (isIncrement: boolean) => void,
  selectedOrderBy: string | undefined,
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
  secondaryClubColor: string,
  seasonStatsToggles: StringToAnyMap,
  hasSkillcorner: boolean,
  openTextModal: (text: string, title: string) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
) => {

  if (!userConfig) return [];

  const seasonCellWidth = 375;

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='player-season-table-top-level-header-cell-sticky' style={{ width: seasonCellWidth }}>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + translate(totalHits === 1 ? 'player' : 'players', userConfig.language, true)}

          {totalHits > hitsPerChapter && (
            <div className='player-table-sticky-header-cell-pagination'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              {currentChapter <= 0 && (
                <div className='player-table-pagination-arrow player-table-pagination-left-arrow player-season-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter > 0 && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    ' player-table-pagination-left-arrow player-season-table-pagination-left-arrow'
                  }
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                </div>
              )}

              <div className='player-table-current-chapter player-season-table-current-chapter'>
                {currentChapter + 1}
              </div>

              {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                <div className='player-table-pagination-arrow player-table-pagination-right-arrow player-season-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter < Math.floor(totalHits / hitsPerChapter) && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    ' player-table-pagination-right-arrow player-season-table-pagination-right-arrow'
                  }
                >
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                </div>
              )}
            </div>
          )}

        </div>,
      id: 'season',
      sticky: 'left',
      accessor: 'season',
      Cell: InfoCell,
      width: seasonCellWidth,
      minWidth: seasonCellWidth,
    }
  ];

  const positionGroupKey = selectedRoles.length > 0 ? roleConfigs[selectedRoles[0]].positionGroup : 'overall';

  columns.push({
    Header:
      <div
        className='player-season-table-top-level-header-cell player-season-table-top-level-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        {translate('dataBasis', userConfig.language)}
      </div>,
    id: 'minutesInfo',
    sticky: 'left',
    columns: [
      {
        Header:
          <div
            className='player-season-table-sub-level-header-cell player-season-table-sub-level-header-cell-with-border'
            style={{ fontSize: 10 }}
          >
            {translate('basedOn<positions>', userConfig.language) + ' '}
            {positionGroupKey !== 'overall'
              ? getDisplayPositions(positionGroupToPositions[positionGroupKey], userConfig.language)
              : translate('allPositions', userConfig.language, true)}
          </div>,
        id: 'minutes',
        accessor: 'minutes',
        Cell: MinutesCell,
        width: 140,
        isFinalSubMetric: true,
      },
    ],
  });

  columns.push({
    Header:
      <div
        className='player-season-table-top-level-header-cell player-season-table-top-level-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        Info
      </div>,
    id: 'playerInfo',
    columns: [
      {
        Header:
          <div className='player-season-table-sub-level-header-cell'>
            {translate('age', userConfig.language)}
          </div>,
        id: 'age',
        accessor: 'age',
        Cell: TextCell,
        width: 90,
      },
      {
        Header:
          <div className='player-season-table-sub-level-header-cell player-season-table-sub-level-header-cell-with-border'>
            {translate('contract', userConfig.language)}
          </div>,
        id: 'contract',
        accessor: 'contract_expiration',
        Cell: ContractCell,
        width: 120,
        isFinalSubMetric: true,
      },
    ],
  });

  const ratingMetricGroup = { 'ratings': ['skill_rating', ...selectedRoles] };
  const normalMetricGroups = isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const skillCornerMetricGroup = hasSkillcorner ? { 'skillcorner': skillcornerStats } : {};

  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups, ...skillCornerMetricGroup };

  Object.entries(metricGroups).forEach(([metricGroupKey, metrics]: [string, string[]], index) => {
    const isFinalMetricGroup = index === Object.keys(metricGroups).length - 1;

    const metricGroupName = metricToDisplayInfo[metricGroupKey].name[userConfig.language];
    const metricGroupDescription = metricToDisplayInfo[metricGroupKey].description[userConfig.language];

    const topLevelCell = {
      Header:
        <div className={'player-season-table-top-level-header-cell' + (!isFinalMetricGroup ? ' player-season-table-top-level-header-cell-with-border' : '')}>
          <div
            className='player-season-table-sub-level-header-cell-text'
            title={translate('showDescription', userConfig.language)}
            onClick={() => openTextModal(metricGroupName, metricGroupDescription)}
            style={{ fontSize: 12 }}
          >
            {metricGroupName}
          </div>

          {metricGroupKey !== 'ratings' && metricGroupKey !== 'obv' && (
            <div
              className='season-table-top-level-header-cell-toggle'
              title={translate(seasonStatsToggles[metricGroupKey] ? 'showNormalizedValues' : 'showActualValues', userConfig.language)}
            >
              <Toggle
                isToggled={seasonStatsToggles[metricGroupKey]}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { [metricGroupKey]: value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          )}
        </div>,
      id: metricGroupKey,

      columns: metrics.map((metric, index) => {
        const isFinalSubMetric = !isFinalMetricGroup && index === metrics.length - 1;
        const roleConfig = roleConfigs[metric];

        const metricName = roleConfig !== undefined
          ? roleConfigs[metric].name
          : metricToDisplayInfo[metric]?.name[userConfig.language];

        const metricDescription = roleConfig !== undefined
          ? undefined
          : metricToDisplayInfo[metric]?.description[userConfig.language];

        const isOrderByMetric = selectedOrderBy === metric;

        const cell = {
          Header:
            <div
              className={'player-season-table-sub-level-header-cell' + (isFinalSubMetric ? ' player-season-table-sub-level-header-cell-with-border' : '')}
              style={{
                color: isOrderByMetric ? secondaryClubColor : undefined,
                fontWeight: isOrderByMetric ? 600 : undefined
              }}
            >
              <div
                className='player-season-table-sub-level-header-cell-text'
                title={translate('showDescription', userConfig.language)}
                onClick={() => roleConfig ? openRoleInfoModal(roleConfig) : openTextModal(metricName, metricDescription)}
              >
                {metricName}
              </div>
            </div>,
          id: metric,
          Cell: renderMetricOrRatingCell(
            metric,
            metricGroupKey,
            seasonStatsToggles[metricGroupKey],
            userConfig.isColorBlind
          ),
          width: getMetricColumnWidth(metricName),
          isFinalSubMetric: isFinalSubMetric,
        };
        return cell;
      }),
    };

    columns.push(topLevelCell);
  });

  return columns;
};
