import './input.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../common/language/translations';


interface ConditionDropDownProps {
  id: string;

  dropDownOptions: string[]; // static language keys
  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling
  height?: number;

  minuteRequirementPerMatch: string | undefined;
  setMinuteRequirementPerMatch: (value: string) => void;
}

export const ConditionDropDown: React.FC<ConditionDropDownProps> = ({
  id,

  dropDownOptions,
  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
  height,

  minuteRequirementPerMatch,
  setMinuteRequirementPerMatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {

    if (!isOptionAlreadySelected && option !== 'matchesPlayed' && option !== 'matchesStarted') {
      removeDropDownExpansion();
    }
    const newSelectedOptions = (isOptionAlreadySelected) ? [] : [option];

    setSelectedOptions(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = height ? height + 'px' : '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const showSelectedOptions = () => {
    let selection = translate(selectedOptions[0], userConfig?.language);

    if (userConfig && minuteRequirementPerMatch && (selectedOptions[0] === 'matchesPlayed' || selectedOptions[0] === 'matchesStarted')) {
      selection += ' (' + translate('atLeast<threshold>', userConfig?.language) + ' ' + minuteRequirementPerMatch + ' min)';
    }

    return selection;
  };


  const onChangeMinuteRequirementInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    if (newValue !== '') {
      const numberValue = Number(newValue);
      if (isNaN(numberValue) || numberValue <= 0 || numberValue > 90) return;

      setMinuteRequirementPerMatch(Number(newValue).toString());
    }
    else {
      setMinuteRequirementPerMatch(newValue);
    }
  };


  const onKeyDownMinuteRequirementInputField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      removeDropDownExpansion();
    }
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedOptions.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ height: height, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-select-button'
        style={{ height: height }}
        onClick={() => handleDropDownClick()}>

        {selectedOptions.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div
        ref={dropDownRef}
        className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}>
        {dropDownOptions.map(option => {
          const isSelected = selectedOptions.includes(option);

          return (
            <div key={option}>
              <div
                className={'drop-down-select-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                style={{
                  marginTop: marginBetweenOptions,
                  // marginBottom: option === 'matchesPlayed' && isSelected ? 65 : 25
                  // height: option === 'matchesPlayed' && isSelected ? 65 : 25
                }}
                onClick={() => handleOptionSelect(option, isSelected)}
              >
                {translate(option, userConfig?.language)}
              </div>

              {(option === 'matchesPlayed' || option === 'matchesStarted') && (
                <div className='flex-row' style={{ transition: '200ms', height: isSelected ? 50 : 0, overflow: 'hidden' }}>
                  <input
                    className='drop-down-input-field'
                    name='condition-drop-down-input-field'
                    type='text'
                    autoComplete='off'
                    value={minuteRequirementPerMatch}
                    placeholder={translate('minuteRequirement?', userConfig?.language)}
                    onChange={(event) => onChangeMinuteRequirementInputField(event)}
                    onKeyDown={(event) => onKeyDownMinuteRequirementInputField(event)}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

    </div>
  );
};
