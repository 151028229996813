import axios from 'axios';

import { useRecoilValue } from 'recoil';
import { currentReactVersionState } from '../../../recoil/atoms/currentReactVersionState';

import { ANALYTICS_API_URL } from '../constants';
import { getBearerToken } from '../token';
import { StringToAnyMap } from '../../../types';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';


export const useTrackEvent = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const currentReactVersion = useRecoilValue(currentReactVersionState);

  const trackEvent = async (
    event: string,
    properties: StringToAnyMap = {},
  ) => {

    const extendedProperties = {
      ...properties,
      currentReactVersion,
    };

    // console.log('trackEvent', event, 'extendedProperties', extendedProperties);

    const data = JSON.stringify({
      'name': event,
      properties: extendedProperties,
    });

    const token = await getBearerToken(currentUser);

    try {
      await axios.post(
        `${ANALYTICS_API_URL}/trackEvent`,
        data,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  return trackEvent;
};
