import '../../platform.css';
import './ownTeam.css';

import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import GroupsIcon from '@mui/icons-material/Groups';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchoolIcon from '@mui/icons-material/School';

import { StringToAnyMap, PlayerEntry, PlayerMap } from '../../types';
import { getAge, getSortDistanceRole } from '../../utils/playerUtils';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { OwnTeamMatrixView } from './OwnTeamMatrixView';
import { OwnTeamTableView } from './OwnTeamTableView';
import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';
import { getEuroToCurrency } from '../../utils/currencyUtils';


export const OwnTeam = () => {

  const clubSettings = useRecoilValue(clubSettingsState);
  const teams = useRecoilValue(teamsState) ?? {};
  const navigationProps = useRecoilValue(navigationPropsState);

  const trackEvent = useTrackEvent();

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const [clubCountryCode, setClubCountryCode] = useState<string | undefined>(undefined);

  const initialActiveTab = navigationProps?.activeSubTab ?? 0;
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [showFirstEleven, setShowFirstEleven] = useState(false);

  const tabOptions = [
    'playerSquad',
    'table',
    'matrix',
    'academy',
  ];

  const tabIcons = [
    <GroupsIcon key={'groups-icon'} style={{ fontSize: 21 }} />,
    <FormatListBulletedIcon key={'format-list-icon'} style={{ fontSize: 21 }} />,
    <WindowOutlinedIcon key={'window-icon'} style={{ fontSize: 21 }} />,
    <SchoolIcon key={'school-icon'} style={{ fontSize: 20.5 }} />,
  ];

  const [logisticTeamViewToggle, setLogisticTeamViewToggle] = useState(false);


  const addPlayerOverviewData = useCallback((player: StringToAnyMap) => {
    if (player.id in playerOverviews && clubCountryCode) {
      const numberId = Number(player.id);

      player['image_url'] = playerOverviews[numberId].image_url;
      player['skill_index'] = playerOverviews[numberId].position_group_stats?.overall?.skill_index;
      player['primary_positions'] = playerOverviews[numberId].primary_positions;

      // values to be used, where user provided values have presedence
      player['birth_date'] = playerOverviews[numberId].birth_date;
      player['country_code'] = playerOverviews[numberId].country_code;
      player['contract_expiration'] = playerOverviews[numberId].contract_expiration;
      player['market_value'] = getEuroToCurrency(playerOverviews[numberId].market_value, clubCountryCode);

      // values that we have in our database
      player['birth_date_in_database'] = playerOverviews[numberId].birth_date;
      player['country_code_in_database'] = playerOverviews[numberId].country_code;
      player['contract_expiration_in_database'] = playerOverviews[numberId].contract_expiration;
      player['market_value_in_database'] = getEuroToCurrency(playerOverviews[numberId].market_value, clubCountryCode);
    }
  }, [clubCountryCode, playerOverviews]);


  // ownTeamConfig = {
  //     'ownTeamPlayers': map from playerId to data for all players in ownTeam,
  //     'academyTeamPlayers': map from playerId to data for all players in academyTeam,
  //     'bench': list of playerData for all players on ownTeam who are not in the starting eleven,
  // }
  const [ownTeamConfig, setOwnTeamConfig] = useState<StringToAnyMap | undefined>(undefined);

  useEffect(() => {

    const ownTeamPlayers: PlayerMap = {};
    const academyTeamPlayers: PlayerMap = {};
    const bench: StringToAnyMap[] = [];

    positionOptionsPlatform.forEach(positionKey => {

      // ownTeam players
      if (teams['ownTeam'] && positionKey in teams['ownTeam']) {
        teams['ownTeam'][positionKey].forEach((playerEntry: PlayerEntry) => {

          let player: StringToAnyMap = { ...playerEntry };

          addPlayerOverviewData(player);

          // data stored in players collection have presedence over data from playerOverviews
          let isStartingEleven = false;
          if (player.id in players) {
            player = { ...player, ...players[player.id] };
            isStartingEleven = players[player.id].isStartingEleven;
          }

          player['age'] = getAge(player['birth_date']);

          ownTeamPlayers[player.id] = player;

          if (!isStartingEleven) {
            bench.push(player);
          }
        });
      }

      // academy players
      if (teams['academyTeam'] && positionKey in teams['academyTeam']) {
        teams['academyTeam'][positionKey].forEach((playerEntry: PlayerEntry) => {

          let player: StringToAnyMap = { ...playerEntry };

          addPlayerOverviewData(player);

          if (player.id in players) {
            player = { ...player, ...players[player.id] };
          }

          player['age'] = getAge(player['birth_date']);

          academyTeamPlayers[player.id] = player;
        });
      }
    });

    bench.sort((a: StringToAnyMap, b: StringToAnyMap) => getSortDistanceRole(a, b));

    const newOwnTeamConfig = {
      'ownTeamPlayers': ownTeamPlayers,
      'academyTeamPlayers': academyTeamPlayers,
      'bench': bench,
    };

    setOwnTeamConfig(newOwnTeamConfig);

  }, [teams, players, playerOverviews, clubCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (clubSettings && !clubCountryCode) {
      setClubCountryCode(clubSettings.countryCode);
    }
  }, [clubSettings]); // eslint-disable-line react-hooks/exhaustive-deps


  const setTabAndResetExpansions = (index: number) => {
    if (index !== activeTab) {
      trackEvent('SubTabClicked', { parentTab: 'ownTeam', tab: tabOptions[index] });
    }

    setActiveTab(index);
    setLogisticTeamViewToggle(!logisticTeamViewToggle);
    setShowFirstEleven(false);
  };


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setTabAndResetExpansions}
        initialActiveTab={initialActiveTab}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && ownTeamConfig && (
        <LogisticTeamView
          teamOrSquadId={'ownTeam'}
          teamOrSquad={teams['ownTeam']}
          isTabLine={true}
          showFirstEleven={showFirstEleven}
          setShowFirstEleven={setShowFirstEleven}
          benchPlayers={ownTeamConfig['bench']}
        />
      )}

      {activeTab === 1 && ownTeamConfig && (
        <OwnTeamTableView
          ownTeamPlayers={ownTeamConfig['ownTeamPlayers']}
          academyTeamPlayers={ownTeamConfig['academyTeamPlayers']}
        />
      )}

      {activeTab === 2 && ownTeamConfig && (
        <OwnTeamMatrixView
          ownTeamPlayers={ownTeamConfig['ownTeamPlayers']}
        />
      )}

      {activeTab === 3 && (
        <LogisticTeamView
          teamOrSquadId={'academyTeam'}
          teamOrSquad={teams['academyTeam']}
          isTabLine={true}
        />
      )}

    </div>
  );
};
