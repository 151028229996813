import { storage } from '../../../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';


// uploads the given files to storage and returns their urls
export const uploadFiles = async (files: File[], documentType: string, documentId: string, club: string) => {

  const uploadPromises = files.map(async file => {
    const storageRef = ref(storage, `${club}/${documentType}/${documentId}/${file.name}`);
    const uploadTask = uploadBytes(storageRef, file);
    await uploadTask;
    return await getDownloadURL(storageRef);
  });

  return await Promise.all(uploadPromises);
};


// deletes the files with the given file names from storage
export const deleteFiles = async (fileNames: string[], documentType: string, documentId: string, club: string) => {

  const deletePromises = fileNames.map(fileName => {
    const fileRef = ref(storage, `${club}/${documentType}/${documentId}/${fileName}`);
    return deleteObject(fileRef);
  });

  await Promise.all(deletePromises);
};
