import './input.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { translate } from '../../../common/language/translations';


interface FileUploadProps {
  handleFileUpload: (file: File) => void;
  language: string;
  isButton?: boolean;
}


export const FileUpload: React.FC<FileUploadProps> = ({
  handleFileUpload,
  language,
  isButton,
}) => {


  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFileUpload(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <div>
      <input
        id='file-upload'
        type='file'
        className='file-input'
        accept='application/pdf'
        placeholder='Upload file'
        onChange={onFileChange}
      />

      {!isButton && (
        <label
          htmlFor='file-upload'
          title={translate('uploadPdf', language)}
          className='file-input-add-icon'>
          <AddCircleOutlineIcon style={{ fontSize: 20 }} />
        </label>
      )}

      {isButton && (
        <label
          htmlFor='file-upload'
          className='file-input-add-button'>
          {translate('uploadPdf', language)}
          <FileUploadOutlinedIcon style={{ fontSize: 20, marginLeft: 10 }} />
        </label>
      )}
    </div>
  );
};
