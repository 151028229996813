import './../scouting.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useTrackEvent } from '../../../services/server/analytics/useTrackEvent';

import { Dialog } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/input/InputField';
import { DropDownSelect } from '../../../components/input/DropDownSelect';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { LeagueDropDown } from '../../../components/input/LeagueDropDown';
import { CountryDropDown } from '../../../components/input/CountryDropDown';
import { Club, StringToAnyMap } from '../../../types';
import { SaveOrLoadSearchModal } from '../../../components/modals/miscModals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/scouting';
import { ClubDropDown } from '../../../components/input/ClubDropDown';
import { PositionAndRoleDropDown } from '../../../components/input/PositionAndRoleDropDown';
import { DropDownSelectWithIcons } from '../../../components/input/DropDownSelectWithIcons';
import {
  ageKeys,
  contractKeys,
  formKeys,
  minConfidenceKeys,
  playingTimeKeys
} from '../../../static/propertyValues';


interface PlayerScoutingFiltersProps {
  nameSearchString: string;
  setNameSearchString: (value: string) => void;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  selectedMaxAge: string[];
  setSelectedMaxAge: (value: string[]) => void;
  selectedPositions: string[];
  setSelectedPositions: (value: string[]) => void;
  selectedRoles: string[];
  setSelectedRoles: (value: string[]) => void;
  selectedLeagues: string[];
  setSelectedLeagues: (value: string[]) => void;
  selectedOrderBy: string[];
  setSelectedOrderBy: (value: string[]) => void;

  selectedClubs: Club[];
  setSelectedClubs: (value: Club[]) => void;
  selectedMinConfidence: string[];
  setSelectedMinConfidence: (value: string[]) => void;
  selectedForm: string[];
  setSelectedForm: (value: string[]) => void;
  selectedPlayingTime: string[];
  setSelectedPlayingTime: (value: string[]) => void;
  selectedNationalities: string[];
  setSelectedNationalities: (value: string[]) => void;
  selectedContractStatus: string[];
  setSelectedContractStatus: (value: string[]) => void;
  isContractStatusToggled: boolean;
  setIsContractStatusToggled: (value: boolean) => void;

  isAnyFilterExpanded: boolean;
  setIsAnyFilterExpanded: (value: boolean) => void;
  isAdvancedFiltersExpanded: boolean;
  handleAdvancedFiltersExpansion: () => void;

  handleFilterUnexpansion: (filter: string) => void;
  handleSearchReset: () => void;
  setSearchWasLoaded: (value: boolean) => void;
}

export const PlayerScoutingFilters: React.FC<PlayerScoutingFiltersProps> = ({
  nameSearchString,
  setNameSearchString,
  onKeyDownNameSearchField,

  selectedMaxAge,
  setSelectedMaxAge,
  selectedPositions,
  setSelectedPositions,
  selectedRoles,
  setSelectedRoles,
  selectedLeagues,
  setSelectedLeagues,
  selectedOrderBy,
  setSelectedOrderBy,

  selectedClubs,
  setSelectedClubs,
  selectedMinConfidence,
  setSelectedMinConfidence,
  selectedForm,
  setSelectedForm,
  selectedPlayingTime,
  setSelectedPlayingTime,
  selectedNationalities,
  setSelectedNationalities,
  selectedContractStatus,
  setSelectedContractStatus,
  isContractStatusToggled,
  setIsContractStatusToggled,

  isAnyFilterExpanded,
  setIsAnyFilterExpanded,
  isAdvancedFiltersExpanded,
  handleAdvancedFiltersExpansion,

  handleFilterUnexpansion,
  handleSearchReset,
  setSearchWasLoaded,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const trackEvent = useTrackEvent();

  const width = useWindowSize().width;

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [modalTypeOpen, setModalTypeOpen] = useState<string | undefined>(undefined);

  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isPositionsAndRolesExpanded, setIsPositionsAndRolesExpanded] = useState(false);
  const [isLeaguesExpanded, setIsLeaguesExpanded] = useState(false);
  const [isOrderByExpanded, setIsOrderByExpanded] = useState(false);

  const [isClubsExpanded, setIsClubsExpanded] = useState(false);
  const [isMinConfidenceExpanded, setIsMinConfidenceExpanded] = useState(false);
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [isPlayingTimeExpanded, setIsPlayingTimeExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isContractStatusExpanded, setIsContractStatusExpanded] = useState(false);


  const onChangeNameSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearchString(event.target.value);
  };


  const handleAdvancedFiltersClick = () => {
    const filterIcon = document.getElementById('filter-icon');
    if (filterIcon) {
      filterIcon.style.transition = '0ms';
      handleAdvancedFiltersExpansion();
      filterIcon.style.transition = '125ms';
    }
  };


  const handleResetFiltersClick = () => {
    handleSearchReset();

    setNameSearchString('');
    setSelectedMaxAge([]);
    setSelectedPositions([]);
    setSelectedRoles([]);
    setSelectedLeagues([]);
    setSelectedOrderBy(['skillIndex']);

    setSelectedClubs([]);
    setSelectedMinConfidence([]);
    setSelectedForm([]);
    setSelectedPlayingTime([]);
    setSelectedNationalities([]);
    setSelectedContractStatus([]);
    setIsContractStatusToggled(false);
  };


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: StringToAnyMap = {
      'searchName': searchName,
      'searchType': 'playerScouting',
    };

    if (nameSearchString.length > 0) search['nameSearchString'] = nameSearchString;
    if (selectedMaxAge.length > 0) search['maxAge'] = selectedMaxAge;
    if (selectedPositions.length > 0) search['positions'] = selectedPositions;
    if (selectedRoles.length > 0) search['roles'] = selectedRoles;
    if (selectedLeagues.length > 0) search['leagues'] = selectedLeagues;
    if (selectedOrderBy.length > 0) search['orderBy'] = selectedOrderBy;

    if (selectedClubs.length > 0) search['clubs'] = selectedClubs.map((club: Club) => {
      return {
        'id': club.id,
        'name': club.name,
        'logo_url': club.logo_url,
      };
    });
    if (selectedMinConfidence.length > 0) search['minConfidence'] = selectedMinConfidence;
    if (selectedForm.length > 0) search['form'] = selectedForm;
    if (selectedPlayingTime.length > 0) search['playingTime'] = selectedPlayingTime;
    if (selectedNationalities.length > 0) search['nationalities'] = selectedNationalities;
    if (selectedContractStatus.length > 0) search['contractStatus'] = selectedContractStatus;
    if (isContractStatusToggled) search['isContractStatusToggled'] = true;

    trackEvent('PlayerSearchSaved');
    saveUserSearch(search, userConfig.email, userConfig.club);
  };


  const loadSearch = (savedSearch: StringToAnyMap) => {
    setNameSearchString(savedSearch['nameSearchString'] ?? '');
    setSelectedMaxAge(savedSearch['maxAge'] ?? []);
    setSelectedPositions(savedSearch['positions'] ?? []);
    setSelectedRoles(savedSearch['roles'] ?? []);
    setSelectedLeagues(savedSearch['leagues'] ?? []);
    setSelectedOrderBy(savedSearch['orderBy'] ?? []);

    setSelectedClubs(savedSearch['clubs'] ?? []);
    setSelectedMinConfidence(savedSearch['minConfidence'] ?? []);
    setSelectedForm(savedSearch['form'] ?? []);
    setSelectedPlayingTime(savedSearch['playingTime'] ?? []);
    setSelectedNationalities(savedSearch['nationalities'] ?? []);
    setSelectedContractStatus(savedSearch['contractStatus'] ?? []);
    setIsContractStatusToggled(savedSearch['isContractStatusToggled'] ?? false);

    trackEvent('PlayerSearchLoaded');
    setSearchWasLoaded(true);
  };


  useEffect(() => {
    if (!isAnyFilterExpanded) {
      setIsMaxAgeExpanded(false);
      setIsPositionsAndRolesExpanded(false);
      setIsLeaguesExpanded(false);
      setIsOrderByExpanded(false);
      setIsClubsExpanded(false);
      setIsMinConfidenceExpanded(false);
      setIsFormExpanded(false);
      setIsPlayingTimeExpanded(false);
      setIsNationalitiesExpanded(false);
      setIsContractStatusExpanded(false);
    }
  }, [isAnyFilterExpanded]);


  useEffect(() => {
    if (!isAdvancedFiltersExpanded) {
      setShowAdvancedFilters(false);
    }
    else {
      const timer = setTimeout(() => {
        setShowAdvancedFilters(true);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isAdvancedFiltersExpanded]);


  useEffect(() => {
    if (!isMaxAgeExpanded) {
      handleFilterUnexpansion('maxAge');
    }
  }, [isMaxAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPositionsAndRolesExpanded) {
      handleFilterUnexpansion('positionsAndRoles');
    }
  }, [isPositionsAndRolesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLeaguesExpanded) {
      handleFilterUnexpansion('leagues');
    }
  }, [isLeaguesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOrderByExpanded) {
      handleFilterUnexpansion('orderBy');
    }
  }, [isOrderByExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isClubsExpanded) {
      handleFilterUnexpansion('clubs');
    }
  }, [isClubsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMinConfidenceExpanded) {
      handleFilterUnexpansion('minConfidence');
    }
  }, [isMinConfidenceExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFormExpanded) {
      handleFilterUnexpansion('form');
    }
  }, [isFormExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPlayingTimeExpanded) {
      handleFilterUnexpansion('playingTime');
    }
  }, [isPlayingTimeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNationalitiesExpanded) {
      handleFilterUnexpansion('nationalities');
    }
  }, [isNationalitiesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isContractStatusExpanded) {
      handleFilterUnexpansion('contractStatus');
    }
  }, [isContractStatusExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={modalTypeOpen !== undefined}
        onClose={() => setModalTypeOpen(undefined)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <SaveOrLoadSearchModal
          modalType={modalTypeOpen}
          searchType={'playerScouting'}
          closeModal={() => setModalTypeOpen(undefined)}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='player-filter-section-input-container'>
        <InputField
          searchString={nameSearchString}
          onChangeInputField={onChangeNameSearchField}
          onKeyDownInputField={onKeyDownNameSearchField}
          resetSearchString={() => setNameSearchString('')}
          defaultInput={translate(width > 1200 ? 'searchForName' : 'name', userConfig?.language)}
          showDefaultInput={true}
          style={{ boxShadow: '0px 0px 3px 1px #0000002d' }}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-confidence'>
        {translate(width > 1000 ? 'minConfidence' : 'confidence', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-confidence'>
        <DropDownSelect
          id={'player-filter-section-confidence-drop-down'}
          dropDownOptions={minConfidenceKeys}
          isDropDownExpanded={isMinConfidenceExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMinConfidenceExpanded(value), setIsAnyFilterExpanded(value); }}
          selectedOptions={selectedMinConfidence}
          setSelectedOptions={setSelectedMinConfidence}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'75vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-age'>
        <DropDownSelect
          id={'player-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          isDropDownExpanded={isMaxAgeExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMaxAgeExpanded(value), setIsAnyFilterExpanded(value); }}
          selectedOptions={selectedMaxAge}
          setSelectedOptions={setSelectedMaxAge}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-positions-and-roles'>
        {translate('positionsAndRoles', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-positions-and-roles'>
        <PositionAndRoleDropDown
          id={'player-filter-section-positions-and-roles-drop-down'}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          isDropDownExpanded={isPositionsAndRolesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsPositionsAndRolesExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-leagues'>
        {translate('leagues', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-leagues'>
        <LeagueDropDown
          id={'player-filter-section-leagues-drop-down'}
          isDropDownExpanded={isLeaguesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsLeaguesExpanded(value), setIsAnyFilterExpanded(value); }}
          selectedLeagues={selectedLeagues}
          setSelectedLeagues={setSelectedLeagues}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-order-by'>
        {translate('orderBy', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-order-by'>
        <DropDownSelect
          id={'player-filter-section-order-by-drop-down'}
          dropDownOptions={[...selectedRoles, 'skillIndex', 'age']}
          isDropDownExpanded={isOrderByExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsOrderByExpanded(value), setIsAnyFilterExpanded(value); }}
          selectedOptions={selectedOrderBy}
          setSelectedOptions={setSelectedOrderBy}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div
        className='filter-section-icon filter-section-left-icon'
        onClick={() => handleResetFiltersClick()}
        title={translate('resetFilters', userConfig?.language)}
      >
        <ReplayIcon style={{ fontSize: 20 }} />
      </div>

      <div
        id='filter-icon'
        className='filter-section-icon filter-section-right-icon'
        onClick={() => handleAdvancedFiltersClick()}
        title={translate('moreFilters', userConfig?.language)}
      >
        {!isAdvancedFiltersExpanded && <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} />}
        {isAdvancedFiltersExpanded && <CloseIcon style={{ fontSize: 20 }} />}
      </div>

      {isAdvancedFiltersExpanded && showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>
          <div className='filter-section-drop-down-title player-filter-section-clubs'>
            {translate('clubs', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-clubs'>
            <ClubDropDown
              id={'player-filter-section-clubs-drop-down'}
              isDropDownExpanded={isClubsExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsClubsExpanded(value), setIsAnyFilterExpanded(value); }}
              selectedClubs={selectedClubs}
              setSelectedClubs={setSelectedClubs}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-form'>
            {translate('form', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-form'>
            <DropDownSelectWithIcons
              id={'player-filter-section-form-drop-down'}
              dropDownOptions={formKeys}
              isDropDownExpanded={isFormExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsFormExpanded(value), setIsAnyFilterExpanded(value); }}
              selectedOptions={selectedForm}
              setSelectedOptions={setSelectedForm}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              multiSelect={true}
              useFormIcons={true}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-playing-time'>
            {translate('playingTime', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-playing-time'>
            <DropDownSelectWithIcons
              id={'player-filter-section-playing-time-drop-down'}
              dropDownOptions={playingTimeKeys}
              isDropDownExpanded={isPlayingTimeExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsPlayingTimeExpanded(value), setIsAnyFilterExpanded(value); }}
              selectedOptions={selectedPlayingTime}
              setSelectedOptions={setSelectedPlayingTime}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              multiSelect={true}
              usePlayingTimeIcons={true}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-nationalities'>
            {translate('nationalities', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-nationalities'>
            <CountryDropDown
              id={'player-filter-section-nationalities-drop-down'}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsNationalitiesExpanded(value), setIsAnyFilterExpanded(value); }}
              selectedCountryCodes={selectedNationalities}
              setSelectedCountryCodes={setSelectedNationalities}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-contract'>
            {translate('contractStatus', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-contract'>
            <DropDownSelect
              id={'player-filter-section-contract-drop-down'}
              dropDownOptions={contractKeys}
              isDropDownExpanded={isContractStatusExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsContractStatusExpanded(value), setIsAnyFilterExpanded(value); }}
              selectedOptions={selectedContractStatus}
              setSelectedOptions={setSelectedContractStatus}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              toggleText={'includeUnknownContractStatus'}
              isToggled={isContractStatusToggled}
              setIsToggled={setIsContractStatusToggled}
            />
          </div>

          <div
            className='filter-section-icon filter-section-left-icon'
            onClick={() => setModalTypeOpen('saveSearch')}
            title={translate('saveSearch', userConfig?.language)}
          >
            <SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />
          </div>

          <div
            className='filter-section-icon filter-section-right-icon'
            onClick={() => setModalTypeOpen('loadSearch')}
            title={translate('loadSearch', userConfig?.language)}
          >
            <ArrowCircleDownIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      )}

    </div>
  );
};
