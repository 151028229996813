import './teamView.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import BrushIcon from '@mui/icons-material/Brush';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { translate } from '../../../common/language/translations';
import { StringToStringMap, StringToAnyMap } from '../../types';
import { archiveTeamOrSquad, resetTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';
import { updateTeamMenu } from '../../services/firestore/userSettings';
import { Toggle } from '../input/Toggle';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { Dialog } from '@mui/material';
import { RoleIndexInTeamsInfoModal } from '../modals/infoModals/RoleIndexInTeamsInfoModal';


interface TeamMenuProps {
  teamOrSquadId: string;
  teamOrSquadData: StringToAnyMap | undefined;

  isSquad?: boolean;

  showFirstEleven?: boolean;
  setShowFirstEleven?: (isToggled: boolean) => void;
  benchPlayers?: StringToAnyMap[];

  draggedPlayer?: StringToAnyMap | undefined;
  setDraggedPlayer?: (player: StringToAnyMap | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void;
}


export const TeamMenu: React.FC<TeamMenuProps> = ({
  teamOrSquadId,
  teamOrSquadData,

  isSquad,

  showFirstEleven,
  setShowFirstEleven,
  benchPlayers,

  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openConfirmModal } = useOpenGlobalModal();
  const [isRoleIndexInfoModalOpen, setIsRoleIndexInfoModalOpen] = useState(false);
  const [doNotShowRoleIndexInfoModalAgain, setDoNotShowRoleIndexInfoModalAgain] = useState(false);

  const [teamButtonDisabled, setTeamButtonDisabled] = useState(false);

  const [showContent, setShowContent] = useState(false);

  const [isIconHighlighted, setIsIconHighlighted] = useState<string | undefined>(undefined);


  const menu = (teamOrSquadId === 'ownTeam'
    ? userSettings?.ownTeamMenu
    : teamOrSquadId === 'academyTeam'
      ? userSettings?.academyTeamMenu
      : userSettings?.teamMenu) ?? {};


  const menuOptions = teamOrSquadId === 'ownTeam'
    ? ['shortNames', 'teamRole', 'nationality', 'age', 'birthYear', 'contract', 'skillIndex', 'roleIndex']
    : teamOrSquadId === 'academyTeam'
      ? ['shortNames', 'teamRole', 'nationality', 'age', 'birthYear', 'contract']
      : ['shortNames', 'nationality', 'club', 'age', 'birthYear', 'contract', 'skillIndex', 'roleIndex'];


  const displayOptionToPropertyMap: StringToStringMap = {
    'shortNames': 'shortNames',
    'teamRole': 'role',
    'nationality': 'country_code',
    'club': 'club_logo_url',
    'age': 'age',
    'birthYear': 'birth_date',
    'contract': 'contract_expiration',
    'skillIndex': 'skill_index',
    'roleIndex': 'role_index',
  };


  const propertiesWithColorIcon = ['age', 'birth_date', 'contract_expiration', 'skill_index', 'role_index', 'role'];
  const propertiesWithSortIcon = ['age', 'birth_date', 'contract_expiration', 'skill_index', 'role_index', 'role'];


  const handleCloseRoleIndexInfoModal = () => {
    if (!userConfig) return;

    if (doNotShowRoleIndexInfoModalAgain) {
      const newMenuFields = { doNotShowRoleIndexInfoModalAgain: true };
      updateTeamMenu(newMenuFields, 'ownTeam', userConfig.email, userConfig.club);
    }

    setIsRoleIndexInfoModalOpen(false);
  };


  // if the user has not opted out, this will open an info modal to explain how this works
  const openRoleIndexInfoModalIfNeeded = () => {
    const userHasOptedOut = userSettings?.ownTeamMenu?.doNotShowRoleIndexInfoModalAgain;

    if (!userHasOptedOut) {
      setTimeout(() => {
        setIsRoleIndexInfoModalOpen(true);
      }, 300);
    }
  };


  const handleOptionClick = (option: string, isOptionAlreadySelected: boolean) => {
    if (!userConfig || !userSettings) return;

    const newMenu: StringToAnyMap = { [option]: !isOptionAlreadySelected };

    if (isOptionAlreadySelected && menu['colored'] === option && option !== 'role') {
      newMenu['colored'] = null;
    }

    if (option === 'role_index') {
      newMenu['skill_index'] = false;
      if (menu['colored'] === 'skill_index') {
        newMenu['colored'] = null;
      }

      if (!isOptionAlreadySelected) {
        openRoleIndexInfoModalIfNeeded();
      }
    }
    else if (option === 'skill_index') {
      newMenu['role_index'] = false;
      if (menu['colored'] === 'role_index') {
        newMenu['colored'] = null;
      }
    }

    else if (option === 'birth_date') {
      newMenu['age'] = false;
      if (menu['colored'] === 'age') {
        newMenu['colored'] = null;
      }
    }
    else if (option === 'age') {
      newMenu['birth_date'] = false;
      if (menu['colored'] === 'birth_date') {
        newMenu['colored'] = null;
      }
    }

    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const toggleUnavailablePlayers = (isToggled: boolean) => {
    if (!userConfig || !userSettings) return;

    const newMenu = { hideUnavailablePlayers: isToggled };
    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  // const toggleScrollTables = (isToggled: boolean, toggleProperty?: string) => {
  //   if (!userConfig || !userSettings || !toggleProperty) return;

  //   const newMenu = { [toggleProperty]: isToggled };
  //   updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  // };


  const handleIconClick = (option: string, isOptionAlreadySelected: boolean, iconProperty: string, event: React.MouseEvent<HTMLElement>) => {
    if (!userConfig || !userSettings) return;
    event.stopPropagation();

    const newMenu: StringToAnyMap = { [iconProperty]: isOptionAlreadySelected ? null : option };

    if (iconProperty === 'colored' && !isOptionAlreadySelected) {
      if (option !== 'role') {
        newMenu[option] = true;
      }

      if (option === 'role_index') {
        newMenu['skill_index'] = false;
      }
      else if (option === 'skill_index') {
        newMenu['role_index'] = false;
      }
      else if (option === 'birth_date') {
        newMenu['age'] = false;
      }
      else if (option === 'age') {
        newMenu['birth_date'] = false;
      }
    }

    if (iconProperty === 'showLeft' && !isOptionAlreadySelected) {
      newMenu[option] = true;
    }

    if (option === 'role_index' && !isOptionAlreadySelected) {
      openRoleIndexInfoModalIfNeeded();
      newMenu['role_index'] = true;
    }

    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const handleBenchPlayerClick = (player: StringToAnyMap) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverviews[player.id],
    });
  };


  const resetTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await resetTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, userConfig.email, userConfig.club);

    setIsIconHighlighted('reset');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  const archiveTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || !teamOrSquadData['formation'] || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await archiveTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, teamOrSquadData['formation'], userConfig.email, userConfig.club);

    setIsIconHighlighted('archive');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div
      className='team-menu-container'
      style={{
        top: teamOrSquadId === 'ownTeam' ? '2vh' : teamOrSquadId === 'academyTeam' ? 0 : '-2vh',
        height: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '100%' : undefined,
      }}
    >

      <Dialog
        open={isRoleIndexInfoModalOpen}
        onClose={() => handleCloseRoleIndexInfoModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <RoleIndexInTeamsInfoModal
          closeModal={handleCloseRoleIndexInfoModal}
          doNotShowRoleIndexInfoModalAgain={doNotShowRoleIndexInfoModalAgain}
          setDoNotShowRoleIndexInfoModalAgain={setDoNotShowRoleIndexInfoModalAgain}
        />
      </Dialog>

      {teamOrSquadId === 'ownTeam' && showFirstEleven !== undefined && setShowFirstEleven && (
        <div className='team-menu-toggle-container' style={{ marginTop: '-4vh' }}>
          <div className='team-menu-toggle-section' style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}>
            <div className='team-menu-toggle-text'>
              {showContent && translate('showStartingEleven', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && <Toggle isToggled={showFirstEleven} setIsToggled={setShowFirstEleven} boxShadow={'0px 0px 2px 1px #181a2366'} />}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'ownTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={translate('hideUnavailablePlayersDescription', userConfig?.language)}
            style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}
          >
            <div className='team-menu-toggle-text'>
              {translate('hideUnavailablePlayers', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['hideUnavailablePlayers']}
                  setIsToggled={toggleUnavailablePlayers}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 0 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && translate('scrollGoalkeepers', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollGoalkeepers']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollGoalkeepers'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && translate('scrollDefenders', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollDefenders']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollDefenders'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}>
            <div className='team-menu-toggle-text'
            >
              {showContent && translate('scrollMidfielders', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollMidfielders']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollMidfielders'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}>
            <div className='team-menu-toggle-text'
            >
              {showContent && translate('scrollAttackers', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollAttackers']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollAttackers'}
                />
              )}
            </div>
          </div>
        </div>
      )} */}

      {!showFirstEleven && (
        <div
          className='team-menu-player-info-section'
          style={{
            paddingTop: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '2vh' : undefined,
          }}
        >
          <div className='team-menu-title fade-in' style={{ marginBottom: (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 0 : '1vh' }}>
            {showContent && translate('showPlayerInfo', userConfig?.language)}
          </div>

          {menuOptions.map((displayOption: string, index: number) => {
            const property = displayOptionToPropertyMap[displayOption];

            const marginTop = (index === 0 || property === 'birth_date' || property === 'role_index')
              ? (teamOrSquadId === 'ownTeam' ? '1.7vh' : '1.9vh')
              : (teamOrSquadId === 'ownTeam' ? '2.5vh' : '2.7vh');

            const hasColorIcon = propertiesWithColorIcon.includes(property);
            const hasSortIcon = propertiesWithSortIcon.includes(property);
            const hasShowLeftIcon = property === 'role';

            const isSelected = menu[property];
            const isSortDisabled = property === 'role' && !menu.orderBy; // special handling for role as the default sort property
            const isOrderedBy = menu.orderBy === property || isSortDisabled;
            const isColored = menu.colored === property;
            const isShowLeft = isSelected && menu.showLeft === property;

            return (
              <div
                key={index}
                className={'team-menu-info-option fade-in' + (isSelected ? ' team-menu-info-option-selected' : '')}
                style={{ marginTop: marginTop, borderColor: isSelected ? '#b3cbee80' : '#ffffff00' }}
                onClick={() => handleOptionClick(property, isSelected)}
              >
                {showContent && translate(displayOption, userConfig?.language)}

                {hasShowLeftIcon && (
                  <div
                    className={'team-menu-info-option-icon team-menu-info-option-icon-switch' + (isShowLeft ? ' team-menu-info-option-icon-selected' : '')}
                    onClick={(event) => handleIconClick(property, isShowLeft, 'showLeft', event)}
                    title={translate('showLeft', userConfig?.language)}
                  >
                    <SwapHorizIcon style={{ fontSize: 17 }} />
                  </div>
                )}

                {hasColorIcon && (
                  <div
                    className={'team-menu-info-option-icon team-menu-info-option-icon-color-toggle' + (isColored ? ' team-menu-info-option-icon-selected' : '')}
                    onClick={(event) => handleIconClick(property, isColored, 'colored', event)}
                    title={translate('showColors', userConfig?.language)}
                  >
                    <BrushIcon style={{ fontSize: 16 }} />
                  </div>
                )}

                {hasSortIcon && (
                  <div
                    className={
                      'team-menu-info-option-icon'
                      + (isOrderedBy ? ' team-menu-info-option-icon-selected' : '')
                      + (isSortDisabled ? ' team-menu-info-option-icon-disabled' : '')
                    }
                    onClick={(event) => handleIconClick(property, isOrderedBy, 'orderBy', event)}
                    title={translate('orderBy', userConfig?.language)}
                  >
                    <SwapVertIcon style={{ fontSize: 17 }} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {!showFirstEleven && (
        <div className='team-menu-team-actions-section'>
          <div className='team-menu-title fade-in' style={{ marginBottom: (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 0 : '1vh' }}>
            {showContent && translate(isSquad ? 'squadActions' : 'teamActions', userConfig?.language)}
          </div>

          <div
            className='team-menu-info-option fade-in'
            style={{ marginTop: '1.9vh' }}
            onClick={() => openConfirmModal(archiveTeam, isSquad ? 'archiveSquad' : 'archiveTeam', isSquad ? 'archiveSquadInfo' : 'archiveTeamInfo')}
          >
            {showContent && translate(isSquad ? 'archiveSquad' : 'archiveTeam', userConfig?.language)}
            <div
              className={
                'team-menu-info-option-icon team-menu-info-option-icon-no-hover' +
                (isIconHighlighted === 'archive' ? ' team-menu-info-option-icon-highlighted' : '')
              }
            >
              <SaveAltIcon style={{ fontSize: 17 }} />
            </div>
          </div>

          {teamOrSquadId !== 'ownTeam' && teamOrSquadId !== 'academyTeam' && (
            <div
              className='team-menu-info-option fade-in'
              style={{ marginTop: '1.9vh' }}
              onClick={() => openConfirmModal(resetTeam, isSquad ? 'resetSquad' : 'resetTeam', isSquad ? 'resetSquadInfo' : 'resetTeamInfo')}
            >
              {showContent && translate(isSquad ? 'resetSquad' : 'resetTeam', userConfig?.language)}
              <div
                className={
                  'team-menu-info-option-icon team-menu-info-option-icon-no-hover' +
                  (isIconHighlighted === 'reset' ? ' team-menu-info-option-icon-highlighted' : '')
                }
              >
                <ReplayIcon style={{ fontSize: 17 }} />
              </div>
            </div>
          )}
        </div>
      )}

      {showFirstEleven && benchPlayers && (
        <div className='own-team-menu-bench-players-table-container fade-in'>
          <div className='own-team-menu-bench-players-table'>
            <PlayerSimpleTable
              data={
                userSettings?.ownTeamMenu.hideUnavailablePlayers
                  ? benchPlayers.filter(player => (player.role !== 'longTermInjured' && player.role !== 'loanedOut'))
                  : benchPlayers
              }
              onPlayerClick={handleBenchPlayerClick}
              tableType={'bench'}
              maxHeight={'74vh'}
              positionKey={'bench'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
              handlePlayerDrop={handlePlayerDrop}
            />
          </div>
        </div>
      )}

    </div>
  );
};
