import '../mobilePlatform.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../common/language/translations';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';


export const MobileSettings: React.FC = () => {


  const { logout } = useAuthContext() as AuthContextType;

  const navigate = useNavigate();


  const handleSignOut = async () => {
    await logout();
    navigate('/login');
  };


  return (
    <div className='mobile-view-container'>
      <div className='mobile-home-view-background'>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='mobile-view-content-container'>
        <div style={{ margin: 'auto' }} className='account-logout-button' onClick={() => handleSignOut()}>
          <div className='account-logout-button-text'>
            {translate('logout', 'en')}
          </div>
        </div>
      </div>
    </div>
  );
};
