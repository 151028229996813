import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../../common/language/translations';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { PositionGroupsTable } from '../../visualization/PositionGroupsTable';


interface PositionGroupsInfoModalProps {
  closeModal: () => void;
}

export const PositionGroupsInfoModal: React.FC<PositionGroupsInfoModalProps> = ({ closeModal }) => {

  const userConfig = useRecoilValue(userConfigState);
  const { openTextModal } = useOpenGlobalModal();


  return (
    <div className='modal-root-container position-groups-info-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {translate('positionGroups', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='position-groups-info-modal-text'>
        {translate('positionGroupsInfo', userConfig?.language)}
      </div>

      <div className='position-groups-info-modal-position-groups-table-container'>
        <PositionGroupsTable />
      </div>

      <div className='position-groups-info-modal-index-and-confidence-container'>

        <div
          className='position-groups-info-modal-index-and-confidence-section'
          onClick={() => openTextModal(
            metricToDisplayInfo['skill_index'].name[userConfig?.language ?? 'en'],
            metricToDisplayInfo['skill_index'].description[userConfig?.language ?? 'en'],
          )}
        >
          {metricToDisplayInfo['skill_index'].name[userConfig?.language ?? 'en']}
          <InfoOutlinedIcon style={{ fontSize: 14, marginTop: 1 }} />
        </div>

        <div
          className='position-groups-info-modal-index-and-confidence-section'
          onClick={() => openTextModal(
            metricToDisplayInfo['confidence'].name[userConfig?.language ?? 'en'],
            metricToDisplayInfo['confidence'].description[userConfig?.language ?? 'en'],
          )}
        >
          {metricToDisplayInfo['confidence'].name[userConfig?.language ?? 'en']}
          <InfoOutlinedIcon style={{ fontSize: 14, marginTop: 1 }} />
        </div>
      </div>
    </div>
  );
};
