import './input.css';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../common/language/translations';
import { countryCodeToCountryInfo } from '../../static/countries';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { Competition } from '../../types';


interface CompetitionDropDownProps {
  id: string;

  competitionOptions: number[];
  selectedCompetitions: number[];
  setSelectedCompetitions: (value: number[]) => void;
  selectedCompetitionString: string;
  setSelectedCompetitionString: (value: string) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling
}


export const CompetitionDropDown: React.FC<CompetitionDropDownProps> = ({
  id,

  competitionOptions,
  selectedCompetitions,
  setSelectedCompetitions,
  selectedCompetitionString,
  setSelectedCompetitionString,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const [sortedLeagues, setSortedLeagues] = useState<Competition[]>([]);
  const [sortedCups, setSortedCups] = useState<Competition[]>([]);

  const [allLeagueIds, setAllLeaguesIds] = useState<number[]>([]);
  const [tier3CompetitionIds, setTier3CompetitionIds] = useState<number[]>([]);
  const [tier4CompetitionIds, setTier4CompetitionIds] = useState<number[]>([]);

  const [allLeaguesSelected, setAllLeaguesSelected] = useState(false);
  const [allCompetitionsSelected, setAllCompetitionsSelected] = useState(false);
  const [tier3Selected, setTier3Selected] = useState(false);
  const [tier4Selected, setTier4Selected] = useState(false);

  const [topOptionWasJustClicked, setTopOptionWasJustClicked] = useState(false);


  const isEuropeanQualifiersAvailable = competitionOptions.some(competitionId => (competitionId === 184 || competitionId === 185 || competitionId === 202));


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (competitionId: string, isOptionAlreadySelected: boolean) => {

    if (competitionId === 'allCompetitions') {
      const newSelectedCompetitions = isOptionAlreadySelected
        ? []
        : isEuropeanQualifiersAvailable
          ? competitionOptions.concat([100000])
          : competitionOptions;

      setSelectedCompetitions(newSelectedCompetitions);
    }

    if (competitionId === 'allLeagues') {
      if (isOptionAlreadySelected) {
        setSelectedCompetitions(selectedCompetitions.filter(competitionId => !allLeagueIds.includes(competitionId)));
      }
      else {
        setSelectedCompetitions(selectedCompetitions.concat(allLeagueIds).filter(competitionId => competitionId >= 0));
      }
    }

    if (competitionId === 'allCompetitions' || competitionId === 'allLeagues') {
      setTopOptionWasJustClicked(true);
      setTimeout(() => {
        setTopOptionWasJustClicked(false);
      }, 25);
      return;
    }

    if (competitionId === 'tier3') {
      if (isOptionAlreadySelected) {
        setSelectedCompetitions(selectedCompetitions.filter(competitionId => !tier3CompetitionIds.includes(competitionId)));
      }
      else {
        setSelectedCompetitions(selectedCompetitions.concat(tier3CompetitionIds).filter(competitionId => competitionId >= 0));
      }
      return;
    }

    if (competitionId === 'tier4') {
      if (isOptionAlreadySelected) {
        setSelectedCompetitions(selectedCompetitions.filter(competitionId => !tier4CompetitionIds.includes(competitionId)));
      }
      else {
        setSelectedCompetitions(selectedCompetitions.concat(tier4CompetitionIds).filter(competitionId => competitionId >= 0));
      }
      return;
    }

    const competitionIdNumber = Number(competitionId);

    if (competitionIdNumber < 0) {
      let newSelectedCompetitions = selectedCompetitions.slice().filter(competitionId => competitionId < 0);

      if (isOptionAlreadySelected) {
        newSelectedCompetitions = newSelectedCompetitions.filter(item => item !== competitionIdNumber);
      }
      else {
        newSelectedCompetitions.push(competitionIdNumber);
      }

      setSelectedCompetitions(newSelectedCompetitions);
      return;
    }

    let newSelectedCompetitions = selectedCompetitions.slice();

    if (isOptionAlreadySelected) {
      newSelectedCompetitions = newSelectedCompetitions.filter(item => item !== competitionIdNumber);
    }
    else {
      newSelectedCompetitions.push(competitionIdNumber);
    }

    setSelectedCompetitions(newSelectedCompetitions.filter(competitionId => competitionId >= 0));
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '26px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const getTierName = useCallback((tier: string, allTierIds: number[]) => {
    if (allTierIds.length === 0) return '';

    const competition = competitions[allTierIds[0]];

    if (allTierIds.length === 1) return competition.name;

    return competition.countryCode === 'NO'
      ? (tier === '3' ? 'PostNord-ligaen' : '3. divisjon')
      : translate('tier', userConfig?.language) + ' ' + tier;
  }, [competitions, userConfig?.language]);


  // const showSelectedCompetitions = () => {

  //   if (allCompetitionsSelected) {
  //     return (userConfig ? staticLanguageMap['allCompetitions'][userConfig.language] : '');
  //   }
  //   if (allLeaguesSelected && selectedCompetitions.length === allLeagueIds.length) {
  //     return (userConfig ? staticLanguageMap['allLeagues'][userConfig.language] : '');
  //   }

  //   if (selectedCompetitions.length === 0) return '';

  //   let display = '';

  //   let tier3IsDisplayed = false;
  //   let tier4IsDisplayed = false;

  //   selectedCompetitions.sort((a, b) => competitions[a].orderIndex - competitions[b].orderIndex);
  //   selectedCompetitions.forEach(competitionId => {
  //     const competition = competitions[competitionId];

  //     if (competition.tier === '3' && !tier3IsDisplayed) {
  //       display += (userConfig
  //         ? (competition.countryCode === 'NO'
  //           ? 'PostNord-ligaen'
  //           : (staticLanguageMap['tier'][userConfig.language] + ' 3'))
  //         : '') + ', ';
  //       tier3IsDisplayed = true;
  //     }

  //     else if (competition.tier === '4' && !tier4IsDisplayed) {
  //       display += (userConfig
  //         ? (competition.countryCode === 'NO'
  //           ? '3. divisjon'
  //           : (staticLanguageMap['tier'][userConfig.language] + ' 4'))
  //         : '') + ', ';
  //       tier4IsDisplayed = true;
  //     }

  //     else if (competition.tier !== '3' && competition.tier !== '4') {
  //       display += competition.name + ', ';
  //     }
  //   });

  //   if (display.length > 0) display = display.slice(0, -2); // remove trailing comma and space

  //   return display;
  // };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (competitions && competitionOptions.length > 0) {

      // some countries have multiple competitions for tier 3 and 4, and in this case we represent all of them as one virtual competition

      const virtualLeagues: Competition[] = [];
      const virtualCups: Competition[] = [];

      const allLeagueIds: number[] = [];
      const tier3CompetitionIds: number[] = [];
      const tier4CompetitionIds: number[] = [];

      let isVirtualEuropeanQualifiersAdded = false;

      competitionOptions.forEach(competitionId => {
        const competition = competitions[competitionId];

        if (competition && competition.type === 'League') {
          allLeagueIds.push(competitionId);

          if (competition.tier === '1' || competition.tier === '2') {
            virtualLeagues.push(competition);
          }

          else if (competition.tier === '3') {
            tier3CompetitionIds.push(competitionId);
          }

          else if (competition.tier === '4') {
            tier4CompetitionIds.push(competitionId);
          }
        }

        else if (competition && competition.type === 'Cup') {

          virtualCups.push(competition);

          // if european cups are present, we want to add one virtual european cup for all qualifiers (id = 100000)
          if ((competition.id === '184' || competition.id === '185' || competition.id === '202') && !isVirtualEuropeanQualifiersAdded) {
            const virtualEuropeanQualifiers: Competition = {
              id: '100000',
              name: 'European Qualifiers',
              type: 'Cup',
              iterationsWithEventData: [],
              orderIndex: 10026,
              countryCode: 'EU',
            };
            virtualCups.push(virtualEuropeanQualifiers);
            isVirtualEuropeanQualifiersAdded = true;
          }
        }
      });

      if (tier3CompetitionIds.length > 0) {
        const virtualTier3Competition: Competition = {
          id: 'tier3',
          name: getTierName('3', tier3CompetitionIds),
          type: 'League',
          iterationsWithEventData: [],
          orderIndex: 9998,
          countryCode: competitions[tier3CompetitionIds[0]].countryCode,
        };
        virtualLeagues.push(virtualTier3Competition);
      }

      if (tier4CompetitionIds.length > 0) {
        const virtualTier4Competition: Competition = {
          id: 'tier4',
          name: getTierName('4', tier4CompetitionIds),
          type: 'League',
          iterationsWithEventData: [],
          orderIndex: 9999,
          countryCode: competitions[tier4CompetitionIds[0]].countryCode,
        };
        virtualLeagues.push(virtualTier4Competition);
      }

      virtualLeagues.sort((a, b) => a.orderIndex - b.orderIndex);
      virtualCups.sort((a, b) => a.orderIndex - b.orderIndex);

      setSortedLeagues(virtualLeagues);
      setSortedCups(virtualCups);

      setTier3CompetitionIds(tier3CompetitionIds);
      setTier4CompetitionIds(tier4CompetitionIds);
      setAllLeaguesIds(allLeagueIds);
    }
  }, [competitionOptions, competitions, getTierName]);


  useEffect(() => {
    if (competitionOptions.length > 0) {
      setAllLeaguesSelected(allLeagueIds.every(competitionId => selectedCompetitions.includes(competitionId)));
      setTier3Selected(tier3CompetitionIds.every(competitionId => selectedCompetitions.includes(competitionId)));
      setTier4Selected(tier4CompetitionIds.every(competitionId => selectedCompetitions.includes(competitionId)));

      const allCompetitionsSelected = selectedCompetitions.length - (isEuropeanQualifiersAvailable ? 1 : 0) === competitionOptions.length;
      setAllCompetitionsSelected(allCompetitionsSelected);

      if (allCompetitionsSelected) {
        setSelectedCompetitionString('allCompetitions');
      }
      else if (allLeagueIds.every(competitionId => selectedCompetitions.includes(competitionId)) && selectedCompetitions.length === allLeagueIds.length) {
        setSelectedCompetitionString('allLeagues');
      }
      else if (selectedCompetitions.length > 0 && selectedCompetitions.every(competitionId => competitionId < 0)) {
        if (selectedCompetitions.length === 2) {
          setSelectedCompetitionString('allNationalTeams');
        }
        else if (selectedCompetitions.includes(-1)) {
          setSelectedCompetitionString('theNationalTeam');
        }
        else if (selectedCompetitions.includes(-2)) {
          setSelectedCompetitionString('youthNationalTeams');
        }
      }
      else {
        setSelectedCompetitionString('someCompetitions');
      }
    }
  }, [allLeagueIds, tier3CompetitionIds, tier4CompetitionIds, selectedCompetitions]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedCompetitions.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div
        className='drop-down-select-button'
        style={{ height: 26 }}
        onClick={() => handleDropDownClick()}>

        {selectedCompetitions.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedCompetitions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div
              style={{ color: '#000000' }}
              className={(selectedCompetitionString === 'allCompetitions' || selectedCompetitionString === 'allLeagues') ? 'fade-in' : undefined}
            >
              {!topOptionWasJustClicked ? translate(selectedCompetitionString, userConfig?.language) : ''}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: 1 }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: 1 }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div
        className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}>

        <div
          className={
            'drop-down-select-option league-drop-down-option competition-drop-down-option' +
            (allCompetitionsSelected ? ' drop-down-select-option-selected' : '')
          }
          style={{ marginTop: marginBetweenOptions }}
          onClick={() => handleOptionSelect('allCompetitions', allCompetitionsSelected)}
        >
          {translate('allCompetitions', userConfig?.language)}
        </div>

        {sortedLeagues.length > 1 && (
          <div
            className={
              'drop-down-select-option league-drop-down-option competition-drop-down-option' +
              (allLeaguesSelected ? ' drop-down-select-option-selected' : '')
            }
            style={{ marginTop: marginBetweenOptions }}
            onClick={() => handleOptionSelect('allLeagues', allLeaguesSelected)}
          >
            {translate('allLeagues', userConfig?.language)}
          </div>
        )}

        <div className='metric-drop-down-select-divider' style={{ marginTop: 20 }}>&nbsp;</div>

        <div className='metric-drop-down-select-group-title-container' style={{ marginBottom: 2 }}>
          <div className='metric-drop-down-select-group-title'>
            {translate('leagues', userConfig?.language)}
          </div>
        </div>

        {sortedLeagues.map((competition, index) => {

          let isSelected = false;

          const competitionId = Number(competition.id);
          if (isNaN(competitionId)) {
            if (competition.id === 'tier3') {
              isSelected = tier3Selected;
            }
            else if (competition.id === 'tier4') {
              isSelected = tier4Selected;
            }
          }
          else {
            isSelected = selectedCompetitions.includes(competitionId);
          }

          return (
            <div
              className={
                'drop-down-select-option league-drop-down-option competition-drop-down-option' +
                (isSelected ? ' drop-down-select-option-selected' : '')
              }
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => handleOptionSelect(competition.id, isSelected)}
            >
              {competition.countryCode && (
                <img
                  className='league-flag competition-drop-down-option-flag'
                  src={countryCodeToCountryInfo[competition.countryCode].flagUrl}
                  alt={competition.countryCode}
                  draggable={false}
                />
              )}

              {competition.name}
            </div>
          );
        })}

        <div className='metric-drop-down-select-divider' style={{ marginTop: 20 }}>&nbsp;</div>

        <div className='metric-drop-down-select-group-title-container' style={{ marginBottom: 2 }}>
          <div className='metric-drop-down-select-group-title'>
            {translate('cups', userConfig?.language)}
          </div>
        </div>

        {sortedCups.map((competition, index) => {

          const isSelected = selectedCompetitions.includes(Number(competition.id));

          const isEuropeanCup = competition.id === '184' || competition.id === '185' || competition.id === '202';
          const isEuropeanQualifiers = competition.id === '100000';

          const isNM = competition.id === '332';

          return (
            <div
              className={
                'drop-down-select-option league-drop-down-option competition-drop-down-option' +
                (isSelected ? ' drop-down-select-option-selected' : '')
              }
              style={{ marginTop: marginBetweenOptions, flexDirection: 'column' }}
              key={index}
              onClick={() => handleOptionSelect(competition.id, isSelected)}
            >
              <div className='competition-drop-down-option' style={{ display: 'flex', flexDirection: 'row' }}>
                {competition.countryCode && (
                  <img
                    className='league-flag competition-drop-down-option-flag'
                    src={countryCodeToCountryInfo[competition.countryCode].flagUrl}
                    alt={competition.countryCode}
                    draggable={false}
                  />
                )}

                {competition.name}
              </div>

              {isEuropeanCup && (
                <div className='competition-drop-down-option' style={{ marginTop: 2, fontSize: 11, color: '#000000dd' }}>
                  {translate('(groupStageAndKnockoutStage)', userConfig?.language)}
                </div>
              )}

              {isEuropeanQualifiers && (
                <div className='competition-drop-down-option' style={{ marginTop: 2, fontSize: 11, color: '#000000dd' }}>
                  {translate('(allEuropeanQualifyingMatches)', userConfig?.language)}
                </div>
              )}

              {isNM && (
                <div className='competition-drop-down-option' style={{ marginTop: 2, fontSize: 11, color: '#000000dd' }}>
                  {translate('(fromAndIncludingRound3)', userConfig?.language)}
                </div>
              )}
            </div>
          );
        })}

        <div className='metric-drop-down-select-divider' style={{ marginTop: 20 }}>&nbsp;</div>

        <div className='metric-drop-down-select-group-title-container' style={{ marginBottom: 2 }}>
          <div className='metric-drop-down-select-group-title'>
            {translate('nationalTeam', userConfig?.language)}
          </div>
        </div>

        {[-1, -2].map((virtualNationalCompetitionId, index) => {

          const isSelected = selectedCompetitions.includes(virtualNationalCompetitionId);

          const competitionNameKey = virtualNationalCompetitionId === -1 ? 'theNationalTeam' : 'youthNationalTeams';

          return (
            <div
              className={
                'drop-down-select-option league-drop-down-option competition-drop-down-option' +
                (isSelected ? ' drop-down-select-option-selected' : '')
              }
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => handleOptionSelect(String(virtualNationalCompetitionId), isSelected)}
            >
              {translate(competitionNameKey, userConfig?.language)}
            </div>
          );
        })}

      </div>

    </div>
  );
};
