import '../../platform.css';
import '../mobilePlatform.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';

import { translate } from '../../../common/language/translations';
import { getClubSecondaryColor } from '../../static/clubConfigs';


export type MobileNavigationTab = 'home' | 'players' | 'scouting' | 'reports' | 'settings';

interface MobileNavigationProps {
  activeTab: MobileNavigationTab;
  setActiveTab: (tab: MobileNavigationTab) => void;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({ activeTab, setActiveTab }) => {

  const userConfig = useRecoilValue(userConfigState);

  const clubSecondaryColor = getClubSecondaryColor(userConfig?.club ?? '');


  const [tabLineLeft, setTabLineLeft] = useState<number | string>(5); // must match padding of mobile-navigation-container


  const updateTabLine = (tab: MobileNavigationTab) => {
    const tabElement = document.getElementById(tab);
    if (tabElement) {
      setTabLineLeft(tabElement.offsetLeft);
    }
  };


  const handleNavigation = (tab: MobileNavigationTab) => {
    setActiveTab(tab);
    updateTabLine(tab);
  };


  return (

    <div className='mobile-navigation-container'>

      <div
        className='mobile-navigation-tab-line-container'
        style={{ left: tabLineLeft }}
      >
        <div
          className='mobile-navigation-tab-line'
          style={{ backgroundColor: clubSecondaryColor }}
        >
          &nbsp;
        </div>
      </div>

      <div
        id='home'
        className='mobile-navigation-tab'
        onClick={() => handleNavigation('home')}
        style={{ color: activeTab === 'home' ? clubSecondaryColor : '#ffffff99' }}
      >
        <div className='mobile-navigation-tab-icon'>
          <HomeIcon style={{ fontSize: 23 }} />
        </div>
        <div className='mobile-navigation-tab-text'>
          {translate('home', userConfig?.language)}
        </div>
      </div>

      <div
        id='players'
        className='mobile-navigation-tab'
        onClick={() => handleNavigation('players')}
        style={{ color: activeTab === 'players' ? clubSecondaryColor : '#ffffff99' }}
      >
        <div className='mobile-navigation-tab-icon'>
          <GroupsIcon style={{ fontSize: 25, marginBottom: -1 }} />
        </div>
        <div className='mobile-navigation-tab-text'>
          {translate('players', userConfig?.language)}
        </div>
      </div>

      <div
        id='scouting'
        className='mobile-navigation-tab'
        onClick={() => handleNavigation('scouting')}
        style={{ color: activeTab === 'scouting' ? clubSecondaryColor : '#ffffff99' }}
      >
        <div className='mobile-navigation-tab-icon'>
          <PersonSearchIcon style={{ fontSize: 24, marginRight: -3 }} />
        </div>
        <div className='mobile-navigation-tab-text'>
          {translate('scouting', userConfig?.language)}
        </div>
      </div>

      <div
        id='reports'
        className='mobile-navigation-tab'
        onClick={() => handleNavigation('reports')}
        style={{ color: activeTab === 'reports' ? clubSecondaryColor : '#ffffff99' }}
      >
        <div className='mobile-navigation-tab-icon'>
          <FolderIcon style={{ fontSize: 22 }} />
        </div>
        <div className='mobile-navigation-tab-text'>
          {translate('reports', userConfig?.language)}
        </div>
      </div>

      <div
        id='settings'
        className='mobile-navigation-tab'
        onClick={() => handleNavigation('settings')}
        style={{ color: activeTab === 'settings' ? clubSecondaryColor : '#ffffff99' }}
      >
        <div className='mobile-navigation-tab-icon'>
          <SettingsIcon style={{ fontSize: 22 }} />
        </div>
        <div className='mobile-navigation-tab-text'>
          {translate('settings', userConfig?.language)}
        </div>
      </div>

    </div >

  );
};
