import { useRecoilState } from 'recoil';
import { navigationPropsState } from '../atoms/navigationPropsState';

import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';
import { NavigationProps } from '../../types';


export const useSetAndTrackNavigationPropsState = () => {

  const [navigationProps, setNavigationProps] = useRecoilState(navigationPropsState);
  const trackEvent = useTrackEvent();

  const useSetAndTrackNavigationPropsState = (newNavigationProps: NavigationProps) => {

    if (newNavigationProps.activeTab !== navigationProps.activeTab) {
      trackEvent('TabClicked', { tab: newNavigationProps.activeTab });
    }

    setNavigationProps(newNavigationProps);
  };

  return useSetAndTrackNavigationPropsState;
};
