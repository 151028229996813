import './existingDocuments.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { translate } from '../../../common/language/translations';
import { getClubColor } from '../../static/clubConfigs';
import { Rating } from '@mui/material';
import { Toggle } from '../input/Toggle';
import { getDateLocaleFormat, getDisplayPlayerName } from '../../utils/playerUtils';
import { PlayerReport } from '../../types';
import { getShortUserName } from '../../utils/userUtils';


interface ExistingReportsProps {
  reports: PlayerReport[];
  existingReportSelected: PlayerReport | undefined;
  handleExistingReportClicked: (report: PlayerReport) => void;
  isPlayerView?: boolean;
}

export const ExistingReports: React.FC<ExistingReportsProps> = ({ reports, handleExistingReportClicked, existingReportSelected, isPlayerView }) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const trackEvent = useTrackEvent();

  const [showOnlyScoutReports, setShowOnlyScoutReports] = useState(false);
  const [showDeletedReports, setShowDeletedReports] = useState(false);


  const handleSelectReportAndTrackEvent = (report: PlayerReport) => {
    trackEvent('ReportEvent', { action: 'viewed', reportType: report.reportType });
    handleExistingReportClicked(report);
  };


  const getExistingReportClassName = (report: PlayerReport) => {
    let baseClassName = 'existing-document';

    baseClassName += (isPlayerView ? ' existing-report-player-view' : ' existing-report-archive-view');

    if (report.isDeleted) {
      baseClassName += ' existing-document-deleted';
    }
    if (report.id === existingReportSelected?.id) {
      baseClassName += ' existing-document-selected';
    }

    if (userConfig && report.userEmail === userConfig.email) {
      if (isPlayerView) {
        baseClassName += ' existing-report-player-view-by-user';
      }
      else {
        baseClassName += ' existing-report-archive-view-by-user';
      }
    }

    return baseClassName;
  };


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='existing-documents-container'>

      <div className='existing-documents-title'>
        {translate('existingReports', userConfig?.language)}
      </div>

      <div className='existing-documents-divider existing-documents-top-divider'>&nbsp;</div>

      <div className='existing-documents-section'>
        {reports.length === 0 && (
          <div className='no-existing-documents-title'>
            {translate('noExistingReports', userConfig?.language)}
          </div>
        )}
        {reports.map((report: PlayerReport) => {
          if (showOnlyScoutReports && report.reportType !== 'ScoutingReport') return null;
          if (!showDeletedReports && report.isDeleted) return null;

          const isRatingReport = report.reportType === 'ScoutingReport' || report.reportType === 'MatchReport';

          return (
            <div
              key={report.id}
              className={getExistingReportClassName(report)}
              onClick={() => handleSelectReportAndTrackEvent(report)}>
              <div>
                {report.reportType === 'ScoutingReport' && <PlagiarismIcon style={{ fontSize: 22, color: '#fdfdfd', marginTop: 1 }} />}
                {report.reportType === 'MatchReport' && <DescriptionIcon style={{ fontSize: 22, color: '#fdfdfd', marginTop: 1 }} />}
                {report.reportType === 'Comment' && <MessageIcon style={{ fontSize: 22, color: '#fdfdfd', marginTop: 3 }} />}
                {report.reportType === 'Attachment' && <PictureAsPdfIcon style={{ fontSize: 22, color: '#fdfdfd', marginTop: 3 }} />}
              </div>

              <div
                className='existing-document-vertical-divider'
                style={{ backgroundColor: clubColor }}>
                &nbsp;
              </div>

              <div className='existing-document-info'>
                <div className='existing-document-row'>
                  <div className={'existing-document-title' + (isPlayerView && !isRatingReport ? ' existing-report-title-player-view-comment' : '')}>
                    {isPlayerView
                      ? translate(report.reportType, userConfig?.language)
                      : getDisplayPlayerName(report.playerName, 14)}
                  </div>
                  <div className={'existing-report-user' + (isPlayerView && !isRatingReport ? ' existing-report-user-player-view-comment' : '')}>
                    {allUsersWithSameClub[report.userEmail] ? getShortUserName(allUsersWithSameClub[report.userEmail].name) : ''}&nbsp;
                  </div>
                </div>
                <div className='existing-document-row'>
                  <div className='existing-report-player-view-rating'>
                    {isPlayerView && isRatingReport && (
                      <Rating
                        name={report.id + '_rating'}
                        value={report.reportFields.overallScore ?? 0}
                        max={5}
                        precision={0.5}
                        sx={{ fontSize: 14, color: clubColor, '& .MuiRating-iconEmpty': { color: '#bbbdc2' } }}
                        readOnly={true}
                      />
                    )}
                    {!isPlayerView && (
                      <div className='existing-report-archive-view-player-name'>
                        {translate(report.reportType, userConfig?.language)}
                      </div>
                    )}
                  </div>
                  <div className={'existing-report-date' + (isPlayerView && !isRatingReport ? ' existing-report-date-player-view-comment' : '')}>
                    {getDateLocaleFormat(report.date.split('T')[0])}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className='existing-documents-divider existing-documents-bottom-divider'>&nbsp;</div>

      <div className='existing-document-toggle-container existing-document-toggle-top-container'>
        <div className='existing-document-toggle-section'>
          <div className='existing-document-toggle-title'>
            {translate('showOnlyScoutReports', userConfig?.language)}
          </div>
          <div className='existing-document-toggle'>
            <Toggle isToggled={showOnlyScoutReports} setIsToggled={setShowOnlyScoutReports} />
          </div>
        </div>
      </div>

      <div className='existing-document-toggle-container existing-document-toggle-bottom-container'>
        <div className='existing-document-toggle-section'>
          <div className='existing-document-toggle-title'>
            {translate('showDeletedReports', userConfig?.language)}
          </div>
          <div className='existing-document-toggle'>
            <Toggle isToggled={showDeletedReports} setIsToggled={setShowDeletedReports} />
          </div>
        </div>
      </div>

    </div>
  );
};
