import './../scouting.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/input/InputField';
import { DropDownSelect } from '../../../components/input/DropDownSelect';
import { ageKeys } from '../../../static/propertyValues';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { LeagueDropDown } from '../../../components/input/LeagueDropDown';
import { CountryDropDown } from '../../../components/input/CountryDropDown';
import { Club } from '../../../types';
import { ClubDropDown } from '../../../components/input/ClubDropDown';


interface PlayerFindingFiltersProps {
  nameSearchString: string;
  setNameSearchString: (value: string) => void;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  selectedMaxAge: string[];
  setSelectedMaxAge: (value: string[]) => void;
  selectedMinAge: string[];
  setSelectedMinAge: (value: string[]) => void;
  selectedLeagues: string[];
  setSelectedLeagues: (value: string[]) => void;
  selectedNationalities: string[];
  setSelectedNationalities: (value: string[]) => void;
  selectedClubs: Club[];
  setSelectedClubs: (value: Club[]) => void;

  isAnyFilterExpanded: boolean;
  setIsAnyFilterExpanded: (value: boolean) => void;

  handleFilterUnexpansion: (filter: string) => void;
  handleSearchReset: () => void;
}

export const PlayerFindingFilters: React.FC<PlayerFindingFiltersProps> = ({
  nameSearchString,
  setNameSearchString,
  onKeyDownNameSearchField,

  selectedMaxAge,
  setSelectedMaxAge,
  selectedMinAge,
  setSelectedMinAge,
  selectedLeagues,
  setSelectedLeagues,
  selectedNationalities,
  setSelectedNationalities,
  selectedClubs,
  setSelectedClubs,

  isAnyFilterExpanded,
  setIsAnyFilterExpanded,

  handleFilterUnexpansion,
  handleSearchReset,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const width = useWindowSize().width;

  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isMinAgeExpanded, setIsMinAgeExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isLeaguesExpanded, setIsLeaguesExpanded] = useState(false);
  const [isClubsExpanded, setIsClubsExpanded] = useState(false);


  const onChangeNameSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearchString(event.target.value);
  };


  const handleResetFiltersClick = () => {
    handleSearchReset();

    setNameSearchString('');

    setSelectedMaxAge([]);
    setSelectedMinAge([]);
    setSelectedNationalities([]);
    setSelectedLeagues([]);
    setSelectedClubs([]);
  };


  useEffect(() => {
    if (!isAnyFilterExpanded) {
      setIsMaxAgeExpanded(false);
      setIsMinAgeExpanded(false);
      setIsNationalitiesExpanded(false);
      setIsLeaguesExpanded(false);
      setIsClubsExpanded(false);
    }
  }, [isAnyFilterExpanded]);


  useEffect(() => {
    if (!isMaxAgeExpanded) {
      handleFilterUnexpansion('maxAge');
    }
  }, [isMaxAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMinAgeExpanded) {
      handleFilterUnexpansion('minAge');
    }
  }, [isMinAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNationalitiesExpanded) {
      handleFilterUnexpansion('nationalities');
    }
  }, [isNationalitiesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLeaguesExpanded) {
      handleFilterUnexpansion('leagues');
    }
  }, [isLeaguesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isClubsExpanded) {
      handleFilterUnexpansion('clubs');
    }
  }, [isClubsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <div className='player-finding-filter-section-input-container-name'>
        <InputField
          searchString={nameSearchString}
          onChangeInputField={onChangeNameSearchField}
          onKeyDownInputField={onKeyDownNameSearchField}
          resetSearchString={() => setNameSearchString('')}
          defaultInput={translate(width > 1150 ? 'searchForName' : 'name', userConfig?.language)}
          showDefaultInput={true}
          style={{ boxShadow: '0px 0px 3px 1px #0000002d' }}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-min-age'>
        {translate('minAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-min-age'>
        <DropDownSelect
          id={'player-finding-filter-section-min-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={selectedMinAge}
          setSelectedOptions={setSelectedMinAge}
          isDropDownExpanded={isMinAgeExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMinAgeExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-max-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-max-age'>
        <DropDownSelect
          id={'player-finding-filter-section-max-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={selectedMaxAge}
          setSelectedOptions={setSelectedMaxAge}
          isDropDownExpanded={isMaxAgeExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMaxAgeExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-nationalities'>
        {translate('nationalities', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-nationalities'>
        <CountryDropDown
          id={'player-finding-filter-section-nationalities-drop-down'}
          selectedCountryCodes={selectedNationalities}
          setSelectedCountryCodes={setSelectedNationalities}
          isDropDownExpanded={isNationalitiesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsNationalitiesExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-competitions'>
        {translate('leagues', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-competitions'>
        <LeagueDropDown
          id={'player-finding-filter-section-competitions-drop-down'}
          selectedLeagues={selectedLeagues}
          setSelectedLeagues={setSelectedLeagues}
          isDropDownExpanded={isLeaguesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsLeaguesExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-clubs'>
        {translate('clubs', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-clubs'>
        <ClubDropDown
          id={'player-finding-filter-section-clubs-drop-down'}
          selectedClubs={selectedClubs}
          setSelectedClubs={setSelectedClubs}
          isDropDownExpanded={isClubsExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsClubsExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div
        className='filter-section-icon filter-section-right-icon'
        onClick={() => handleResetFiltersClick()}
        title={translate('resetFilters', userConfig?.language)}
      >
        <ReplayIcon style={{ fontSize: 20 }} />
      </div>

    </div>
  );
};
