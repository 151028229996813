import {
  ClubSettings,
  CompetitionMap,
  StringToAnyMap,
  PlayerClubIteration,
  UserConfig,
  StringToNumberMap,
  RoleConfig,
} from '../../../types';

import {
  goalkeeperSeasonTableMetricGroups,
  metricToDisplayInfo,
  outfieldSeasonTableMetricGroups,
  skillcornerStats
} from '../../../static/playerMetrics';

import LockIcon from '@mui/icons-material/Lock';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BlockIcon from '@mui/icons-material/Block';
import BuildIcon from '@mui/icons-material/Build';

import { translate } from '../../../../common/language/translations';
import { countryCodeToCountryInfo } from '../../../static/countries';
import { getColorBlindRatingColor, getIndexColor, getRatingColor } from '../../../utils/colorUtils';
import { Toggle } from '../../input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { getDisplayPositions } from '../../../utils/playerUtils';
import { ProgressCircle } from '../../visualization/ProgressCircle';


const renderSeasonCell = (
  competitions: CompetitionMap,
  language: string,
  selectedPositionGroupKey: string,
) => {
  const SeasonCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <SeasonCell
        clubIteration={row.original}
        competitions={competitions}
        language={language}
        selectedPositionGroupKey={selectedPositionGroupKey}
      />
    );
  };

  SeasonCellRenderer.displayName = 'SeasonCellRenderer';
  return SeasonCellRenderer;
};


const SeasonCell: React.FC<{
  clubIteration: PlayerClubIteration,
  competitions: CompetitionMap,
  language: string,
  selectedPositionGroupKey: string,
}> = ({ clubIteration, competitions, language, selectedPositionGroupKey }) => {

  // iteration info
  const competition = competitions[clubIteration.competition_id];
  const flagUrl = (competition && competition.countryCode && countryCodeToCountryInfo[competition.countryCode])
    ? countryCodeToCountryInfo[competition.countryCode].flagUrl
    : undefined;
  const competitionName = competition?.name;
  const iterationFontSize = competitionName && competitionName.length > 20 ? 11 : 12;

  // club info
  const club = clubIteration.club;
  let clubName: string | undefined = club?.name;
  let clubLogoUrl: string | undefined = club?.logo_url;
  if (club?.is_national_team) {
    clubName = club?.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[club?.country_code].name[language] : undefined;
    clubLogoUrl = club?.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[club?.country_code].flagUrl : undefined;
  }
  const clubFontSize = clubName && clubName.length > 20 ? 11 : 12;

  // total minutes info
  const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
    ? clubIteration.position_groups[0]
    : selectedPositionGroupKey;
  const positionGroupStats = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration];
  const totalMinutesPlayed = positionGroupStats?.minutes_played ?? 0;
  const minutesColorIndex = Math.min(totalMinutesPlayed / 150, 10);
  const minutesColor = minutesColorIndex ? getIndexColor(minutesColorIndex, 65) : 'transparent';

  // if selectedPositionGroupKey is overall, we want to display positions similar to other tables, and then minutes played
  // if selectedPositionGroupKey is not overall, we want to display minutes played for each position
  let positionColumn: JSX.Element | undefined = undefined;
  if (selectedPositionGroupKey === 'overall') {
    const primaryPositions = clubIteration.primary_positions ?? [];
    const secondaryPositions = clubIteration.secondary_positions ?? [];

    const primaryPositionsToUse = primaryPositions.slice(0, 3);
    const primaryPositionString = getDisplayPositions(primaryPositionsToUse, language);

    let secondaryPositionsToUse = [];
    let secondaryPositionString: string | undefined = undefined;

    if (primaryPositionsToUse.length <= 1 && secondaryPositions.length > 0) {
      secondaryPositionsToUse = secondaryPositions.slice(0, 2);
      secondaryPositionString = getDisplayPositions(secondaryPositionsToUse, language);
    }

    const primaryFontSize = primaryPositionsToUse.length > 2 ? 10 : secondaryPositionsToUse.length > 1 ? 11 : 12;
    const secondaryFontSize = 12 - secondaryPositionsToUse.length;

    positionColumn = (
      <div className='season-table-info-cell-position-column' style={{ fontSize: primaryFontSize, gap: 4 }}>
        <div className='season-table-info-cell-position-row' style={{ justifyContent: 'left', gap: 4 }}>
          {primaryPositionString}

          {secondaryPositionString && (
            <div style={{ fontSize: secondaryFontSize, color: '#ffffffbb' }}>
              {'(' + secondaryPositionString + ')'}
            </div>
          )}
        </div>

        <div style={{ fontSize: 11 }}>
          {totalMinutesPlayed} min
        </div>
      </div>
    );
  }

  else {
    let positions = positionGroupStats?.positions ?? [];
    let positionColor: string | undefined = undefined;
    let minutesColor: string | undefined = undefined;

    if (positions.length === 0) {
      positions = [{ position: '-', percentage: 0, minutes: 0 }];
      positionColor = '#ffffff99';
      minutesColor = '#ffffff66';
    }

    const fontSize = positions.length > 2 ? 10 : 11;

    positionColumn = (
      <div className='season-table-info-cell-position-column' style={{ fontSize: fontSize }}>
        {positions.map((position, index) => {
          return (
            <div key={index} className='season-table-info-cell-position-row' style={{ color: positionColor }}>
              {getDisplayPositions([position.position], language)}

              <div style={{ color: minutesColor }}>
                {position.minutes} min
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className='season-table-info-cell'>

      <div className='season-table-info-cell-season-column'>

        <div className='season-table-info-cell-row' style={{ fontSize: iterationFontSize }}>
          {flagUrl && (
            <img
              className='season-table-info-cell-flag'
              src={flagUrl}
              alt=''
              draggable={false}
            />
          )}

          {competitionName}

          <div style={{ marginLeft: 4 }}>
            {clubIteration.season}
          </div>
        </div>

        <div className='season-table-info-cell-row' style={{ fontSize: clubFontSize }}>
          {clubLogoUrl && !clubIteration.club?.is_national_team && (
            <div className='season-table-club-logo-container'>
              <img
                className='season-table-club-logo'
                src={clubLogoUrl}
                alt=''
                draggable={false}
              />
            </div>
          )}

          {clubLogoUrl && clubIteration.club?.is_national_team && (
            <div className='season-table-club-logo-container'>
              <img
                className='season-table-info-cell-flag'
                style={{ marginTop: 1 }}
                src={clubLogoUrl}
                alt=''
                draggable={false}
              />
            </div>
          )}

          {clubName}
        </div>

      </div>

      {positionColumn}

      <div className='season-table-info-cell-minutes-column'>
        <ProgressCircle
          size={14}
          progress={minutesColorIndex * 10}
          color={minutesColor}
          strokeWidth={9}
          trailColor='#ffffff44'
        />
      </div>

    </div>
  );
};


const renderMetricOrRoleRatingCell = (
  property: string,
  metricGroupKey: string,
  selectedPositionGroupKey: string,
  roleConfig: RoleConfig | undefined,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
) => {
  const MetricOrRoleRatingCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <MetricOrRoleRatingCell
        clubIteration={row.original}
        property={property}
        selectedPositionGroupKey={selectedPositionGroupKey}
        roleConfig={roleConfig}
        metricGroupKey={metricGroupKey}
        metricGroupIsToggled={metricGroupIsToggled}
        isColorBlind={isColorBlind}
      />
    );
  };

  MetricOrRoleRatingCellRenderer.displayName = 'MetricOrRoleRatingCellRenderer';
  return MetricOrRoleRatingCellRenderer;
};


const MetricOrRoleRatingCell: React.FC<{
  clubIteration: PlayerClubIteration,
  property: string,
  selectedPositionGroupKey: string,
  roleConfig: RoleConfig | undefined,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
}> = ({ clubIteration, property, selectedPositionGroupKey, roleConfig, metricGroupKey, metricGroupIsToggled, isColorBlind }) => {

  const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
    ? clubIteration.position_groups[0]
    : selectedPositionGroupKey;

  if (
    !clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration] ||
    !clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].minutes_played ||
    !clubIteration.event_data_available
  ) {
    return (
      <div className='season-table-color-cell-container'>
        <div className='season-table-color-cell' style={{ color: '#ffffff99' }}>
          -
        </div>
      </div>
    );
  }

  const isRoleRatingUnderConstruction = roleConfig && (
    !roleConfig.weightsLastUpdated ||
    !roleConfig.dataLastUpdated ||
    roleConfig.dataLastUpdated < roleConfig.weightsLastUpdated
  );

  if (isRoleRatingUnderConstruction) {
    return (
      <div className='season-table-color-cell-container'>
        <div className='season-table-color-cell'>
          <BuildIcon style={{ fontSize: 14, marginTop: 1, color: '#ffffffaa' }} />
        </div>
      </div>
    );
  }

  const metricMap: StringToNumberMap | undefined = metricGroupKey === 'ratings' && roleConfig
    ? clubIteration.position_group_stats[roleConfig.positionGroup]?.custom_ratings
    : clubIteration.position_group_stats[selectedPositionGroupKeyForIteration]?.metrics;

  const normalizedMetricKey = metricGroupKey === 'ratings'
    ? property + '_rating'
    : property + '_normalized';

  const normalizedValue = metricMap
    ? metricMap[normalizedMetricKey]
    : undefined;

  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricMap && metricGroupIsToggled) {
    let actualValue = metricMap[property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (metricGroupKey === 'attacking' && property !== 'n_shots_per_90') || property === 'gk_postshot_xg_prevented_difference_per_shot'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_90', 'skillcorner_distance_otip_per_90'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }
    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_90',
      'skillcorner_sprinting_distance_otip_per_90',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_90',
      'skillcorner_hsr_distance_otip_per_90',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }
    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }
    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = metricMap['gk_n_penalties_saved']
        + '/'
        + metricMap['gk_n_penalties_faced']
        + ' ('
        + displayValue
        + ')';
    }
  }

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(normalizedValue ?? 0, 0.7)
    : getRatingColor(normalizedValue ?? 0);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500,
        }}>
        {displayValue}
      </div>
    </div>
  );
};


// skill rating is always the first column and responsible for rendering locks and blocks
const renderSkillRatingCell = (selectedPositionGroupKey: string, isColorBlind: boolean) => {
  const SkillRatingCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <SkillRatingCell
        clubIteration={row.original}
        selectedPositionGroupKey={selectedPositionGroupKey}
        isColorBlind={isColorBlind}
      />
    );
  };

  SkillRatingCellRenderer.displayName = 'SkillRatingCellRenderer';
  return SkillRatingCellRenderer;
};

const SkillRatingCell: React.FC<{
  clubIteration: PlayerClubIteration,
  selectedPositionGroupKey: string,
  isColorBlind: boolean
}> = ({ clubIteration, selectedPositionGroupKey, isColorBlind, }) => {

  const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
    ? clubIteration.position_groups[0]
    : selectedPositionGroupKey;

  if (
    !clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration] ||
    !clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].minutes_played
  ) {
    return (
      <div className='season-table-text-cell'>
        <BlockIcon style={{ fontSize: 18, marginLeft: 14, color: '#ffffff55' }} />
        <ArrowRightAltIcon style={{ fontSize: 16, marginLeft: 1, color: '#ffffff55' }} />
      </div>
    );
  }
  else if (!clubIteration.event_data_available) {
    return (
      <div className='season-table-text-cell'>
        <LockIcon style={{ fontSize: 18, marginTop: -1, marginLeft: 14, color: '#ffffffaa' }} />
        <ArrowRightAltIcon style={{ fontSize: 16, marginLeft: 1, color: '#ffffffaa' }} />
      </div>
    );
  }

  const value = clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].skill_rating;

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(value, 0.7)
    : getRatingColor(value);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500,
        }}>
        {value !== undefined ? Math.round(value * 10) / 10 : '-'}
      </div>
    </div>
  );
};


export const getSeasonColumns = (
  selectedPositionGroupKey: string,
  clubSettings: ClubSettings | null,
  userConfig: UserConfig | null,
  seasonStatsToggles: StringToAnyMap,
  competitions: CompetitionMap,
  hasSkillcorner: boolean,
  openTextModal: (title: string, text: string) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
) => {

  if (!userConfig || !clubSettings) return [];

  const seasonCellWidth = 360;

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='season-table-top-level-header-cell-sticky' style={{ width: seasonCellWidth }}>
          {translate('season', userConfig.language)}

          <div
            className='season-table-top-level-header-cell-sticky-toggle-section'
            title={translate('showOnlyDomesticLeagues', userConfig.language)}
            style={{ marginLeft: 86 }}
          >
            {translate('leagues', userConfig.language)}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={seasonStatsToggles['showOnlyDomesticLeagues']}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { 'showOnlyDomesticLeagues': value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          </div>

          <div
            className='season-table-top-level-header-cell-sticky-toggle-section'
            title={translate('showOnlySeasonsWith180Minutes', userConfig.language)}
          >
            {translate('minutes', userConfig.language)}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={seasonStatsToggles['showOnlySeasonsWith180Minutes'] ?? false}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { 'showOnlySeasonsWith180Minutes': value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          </div>
        </div>,
      id: 'season',
      sticky: 'left',
      accessor: 'season',
      Cell: renderSeasonCell(competitions, userConfig.language, selectedPositionGroupKey),
      width: seasonCellWidth,
      isStickyColumn: true,
    }
  ];

  const roleRatings = clubSettings.positionGroupToRoleIds[selectedPositionGroupKey] ?? [];
  const ratingMetricGroup = { 'ratings': ['skill_rating', ...roleRatings] };
  const normalMetricGroups = selectedPositionGroupKey === 'GK' ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const skillCornerMetricGroup = hasSkillcorner ? { 'skillcorner': skillcornerStats } : {};

  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups, ...skillCornerMetricGroup };

  Object.entries(metricGroups).forEach(([metricGroupKey, metrics]: [string, string[]], index) => {
    const isFinalMetricGroup = index === Object.keys(metricGroups).length - 1;

    const metricGroupName = metricToDisplayInfo[metricGroupKey].name[userConfig.language];
    const metricGroupDescription = metricToDisplayInfo[metricGroupKey].description[userConfig.language];

    const topLevelCell = {
      Header:
        <div className={'season-table-top-level-header-cell' + (!isFinalMetricGroup ? ' season-table-top-level-header-cell-with-border' : '')}>
          <div
            className='season-table-sub-level-header-cell-text'
            title={translate('showDescription', userConfig.language)}
            onClick={() => openTextModal(metricGroupName, metricGroupDescription)}
          >
            {metricGroupName}
          </div>

          {metricGroupKey !== 'ratings' && metricGroupKey !== 'obv' && (
            <div
              className='season-table-top-level-header-cell-toggle'
              title={translate(seasonStatsToggles[metricGroupKey] ? 'showNormalizedValues' : 'showActualValues', userConfig.language)}
            >
              <Toggle
                isToggled={seasonStatsToggles[metricGroupKey]}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { [metricGroupKey]: value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          )}
        </div>,
      id: metricGroupKey,

      columns: metrics.map((metric: string, index: number) => {
        const isFinalSubMetric = !isFinalMetricGroup && index === metrics.length - 1;
        const roleConfig = clubSettings?.roleConfigs[metric];

        const metricName = roleConfig !== undefined
          ? roleConfig.name
          : metricToDisplayInfo[metric]?.name[userConfig.language];

        const metricDescription = roleConfig !== undefined
          ? undefined
          : metricToDisplayInfo[metric]?.description[userConfig.language];

        const cell = {
          Header:
            <div className={'season-table-sub-level-header-cell' + (isFinalSubMetric ? ' season-table-sub-level-header-cell-with-border' : '')}>
              <div
                className='season-table-sub-level-header-cell-text'
                title={translate('showDescription', userConfig.language)}
                onClick={() => roleConfig ? openRoleInfoModal(roleConfig) : openTextModal(metricName, metricDescription)}
              >
                {metricName}
              </div>
            </div>,
          id: metric,
          accessor: metric,
          Cell: metric === 'skill_rating'
            ? renderSkillRatingCell(selectedPositionGroupKey, userConfig.isColorBlind)
            : renderMetricOrRoleRatingCell(
              metric,
              metricGroupKey,
              selectedPositionGroupKey,
              roleConfig,
              seasonStatsToggles[metricGroupKey] ?? false,
              userConfig.isColorBlind
            ),
          width: getMetricColumnWidth(metricName),
          isFinalSubMetric: isFinalSubMetric,
        };
        return cell;
      }),
    };

    columns.push(topLevelCell);
  });

  return columns;
};


export const getMetricColumnWidth = (metricName: string) => {
  return Math.max(metricName.length * 7 + 10.5, 50) + 20;
};
