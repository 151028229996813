import { selector } from 'recoil';
import { teamsState } from '../atoms/teamsState';
import { PlayerMap, PlayerEntry } from '../../types';
import { positionOptionsPlatform } from '../../static/propertyValues';


export const playerTeamDataSelector = selector({
  key: 'playerTeamData',
  get: ({ get }) => {
    const teams = get(teamsState);

    if (teams) {
      const playerTeamData: PlayerMap = {};

      positionOptionsPlatform.forEach(positionKey => {
        Object.keys(teams).forEach(teamId => {

          const teamData = teams[teamId];
          if (positionKey in teamData) {

            const positionArray = teamData[positionKey];
            positionArray.forEach((player: PlayerEntry) => {

              playerTeamData[player.id] = {
                currentTeam: teamId,
                currentPosition: positionKey,
              };
            });
          }
        });
      });

      return playerTeamData;
    }

    return null;
  },
});
