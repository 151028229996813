import {
  CompetitionMap,
  StringToAnyMap,
  PlayerId,
  PlayerMap,
  PlayerOverview
} from '../types';

import {
  academyRoleKeysToSortOrder,
  accessKeysToSortOrder,
  contractKeysToMonths,
  roleKeysToSortOrder
} from '../static/propertyValues';

import { translate } from '../../common/language/translations';


// get player name to display, where the first name pattern that is not longer than maxLength is returned
// the patterns are ordered <fullname>, <firstName><initials><lastName>, <firstName><lastName>, <initials><lastName>
// if fullname does not contain a space, the fullname is returned regardless of length
// if none of the three first patterns are short enough, the shortest pattern is returned unless shortestNameIsNotAnOption is true
export const getDisplayPlayerName = (
  fullname: string,
  maxLength?: number,
  shortestNameIsNotAnOption?: boolean,
) => {
  if (fullname === undefined) return '';

  if (maxLength === undefined || fullname.length <= maxLength) {
    return fullname;
  }

  const playerName = fullname.trim().replace('"', '');
  const nameList = playerName.split(' ');

  if (nameList.length === 1) {
    return fullname;
  }

  const firstName = nameList[0];
  const lastName = nameList[nameList.length - 1];
  const initials = nameList.slice(1, nameList.length - 1).map(name => name[0]).join('. ') + '.';

  const nameWithInitials = `${firstName} ${initials} ${lastName}`;
  if (nameWithInitials.length <= maxLength) {
    return nameWithInitials;
  }

  const nameWithoutInitials = `${firstName} ${lastName}`;
  if (shortestNameIsNotAnOption || nameWithoutInitials.length <= maxLength) {
    return nameWithoutInitials;
  }

  return `${firstName[0]}. ${lastName}`;
};


// Get playerId, where a number is returned if possible, else string
export const getStrictPlayerId = (playerId: PlayerId) => {
  const numberId = Number(playerId);
  if (isNaN(numberId)) {
    return playerId;
  }
  return numberId;
};


// Gets the year of a date
export const getYear = (date: string) => {
  if (date) {
    return date.split('-')[0];
  }
  return '';
};


// Gets last two digits of the year of a date
export const getYearShort = (date: string) => {
  if (date) {
    return date.split('-')[0].substring(2);
  }
  return '';
};


// Gets the date in locale format (dd/mm/yyyy, yyyy//mm/dd, etc.)
export const getDateLocaleFormat = (date: string | undefined) => {
  if (!date) {
    return '-';
  }

  const dateList = date.split('-');

  return dateList[2] + '.' + dateList[1] + '.' + dateList[0];
};


// Get number of months left of a contract
export const getMonthsLeftOfContract = (contractExpiration: string): number | undefined => {
  if (!contractExpiration) return undefined;

  const expirationDate = new Date(contractExpiration);
  const currentDate = new Date();

  const startYear = currentDate.getFullYear();
  const startMonth = currentDate.getMonth();

  const endYear = expirationDate.getFullYear();
  const endMonth = expirationDate.getMonth();

  const monthsLeft = (endYear - startYear) * 12 + (endMonth - startMonth);

  return Math.max(0, monthsLeft);
};


// Get age based on birth date
export const getAge = (birthDate: string) => {
  if (!birthDate || birthDate === '') return undefined;

  const date = new Date(birthDate);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - date.getTime();

  const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  return Number(diffInYears.toFixed(1));
};


// Gets sort distance between two players based on role (descending, where order of roles defined above)
export const getSortDistanceRole = (a: StringToAnyMap, b: StringToAnyMap, useAcademyRole?: boolean) => {
  const roleKeys = useAcademyRole ? academyRoleKeysToSortOrder : roleKeysToSortOrder;

  if (!b.role || !roleKeys[b.role]) {
    return -1;
  }
  if (!a.role || !roleKeys[a.role]) {
    return 1;
  }
  return roleKeys[b.role] - roleKeys[a.role];
};


// Gets sort distance between two access categories
export const getSortDistanceAccess = (a: StringToAnyMap, b: StringToAnyMap) => {
  const aHasAccess = a.access && accessKeysToSortOrder[a.access];
  const bHasAccess = b.access && accessKeysToSortOrder[b.access];

  if (!bHasAccess && aHasAccess) return -1;
  if (!aHasAccess && bHasAccess) return 1;

  if (aHasAccess && bHasAccess) {
    return accessKeysToSortOrder[b.access] - accessKeysToSortOrder[a.access];
  }

  return a.name.localeCompare(b.name);
};


// Gets sort distance between two players based on a number property (descending)
export const getSortDistanceNumberProperty = (a: StringToAnyMap, b: StringToAnyMap, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!b[property]) {
    return -1;
  }
  if (!a[property]) {
    return 1;
  }
  return b[property] - a[property];
};


// Gets sort distance between two players based on a date property (descending)
export const getSortDistanceDateProperty = (a: StringToAnyMap, b: StringToAnyMap, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!a[property]) {
    return -1;
  }
  if (!b[property]) {
    return 1;
  }
  const a_time = new Date(a[property]).getTime();
  const b_time = new Date(b[property]).getTime();
  return b_time - a_time;
};


// Player property getters

export const getPlayerPropertyValue = (
  property: string,
  playerOverview?: StringToAnyMap | undefined,
  playerId?: PlayerId | undefined,
  players?: PlayerMap | undefined,
  valueTransformer?: (value: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
  resultIfUndefined?: string,
) => {

  const playerDocument = playerId !== undefined && players ? players[playerId] : undefined;

  // user provided values have presedence
  if (playerDocument && property in playerDocument) {
    const value = playerDocument[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  if (playerOverview && property in playerOverview) {
    const value = playerOverview[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  return resultIfUndefined;
};


export const getDisplayPositions = (
  positionList: string[] | undefined,
  language: string | undefined,
) => {
  if (positionList && positionList.length > 0) {
    const positions = positionList.map((position: string) => {
      return translate(position, language);
    });

    return positions.join(' / ');
  }
};


export const getDisplayCompetition = (
  club: StringToAnyMap | undefined,
  competitions: CompetitionMap
) => {
  if (club && club.current_competition_id) {
    return competitions[club.current_competition_id]?.name ?? '-';
  }
  return '-';
};


// returns true of given players matches all given filters (currently only used for alert filters)
export const playerMatchesFilters = (
  playerOverview: PlayerOverview,
  filters: StringToAnyMap
) => {

  if (filters['maxAge']) {
    const playerAge = getAge(playerOverview.birth_date);

    if (playerAge === undefined || playerAge > filters['maxAge']) {
      return false;
    }
  }

  if (filters['contractStatus']) {
    const monthsLeft = getMonthsLeftOfContract(playerOverview.contract_expiration);
    const monthsLeftFilter = contractKeysToMonths[filters['contractStatus']];
    const includePlayersWithoutContract = filters['isContractStatusToggled'];

    if (monthsLeft !== undefined && monthsLeft > monthsLeftFilter) {
      return false;
    }

    if (monthsLeft === undefined && !includePlayersWithoutContract) {
      return false;
    }
  }

  if (filters['leagues'] && !filters['leagues'].includes(playerOverview.club.current_competition_id)) {
    return false;
  }

  if (filters['positions']) {

    if (!filters['positions'].some((position: string) => playerOverview.primary_positions.includes(position))) {
      return false;
    }
  }

  if (filters['minConfidence']
    && playerOverview.position_group_stats?.overall
    && playerOverview.position_group_stats.overall.confidence < filters['minConfidence']
  ) {
    return false;
  }

  if (filters['minSkillIndex']
    && playerOverview.position_group_stats?.overall
    && playerOverview.position_group_stats.overall.skill_index < filters['minSkillIndex']) {
    return false;
  }

  if (filters['nationalities'] && !filters['nationalities'].includes(playerOverview.country_code)) {
    return false;
  }

  return true;
};
