import './../scouting.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useTrackEvent } from '../../../services/server/analytics/useTrackEvent';

import { Dialog } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { translate } from '../../../../common/language/translations';
import { DropDownSelect } from '../../../components/input/DropDownSelect';
import { minuteAbsoluteKeys, minutePercentageKeys, ageKeys, contractKeys } from '../../../static/propertyValues';
import { StringToAnyMap, Iteration, RatingRequirement } from '../../../types';
import { SaveOrLoadSearchModal } from '../../../components/modals/miscModals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/scouting';
import { CountryDropDown } from '../../../components/input/CountryDropDown';
import { RatingDropDown } from '../../../components/input/RatingDropDown';
import { IterationDropDown } from '../../../components/input/IterationDropDown';
import { MetricDropDown } from '../../../components/input/MetricDropDown';
import { PositionAndRoleDropDown } from '../../../components/input/PositionAndRoleDropDown';


interface SeasonScoutingFiltersProps {
  selectedMinutes: string[];
  setSelectedMinutes: (value: string[]) => void;
  selectedMaxAge: string[];
  setSelectedMaxAge: (value: string[]) => void;
  selectedPositions: string[];
  setSelectedPositions: (value: string[]) => void;
  selectedRoles: string[];
  setSelectedRoles: (value: string[]) => void;
  selectedIterations: Iteration[];
  setSelectedIterations: (value: Iteration[]) => void;
  selectedOrderBy: string[];
  setSelectedOrderBy: (value: string[]) => void;

  selectedRatings: RatingRequirement[];
  setSelectedRatings: (value: RatingRequirement[]) => void;
  selectedNationalities: string[];
  setSelectedNationalities: (value: string[]) => void;
  selectedContractStatus: string[];
  setSelectedContractStatus: (value: string[]) => void;
  isContractStatusToggled: boolean;
  setIsContractStatusToggled: (value: boolean) => void;

  isAnyFilterExpanded: boolean;
  setIsAnyFilterExpanded: (value: boolean) => void;
  isAdvancedFiltersExpanded: boolean;
  handleAdvancedFiltersExpansion: () => void;

  handleFilterUnexpansion: (filter: string) => void;
  handleSearchReset: () => void;
  setSearchWasLoaded: (value: boolean) => void;
}

export const SeasonScoutingFilters: React.FC<SeasonScoutingFiltersProps> = ({
  selectedMinutes,
  setSelectedMinutes,
  selectedMaxAge,
  setSelectedMaxAge,
  selectedPositions,
  setSelectedPositions,
  selectedRoles,
  setSelectedRoles,
  selectedIterations,
  setSelectedIterations,
  selectedOrderBy,
  setSelectedOrderBy,

  selectedRatings,
  setSelectedRatings,
  selectedNationalities,
  setSelectedNationalities,
  selectedContractStatus,
  setSelectedContractStatus,
  isContractStatusToggled,
  setIsContractStatusToggled,

  isAnyFilterExpanded,
  setIsAnyFilterExpanded,
  isAdvancedFiltersExpanded,
  handleAdvancedFiltersExpansion,

  handleFilterUnexpansion,
  handleSearchReset,
  setSearchWasLoaded,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const trackEvent = useTrackEvent();

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [modalTypeOpen, setModalTypeOpen] = useState<string | undefined>(undefined);

  const [isMinutesExpanded, setIsMinutesExpanded] = useState(false);
  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isPositionsAndRolesExpanded, setIsPositionsAndRolesExpanded] = useState(false);
  const [isIterationsExpanded, setIsIterationsExpanded] = useState(false);
  const [isOrderByExpanded, setIsOrderByExpanded] = useState(false);

  const [isRatingsExpanded, setIsRatingsExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isContractStatusExpanded, setIsContractStatusExpanded] = useState(false);

  const [anySkillcornerFiltersSelected, setAnySkillcornerFiltersSelected] = useState(false);
  const [anyIterationWithoutSkillcornerSelected, setAnyIterationWithoutSkillcornerSelected] = useState(false);


  const handleAdvancedFiltersClick = () => {
    const filterIcon = document.getElementById('filter-icon');
    if (filterIcon) {
      filterIcon.style.transition = '0ms';
      handleAdvancedFiltersExpansion();
      filterIcon.style.transition = '125ms';
    }
  };


  const handleResetFiltersClick = () => {
    handleSearchReset();

    setSelectedMinutes([]);
    setSelectedMaxAge([]);
    setSelectedPositions([]);
    setSelectedRoles([]);
    setSelectedIterations([]);
    setSelectedOrderBy(['skill_rating']);

    setSelectedRatings([]);
    setSelectedNationalities([]);
    setSelectedContractStatus([]);
    setIsContractStatusToggled(false);

    setAnySkillcornerFiltersSelected(false);
    setAnyIterationWithoutSkillcornerSelected(false);
  };


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: StringToAnyMap = {
      'searchName': searchName,
      'searchType': 'seasonScouting',
    };

    if (selectedMinutes.length > 0) search['minMinutes'] = selectedMinutes;
    if (selectedMaxAge.length > 0) search['maxAge'] = selectedMaxAge;
    if (selectedPositions.length > 0) search['positions'] = selectedPositions;
    if (selectedRoles.length > 0) search['roles'] = selectedRoles;
    if (selectedIterations.length > 0) search['iterations'] = selectedIterations;
    if (selectedOrderBy.length > 0) search['orderBy'] = selectedOrderBy;

    if (selectedRatings.length > 0) search['ratings'] = selectedRatings;
    if (selectedNationalities.length > 0) search['nationalities'] = selectedNationalities;
    if (selectedContractStatus.length > 0) search['contractStatus'] = selectedContractStatus;
    if (isContractStatusToggled) search['isContractStatusToggled'] = true;

    trackEvent('SeasonSearchSaved');
    saveUserSearch(search, userConfig.email, userConfig.club);
  };


  const loadSearch = (savedSearch: StringToAnyMap) => {
    setSelectedMinutes(savedSearch['minMinutes'] ? savedSearch['minMinutes'] : []);
    setSelectedMaxAge(savedSearch['maxAge'] ? savedSearch['maxAge'] : []);
    setSelectedPositions(savedSearch['positions'] ? savedSearch['positions'] : []);
    setSelectedRoles(savedSearch['roles'] ? savedSearch['roles'] : []);
    setSelectedIterations(savedSearch['iterations'] ? savedSearch['iterations'] : []);
    setSelectedOrderBy(savedSearch['orderBy'] ? savedSearch['orderBy'] : []);

    setSelectedRatings(savedSearch['ratings'] ? savedSearch['ratings'] : []);
    setSelectedNationalities(savedSearch['nationalities'] ? savedSearch['nationalities'] : []);
    setSelectedContractStatus(savedSearch['contractStatus'] ? savedSearch['contractStatus'] : []);
    setIsContractStatusToggled(savedSearch['isContractStatusToggled'] ?? false);

    trackEvent('SeasonSearchLoaded');
    setSearchWasLoaded(true);
  };


  useEffect(() => {
    if (!isAnyFilterExpanded) {
      setIsMinutesExpanded(false);
      setIsMaxAgeExpanded(false);
      setIsPositionsAndRolesExpanded(false);
      setIsIterationsExpanded(false);
      setIsOrderByExpanded(false);
      setIsRatingsExpanded(false);
      setIsNationalitiesExpanded(false);
      setIsContractStatusExpanded(false);
    }
  }, [isAnyFilterExpanded]);


  useEffect(() => {
    if (!isAdvancedFiltersExpanded) {
      setShowAdvancedFilters(false);
    }
    else {
      const timer = setTimeout(() => {
        setShowAdvancedFilters(true);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isAdvancedFiltersExpanded]);


  useEffect(() => {
    if (!isMinutesExpanded) {
      handleFilterUnexpansion('minutes');
    }
  }, [isMinutesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMaxAgeExpanded) {
      handleFilterUnexpansion('maxAge');
    }
  }, [isMaxAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPositionsAndRolesExpanded) {
      handleFilterUnexpansion('positionsAndRoles');
    }
  }, [isPositionsAndRolesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isIterationsExpanded) {
      handleFilterUnexpansion('iterations');
    }
  }, [isIterationsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOrderByExpanded) {
      handleFilterUnexpansion('orderBy');
    }
  }, [isOrderByExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isRatingsExpanded) {
      handleFilterUnexpansion('ratings');
    }
  }, [isRatingsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNationalitiesExpanded) {
      handleFilterUnexpansion('nationalities');
    }
  }, [isNationalitiesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isContractStatusExpanded) {
      handleFilterUnexpansion('contractStatus');
    }
  }, [isContractStatusExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={modalTypeOpen !== undefined}
        onClose={() => setModalTypeOpen(undefined)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <SaveOrLoadSearchModal
          modalType={modalTypeOpen}
          searchType={'seasonScouting'}
          closeModal={() => setModalTypeOpen(undefined)}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='filter-section-drop-down-title season-filter-section-minutes'>
        {translate('minMinutesPlayed', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-minutes'>
        <DropDownSelect
          id={'season-filter-section-minutes-drop-down'}
          dropDownOptions={minuteAbsoluteKeys}
          dropDownSecondCategoryOptions={minutePercentageKeys}
          selectedOptions={selectedMinutes}
          setSelectedOptions={setSelectedMinutes}
          isDropDownExpanded={isMinutesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMinutesExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-age'>
        <DropDownSelect
          id={'season-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={selectedMaxAge}
          setSelectedOptions={setSelectedMaxAge}
          isDropDownExpanded={isMaxAgeExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsMaxAgeExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}

          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-positions-and-roles'>
        {translate('positionsAndRoles', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-positions-and-roles'>
        <PositionAndRoleDropDown
          id={'season-filter-section-positions-and-roles-drop-down'}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          isDropDownExpanded={isPositionsAndRolesExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsPositionsAndRolesExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-iterations'>
        {translate('seasons', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-iterations'>
        <IterationDropDown
          id={'season-filter-section-iterations-drop-down'}
          selectedIterations={selectedIterations}
          setSelectedIterations={setSelectedIterations}
          isDropDownExpanded={isIterationsExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsIterationsExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
          anySkillcornerFiltersSelected={anySkillcornerFiltersSelected}
          setAnyIterationWithoutSkillcornerSelected={setAnyIterationWithoutSkillcornerSelected}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-order-by'>
        {translate('orderBy', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-order-by'>
        <MetricDropDown
          id={'season-filter-section-order-by-drop-down'}
          selectedOptions={selectedOrderBy}
          setSelectedOptions={setSelectedOrderBy}
          selectedRoles={selectedRoles}
          isDropDownExpanded={isOrderByExpanded}
          setIsDropDownExpanded={(value: boolean) => { setIsOrderByExpanded(value), setIsAnyFilterExpanded(value); }}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
          forceSelection={true}
          isGoalkeeperSelected={selectedPositions.includes('GK')}
          anyIterationWithoutSkillcornerSelected={anyIterationWithoutSkillcornerSelected}
          setAnySkillcornerFiltersSelected={setAnySkillcornerFiltersSelected}
        />
      </div>

      <div
        className='filter-section-icon filter-section-left-icon'
        onClick={() => handleResetFiltersClick()}
        title={translate('resetFilters', userConfig?.language)}
      >
        <ReplayIcon style={{ fontSize: 20 }} />
      </div>

      <div
        id='filter-icon'
        className='filter-section-icon filter-section-right-icon'
        onClick={() => handleAdvancedFiltersClick()}
        title={translate('moreFilters', userConfig?.language)}
      >
        {!isAdvancedFiltersExpanded && <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} />}
        {isAdvancedFiltersExpanded && <CloseIcon style={{ fontSize: 20 }} />}
      </div>

      {isAdvancedFiltersExpanded && showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>

          <div className='filter-section-drop-down-title season-filter-section-ratings'>
            {translate('ratingRequirements', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-ratings'>
            <RatingDropDown
              id={'season-filter-section-ratings-drop-down'}
              selectedOptions={selectedRatings}
              setSelectedOptions={setSelectedRatings}
              selectedRoles={selectedRoles}
              isDropDownExpanded={isRatingsExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsRatingsExpanded(value), setIsAnyFilterExpanded(value); }}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              isGoalkeeperSelected={selectedPositions.includes('GK')}
              anyIterationWithoutSkillcornerSelected={anyIterationWithoutSkillcornerSelected}
              setAnySkillcornerFiltersSelected={setAnySkillcornerFiltersSelected}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-nationalities'>
            {translate('nationalities', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-nationalities'>
            <CountryDropDown
              id={'season-filter-section-nationalities-drop-down'}
              selectedCountryCodes={selectedNationalities}
              setSelectedCountryCodes={setSelectedNationalities}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsNationalitiesExpanded(value), setIsAnyFilterExpanded(value); }}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-contract'>
            {translate('contractStatus', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-contract'>
            <DropDownSelect
              id={'season-filter-section-contract-drop-down'}
              dropDownOptions={contractKeys}
              selectedOptions={selectedContractStatus}
              setSelectedOptions={setSelectedContractStatus}
              isDropDownExpanded={isContractStatusExpanded}
              setIsDropDownExpanded={(value: boolean) => { setIsContractStatusExpanded(value), setIsAnyFilterExpanded(value); }}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              toggleText={'includeUnknownContractStatus'}
              isToggled={isContractStatusToggled}
              setIsToggled={setIsContractStatusToggled}
            />
          </div>

          <div
            className='filter-section-icon filter-section-left-icon'
            onClick={() => setModalTypeOpen('saveSearch')}
            title={translate('saveSearch', userConfig?.language)}
          >
            <SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />
          </div>

          <div
            className='filter-section-icon filter-section-right-icon'
            onClick={() => setModalTypeOpen('loadSearch')}
            title={translate('loadSearch', userConfig?.language)}
          >
            <ArrowCircleDownIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      )}

    </div>
  );
};
