import './input.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { competitionsState } from '../../recoil/atoms/competitionsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LockIcon from '@mui/icons-material/Lock';

import { translate } from '../../../common/language/translations';
import { PlayerClubIteration, StringToAnyMap } from '../../types';
import { countryCodeToCountryInfo } from '../../static/countries';


interface PlayerIterationDropDownProps {
  id: string;

  dropDownOptions: PlayerClubIteration[];
  selectedOption: number | undefined;
  handleIterationClick: (value: number | undefined) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight: string;
}

export const PlayerIterationDropDown: React.FC<PlayerIterationDropDownProps> = ({
  id,

  dropDownOptions,
  selectedOption,
  handleIterationClick,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (index: number) => {
    if (index === selectedOption) {
      handleIterationClick(undefined);
    }
    else {
      handleIterationClick(index);
      setIsDropDownExpanded(false);
    }
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const getCompetitionName = (index: number) => {
    const competitionId = dropDownOptions[index]['competition_id'];
    const competition = competitions[competitionId];
    if (competition) {
      return competition.name;
    }
  };

  const getFlagUrl = (index: number) => {
    const competitionId = dropDownOptions[index]['competition_id'];
    const competition = competitions[competitionId];
    if (competition && competition.countryCode && countryCodeToCountryInfo[competition.countryCode]) {
      return countryCodeToCountryInfo[competition.countryCode].flagUrl;
    }
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedOption !== undefined) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedOption !== undefined && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedOption === undefined ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div className='player-iteration-drop-down-selected-option-display' style={{ color: '#000000' }}>
              {getFlagUrl(selectedOption) && (
                <img
                  className='league-flag player-iteration-drop-down-display-flag'
                  src={getFlagUrl(selectedOption)}
                  draggable={false}
                />
              )}
              {getCompetitionName(selectedOption)}
              &nbsp;
              {dropDownOptions[selectedOption].season}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div className='drop-down-select-options drop-down-select-options-scroll'>
        {dropDownOptions.map((option: StringToAnyMap, index: number) => {
          const isSelected = selectedOption === index;
          const isAvailable = option['event_data_available'];
          return (
            <div
              className={
                'drop-down-select-option player-iteration-drop-down-select-option'
                + (isSelected ? ' drop-down-select-option-selected' : '')
                + (isAvailable ? '' : ' player-iteration-drop-down-select-option-not-available')
              }
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => isAvailable ? handleOptionSelect(index) : null}
            >
              <div className='player-iteration-drop-down-select-option-row'>
                {getFlagUrl(index) && (
                  <img
                    className='league-flag player-iteration-drop-down-option-flag'
                    src={getFlagUrl(index)}
                    draggable={false}
                  />
                )}
                {getCompetitionName(index)}
                &nbsp;
                {option.season}
              </div>
              <div className='player-iteration-drop-down-select-option-row'>
                <div className='player-iteration-drop-down-club-logo-container'>
                  {option.club?.logo_url && (
                    <img
                      src={option.club.logo_url ?? ''}
                      style={{
                        marginTop: option.club.is_national_team ? 1 : undefined,
                        marginLeft: option.club.is_national_team ? 4 : undefined,
                      }}
                      className={option.club.is_national_team ? 'league-flag player-iteration-drop-down-option-flag' : ''}
                      draggable={false}
                    />
                  )}
                </div>
                <div className='player-iteration-drop-down-club-name' style={{ marginLeft: option.club?.logo_url ? 5 : 0 }}>
                  {option.club?.is_national_team && option.club.country_code in countryCodeToCountryInfo
                    ? countryCodeToCountryInfo[option.club?.country_code ?? ''].name[userConfig?.language ?? 'en']
                    : option.club?.name}
                </div>

                <div className='player-iteration-drop-down-minutes-value'>
                  {option.basic_stats?.minutes_played}
                </div>
                <div className='player-iteration-drop-down-minutes-text'>
                  min
                </div>
              </div>

              {!isAvailable && (
                <div className='player-iteration-drop-down-lock'>
                  <LockIcon style={{ fontSize: 18 }} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
