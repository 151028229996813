import '../tables.css';

import { useRecoilValue } from 'recoil';
import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { CellProps } from 'react-table';
import { StringToAnyMap, RoleConfigMap, PlayerOverview, RoleConfig } from '../../../types';
import { getDateLocaleFormat, getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';
import { countryCodeToCountryInfo } from '../../../static/countries';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';

import { translate } from '../../../../common/language/translations';
import { LazyImage } from '../../../../common/components/LazyImage';
import { getFormIcon, getPlayingTimeIcon, getTeamIcon } from '../../../utils/iconUtils';
import { ReactNode } from 'react';
import { ProgressCircle } from '../../visualization/ProgressCircle';
import { getIndexColor } from '../../../utils/colorUtils';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { positionGroupToPositions } from '../../../static/propertyValues';


const InfoCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playerOverview: PlayerOverview = row.original;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  const { openPlayerViewModal } = useOpenGlobalModal();

  const competitionName = competitions[playerOverview.club?.current_competition_id]?.name;

  const currentTeam = playerTeamData[playerOverview.id]?.currentTeam;
  let currentTeamIcon = getTeamIcon(currentTeam, 'none', 18);
  if (!currentTeamIcon) {
    currentTeamIcon = <AddIcon style={{ fontSize: 13, color: '#00000099' }} />;
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={playerOverview.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-column'>

        <div className='player-table-info-cell-row'>
          {getDisplayPlayerName(playerOverview.fullname, 22)}

          {playerOverview.country_code in countryCodeToCountryInfo && (
            <img
              className='player-table-info-cell-flag'
              src={countryCodeToCountryInfo[playerOverview.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        {playerOverview.club && competitionName && (
          <div>
            <div className='player-table-info-cell-info'>
              {playerOverview.club?.logo_url && (
                <div className='player-table-club-logo-container'>
                  <img
                    className='player-table-club-logo'
                    src={playerOverview.club?.logo_url}
                    alt=''
                    draggable={false}
                  />
                </div>
              )}
              <div className='player-table-info-cell-info-text'>
                {playerOverview.club?.name}
              </div>
            </div>

            <div className='player-table-info-cell-info'>
              <div className='player-table-info-cell-info-text'>
                {competitionName}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className='player-table-info-cell-team-icon'
        title={translate(currentTeam ? 'editTeamStatus' : 'addToTeam', userConfig?.language)}
        onClick={(event) => {
          {
            event.stopPropagation();
            openPlayerViewModal(
              playerOverview.id,
              playerOverview.fullname,
              undefined,
              undefined,
              true
            );
          }
        }}
      >
        {currentTeamIcon}
      </div>
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='player-season-table-cell'>{value ?? '-'}</div>;
};


const renderIndexCell = (property: string, positionGroup: string) => {
  const IndexCellRenderer = ({ row }: StringToAnyMap) => {
    return <IndexCell playerOverview={row.original} property={property} positionGroup={positionGroup} />;
  };

  IndexCellRenderer.displayName = 'IndexCellRenderer';
  return IndexCellRenderer;
};

const IndexCell: React.FC<{ playerOverview: PlayerOverview, property: string, positionGroup: string }> = ({
  playerOverview,
  property,
  positionGroup,
}) => {

  let value: number | string | undefined = property === 'skill_index' || property === 'confidence'
    ? playerOverview.position_group_stats?.[positionGroup]?.[property]
    : playerOverview.position_group_stats?.[positionGroup]?.custom_indexes[property + '_index'];

  // not entirely sure if both checks are necessary
  value = value !== undefined && value !== null
    ? property === 'confidence'
      ? (Math.round(value * 100))
      : (Math.round(value * 10) / 10)
    : '-';

  return (
    <div className='player-season-table-cell'>
      {value}
    </div>
  );
};


const renderConfidenceCell = (positionGroupKey: string) => {
  const ConfidenceCellRenderer = ({ row }: StringToAnyMap) => {
    return <ConfidenceCell playerOverview={row.original} positionGroupKey={positionGroupKey} />;
  };

  ConfidenceCellRenderer.displayName = 'ConfidenceCellRenderer';
  return ConfidenceCellRenderer;
};


const ConfidenceCell: React.FC<{ playerOverview: PlayerOverview, positionGroupKey: string }> = ({ playerOverview, positionGroupKey }) => {

  const confidence = playerOverview.position_group_stats?.[positionGroupKey]?.confidence;

  return (
    <div
      className='player-season-table-cell'
      style={{ paddingRight: lastColumnPadding }}
    >
      {confidence !== undefined && confidence !== null && (
        <ProgressCircle
          size={28}
          progress={confidence * 100}
          value={Math.round(confidence * 100)}
          trailColor='#00000022'
          color={getIndexColor(confidence * 10, 47)}
          fontSize={12}
        />
      )}
    </div>
  );
};


const renderContractCell = () => {
  const ContractCellRenderer = ({ row }: StringToAnyMap) => {
    return <ContractCell playerOverview={row.original} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ playerOverview: PlayerOverview }> = ({ playerOverview }) => {
  return (
    <div className='player-season-table-cell'>
      {getDateLocaleFormat(playerOverview.contract_expiration)}
    </div>
  );
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: StringToAnyMap) => {
    return <PositionCell playerOverview={row.original} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{
  playerOverview: PlayerOverview;
  language: string;
}> = ({ playerOverview, language }) => {

  let primaryPositions = [];
  let secondaryPositions = [];

  if (playerOverview.event_data_exist) {
    primaryPositions = playerOverview.primary_positions ?? [];
    secondaryPositions = playerOverview.secondary_positions ?? [];
  }

  else {
    // if FAPI positions, we use the first position as primary and the second position as secondary (whether it's primary or secondary)
    const allPositions = (playerOverview.primary_positions ?? []).concat(playerOverview.secondary_positions ?? []);
    primaryPositions = allPositions.slice(0, 1);
    secondaryPositions = allPositions.slice(1);
  }

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  return (
    <div
      className='player-season-table-position-cell'
      style={{ fontSize: primaryPositions.length > 2 ? 12 : undefined }}
    >
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: secondaryPositions.length > 2 ? 10 : 11, color: '#000000dd' }}>
          {secondaryPositionString}
        </div>
      )}
    </div>
  );
};


const FormCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const formIcon = row.original.event_data_available
    ? getFormIcon(row.original.form_status, '#00000088', 18, 43)
    : undefined;

  return (
    <div className='player-season-table-cell'>
      {formIcon}
    </div>
  );
};


const PlayingTimeCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playingTimeIcon = row.original.event_data_exist
    ? getPlayingTimeIcon(row.original.availability_status, '#00000088', 18)
    : undefined;

  return (
    <div className='player-season-table-cell'>
      {playingTimeIcon}
    </div>
  );
};


// const formIcon = playerOverview.event_data_available
//   ? getFormIcon(playerOverview.form_status, '#00000088', 16, 43)
//   : undefined;

// const playingTimeIcon = playerOverview.event_data_exist
//   ? getPlayingTimeIcon(playerOverview.availability_status, '#00000088', 16)
//   : undefined;


export const playerInfoCellWidth = 320;
const lastColumnPadding = 25;


export const getPlayerColumns = (
  widthUnit: number,
  language: string,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
  selectedOrderBy: string | undefined,
  secondaryClubColor: string,
  openTextModal: (title: string, text: string) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
  handleChangeCurrentChapter: (isIncrement: boolean) => void,
) => {

  const positionGroupKey = selectedRoles.length > 0 ? roleConfigs[selectedRoles[0]].positionGroup : 'overall';

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='player-season-table-top-level-header-cell-sticky' style={{ width: playerInfoCellWidth }}>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + translate(totalHits === 1 ? 'player' : 'players', language, true)}

          {totalHits > hitsPerChapter && (
            <div className='player-table-sticky-header-cell-pagination'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              {currentChapter <= 0 && (
                <div className='player-table-pagination-arrow player-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter > 0 && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    ' player-table-pagination-left-arrow'
                  }
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                </div>
              )}

              <div className='player-table-current-chapter'>
                {currentChapter + 1}
              </div>

              {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                <div className='player-table-pagination-arrow player-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter < Math.floor(totalHits / hitsPerChapter) && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    ' player-table-pagination-right-arrow'
                  }
                >
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                </div>
              )}
            </div>
          )}

        </div>,

      id: 'player',
      accessor: 'id',
      sticky: 'left',
      Cell: InfoCell,
      width: playerInfoCellWidth,
      minWidth: playerInfoCellWidth,
    },
  ];

  const infoColumns = [
    {
      Header:
        <div className='player-season-table-sub-level-header-cell'>
          {translate('age', language)}
        </div>,
      id: 'age',
      accessor: 'age',
      Cell: TextCell,
      width: widthUnit * 60,
      minWidth: 60,
    },
    {
      Header:
        <div className='player-season-table-sub-level-header-cell'>
          {translate('contract', language)}
        </div>,
      id: 'contract',
      accessor: 'contract_expiration',
      Cell: renderContractCell(),
      width: widthUnit * 100,
      minWidth: 100,
    },
    {
      Header:
        <div className='player-season-table-sub-level-header-cell'>
          {translate('position', language)}
        </div>,
      id: 'position',
      accessor: 'position',
      Cell: renderPositionCell(language),
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header:
        <div className='player-season-table-sub-level-header-cell'>
          <div
            className='player-season-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(
              translate('form', language),
              translate('formInfo', language)
            )}
          >
            {translate('form', language)}
          </div>
        </div>,
      id: 'form',
      accessor: 'form',
      Cell: FormCell,
      width: widthUnit * 80,
      minWidth: 80,
    },
    {
      Header:
        <div className='player-season-table-sub-level-header-cell player-season-table-sub-level-header-cell-with-border'>
          <div
            className='player-season-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(
              translate('playingTime', language),
              translate('playingTimeInfo', language)
            )}
          >
            {translate('playingTime', language)}
          </div>
        </div>,
      id: 'playingTime',
      accessor: 'playingTime',
      Cell: PlayingTimeCell,
      width: widthUnit * 80,
      minWidth: 80,
      isFinalSubMetric: true,
    },
  ];

  columns.push({
    Header:
      <div
        className='player-season-table-top-level-header-cell player-season-table-top-level-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        Info
      </div>,
    id: 'playerInfo',
    columns: infoColumns,
  });


  const dataColumns: StringToAnyMap[] = [
    {
      Header:
        <div
          className='player-season-table-sub-level-header-cell'
          style={{
            color: selectedOrderBy === 'skillIndex' ? secondaryClubColor : undefined,
            fontWeight: selectedOrderBy === 'skillIndex' ? 600 : undefined,
          }}
        >
          <div
            className='player-season-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(metricToDisplayInfo['skill_index'].name[language], metricToDisplayInfo['skill_index'].description[language])}
          >
            {metricToDisplayInfo['skill_index'].name[language]}
          </div>
        </div>,
      id: 'skill_index',
      accessor: 'skill_index',
      Cell: renderIndexCell('skill_index', positionGroupKey),
      width: widthUnit * 90,
      minWidth: 90,
    },
  ];


  selectedRoles.forEach(roleId => {

    const roleName = roleConfigs[roleId].name;
    const width = getRoleColumnWidth(roleName);

    dataColumns.push(
      {
        Header:
          <div
            className='player-season-table-sub-level-header-cell'
            style={{
              color: selectedOrderBy === roleId ? secondaryClubColor : undefined,
              fontWeight: selectedOrderBy === roleId ? 600 : undefined,
            }}
          >
            <div
              className='player-season-table-sub-level-header-cell-text'
              title={translate('showDescription', language)}
              onClick={() => openRoleInfoModal(roleConfigs[roleId])}
            >
              {roleName}
            </div>
          </div>,
        accessor: roleId,
        Cell: renderIndexCell(roleId, positionGroupKey),
        width: widthUnit * width,
        minWidth: width,
      }
    );
  });

  dataColumns.push(
    {
      Header:
        <div
          className='player-season-table-sub-level-header-cell'
          style={{ paddingRight: lastColumnPadding }}
        >
          <div
            className='player-season-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(metricToDisplayInfo['confidence'].name[language], metricToDisplayInfo['confidence'].description[language])}
          >
            {metricToDisplayInfo['confidence'].name[language]}
          </div>
        </div>,
      id: 'confidence',
      accessor: 'confidence',
      Cell: renderConfidenceCell(positionGroupKey),
      width: widthUnit * (80 + lastColumnPadding),
      minWidth: 80 + lastColumnPadding,
    },
  );


  columns.push({
    Header:
      <div
        className='player-season-table-top-level-header-cell player-season-table-top-level-header-cell-with-border'
        style={{
          paddingRight: lastColumnPadding / 2,
          fontSize: 12
        }}
      >
        {translate('basedOn<positions>', language) + ' '}
        {positionGroupKey !== 'overall'
          ? getDisplayPositions(positionGroupToPositions[positionGroupKey], language)
          : translate('allPositions', language, true)}
      </div>,
    id: 'playerData',
    columns: dataColumns,
  });

  return columns;
};


const getRoleColumnWidth = (roleName: string) => {
  return Math.min(Math.max(65, roleName.length * 8), 100);
};


export const getPlayerTableTotalColumnsMinWidth = (
  selectedRoles: string[] | undefined,
  roleConfigs: RoleConfigMap | undefined,
) => {

  const baseColumnsMinWIdth = 580 + lastColumnPadding;

  const roleColumnsMinWidth = selectedRoles && roleConfigs
    ? selectedRoles.reduce((acc, role) => acc + getRoleColumnWidth(roleConfigs[role].name), 0)
    : 0;

  return baseColumnsMinWIdth + roleColumnsMinWidth;
};
