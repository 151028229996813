import { useRecoilValue, useSetRecoilState } from 'recoil';
import { teamsState } from '../atoms/teamsState';
import { playerCareersState } from '../atoms/playerCareersState';
import { getPlayerCareersForTeam } from '../../services/server/application/playerCareers';
import { FirebaseUser } from '../../../firebase';


export const useRefreshPlayerCareers = () => {

  const setPlayerCareers = useSetRecoilState(playerCareersState);
  const teams = useRecoilValue(teamsState);

  // this will initialize the playerCareers atom with player careers for all players in ownTeam
  const refreshPlayerCareers = async (currentUser: FirebaseUser): Promise<void> => {
    if (!teams || !teams['ownTeam']) return;

    const newPlayerCareers = await getPlayerCareersForTeam(teams['ownTeam'], currentUser);
    setPlayerCareers(newPlayerCareers);
  };

  return refreshPlayerCareers;
};
