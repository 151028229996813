import { useRecoilState } from 'recoil';
import { playerOverviewsState } from '../atoms/playerOverviewsState';
import { FirebaseUser } from '../../../firebase';
import { getPlayerOverviews } from '../../services/server/application/playerOverviews';
import { PlayerId, PlayerOverview } from '../../types';


export const useGetAndAddPlayerOverview = () => {

  const [playerOverviews, setPlayerOverviews] = useRecoilState(playerOverviewsState);


  const getAndAddPlayerOverview = async (playerId: PlayerId, currentUser: FirebaseUser): Promise<PlayerOverview | undefined> => {

    let playerOverview: PlayerOverview | undefined = undefined;

    if (!isNaN(Number(playerId))) {

      playerOverview = playerOverviews[Number(playerId)];

      if (!playerOverview) {
        const newPlayerOverviews = await getPlayerOverviews([Number(playerId)], currentUser);

        if (newPlayerOverviews) {
          setPlayerOverviews(currentPlayerOverviews => ({
            ...currentPlayerOverviews,
            ...newPlayerOverviews
          }));

          playerOverview = newPlayerOverviews[Number(playerId)];
        }
      }
    }

    return playerOverview;
  };


  return getAndAddPlayerOverview;
};
