

// Searching for GB is not allowed, so if GB is selected, we instead select all GB subregions
export const getCountryCodeSearchString = (countryCodes: string[]): string => {

  const filteredCountryCodes = new Set<string>();

  countryCodes.forEach((countryCode) => {
    if (countryCode === 'GB') {
      filteredCountryCodes.add('GB');
      filteredCountryCodes.add('GB-ENG');
      filteredCountryCodes.add('GB-NIR');
      filteredCountryCodes.add('GB-SCT');
      filteredCountryCodes.add('GB-WLS');
    } else {
      filteredCountryCodes.add(countryCode);
    }
  });

  return Array.from(filteredCountryCodes).join(',');
};
