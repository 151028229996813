import '../../platform.css';
import './teamView.css';

import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';

import { StringToAnyMap, PlayerEntry, PlayerId, PlayerOverviews, NumberToNumberMap, AggregationRule } from '../../types';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { translate } from '../../../common/language/translations';
import { getTeamViewData } from '../../utils/teamViewUtils';
import { FootballFieldSvg } from '../../svg/FootballFieldSvg';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { getEmptyTableWidth } from '../tables/playerSimpleTable/playerSimpleColumns';
import { getAge, getStrictPlayerId } from '../../utils/playerUtils';
import { roleKeysToSortOrder } from '../../static/propertyValues';
import { removePlayerFromTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { deleteOwnTeamAndAcademyPlayerData, deletePlayerDocument } from '../../services/firestore/players';
import { LastDraggedOwnTeamPlayerData } from './LogisticTeamView';
import { userHasFullAccess } from '../../utils/userUtils';
import { useWindowSize } from '../../../common/hooks/WindowSize';


interface TeamViewProps {
  teamOrSquadId: string | undefined;
  teamOrSquadData: StringToAnyMap | undefined;
  isSquad?: boolean | undefined;

  draggedPlayer?: StringToAnyMap | undefined;
  setDraggedPlayer?: (player: StringToAnyMap | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void;
  setLastDraggedOwnTeamPlayerData?: (draggedPlayerData: LastDraggedOwnTeamPlayerData) => void;
  handlePlayerClick?: (player: StringToAnyMap) => void;

  isLeftSectionExpanded?: boolean;
  setIsLeftSectionExpanded?: (isExpanded: boolean) => void;
  rightSectionExpansionType?: string | undefined;
  setRightSectionExpansionType?: (expansionType: string | undefined) => void;

  showFirstEleven?: boolean;

  isArchivedTeamDeleted?: boolean;
  closeArchivedTeam?: () => void;
  deleteOrRestoreArchivedTeam?: () => void;
  additionalPlayerOverviews?: PlayerOverviews;

  highlightedPlayerId?: PlayerId | undefined;

  confirmNewAggregationRule?: (newAggregationRule: AggregationRule, positionKey: string) => void;
  moveRoleConfigUp?: (currentIndex: number, positionKey: string) => void;
}

export const TeamView: React.FC<TeamViewProps> = ({
  teamOrSquadId,
  teamOrSquadData,
  isSquad,

  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
  setLastDraggedOwnTeamPlayerData,
  handlePlayerClick,

  isLeftSectionExpanded,
  setIsLeftSectionExpanded,
  rightSectionExpansionType,
  setRightSectionExpansionType,

  showFirstEleven,

  isArchivedTeamDeleted,
  closeArchivedTeam,
  deleteOrRestoreArchivedTeam,
  additionalPlayerOverviews,

  highlightedPlayerId,

  confirmNewAggregationRule,
  moveRoleConfigUp,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const hasFullAccess = userHasFullAccess(userConfig);

  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const { height } = useWindowSize();

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const [isExpanding, setIsExpanding] = useState(false);

  const [isIconHighlighted, setIsIconHighlighted] = useState(false);

  const [data, setData] = useState<StringToAnyMap[] | undefined>(undefined);
  const [scaledMaxNumberOfPlayersPerPositionRow, setScaledMaxNumberOfPlayersPerPositionRow] = useState<NumberToNumberMap>({});
  const [scaledMaxNumberOfPlayersPerPositionRowSum, setScaledMaxNumberOfPlayersPerPositionRowSum] = useState(0);
  const [maxNumberOfPlayersPerPositionRowSum, setMaxNumberOfPlayersPerPositionRowSum] = useState(0);
  const [numberOfPositionRowsWithAtLeastOnePlayer, setNumberOfPositionRowsWithAtLeastOnePlayer] = useState(0);
  const [numberOfPositionRowsWithAtLeastTwoPlayers, setNumberOfPositionRowsWithAtLeastTwoPlayers] = useState(0);

  const formation = teamOrSquadData ? teamOrSquadData['formation'] : undefined;

  const teamMenu = (teamOrSquadId === 'ownTeam'
    ? userSettings?.ownTeamMenu
    : teamOrSquadId === 'academyTeam'
      ? userSettings?.academyTeamMenu
      : userSettings?.teamMenu) ?? {};


  const onPlayerClick = (player: StringToAnyMap) => {
    if (handlePlayerClick) {
      handlePlayerClick(player);
      return;
    }

    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverviews[player.id] ?? (
        additionalPlayerOverviews
          ? additionalPlayerOverviews[player.id]
          : undefined
      ),
      squadId: isSquad ? teamOrSquadId : undefined,
    });
  };


  const handleAddPlayerIconClick = () => {
    if (!setIsLeftSectionExpanded || !setRightSectionExpansionType) return;

    setIsExpanding(true);
    setIsLeftSectionExpanded(!isLeftSectionExpanded);
    setRightSectionExpansionType(undefined);
    setTimeout(() => {
      setIsExpanding(false);
    }, 250);
  };


  const handleRightIconClick = (expansionType: string) => {
    if (!setIsLeftSectionExpanded || !setRightSectionExpansionType) return;

    const isDeexpansion = rightSectionExpansionType === expansionType;
    setRightSectionExpansionType(isDeexpansion ? undefined : expansionType);

    setIsExpanding(true);
    setIsLeftSectionExpanded(false);
    setTimeout(() => {
      setIsExpanding(false);
    }, 250);
  };


  const deleteOrRestoreArchivedTeamInternal = () => {
    if (!deleteOrRestoreArchivedTeam) return;

    deleteOrRestoreArchivedTeam();

    if (isArchivedTeamDeleted) {
      setIsIconHighlighted(true);
      setTimeout(() => {
        setIsIconHighlighted(false);
      }, 750);
    }
  };


  // this is where we compute the value to show for role index, based on the positionToRoleIndexConfig settings
  // there are 5 options:
  // no role configured for position -> show skill index
  // at least one of the roles configured is not ready -> show 'beingComputed'
  // aggregationRule === 'priority' -> show the first role index present
  // aggregationRule === 'max' -> show the max role index
  // aggregationRule === 'average' -> show the average of role indexes
  const getRoleIndexToShow = (player: StringToAnyMap, position: string) => {

    const roleIndexConfigOfPosition = clubSettings?.positionToRoleIndexConfig?.[position];
    if (!roleIndexConfigOfPosition) {
      return player['skill_index'];
    }

    const roleIds = roleIndexConfigOfPosition.roleIds;
    const allRoleConfigsAreReady = roleIds.every((roleId) => {
      const roleConfig = clubSettings?.roleConfigs?.[roleId];
      return roleConfig && roleConfig.weightsLastUpdated && roleConfig.dataLastUpdated && roleConfig.dataLastUpdated >= roleConfig.weightsLastUpdated;
    });

    if (!allRoleConfigsAreReady) {
      return 'beingComputed';
    }

    const aggregationRule = roleIndexConfigOfPosition.aggregationRule;
    if (aggregationRule === 'priority') {
      for (const roleId of roleIds) {
        const positionGroup = clubSettings?.roleConfigs?.[roleId]?.positionGroup;
        if (positionGroup) {
          const roleIndex = playerOverviews[player.id].position_group_stats?.[positionGroup]?.custom_indexes[roleId + '_index'];
          if (roleIndex !== undefined && roleIndex !== null) {
            return roleIndex;
          }
        }

      }
    }

    else if (aggregationRule === 'max') {
      let maxValue = -1;
      for (const roleId of roleIds) {
        const positionGroup = clubSettings?.roleConfigs?.[roleId]?.positionGroup;
        if (positionGroup) {
          const roleIndex = playerOverviews[player.id].position_group_stats?.[positionGroup]?.custom_indexes[roleId + '_index'];
          if (roleIndex !== undefined && roleIndex !== null) {
            maxValue = Math.max(maxValue, roleIndex);
          }
        }
      }
      if (maxValue !== -1) {
        return maxValue;
      }
    }

    else if (aggregationRule === 'average') {
      let sum = 0;
      let count = 0;
      for (const roleId of roleIds) {
        const positionGroup = clubSettings?.roleConfigs?.[roleId]?.positionGroup;
        if (positionGroup) {
          const roleIndex = playerOverviews[player.id].position_group_stats?.[positionGroup]?.custom_indexes[roleId + '_index'];
          if (roleIndex !== undefined && roleIndex !== null) {
            sum += roleIndex;
            count++;
          }
        }
      }
      if (count > 0) {
        return sum / count;
      }
    }

    else if (aggregationRule === 'confidence') {
      let maxValue = -1;
      let maxConfidence = -1;
      for (const roleId of roleIds) {
        const positionGroup = clubSettings?.roleConfigs?.[roleId]?.positionGroup;
        if (positionGroup) {
          const roleIndex = playerOverviews[player.id].position_group_stats?.[positionGroup]?.custom_indexes[roleId + '_index'];
          if (roleIndex !== undefined && roleIndex !== null) {
            const confidence = playerOverviews[player.id].position_group_stats?.[positionGroup]?.confidence;
            if (confidence && confidence > maxConfidence) {
              maxValue = roleIndex;
              maxConfidence = confidence;
            }
          }
        }
      }
      if (maxValue !== -1) {
        return maxValue;
      }
    }

    return undefined;
  };


  const getTableData = (positionData: StringToAnyMap) => {
    const tableData: StringToAnyMap[] = [];

    positionData['players'].forEach((playerEntry: PlayerEntry) => {

      let player: StringToAnyMap = { ...playerEntry };

      const playerDocument = (players && players[player.id]) ?? {};

      const shouldHidePlayerDueToStartingEleven =
        teamOrSquadId === 'ownTeam' &&
        showFirstEleven &&
        !playerDocument['isStartingEleven'];

      const shouldHidePlayerDueToAvailability =
        teamOrSquadId === 'ownTeam' &&
        userSettings &&
        userSettings.ownTeamMenu &&
        userSettings.ownTeamMenu.hideUnavailablePlayers &&
        (playerDocument.role === 'longTermInjured' || playerDocument.role === 'loanedOut');

      if (player && !(shouldHidePlayerDueToStartingEleven || shouldHidePlayerDueToAvailability)) {

        if (player.id in playerOverviews) {

          player['image_url'] = playerOverviews[player.id].image_url;

          player['birth_date'] = playerOverviews[player.id].birth_date;
          player['country_code'] = playerOverviews[player.id].country_code;
          player['contract_expiration'] = playerOverviews[player.id].contract_expiration;

          player['primary_positions'] = playerOverviews[player.id].primary_positions;
          player['club_logo_url'] = playerOverviews[player.id].club?.logo_url;

          player['skill_index'] = playerOverviews[player.id].position_group_stats?.overall?.skill_index;
          player['role_index'] = getRoleIndexToShow(player, positionData['position']);
        }

        else if (additionalPlayerOverviews && player.id in additionalPlayerOverviews) {
          player['image_url'] = additionalPlayerOverviews[player.id].image_url;
        }

        player = { ...player, ...playerDocument };

        player['age'] = getAge(player['birth_date']);

        tableData.push(player);
      }
    });

    const orderByProperty: string | undefined = teamMenu.orderBy ?? ((teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 'role' : undefined);

    tableData.sort((a: StringToAnyMap, b: StringToAnyMap) => {
      if (!orderByProperty) return 0;

      if (!a[orderByProperty]) return 1;
      if (!b[orderByProperty]) return -1;

      const aValue = orderByProperty === 'role'
        ? (a[orderByProperty] in roleKeysToSortOrder ? roleKeysToSortOrder[a[orderByProperty]] : 0)
        : a[orderByProperty];

      const bValue = orderByProperty === 'role'
        ? (b[orderByProperty] in roleKeysToSortOrder ? roleKeysToSortOrder[b[orderByProperty]] : 0)
        : b[orderByProperty];

      return aValue > bValue ? -1 : 1;
    });

    return tableData;
  };


  const getRowClassName = (rowIndex: number, rowLength: number) => {
    const base = 'row-container';
    const indices = '-' + rowIndex + '-' + rowLength;
    return base + ' ' + base + indices + ' ' + base + indices + (formation ? '-' + formation : '');
  };

  const getPositionClassName = (rowIndex: number, rowLength: number, posIndex: number) => {
    const base = 'position-container';
    const indices = '-' + rowIndex + '-' + rowLength + '-' + posIndex;
    return base + ' ' + base + indices + ' ' + base + indices + (formation ? '-' + formation : '');
  };


  const getScaledValue = (value: number) => {
    if (value <= 4) {
      return value;
    }
    else if (value <= 10) {
      return 4 + Math.sqrt(value - 4);
    }
    return 6 + Math.log(value);
  };


  useEffect(() => {
    if (!teamOrSquadData || !formation) return;

    const teamData = getTeamViewData(teamOrSquadData, formation);

    const maxPlayersPerPositionRow: NumberToNumberMap = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    };

    teamData.forEach((row: StringToAnyMap[], rowIndex) => {
      row.forEach((positionData: StringToAnyMap) => {
        const players = positionData['players'];

        if (players.length > (maxPlayersPerPositionRow[rowIndex] ?? 0)) {
          maxPlayersPerPositionRow[rowIndex] = players.length;
        }
      });
    });

    setNumberOfPositionRowsWithAtLeastOnePlayer(Object.values(maxPlayersPerPositionRow).filter((num) => num > 0).length);
    setNumberOfPositionRowsWithAtLeastTwoPlayers(Object.values(maxPlayersPerPositionRow).filter((num) => num > 1).length);
    setMaxNumberOfPlayersPerPositionRowSum(Object.values(maxPlayersPerPositionRow).reduce((a, b) => a + b, 0));

    const scaledMaxPlayersPerPositionRow: NumberToNumberMap = {};
    Object.keys(maxPlayersPerPositionRow).forEach((key) => {
      scaledMaxPlayersPerPositionRow[Number(key)] = getScaledValue(maxPlayersPerPositionRow[Number(key)]);
    });
    setScaledMaxNumberOfPlayersPerPositionRow(scaledMaxPlayersPerPositionRow);
    setScaledMaxNumberOfPlayersPerPositionRowSum(Object.values(scaledMaxPlayersPerPositionRow).reduce((a, b) => a + b, 0));

    setData(teamData);
  }, [formation, teamOrSquadData]);


  // Drag section for deleting players
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [playerWasRecentlyRemoved, setPlayerWasRecentlyRemoved] = useState(false);

  const showDeleteIcon = draggedPlayer && draggedPlayer['positionKey'] !== 'addPlayerTable' && draggedPlayer['positionKey'] !== 'addPlayerTableExistingPlayers';

  const handleDragDrop = () => {

    if (draggedPlayer !== undefined && teamOrSquadId && userConfig) {
      const playerId = getStrictPlayerId(draggedPlayer['id']);
      removePlayerFromTeamOrSquad(playerId, teamOrSquadId, isSquad ?? false, userConfig.email, userConfig.club);

      if (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') {

        // if this is a player in the database, we delete the player document
        if (!isNaN(Number(playerId))) {
          deletePlayerDocument(playerId, userConfig.club);

          // if player was removed from ownTeam, open modal to add clause
          if (teamOrSquadId === 'ownTeam' && hasFullAccess) {
            if (!isNaN(Number(playerId))) {
              if (setLastDraggedOwnTeamPlayerData) {
                setLastDraggedOwnTeamPlayerData({
                  playerId: Number(playerId),
                  playerName: draggedPlayer['fullname'],
                  wasAdded: false,
                });
              }
            }
          }
        }

        // if this is a player without data, we only delete the ownTeam and academyTeam player data
        else {
          deleteOwnTeamAndAcademyPlayerData(playerId, userConfig.club);
        }
      }
    }

    setPlayerWasRecentlyRemoved(true);
    handleDragLeave();

    setTimeout(() => {
      setPlayerWasRecentlyRemoved(false);
    }, 250);

  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    setIsDraggedOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggedOver(false);
  };


  const getBoxShadow = () => {
    if (isDraggedOver) {
      return '0px 0px 0px 3px #ff2424';
    }
    return 'none';
  };


  const getMaxTableHeight = (positionRowIndex: number) => {

    const availableHeight = height + 150 - (height * 0.25) - (30 * numberOfPositionRowsWithAtLeastOnePlayer) - (15 * numberOfPositionRowsWithAtLeastTwoPlayers);
    const numberOfSlots = availableHeight / 33;

    // if there is space for all players, we don't want to scroll
    if (maxNumberOfPlayersPerPositionRowSum <= numberOfSlots) {
      return undefined;
    }

    // if there is not enough space for all players, we want to allocate a height logarithmically proportional to the number of players
    const scaledNumberOfPlayers = scaledMaxNumberOfPlayersPerPositionRow[positionRowIndex];
    const heightAllocated = (scaledNumberOfPlayers / scaledMaxNumberOfPlayersPerPositionRowSum) * availableHeight;
    const maxHeight = (Math.max(Math.floor(heightAllocated / 33), 2)) * 33;

    return maxHeight + 'px';
  };


  const shouldDisableAddPlayerIcon = teamOrSquadId === 'ownTeam' && !hasFullAccess;


  const shouldShowTeamViewIcons = teamOrSquadId !== 'ownTeamPlanner' && teamOrSquadId !== 'archivedTeam' && teamOrSquadId !== 'roleConfigs';


  return (
    <div className='team-view-container'>

      <FootballFieldSvg />

      <div className={'team-view-players-section' + (scaledMaxNumberOfPlayersPerPositionRow[3] ? ' team-view-players-section-with-goalkeeper' : '')}>

        {data && data.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={getRowClassName(rowIndex, row.length)}
          >

            {row.map((positionData: StringToAnyMap, posIndex: number) => (
              <div
                key={rowIndex + posIndex}
                className={getPositionClassName(rowIndex, row.length, posIndex)}
              >

                <div
                  className='team-view-player-section'
                  style={{ width: getEmptyTableWidth(teamOrSquadId, teamMenu) }}
                >
                  <PlayerSimpleTable
                    data={getTableData(positionData)}
                    onPlayerClick={onPlayerClick}
                    tableType={teamOrSquadId}

                    positionKey={positionData['position']}
                    draggedPlayer={draggedPlayer}
                    setDraggedPlayer={setDraggedPlayer}
                    handlePlayerDrop={handlePlayerDrop}

                    maxHeight={getMaxTableHeight(rowIndex)}
                    scrollBarColor={'#919dcd'}
                    highlightedPlayerId={highlightedPlayerId}

                    confirmNewAggregationRule={confirmNewAggregationRule}
                    moveRoleConfigUp={moveRoleConfigUp}
                  />
                </div>

              </div>
            ))}

          </div>
        ))}

      </div>

      {shouldShowTeamViewIcons && showDeleteIcon && !shouldDisableAddPlayerIcon && (
        <div
          className='team-view-delete-player-icon-container-overlay'
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDragDrop}
        >
          &nbsp;
        </div>
      )}

      {shouldShowTeamViewIcons && !shouldDisableAddPlayerIcon && (
        <div
          style={{ boxShadow: getBoxShadow(), opacity: playerWasRecentlyRemoved ? 0 : 1 }}
          className={'team-view-circular-button team-view-add-player-button' + (showDeleteIcon ? ' team-view-delete-player-icon-container' : '')}
          onClick={() => !showDeleteIcon ? handleAddPlayerIconClick() : null}
        >
          {!showDeleteIcon && !isLeftSectionExpanded && <PersonAddAlt1Icon style={{ fontSize: 24, marginLeft: 2, marginTop: -2 }} />}
          {!showDeleteIcon && isLeftSectionExpanded && <KeyboardArrowLeftIcon style={{ fontSize: 24, marginLeft: -1 }} />}
          {showDeleteIcon && <DeleteForeverIcon style={{ fontSize: 28 }} />}

          {!isLeftSectionExpanded && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {translate('addPlayer', userConfig?.language)}
              </div>
            </div>
          )}
        </div>
      )}

      {shouldShowTeamViewIcons && (
        <div
          className='team-view-circular-button team-view-history-button'
          onClick={() => handleRightIconClick('history')}
        >
          {rightSectionExpansionType !== 'history' && <LibraryBooksIcon style={{ fontSize: 22 }} />}
          {rightSectionExpansionType === 'history' && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
          {rightSectionExpansionType !== 'history' && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {translate('log', userConfig?.language)}
              </div>
            </div>
          )}
        </div>
      )}

      {shouldShowTeamViewIcons && (
        <div
          className='team-view-circular-button team-view-menu-button'
          onClick={() => handleRightIconClick('menu')}
        >
          {rightSectionExpansionType !== 'menu' && <MenuIcon style={{ fontSize: 22 }} />}
          {rightSectionExpansionType === 'menu' && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
          {rightSectionExpansionType !== 'menu' && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {translate('teamMenu', userConfig?.language)}
              </div>
            </div>
          )}
        </div>
      )}


      {teamOrSquadId === 'archivedTeam' && closeArchivedTeam && (
        <div
          className='team-view-circular-button team-view-close-button'
          onClick={() => closeArchivedTeam()}>
          {<CloseIcon style={{ fontSize: 22 }} />}
        </div>
      )}

      {teamOrSquadId === 'archivedTeam' && deleteOrRestoreArchivedTeam && (
        <div
          className={'team-view-circular-button team-view-delete-or-restore-button' + (isIconHighlighted ? ' team-view-circular-button-highlighted' : '')}
          onClick={() => deleteOrRestoreArchivedTeamInternal()}
        >
          {isArchivedTeamDeleted && <RestoreIcon style={{ fontSize: 22 }} />}
          {!isArchivedTeamDeleted && <DeleteOutlineIcon style={{ fontSize: 22 }} />}
          {!isIconHighlighted && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {translate(isArchivedTeamDeleted ? 'restore' : 'delete', userConfig?.language)}
              </div>
            </div>
          )}
        </div>
      )}

    </div>
  );
};
