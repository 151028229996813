import '../../platform.css';

import { useRecoilValue } from 'recoil';
import { teamsState } from '../../recoil/atoms/teamsState';

import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';


interface SingleTeamProps {
  teamId: string;
}

export const SingleTeam: React.FC<SingleTeamProps> = ({ teamId }) => {

  const teams = useRecoilValue(teamsState) ?? {};

  return (
    <div className='platform-view-section platform-view-section-dark'>

      <LogisticTeamView
        teamOrSquadId={teamId}
        teamOrSquad={teams[teamId]}
        isTabLine={false}
      />

    </div>
  );
};
