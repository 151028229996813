import { forwardRef } from 'react';
import './input.css';

import CloseIcon from '@mui/icons-material/Close';


interface InputFieldProps {
  searchString: string;
  onChangeInputField: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownInputField?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  resetSearchString: () => void;
  defaultInput: string;
  showDefaultInput: boolean;

  height?: number;
  isSquare?: boolean;
  style?: React.CSSProperties;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((
  {
    searchString,
    onChangeInputField,
    onKeyDownInputField,
    resetSearchString,
    defaultInput,
    showDefaultInput,

    height,
    isSquare,
    style
  },
  ref,
) => {

  return (
    <div className='input-field-input-container' style={{ height: height ?? 31 }}>
      <input
        ref={ref}
        style={style}
        className={'input-field-input' + (isSquare ? ' input-field-input-square' : '')}
        name='input-field'
        type='text'
        autoComplete='off'
        spellCheck='false'
        value={searchString}
        placeholder={showDefaultInput ? defaultInput : undefined}
        onChange={(event) => onChangeInputField(event)}
        onKeyDown={(event) => onKeyDownInputField ? onKeyDownInputField(event) : undefined}
      />
      {searchString.length > 0 && (
        <div className='input-field-close-icon' onClick={() => resetSearchString()}>
          <CloseIcon style={{ fontSize: 16 }} />
        </div>
      )}
    </div>
  );
});

InputField.displayName = 'InputField';
