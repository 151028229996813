import { db } from '../../../firebase';

import {
  collection,
  addDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  orderBy,
  where
} from 'firebase/firestore';
import { StringToAnyMap, PlayerId, PlayerReport, PlayerReportEdit } from '../../types';


// Get all reports for all players
export const getAllReports = async (club: string): Promise<PlayerReport[]> => {

  const reportsCollectionRef = collection(db, 'configs', club, 'reports');

  const q = query(reportsCollectionRef, orderBy('date', 'desc'));

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as PlayerReport }));
  }
  catch (error) {
    return [];
  }
};


// Get all reports for a player
export const getReportsForPlayer = async (
  playerId: PlayerId,
  club: string,
  specificReportId?: string
): Promise<{ reports: PlayerReport[], specificReport: PlayerReport | undefined }> => {

  const reportsRef = collection(db, 'configs', club, 'reports');
  const q = query(reportsRef, where('playerId', '==', playerId));

  let specificReport: PlayerReport | undefined = undefined;

  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map(doc => {

    const report = { id: doc.id, ...doc.data() as PlayerReport };

    if (specificReportId && doc.id === specificReportId) {
      specificReport = report;
    }

    return report;
  });

  // todo: could add index in firestore to sort
  docs.sort((a, b) => b.date.localeCompare(a.date));

  return {
    reports: docs,
    specificReport: specificReport
  };
};


// Add a new report and return the document id as the new report id
export const addReport = async (report: PlayerReport, club: string): Promise<string | undefined> => {
  const reportsCollectionRef = collection(db, 'configs', club, 'reports');

  try {
    const docRef = await addDoc(reportsCollectionRef, report);
    return docRef.id;
  }
  catch (error) {
    return undefined;
  }
};


// Update a report
export const updateReportIsDeletedStatus = async (reportId: string, isDeleted: boolean, club: string): Promise<boolean> => {

  const reportDocRef = doc(db, 'configs', club, 'reports', reportId);

  try {
    await updateDoc(reportDocRef, {
      isDeleted: isDeleted
    });
    return true;
  }
  catch (error) {
    return false;
  }
};


// Update a report with fileNames and fileUrls
export const updateReportFileNamesAndFileUrls = async (reportId: string, fileNames: string[], fileUrls: string[], club: string): Promise<boolean> => {

  const reportDocRef = doc(db, 'configs', club, 'reports', reportId);

  try {
    await updateDoc(reportDocRef, {
      'reportFields.fileNames': fileNames,
      'reportFields.fileUrls': fileUrls
    });
    return true;
  }
  catch (error) {
    return false;
  }
};


// Update a report - this will update the report and replace current reportFields, but the current version will be saved in previousEdits
export const updateReport = async (
  reportId: string,
  newReportFields: StringToAnyMap,
  newPreviousEdits: PlayerReportEdit[],
  club: string
): Promise<boolean> => {

  const reportDocRef = doc(db, 'configs', club, 'reports', reportId);

  try {
    await updateDoc(reportDocRef, {
      reportFields: newReportFields,
      previousEdits: newPreviousEdits,
    });
    return true;
  }
  catch (error) {
    return false;
  }
};


// Update player of report
export const updatePlayerOfReport = async (reportId: string, newPlayerId: PlayerId, newPlayerFullname: string, club: string) => {

  const reportDocRef = doc(db, 'configs', club, 'reports', reportId);

  try {
    await updateDoc(reportDocRef, {
      playerId: newPlayerId,
      playerName: newPlayerFullname
    });
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
};
